import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    limit,
    query,
    setDoc,
    where,
  } from "firebase/firestore";
  import * as DBConstants from "../constants/DBConstants";
  import { db } from "../config/FirebaseConfig";
  import { updateAudit } from "../utils/utils";
import { saveOrder } from "./OrderService";
import { findPerson } from "./PersonService";
  
  // Get a cart with their ID
  export const findCartByUserId = async (uid) => {
    const q = query(
      collection(db, DBConstants.DB_CARTS),
      where("uid", "==", uid),
      limit(1)
    );
    const snapshot = await getDocs(q);
    let cart = null;
    if (!snapshot.empty) {
      cart = snapshot.docs[0];
    }
    return cart ? cart.data() : null;
  };
  
  // Find a cart with id
  export const findCart = async (id) => {
    const docRef = doc(db, DBConstants.DB_CARTS, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
  };
  
  // Save a Cart
  export const saveCart = async (uid, obj) => {
   //console.log ('Save cart called: ', uid, ' obj: ', obj)
  
    let docRef = null;
    let cart = null;
  
    updateAudit(uid, obj);
  
    if (obj && obj.id) {
      docRef = doc(db, DBConstants.DB_CARTS, obj.id);
      await setDoc(docRef, obj, { merge: true });
    } else {
      // Save the new doc
      docRef = doc(collection(db, DBConstants.DB_CARTS));
      obj.id = docRef.id;
      await setDoc(docRef, obj, { merge: true });
    }
    cart = await findCart(obj.id);
    return cart;
  };
  
  export const deleteCart = async (uid) => {
    const ref = doc(db, DBConstants.DB_CARTS, uid);
    await deleteDoc(ref);
  };
  
  // Add item to cart
export const addItemToCart = async (uid, item) => {
    // Check if the user has a cart, create one if not
    let cart = await findCartByUserId(uid);
    console.log ('Cart: ', cart )
    if (!cart) {
        cart = await saveCart(uid, {
          id: uid,
          uid: uid,
          items: [],
      });
      console.log ('Saved cart: ', cart)
    }

    cart.items.push(item);
    return await saveCart(uid, cart);
}

// Remove item from cart
export const removeItemFromCart = async (uid, productId) => {
    let cart = await findCartByUserId(uid);
    cart.items = cart.items.filter(i => i.productId !== productId);
    return await saveCart(uid, cart);
}

// Update item in cart
export const updateItemInCart = async (uid, item) => {
    let cart = await findCartByUserId(uid);
    cart.items = cart.items.map(i => i.productId === item.productId ? item : i);
    return await saveCart(uid, cart);
}

// Clear the Cart 
export const clearCart = async (uid) => {
    let cart = await findCartByUserId(uid);
    cart.items = [];
    return await saveCart(uid, cart);
}

// Place the order. Convert the items in the cart to items in the order.
export const placeOrder = async (uid) => {

  // Find the person
  const p = await findPerson(uid);

    let cart = await findCartByUserId(uid);
    let order = null;
    if (cart) {
        order = await saveOrder(uid, {
            tenant: {
                id: p?.tenant?.id,
                name: p?.tenant?.name,
            },
            person: {
                id: p?.id,
                firstName: p?.firstName,
                lastName: p?.lastName,
            },
            items: cart.items,
        });
        await clearCart(uid);
    }
    return order;
}