export const Roles = {
    Common: [
        {
            name: "Manager",
            description: "Oversees and leads teams, projects, and operations.",
        },
        {
            name: "Coordinator",
            description: "Organizes and manages tasks, schedules, and activities.",
        },
        {
            name: "Analyst",
            description: "Analyzes data, information, and processes to provide insights.",
        },
        {
            name: "Specialist",
            description: "Has expertise in a specific field or area of work.",
        },
        {
            name: "Administrator",
            description: "Manages administrative tasks, systems, and processes.",
        },
        {
            name: "Technician",
            description: "Performs technical tasks, maintenance, and troubleshooting.",
        },
        {
            name: "Consultant",
            description: "Provides expert advice, guidance, and solutions.",
        },
        {
            name: "Engineer",
            description: "Designs, develops, and maintains systems and solutions.",
        },
        {
            name: "Supervisor",
            description: "Oversees and guides a team's daily activities.",
        },
        {
            name: "Coordinator",
            description: "Organizes and manages tasks, schedules, and activities.",
        },
        {
            name: "Project Manager",
            description: "Plans, executes, and monitors projects from start to finish.",
        },
        {
            name: "Administrator",
            description: "Manages administrative tasks, systems, and processes.",
        },
        {
            name: "Analyst",
            description: "Analyzes data, information, and processes to provide insights.",
        },
        {
            name: "Specialist",
            description: "Has expertise in a specific field or area of work.",
        },
        {
            name: "Coordinator",
            description: "Organizes and manages tasks, schedules, and activities.",
        },
        {
            name: "Technician",
            description: "Performs technical tasks, maintenance, and troubleshooting.",
        },
        {
            name: "Consultant",
            description: "Provides expert advice, guidance, and solutions.",
        },
        {
            name: "Engineer",
            description: "Designs, develops, and maintains systems and solutions.",
        },
        {
            name: "Supervisor",
            description: "Oversees and guides a team's daily activities.",
        },
        {
            name: "Coordinator",
            description: "Organizes and manages tasks, schedules, and activities.",
        },
    ],
    FinancialServices: [
        {
            name: "Investment Banker",
            description: "Provides financial advisory services and manages investment portfolios.",
        },
        {
            name: "Financial Analyst",
            description: "Analyzes financial data and provides insights for decision-making.",
        },
        {
            name: "Asset Manager",
            description: "Manages investment portfolios and assets for clients.",
        },
        {
            name: "Risk Manager",
            description: "Identifies and manages financial risks within an organization.",
        },
        {
            name: "Credit Analyst",
            description: "Assesses creditworthiness and analyzes loan applications.",
        },
        {
            name: "Financial Planner",
            description: "Creates financial plans and strategies for clients' goals.",
        },
        {
            name: "Accountant",
            description: "Records and analyzes financial transactions and statements.",
        },
        {
            name: "Hedge Fund Manager",
            description: "Manages investments in hedge funds for high-net-worth clients.",
        },
        {
            name: "Compliance Officer",
            description: "Ensures adherence to financial regulations and industry standards.",
        },
        {
            name: "Financial Controller",
            description: "Manages financial reporting, budgeting, and accounting operations.",
        },
        {
            name: "Private Equity Analyst",
            description: "Analyzes potential investments for private equity firms.",
        },
        {
            name: "Wealth Manager",
            description: "Provides comprehensive financial planning and wealth management.",
        },
        {
            name: "Mortgage Broker",
            description: "Assists clients in securing mortgage loans for property purchase.",
        },
        {
            name: "Derivatives Trader",
            description: "Trades financial derivatives for profit.",
        },
        {
            name: "Quantitative Analyst",
            description: "Applies mathematical and statistical models to financial data.",
        },
        {
            name: "Financial Consultant",
            description: "Provides financial advice and solutions to individuals and businesses.",
        },
        {
            name: "Tax Consultant",
            description: "Provides expert advice on tax planning and optimization.",
        },
        {
            name: "Corporate Treasurer",
            description: "Manages an organization's financial assets and liquidity.",
        },
        {
            name: "Investment Analyst",
            description: "Evaluates and recommends investment opportunities for clients.",
        },
        {
            name: "Financial Reporting Analyst",
            description: "Prepares and analyzes financial reports for regulatory compliance.",
        },
        {
            name: "Financial Investigator",
            description: "Conducts financial fraud investigations and analysis.",
        },
        {
            name: "Equity Research Analyst",
            description: "Analyzes companies and industries to provide investment insights.",
        },
        {
            name: "Pension Fund Manager",
            description: "Manages pension funds and investments for retirement.",
        },
        {
            name: "Portfolio Manager",
            description: "Manages investment portfolios and makes investment decisions.",
        },
        {
            name: "Financial Risk Analyst",
            description: "Identifies and assesses potential financial risks in investments.",
        },
        {
            name: "Bank Branch Manager",
            description: "Oversees the operations and staff of a bank branch.",
        },
        {
            name: "Insurance Underwriter",
            description: "Evaluates and determines insurance coverage and premiums.",
        },
        {
            name: "Venture Capital Analyst",
            description: "Assesses investment opportunities for venture capital firms.",
        },
        {
            name: "Financial Software Developer",
            description: "Develops software and tools for financial analysis and trading.",
        },
        {
            name: "Investor Relations Manager",
            description: "Manages communication between a company and its investors.",
        },
        {
            name: "Real Estate Appraiser",
            description: "Evaluates property values for real estate transactions.",
        },
        {
            name: "Mergers and Acquisitions Specialist",
            description: "Coordinates and manages mergers and acquisitions processes.",
        },
        {
            name: "Financial Educator",
            description: "Educates individuals about financial literacy and money management.",
        },
        {
            name: "Securities Analyst",
            description: "Analyzes financial securities and provides investment recommendations.",
        },
        {
            name: "Credit Risk Analyst",
            description: "Assesses and manages credit risks in lending and investments.",
        },
        {
            name: "Investment Banking Associate",
            description: "Assists senior investment bankers in financial transactions.",
        },
        {
            name: "Financial Examiner",
            description: "Reviews and examines financial records for compliance.",
        },
        {
            name: "Portfolio Analyst",
            description: "Analyzes and evaluates investment portfolios' performance.",
        },
        {
            name: "Financial Writer",
            description: "Creates content about financial topics for publications.",
        },
        {
            name: "Corporate Finance Manager",
            description: "Manages financial planning and analysis for corporations.",
        },
        {
            name: "Investment Advisor",
            description: "Provides personalized investment advice to clients.",
        },
        {
            name: "Commodities Trader",
            description: "Trades commodities such as oil, metals, and agricultural products.",
        },
        {
            name: "Financial Auditor",
            description: "Conducts financial audits to ensure accuracy and compliance.",
        },
        {
            name: "Retirement Planner",
            description: "Assists clients in planning for their retirement needs.",
        },
        {
            name: "Financial Operations Manager",
            description: "Manages financial operations and processes within an organization.",
        },
        {
            name: "Equity Trader",
            description: "Executes trades in stocks and equities on financial markets.",
        },
        {
            name: "Treasury Analyst",
            description: "Analyzes and manages an organization's treasury operations.",
        },
        {
            name: "Investment Operations Analyst",
            description: "Supports investment operations and processes.",
        },
        {
            name: "Financial Compliance Officer",
            description: "Ensures compliance with financial regulations and standards.",
        },
        {
            name: "Financial Client Relationship Manager",
            description: "Manages relationships with clients and provides financial advice.",
        },
    ],
    "Healthcare and Life Sciences": [
        {
            name: "Physician",
            description: "Diagnoses and treats medical conditions in patients.",
        },
        {
            name: "Nurse",
            description: "Provides direct patient care, assists physicians, and administers medications.",
        },
        {
            name: "Pharmacist",
            description: "Dispenses medications and provides medication-related advice.",
        },
        {
            name: "Medical Laboratory Technician",
            description: "Performs lab tests and analyzes patient samples.",
        },
        {
            name: "Radiologic Technologist",
            description: "Operates imaging equipment to produce medical images.",
        },
        {
            name: "Medical Research Scientist",
            description: "Conducts research to advance medical knowledge and treatments.",
        },
        {
            name: "Physical Therapist",
            description: "Provides rehabilitation and physical therapy to patients.",
        },
        {
            name: "Healthcare Administrator",
            description: "Manages healthcare facilities, operations, and staff.",
        },
        {
            name: "Clinical Nurse Specialist",
            description: "Provides specialized care and expertise in a specific field.",
        },
        {
            name: "Medical Coding Specialist",
            description: "Assigns codes to medical procedures and diagnoses for billing.",
        },
        {
            name: "Medical Illustrator",
            description: "Creates visual representations of medical information.",
        },
        {
            name: "Genetic Counselor",
            description: "Provides information and support about genetic disorders.",
        },
        {
            name: "Dietitian",
            description: "Provides nutritional advice and plans for patients.",
        },
        {
            name: "Medical Writer",
            description: "Writes medical content for publications, websites, and documentation.",
        },
        {
            name: "Phlebotomist",
            description: "Collects blood samples from patients for testing.",
        },
        {
            name: "Occupational Therapist",
            description: "Assists patients in regaining skills for daily living and work.",
        },
        {
            name: "Biomedical Engineer",
            description: "Designs and develops medical equipment and devices.",
        },
        {
            name: "Medical Transcriptionist",
            description: "Converts voice recordings into written medical reports.",
        },
        {
            name: "Clinical Psychologist",
            description: "Provides therapy and psychological support to patients.",
        },
        {
            name: "Medical Sales Representative",
            description: "Promotes and sells medical products and equipment.",
        },
        {
            name: "Emergency Medical Technician (EMT)",
            description: "Provides emergency medical care and transportation.",
        },
        {
            name: "Medical Office Manager",
            description: "Oversees administrative operations in medical offices.",
        },
        {
            name: "Speech Language Pathologist",
            description: "Assesses and treats communication and speech disorders.",
        },
        {
            name: "Health Informatics Specialist",
            description: "Manages healthcare data and information systems.",
        },
        {
            name: "Clinical Research Coordinator",
            description: "Coordinates and manages clinical research studies.",
        },
        {
            name: "Medical Librarian",
            description: "Manages medical literature and provides research support.",
        },
        {
            name: "Anesthesiologist",
            description: "Administers anesthesia and monitors patients during surgery.",
        },
        {
            name: "Neurologist",
            description: "Diagnoses and treats disorders of the nervous system.",
        },
        {
            name: "Pharmaceutical Research Scientist",
            description: "Conducts research to develop new drugs and medications.",
        },
        {
            name: "Radiation Therapist",
            description: "Administers radiation treatments to cancer patients.",
        },
        {
            name: "Healthcare IT Specialist",
            description: "Manages and supports healthcare information technology systems.",
        },
        {
            name: "Clinical Social Worker",
            description: "Provides counseling and support to patients and families.",
        },
        {
            name: "Medical Interpreter",
            description: "Interprets languages for patients and healthcare providers.",
        },
        {
            name: "Medical Supply Technician",
            description: "Manages and distributes medical supplies and equipment.",
        },
        {
            name: "Medical Equipment Technician",
            description: "Installs, maintains, and repairs medical equipment.",
        },
        {
            name: "Nurse Practitioner",
            description: "Provides advanced nursing care, diagnoses, and treatment.",
        },
        {
            name: "Clinical Laboratory Technologist",
            description: "Performs complex medical laboratory tests.",
        },
        {
            name: "Healthcare Quality Manager",
            description: "Manages and improves healthcare service quality.",
        },
        {
            name: "Home Health Aide",
            description: "Assists patients with daily activities at their homes.",
        },
        {
            name: "Medical Billing Specialist",
            description: "Processes medical bills and insurance claims.",
        },
        {
            name: "Clinical Dietitian",
            description: "Assesses and plans diets for patients with medical conditions.",
        },
        {
            name: "Medical Records Technician",
            description: "Maintains and organizes patient medical records.",
        },
        {
            name: "Geriatrician",
            description: "Specializes in medical care for elderly patients.",
        },
        {
            name: "Veterinarian",
            description: "Diagnoses and treats medical conditions in animals.",
        },
        {
            name: "Neonatal Nurse",
            description: "Provides specialized care for newborn infants.",
        },
        {
            name: "Clinical Geneticist",
            description: "Diagnoses and treats genetic disorders and conditions.",
        },
        {
            name: "Medical Sales Manager",
            description: "Manages sales teams and strategies for medical products.",
        },
        {
            name: "Public Health Analyst",
            description: "Analyzes and monitors public health trends and data.",
        },
        {
            name: "Bioinformatics Specialist",
            description: "Analyzes and interprets biological data using computational methods.",
        },
        {
            name: "Healthcare Consultant",
            description: "Provides expert advice and consulting for healthcare organizations.",
        },
    ],
    Technology: [
        {
            name: "Software Engineer",
            description: "Designs and develops software applications and systems.",
        },
        {
            name: "Data Scientist",
            description: "Analyzes and interprets complex data to extract insights.",
        },
        {
            name: "Network Engineer",
            description: "Designs, implements, and maintains computer networks.",
        },
        {
            name: "UI/UX Designer",
            description: "Creates user interfaces and experiences for digital products.",
        },
        {
            name: "System Administrator",
            description: "Manages and maintains IT infrastructure and systems.",
        },
        {
            name: "Cybersecurity Analyst",
            description: "Protects computer systems and networks from security threats.",
        },
        {
            name: "IT Project Manager",
            description: "Manages and oversees IT projects from initiation to completion.",
        },
        {
            name: "Cloud Architect",
            description: "Designs and manages cloud infrastructure and solutions.",
        },
        {
            name: "DevOps Engineer",
            description: "Combines software development and IT operations for efficient systems.",
        },
        {
            name: "Database Administrator",
            description: "Manages and maintains databases, ensuring data integrity.",
        },
        {
            name: "Artificial Intelligence Engineer",
            description: "Develops AI algorithms and systems for automation and learning.",
        },
        {
            name: "Machine Learning Engineer",
            description: "Designs and deploys machine learning models and systems.",
        },
        {
            name: "Web Developer",
            description: "Builds and maintains websites and web applications.",
        },
        {
            name: "IT Support Specialist",
            description: "Provides technical support and troubleshooting to users.",
        },
        {
            name: "Business Analyst",
            description: "Analyzes business needs and designs IT solutions.",
        },
        {
            name: "QA Engineer",
            description: "Tests software and systems to ensure quality and functionality.",
        },
        {
            name: "Big Data Analyst",
            description: "Processes and analyzes large datasets to derive insights.",
        },
        {
            name: "IT Consultant",
            description: "Advises clients on IT strategies and solutions.",
        },
        {
            name: "Blockchain Developer",
            description: "Builds applications using blockchain technology.",
        },
        {
            name: "Network Security Engineer",
            description: "Secures network systems and prevents unauthorized access.",
        },
        {
            name: "Data Engineer",
            description: "Designs and develops data pipelines and storage solutions.",
        },
        {
            name: "IT Trainer",
            description: "Provides training on software, tools, and IT processes.",
        },
        {
            name: "Front-End Developer",
            description: "Develops and optimizes user interfaces for web applications.",
        },
        {
            name: "IT Security Analyst",
            description: "Monitors and protects IT systems from security threats.",
        },
        {
            name: "Product Manager",
            description: "Manages the development and launch of IT products and solutions.",
        },
        {
            name: "IT Operations Manager",
            description: "Oversees IT operations and ensures efficient systems.",
        },
        {
            name: "AI Ethics Specialist",
            description: "Addresses ethical considerations in AI development and deployment.",
        },
        {
            name: "Software Architect",
            description: "Designs high-level software structures and systems.",
        },
        {
            name: "Network Administrator",
            description: "Manages and maintains computer networks and communication systems.",
        },
        {
            name: "IT Business Analyst",
            description: "Analyzes business needs and aligns them with IT solutions.",
        },
        {
            name: "UX Researcher",
            description: "Conducts user research to inform UX design decisions.",
        },
        {
            name: "IT Auditor",
            description: "Assesses and ensures IT systems compliance and security.",
        },
        {
            name: "Mobile App Developer",
            description: "Creates applications for mobile devices and platforms.",
        },
        {
            name: "IT Solution Architect",
            description: "Designs comprehensive IT solutions to meet business needs.",
        },
        {
            name: "AI Product Manager",
            description: "Manages the development and strategy of AI-powered products.",
        },
        {
            name: "Full Stack Developer",
            description: "Develops both front-end and back-end components of applications.",
        },
        {
            name: "IT Compliance Specialist",
            description: "Ensures IT systems comply with industry regulations.",
        },
        {
            name: "Data Privacy Analyst",
            description: "Manages and ensures data privacy and protection.",
        },
        {
            name: "IT Operations Analyst",
            description: "Analyzes and improves IT operations and processes.",
        },
    ],
    "Government and Public Administration": [
        {
            name: "Governor",
            description: "The elected leader of a state or territory.",
        },
        {
            name: "Mayor",
            description: "The elected leader of a city or municipality.",
        },
        {
            name: "Legislator",
            description: "Creates and passes laws in a legislative body.",
        },
        {
            name: "Judge",
            description: "Presides over legal proceedings and makes decisions based on the law.",
        },
        {
            name: "Police Officer",
            description: "Enforces laws, maintains order, and protects the public.",
        },
        {
            name: "Firefighter",
            description: "Responds to fires, emergencies, and other incidents to save lives and property.",
        },
        {
            name: "Public Health Official",
            description: "Oversees health programs, disease prevention, and public safety.",
        },
        {
            name: "Social Worker",
            description: "Provides support and services to individuals and families in need.",
        },
        {
            name: "City Planner",
            description: "Develops and manages urban development and land use policies.",
        },
        {
            name: "Budget Analyst",
            description: "Evaluates financial data and prepares budgets for government entities.",
        },
        {
            name: "Human Resources Specialist",
            description: "Manages personnel matters and policies for government agencies.",
        },
        {
            name: "Public Relations Officer",
            description: "Handles communication and media relations for government organizations.",
        },
        {
            name: "Environmental Specialist",
            description: "Monitors and manages environmental issues and regulations.",
        },
        {
            name: "Transportation Planner",
            description: "Develops and improves transportation infrastructure and systems.",
        },
        {
            name: "Public Safety Director",
            description: "Oversees public safety agencies, such as police and fire departments.",
        },
        {
            name: "Economic Development Manager",
            description: "Promotes economic growth and development in the local community.",
        },
        {
            name: "City Clerk",
            description: "Manages official records, documents, and municipal proceedings.",
        },
        {
            name: "Community Outreach Coordinator",
            description: "Engages with the community and coordinates outreach programs.",
        },
        {
            name: "Education Administrator",
            description: "Manages education programs and policies within public institutions.",
        },
        {
            name: "Government Auditor",
            description: "Reviews financial records and operations of government entities.",
        },
        {
            name: "Emergency Management Coordinator",
            description: "Plans and responds to emergencies and disasters in the community.",
        },
        {
            name: "Housing Specialist",
            description: "Addresses housing needs and policies in the community.",
        },
        {
            name: "Criminal Investigator",
            description: "Conducts investigations into criminal activities and enforces the law.",
        },
        {
            name: "Social Services Director",
            description: "Oversees social services programs and support for vulnerable populations.",
        },
        {
            name: "Urban Planner",
            description: "Designs land use and urban development plans for sustainable growth.",
        },
        {
            name: "Health Inspector",
            description: "Inspects and enforces health and safety regulations in public spaces.",
        },
        {
            name: "Tax Assessor",
            description: "Evaluates property values for taxation purposes.",
        },
        {
            name: "Community Development Coordinator",
            description: "Leads community development initiatives and projects.",
        },
        {
            name: "Policy Analyst",
            description: "Analyzes policies and recommends changes for government improvement.",
        },
        {
            name: "Labor Relations Specialist",
            description: "Manages labor relations and negotiations for government agencies.",
        },
        {
            name: "City Attorney",
            description: "Provides legal counsel to the city or municipal government.",
        },
        {
            name: "Emergency Medical Technician (EMT)",
            description: "Provides emergency medical care and transportation to those in need.",
        },
        {
            name: "Public Defender",
            description: "Represents individuals who cannot afford legal representation.",
        },
        {
            name: "Social Media Coordinator",
            description: "Manages social media presence and engagement for government agencies.",
        },
        {
            name: "Public Works Director",
            description: "Oversees public infrastructure and maintenance projects.",
        },
        {
            name: "Grant Writer",
            description: "Writes proposals to secure funding for government programs and projects.",
        },
        {
            name: "City Manager",
            description: "Administers and manages the operations of the city government.",
        },
        {
            name: "Urban Development Planner",
            description: "Plans and manages urban development projects and initiatives.",
        },
        {
            name: "Education Policy Analyst",
            description: "Analyzes and proposes education policies for government institutions.",
        },
        {
            name: "Public Affairs Specialist",
            description: "Manages public relations and communication for government agencies.",
        },
        {
            name: "City Engineer",
            description: "Oversees engineering projects and infrastructure development.",
        },
        {
            name: "Government Relations Manager",
            description: "Manages relationships and interactions with government entities.",
        },
        {
            name: "Equal Opportunity Specialist",
            description: "Promotes diversity and ensures equal opportunities in government programs.",
        },
        {
            name: "Public Policy Advisor",
            description: "Provides guidance and recommendations on public policy matters.",
        },
        {
            name: "Community Liaison",
            description: "Builds connections between government agencies and the community.",
        },
        {
            name: "City Planner",
            description: "Designs and manages urban development and land use policies.",
        },
        {
            name: "Emergency Preparedness Coordinator",
            description: "Plans and coordinates preparedness efforts for emergencies.",
        },
        {
            name: "Public Outreach Coordinator",
            description: "Leads public engagement and outreach initiatives.",
        },
        {
            name: "Public Administrator",
            description: "Manages administrative functions of government agencies.",
        },
        {
            name: "City Treasurer",
            description: "Manages city funds, investments, and financial matters.",
        },
    ],
    Retail: [
        {
            name: "Store Manager",
            description: "Oversees store operations and customer service.",
        },
        {
            name: "Visual Merchandiser",
            description: "Creates attractive store displays to enhance customer experience.",
        },
        {
            name: "Inventory Analyst",
            description: "Manages inventory levels to optimize supply chain efficiency.",
        },
        {
            name: "Retail Buyer",
            description: "Sources and selects products for the store's inventory.",
        },
        {
            name: "Customer Service Representative",
            description: "Assists customers with inquiries, purchases, and returns.",
        },
        {
            name: "E-Commerce Manager",
            description: "Manages online sales and digital presence for the business.",
        },
        {
            name: "Visual Designer",
            description: "Creates visual assets and branding materials for the store.",
        },
        {
            name: "Supply Chain Coordinator",
            description: "Coordinates logistics and distribution to ensure timely product delivery.",
        },
        {
            name: "Retail Sales Associate",
            description: "Assists customers, provides product information, and promotes sales.",
        },
        {
            name: "Marketing Specialist",
            description: "Develops and implements marketing strategies to attract customers.",
        },
        {
            name: "Loss Prevention Officer",
            description: "Prevents theft and maintains security in retail environments.",
        },
        {
            name: "Merchandise Planner",
            description: "Plans and forecasts product demand to optimize inventory.",
        },
        {
            name: "Retail Operations Manager",
            description: "Manages overall operations and efficiency of retail stores.",
        },
        {
            name: "Fashion Buyer",
            description: "Selects and purchases fashion products for retail stores.",
        },
        {
            name: "Online Marketplace Manager",
            description: "Manages presence and sales on online marketplaces.",
        },
        {
            name: "Customer Experience Manager",
            description: "Enhances customer satisfaction and loyalty through experiences.",
        },
        {
            name: "Retail Analyst",
            description: "Analyzes sales data and trends to provide insights.",
        },
        {
            name: "Store Visual Coordinator",
            description: "Ensures consistent visual merchandising across stores.",
        },
        {
            name: "Retail Trainer",
            description: "Trains store staff on product knowledge and customer service.",
        },
        {
            name: "Category Manager",
            description: "Manages product categories and maximizes profitability.",
        },
    ],
    Manufacturing: [
        {
            name: "Production Supervisor",
            description: "Manages and oversees manufacturing operations on the shop floor.",
        },
        {
            name: "Quality Control Inspector",
            description: "Inspects products to ensure they meet quality standards.",
        },
        {
            name: "Process Engineer",
            description: "Optimizes manufacturing processes for efficiency and quality.",
        },
        {
            name: "Maintenance Technician",
            description: "Performs equipment maintenance to prevent breakdowns.",
        },
        {
            name: "Manufacturing Engineer",
            description: "Designs and develops manufacturing processes and systems.",
        },
        {
            name: "Supply Chain Manager",
            description: "Manages the end-to-end supply chain for materials and products.",
        },
        {
            name: "Safety Coordinator",
            description: "Promotes and enforces safety practices in the manufacturing environment.",
        },
        {
            name: "Industrial Designer",
            description: "Creates and designs industrial products and components.",
        },
        {
            name: "Logistics Specialist",
            description: "Coordinates transportation and distribution of goods.",
        },
        {
            name: "Manufacturing Technician",
            description: "Operates and maintains machinery on the production line.",
        },
        {
            name: "Materials Planner",
            description: "Plans and manages inventory of raw materials and components.",
        },
        {
            name: "Quality Assurance Engineer",
            description: "Develops and implements quality assurance processes.",
        },
        {
            name: "Process Technician",
            description: "Monitors and troubleshoots manufacturing processes.",
        },
        {
            name: "Lean Manufacturing Specialist",
            description: "Applies lean principles to optimize production efficiency.",
        },
        {
            name: "Plant Manager",
            description: "Oversees overall operations and production in a manufacturing plant.",
        },
        {
            name: "Automation Engineer",
            description: "Designs and implements automated manufacturing systems.",
        },
        {
            name: "Production Planner",
            description: "Plans production schedules and resources for optimal output.",
        },
        {
            name: "Industrial Electrician",
            description: "Installs and maintains electrical systems in industrial settings.",
        },
        {
            name: "Supply Chain Analyst",
            description: "Analyzes supply chain data to improve efficiency and cost-effectiveness.",
        },
        {
            name: "Manufacturing Manager",
            description: "Manages day-to-day manufacturing operations and teams.",
        },
    ],
    "Media and Entertainment": [
        {
            name: "Film Director",
            description: "Leads the creative direction and production of films.",
        },
        {
            name: "Journalist",
            description: "Researches, writes, and reports news and stories.",
        },
        {
            name: "Content Creator",
            description: "Produces digital content for online platforms.",
        },
        {
            name: "Art Director",
            description: "Oversees visual aesthetics and artistic elements of media projects.",
        },
        {
            name: "Musician",
            description: "Creates and performs music for various media projects.",
        },
        {
            name: "Video Editor",
            description: "Edits and assembles video footage for productions.",
        },
        {
            name: "Radio Host",
            description: "Hosts radio programs, conducts interviews, and engages listeners.",
        },
        {
            name: "Cinematographer",
            description: "Captures and frames visuals in film and video productions.",
        },
        {
            name: "Digital Marketing Specialist",
            description: "Promotes media content through digital marketing strategies.",
        },
        {
            name: "Scriptwriter",
            description: "Writes scripts and screenplays for media projects.",
        },
        {
            name: "Social Media Manager",
            description: "Manages and curates social media presence and engagement.",
        },
        {
            name: "Public Relations Manager",
            description: "Handles media relations and communication with the public.",
        },
        {
            name: "Graphic Designer",
            description: "Creates visual designs for various media materials.",
        },
        {
            name: "Voice Actor",
            description: "Provides voices for characters in animation, films, and games.",
        },
        {
            name: "Entertainment Lawyer",
            description: "Provides legal counsel and advice for media and entertainment matters.",
        },
        {
            name: "Sound Engineer",
            description: "Manages sound and audio production for media projects.",
        },
        {
            name: "Film Producer",
            description: "Manages production logistics and resources for film projects.",
        },
        {
            name: "Entertainment Reporter",
            description: "Covers entertainment news and events for media outlets.",
        },
        {
            name: "Animator",
            description: "Creates animated visuals and graphics for media content.",
        },
        {
            name: "Talent Agent",
            description: "Represents and manages artists and performers in the industry.",
        },
    ],
    Energy: [
        {
            name: "Power Plant Operator",
            description: "Operates and maintains power generation equipment.",
        },
        {
            name: "Electrical Engineer",
            description: "Designs and develops electrical systems for power distribution.",
        },
        {
            name: "Environmental Specialist",
            description: "Monitors and manages environmental compliance in energy operations.",
        },
        {
            name: "Renewable Energy Analyst",
            description: "Analyzes and assesses renewable energy opportunities and trends.",
        },
        {
            name: "Utility Technician",
            description: "Maintains and repairs utility infrastructure and equipment.",
        },
        {
            name: "Energy Efficiency Specialist",
            description: "Promotes energy-saving practices and efficiency improvements.",
        },
        {
            name: "Pipeline Engineer",
            description: "Designs and manages pipelines for oil, gas, and other utilities.",
        },
        {
            name: "Environmental Engineer",
            description: "Designs and implements environmental protection measures in energy projects.",
        },
        {
            name: "Grid Operations Manager",
            description: "Oversees the operation and maintenance of electrical grids.",
        },
        {
            name: "Safety Coordinator",
            description: "Ensures safety compliance and practices in energy operations.",
        },
        {
            name: "Power Engineer",
            description: "Designs and operates systems for power generation and distribution.",
        },
        {
            name: "Energy Consultant",
            description: "Provides expertise on energy efficiency and sustainable practices.",
        },
        {
            name: "Transmission Engineer",
            description: "Designs and maintains electrical transmission systems.",
        },
        {
            name: "Instrumentation Technician",
            description: "Installs and maintains instruments and control systems in energy facilities.",
        },
        {
            name: "Nuclear Reactor Operator",
            description: "Operates and monitors nuclear reactors in power plants.",
        },
        {
            name: "Environmental Compliance Specialist",
            description: "Ensures compliance with environmental regulations in energy projects.",
        },
        {
            name: "Wind Turbine Technician",
            description: "Maintains and repairs wind turbines in renewable energy projects.",
        },
        {
            name: "Energy Analyst",
            description: "Analyzes energy consumption data and identifies efficiency opportunities.",
        },
        {
            name: "Gas Technician",
            description: "Maintains and repairs natural gas distribution systems.",
        },
        {
            name: "Hydroelectric Plant Operator",
            description: "Operates and maintains hydroelectric power generation equipment.",
        },
    ],
    Telecommunications: [
        {
            name: "Network Engineer",
            description: "Designs and manages telecommunications networks.",
        },
        {
            name: "Telecom Sales Representative",
            description: "Sells telecommunications products and services to clients.",
        },
        {
            name: "Systems Analyst",
            description: "Analyzes and optimizes IT systems and solutions.",
        },
        {
            name: "Customer Support Specialist",
            description: "Assists customers with technical issues and inquiries.",
        },
        {
            name: "Telecom Project Manager",
            description: "Manages and oversees telecommunications projects.",
        },
        {
            name: "VoIP Engineer",
            description: "Designs and implements Voice over IP (VoIP) solutions.",
        },
        {
            name: "Wireless Network Specialist",
            description: "Specializes in designing and optimizing wireless networks.",
        },
        {
            name: "Telecom Technician",
            description: "Installs and maintains telecommunications equipment.",
        },
        {
            name: "Data Analyst",
            description: "Analyzes and interprets data related to telecom services.",
        },
        {
            name: "Network Security Engineer",
            description: "Secures and protects telecommunications networks from threats.",
        },
        {
            name: "Telecom Billing Analyst",
            description: "Manages and analyzes billing and financial data.",
        },
        {
            name: "Broadband Technician",
            description: "Installs and troubleshoots broadband and internet services.",
        },
        {
            name: "Telecom Operations Manager",
            description: "Oversees day-to-day operations of telecom services.",
        },
        {
            name: "Fiber Optic Technician",
            description: "Installs and maintains fiber optic communication systems.",
        },
        {
            name: "Telecom Regulatory Specialist",
            description: "Ensures compliance with telecommunications regulations.",
        },
        {
            name: "RF Engineer",
            description: "Designs and optimizes radio frequency systems.",
        },
        {
            name: "Technical Support Engineer",
            description: "Provides technical assistance to clients and end-users.",
        },
        {
            name: "Telecom Network Architect",
            description: "Designs high-level architecture for telecom networks.",
        },
        {
            name: "Network Administrator",
            description: "Administers and maintains network infrastructure.",
        },
        {
            name: "Telecom Test Engineer",
            description: "Tests and verifies telecommunications equipment and systems.",
        },
    ],
    Education: [
        {
            name: "Teacher",
            description: "Educates students in various subjects and grade levels.",
        },
        {
            name: "Principal",
            description: "Leads and manages school operations and staff.",
        },
        {
            name: "Guidance Counselor",
            description: "Provides academic and personal guidance to students.",
        },
        {
            name: "Curriculum Developer",
            description: "Designs educational curriculum and lesson plans.",
        },
        {
            name: "Education Coordinator",
            description: "Coordinates and oversees educational programs and initiatives.",
        },
        {
            name: "School Psychologist",
            description: "Assesses and supports students' mental and emotional well-being.",
        },
        {
            name: "Librarian",
            description: "Manages library resources and supports student research.",
        },
        {
            name: "Special Education Teacher",
            description: "Educates students with special needs and adaptations.",
        },
        {
            name: "Educational Technologist",
            description: "Integrates technology into educational practices.",
        },
        {
            name: "Admissions Officer",
            description: "Manages student admissions and enrollment processes.",
        },
        {
            name: "Academic Advisor",
            description: "Provides academic guidance and helps students with course selection.",
        },
        {
            name: "E-Learning Developer",
            description: "Creates online courses and e-learning materials.",
        },
        {
            name: "Education Consultant",
            description: "Provides expert advice and consulting to educational institutions.",
        },
        {
            name: "School Administrator",
            description: "Oversees school operations and manages administrative staff.",
        },
        {
            name: "Researcher",
            description: "Conducts educational research and contributes to academic knowledge.",
        },
        {
            name: "Teacher Assistant",
            description: "Supports teachers in classroom activities and administrative tasks.",
        },
        {
            name: "Education Program Manager",
            description: "Manages and implements educational programs and initiatives.",
        },
        {
            name: "Curriculum Coordinator",
            description: "Coordinates and aligns educational curriculum across grade levels.",
        },
        {
            name: "Online Instructor",
            description: "Teaches courses in virtual or online settings.",
        },
        {
            name: "Education Policy Analyst",
            description: "Analyzes and assesses educational policies and their impact.",
        },
    ],
    Transportation: [
        {
            name: "Pilot",
            description: "Flies and operates aircraft for passenger or cargo transportation.",
        },
        {
            name: "Flight Attendant",
            description: "Provides customer service and ensures safety on flights.",
        },
        {
            name: "Hotel Manager",
            description: "Oversees hotel operations and guest services.",
        },
        {
            name: "Tour Guide",
            description: "Leads and narrates tours for tourists and visitors.",
        },
        {
            name: "Bus Driver",
            description: "Drives buses for public transportation or tours.",
        },
        {
            name: "Cruise Ship Captain",
            description: "Commands and navigates cruise ships on water routes.",
        },
        {
            name: "Event Planner",
            description: "Organizes and coordinates events and conferences.",
        },
        {
            name: "Travel Agent",
            description: "Assists clients with travel plans, bookings, and itineraries.",
        },
        {
            name: "Taxi Driver",
            description: "Provides transportation services using taxis or ride-sharing platforms.",
        },
        {
            name: "Restaurant Manager",
            description: "Manages restaurant operations, staff, and customer experiences.",
        },
        {
            name: "Concierge",
            description: "Assists hotel guests with information, reservations, and services.",
        },
        {
            name: "Limo Driver",
            description: "Drives luxury limousines for special occasions or events.",
        },
        {
            name: "Flight Dispatcher",
            description: "Coordinates flight schedules and plans for safe air travel.",
        },
        {
            name: "Reservations Agent",
            description: "Handles reservations and bookings for transportation and lodging.",
        },
        {
            name: "Valet Parking Attendant",
            description: "Parks and retrieves vehicles for guests at hotels or events.",
        },
        {
            name: "Cabin Crew",
            description: "Assists passengers on commercial flights and ensures safety.",
        },
        {
            name: "Front Desk Agent",
            description: "Manages front desk services and guest check-ins at hotels.",
        },
        {
            name: "Tourism Coordinator",
            description: "Coordinates and plans tourism activities and packages.",
        },
        {
            name: "Limousine Dispatcher",
            description: "Coordinates and schedules limousine services for clients.",
        },
        {
            name: "Catering Manager",
            description: "Manages catering services for events, parties, and functions.",
        },
    ],
};
