import { Box, Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

export default function ProfileEdit({ updateProfile, handleCancel }) {
    const { user, person } = useAuthContext();
    const [state, setState] = useState(person);

    // Change form values
    const handleFormChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <form
            //className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={updateProfile}
        >
            <Box m={0} sx={{ display: "flex", flexDirection: "column" }}>
                <Box m={0} sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
                    <TextField
                        name="firstName"
                        label="First Name"
                        value={state.firstName ?? ""}
                        onChange={handleFormChange}
                        fullWidth
                        
                        variant="standard"
                    />
                    <TextField
                        name="lastName"
                        label="Last Name"
                        value={state.lastName ?? ""}
                        onChange={handleFormChange}
                        fullWidth
                        
                        variant="standard"
                    />
                </Box>
                <Box m={1} sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
                    <TextField
                        name="addressLine1"
                        label="Street Name"
                        value={state.addressLine1 ?? ""}
                        onChange={handleFormChange}
                        fullWidth
                        
                        variant="standard"
                    />
                    <TextField
                        name="addressLine2"
                        label="Suite Number"
                        value={state.addressLine2 ?? ""}
                        onChange={handleFormChange}
                        
                        variant="standard"
                    />
                </Box>
                <Box m={1} sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                    <TextField
                        name="city"
                        label="City"
                        value={state.city ?? ""}
                        onChange={handleFormChange}
                        fullWidth
                        
                        variant="standard"
                    />
                    <TextField name="state" label="State" value={state.state ?? ""} onChange={handleFormChange}  variant="standard" />
                    <TextField
                        name="zipcode"
                        label="Zipcode"
                        value={state.zipcode ?? ""}
                        onChange={handleFormChange}
                        
                        variant="standard"
                    />
                </Box>
                <Box m={1} sx={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                        name="phone"
                        label="Phone Number"
                        value={state.phone ?? ""}
                        onChange={handleFormChange}
                        fullWidth
                        
                        variant="standard"
                    />
                    <TextField
                        name="email"
                        label="Email"
                        value={state.email ?? ""}
                        onChange={handleFormChange}
                        fullWidth
                        
                        variant="standard"
                    />
                </Box>
                <Box
                    m={1}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        gap: 1,
                    }}
                >
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" type="submit">
                        Update Profile
                    </Button>
                </Box>
            </Box>
        </form>
    );
}
