import { Box, Button, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function ProfileView() {
  const classes = useStyles();
  const { user, person } = useAuthContext();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Box m={1} sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <Typography variant="body1" fontWeight={"bold"}>
            First Name
          </Typography>
          <Typography variant="body1" gutterBottom>
            {person.firstName}
          </Typography>
          <Typography variant="body1" fontWeight={"bold"}>
            Last Name
          </Typography>
          <Typography variant="body1" gutterBottom>
          {person.lastName}
          </Typography>
          <Typography variant="body1" fontWeight={"bold"}>
            Address
          </Typography>
          <Typography variant="body1">
          {person.addressLine1}
          </Typography>
          <Typography variant="body1" gutterBottom>
          {person.city} {person.state} {person.zipcode}

          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1}}>
          <Typography variant="body1" fontWeight={"bold"}>
            Email
          </Typography>
          <Typography variant="body1" gutterBottom>
            {person.email} ({user.emailVerified? 'Verified': 'Not Verified'})
          </Typography>
          <Typography variant="body1" fontWeight={"bold"}>
            Phone
          </Typography>
          <Typography variant="body1" gutterBottom>
            {person.phone}
          </Typography>
        </Box>
      </Box>
    </form>
  );
}
