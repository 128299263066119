// Questions for Assessements. Will be used in AI/ML
export const ASSESSMENT_REGION = "In which regions do you primarily operate?"
export const ASSESSMENT_INDUSTRY = "Which industry or industries does your organization operate in?"
export const ASSESSMENT_ORG_SIZE ="What is the size of your organization in terms of employees and contractors?"
export const ASSESSMENT_IAM_TEAM_SIZE ="What is the size of your Identity and Access Management (IAM) team within your organization?"
export const ASSESSMENT_IAM_SOLUTION_REASONS="Why are you seeking an IAM solution?"
export const ASSESSMENT_IAM_CAPABILITIES="What capabilities are fully enabled? "
export const ASSESSMENT_RISK_MANAGEMENT_TEAM="Does your organization have a dedicated Risk Management Team?"
export const ASSESSMENT_EXISTING_IAM_SOLUTION="Do you have an existing IAM solution?"
export const ASSESSMENT_EXISTING_IAM_SOLUTION_DETAILS="If yes, please choose the existing IAM solution(s) and provide details:"
export const ASSESSMENT_PROGRAM_GOVERNANCE="Do you have Program Governance for your security program?"