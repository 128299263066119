import { Reply } from "@mui/icons-material";
import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, Divider } from "@mui/material";
import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { validateContact } from "../../utils/ValidationUtils";
import PersonEditForm from "../../components/PersonEditForm";
import { handleFormChange } from "../../utils/FormUtils";
import { useTheme } from "@mui/material/styles";
import { GridLoader } from "react-spinners";
import { findPerson, savePerson } from "../../services/PersonService";
import { personName, removeEmptyJsonValues } from "../../utils/utils";
import { useAuthContext } from "../../hooks/useAuthContext";
import StatusMessage from "../../components/StatusMessage";
import { createSignUpInvitation } from "../../services/InvitationService";
import { EVENT_INVITE_TO_SIGNUP } from "../../constants/EventTypes";
import { saveRelation } from "../../services/RelationService";
import { ROLE_INTERNAL_USER } from "../../constants/Authorities";
import * as Constants from "../../constants/Constants";

export default function PersonEdit() {
    const navigate = useNavigate();
    const { person: currentUser } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const theme = useTheme();

    // Determine if its a new or existing person based on the personId from the URL
    const { state } = useLocation();
    const personId = state?.personId;

    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "US",
        phone: "",
        email: "",
    });
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
    });

    useEffect(() => {
        async function getPerson() {
            const data = await findPerson(personId);
            setFormData(data);
        }

        if (personId) getPerson();
    }, [personId]);

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        // Validate firstName field
        if (formData.firstName.trim() === "") {
            newErrors.firstName = "First name is required";
            isValid = false;
        } else {
            newErrors.firstName = "";
        }
        if (formData.lastName.trim() === "") {
            newErrors.lastName = "Last name is required";
            isValid = false;
        } else {
            newErrors.lastName = "";
        }

        // Validate Address
        let contactIsValid = validateContact(formData, newErrors);
        if (!contactIsValid) {
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsPending(true);
        setError(null);

        try {
            if (validateForm()) {
                //const p = removeEmptyJsonValues(formData);
                const savedPerson = await savePerson(currentUser.id, formData);

                // Send a notification to the person
                if (savedPerson?.email) {
                    const invite = await createSignUpInvitation(currentUser, savedPerson, EVENT_INVITE_TO_SIGNUP);
                    console.log("Invitation: ", invite);
                    let rel = {
                        from: savedPerson?.id,
                        fromName: personName(savedPerson),
                        fromEntity: Constants.ENTITY_PERSON,
                        to: currentUser?.tenant.id,
                        toName: currentUser?.tenant.name,
                        toEntity: Constants.ENTITY_ORGANIZATION,
                        relation: ROLE_INTERNAL_USER,
                        status: Constants.STATUS_ACTIVE,
                        
                    }
                    rel.invitationRef = invite.id;
                    await saveRelation(currentUser.id, rel);
                }

                setStatus({
                    open: true,
                    type: "success",
                    message: `Updated user details`,
                });
            }

            setError(null);
            setIsPending(false);
            navigate(-1);
        } catch (err) {
            setError(err.message);
            setIsPending(false);
        }
    };

    const handleCancel = (e) => {
        navigate(-1);
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <Card>
                <CardHeader
                    title={personId ? `Edit ${personName(formData)}` : "Add Person"}
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="Add a new person to your directory"
                    action={
                        <Button startIcon={<Reply />} onClick={handleBack} color="info">
                            Back
                        </Button>
                    }
                />
                <Divider />

                <form onSubmit={handleSubmit}>
                    <CardContent>
                        <PersonEditForm formData={formData} errors={errors} handleChange={(e) => handleFormChange(setFormData, e)} />
                    </CardContent>
                    <CardActions
                        //disableSpacing
                        sx={{
                            alignSelf: "stretch",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-start",
                            paddingRight: 4,
                        }}
                    >
                        <Button color="inherit" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" type="submit" disabled={isPending && isPending ? true : false}>
                            {isPending && isPending ? <GridLoader color={theme.palette.primary.light} /> : "Save"}
                        </Button>

                        {error && <Alert severity="error">{error}</Alert>}
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}
