import { Box, Checkbox, Fade, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React from "react";
import { ASSESSMENT_IAM_CAPABILITIES, ASSESSMENT_IAM_SOLUTION_REASONS, ASSESSMENT_PROGRAM_GOVERNANCE } from "./AssessmentQuestions";
import { IAMSolutionCapabilityCodes, IAMSolutionReasonCodes, OTHER_ID, YesNoCodes, getCodelist } from "../../constants/Codelist";
import { checkIdExists, deleteObjectById, getIdName } from "../../utils/utils";

export default function ObjectivesForm({ assessment, updateAssessment }) {
    const handleIAMSolutionReasonChange = (reason) => {
        let newAssessment = { ...assessment };
        // Check if the industry is already selected
        if (!checkIdExists(newAssessment?.iamSolutionReasons, reason.id)) {
            newAssessment.iamSolutionReasons.push(getIdName(reason));
        } else {
            newAssessment.iamSolutionReasons = deleteObjectById(newAssessment?.iamSolutionReasons, reason.id);
        }
        updateAssessment(newAssessment);
    };

    const handleOtherIAMSolutionReason = (event) => {
        // Set the value of iamSolutionReasonsOther to assessment
        let newAssessment = { ...assessment };
        newAssessment.iamSolutionReasonsOther = event.target.value;
        updateAssessment(newAssessment);
    };

    const handleIAMCapabilityChange = (capability) => {
        let newAssessment = { ...assessment };
        // Check if the industry is already selected
        if (!checkIdExists(newAssessment?.iamCapabilities, capability.id)) {
            newAssessment.iamCapabilities.push(getIdName(capability));
        } else {
            newAssessment.iamCapabilities = deleteObjectById(newAssessment?.iamCapabilities, capability.id);
        }
        updateAssessment(newAssessment);
    };

    const handleOtherIAMCapability = (event) => {
        // Set the value of iamCapabilitiesOther to assessment
        let newAssessment = { ...assessment };
        newAssessment.iamCapabilitiesOther = event.target.value;
        updateAssessment(newAssessment);
    };

    const handleProgramGovernanceChange = (code)  => {
        let newAssessment = { ...assessment };
        newAssessment.haveProgramGovernance = getIdName(code);
        updateAssessment(newAssessment);
    };

    return (
        <Box display="flex" flexDirection="column" pl={4}>
            <Typography variant="headline" mb={4}>
                {ASSESSMENT_IAM_SOLUTION_REASONS}
            </Typography>
            <Fade in={true} timeout={1000} unmountOnExit>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                    {getCodelist(IAMSolutionReasonCodes).map((reason) => {
                        return (
                            <Box
                                key={reason.id}
                                width="15%"
                                flexDirection="column"
                                p={1}
                                sx={{ boxShadow: 1, bgcolor: checkIdExists(assessment?.iamSolutionReasons, reason.id) ? "secondary.main" : "#eee" }}
                                textAlign="center"
                                onClick={() => handleIAMSolutionReasonChange(reason)}
                            >
                                <Box sx={{ boxShadow: 0 }} display="flex" justifyContent="center" alignItems="center">
                                    <IconButton sx={{ color: 'black'}}>{reason.image}</IconButton>
                                </Box>

                                <Box textAlign="center">
                                    <Typography variant="subtitle" color={(checkIdExists(assessment?.iamSolutionReasons, reason.id)) ? "white" : "primary.main"} gutterBottom>
                                        {reason.name}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Fade>
            {checkIdExists(assessment?.iamSolutionReasons, OTHER_ID) && (
                <Box display="flex" flexDirection="column" mt={2} width="90%">
                    <TextField
                        label="If Other, please elaborate"
                        value={assessment?.iamSolutionReasonsOther}
                        multiline
                        rows={1}
                        fullWidth
                        variant="outlined"
                        onChange={handleOtherIAMSolutionReason}
                    />
                </Box>
            )}

            <Box pt={8}></Box>
            <Typography variant="headline" mb={4}>
                {ASSESSMENT_IAM_CAPABILITIES}
            </Typography>
            <Fade in={true} timeout={1000} unmountOnExit>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                    {getCodelist(IAMSolutionCapabilityCodes).map((capability) => {
                        return (
                            <Box
                                key={capability.id}
                                width="15%"
                                flexDirection="column"
                                p={1}
                                sx={{ boxShadow: 1, bgcolor: checkIdExists(assessment?.iamCapabilities, capability.id) ? "secondary.main" : "#eee" }}
                                textAlign="center"
                                onClick={() => handleIAMCapabilityChange(capability)}
                            >
                                <Box sx={{ boxShadow: 0 }} display="flex" justifyContent="center" alignItems="center">
                                    <IconButton sx={{ color: 'black'}}>{capability.image}</IconButton>
                                </Box>

                                <Box textAlign="center">
                                    <Typography variant="subtitle" color={(checkIdExists(assessment?.iamCapabilities, capability.id)) ? "white" : "primary.main"} gutterBottom>
                                        {capability.name}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Fade>
            {checkIdExists(assessment?.iamCapabilities, OTHER_ID) && (
                <Box display="flex" flexDirection="column" mt={2} width="90%">
                    <TextField
                        label="If Other, please elaborate"
                        value={assessment?.iamCapabilitiesOther}
                        multiline
                        rows={1}
                        fullWidth
                        variant="outlined"
                        onChange={handleOtherIAMCapability}
                    />
                </Box>
            )}

            <Box pt={8}></Box>
            <Typography variant="headline" mb={4}>
                {ASSESSMENT_PROGRAM_GOVERNANCE}
            </Typography>
            <Fade in={true} timeout={1000} unmountOnExit>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                    {getCodelist(YesNoCodes).map((code) => {
                        return (
                            <Box
                                key={code.id}
                                width="15%"
                                flexDirection="column"
                                p={1}
                                sx={{ boxShadow: 1, bgcolor: (assessment?.haveProgramGovernance.id === code.id) ? "secondary.main" : "#eee" }}
                                textAlign="center"
                                onClick={() => handleProgramGovernanceChange(code)}
                            >
                                <Box sx={{ boxShadow: 0 }} display="flex" justifyContent="center" alignItems="center">
                                    <IconButton sx={{ color: 'black'}}>{code.image}</IconButton>
                                </Box>

                                <Box textAlign="center">
                                    <Typography variant="subtitle" color={(assessment?.haveProgramGovernance.id === code.id) ? "white" : "primary.main"} gutterBottom>
                                        {code.name}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Fade>
        </Box>
    );
}
