import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Delete, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { deleteOrder } from "../../services/OrderService";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { GridLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import StatusMessage from "../../components/StatusMessage";
import { formatDateTime, getAttributeCSV } from "../../utils/utils";
import { useTheme } from "@emotion/react";

export default function OrderTable({ orders, isTab }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [rowModesModel, setRowModesModel] = useState({});
    const [rows, setRows] = useState([]);
    const { user, order } = useAuthContext();
    const [loading, setLoading] = useState(false);

    // Warning states
    const [selectedId, setSelectedId] = useState();
    const [openDelete, setOpenDelete] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    useEffect(() => {
        setRows(orders);
    }, [orders]);

    const columns = [
        {
            field: "createdDate",
            headerName: "Order Date",
            width: "200",
            valueGetter: (params) => {
                return params.row.createdDate ? formatDateTime(params.row.createdDate) : "";
            },
        },
        {
            field: "tenant",
            headerName: "Customer",
            width: "200",
            valueGetter: (params) => {
                return params.row.tenant?.name ?? "";
            },
        },
        {
            field: "orderBy",
            headerName: "Order By",
            width: "200",
            valueGetter: (params) => {
                return `${params.row.person?.firstName} ${params.row.person?.lastName}` ?? "";
            },
        },
        {
            field: "products",
            headerName: "Products",
            width: "200",
            valueGetter: (params) => {
                let products = getAttributeCSV (params.row.items, "productName") ?? "";
                let htmlString = products.split(",").join("<br/>");
                return htmlString;
            },
            renderCell: (params) => {
                return (
                    <div dangerouslySetInnerHTML={{ __html: params.value }} />
                );
            }
        },

        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 200,
            cellClassName: "actions",

            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                return [
                    // <GridActionsCellItem icon={<Edit color="primary" />} label="Edit" onClick={handleEditOrder(id)} />,
                    <GridActionsCellItem icon={<Delete color="warning" />} label="Delete" onClick={() => handleOpenDelete(id)} />,
                ];
            },
        },
    ];

    const handleEditOrder = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        navigate(`/billing/orders/edit/${id}`);
    };

    const handleDeleteOrder = (id) => async () => {
        setRows(rows.filter((row) => row.id !== id));
        await deleteOrder(id);
        handleCloseDelete();
        setStatus({ open: true, type: "success", message: "Deleted Order" });
    };

    const handleSelectionModelChange = (selectedIds) => {
        setSelectedRows(selectedIds);
    };

    // Returns a custom tool bar for the order table
    function OrderTableToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        );
    }

    function NoRowsOverlay() {
        return (
            <Box display="flex" flexDirection="column" justifyContent={"center"} alignItems={"center"}>
                <Typography sx={{ padding: 2 }}>No orders found</Typography>
            </Box>
        );
    }

    const handleOpenDelete = (id) => {
        setSelectedId(id);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setSelectedId(null);
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <div style={{ width: "100%" }}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                checkboxSelection
                disableRowSelectionOnClick
                rowHeight={100}
                //autoHeight
                slots={{
                    toolbar: OrderTableToolbar,
                    noRowsOverlay: NoRowsOverlay,
                }}
                sx={{
                    border: 0,
                    boxShadow: 0,
                    fontSize: "1rem",
                }}
                // Called on checkbox selection
                selectionModel={selectedRows}
                onRowSelectionModelChange={handleSelectionModelChange}
            />

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDelete} onClose={handleCloseDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Delete order ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting a order will remove them from the system. Are you sure?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDelete} color="inherit">
                        No
                    </Button>
                    <Button variant="contained" color="warning" onClick={handleDeleteOrder(selectedId)} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
