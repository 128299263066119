import { Delete, Edit, GppMaybe, PersonAdd, Search } from "@mui/icons-material";
import { Badge, Box, Button, Card, CardContent, CardHeader, Divider, Drawer, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import PersonEdit from "./PersonEdit";
import { BackgroundIcon } from "../../utils/UIStyles";

const useStyles = makeStyles((theme) => ({
    gradientHeader: {
        background: `linear-gradient(90deg, black, ${theme.palette.primary.light})`,
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
    gradientBody: {
        background: `linear-gradient(to right bottom, black, ${theme.palette.primary.light})`,
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
}));

export default function UserCard() {
    const classes = useStyles();
    const [showUserEdit, setShowUserEdit] = useState(false);

    const handleUser = () => {
        console.log("User clicked");
        setShowUserEdit(true);
    };

    return (
        <>
            <Card elevation={1} sx={{ width: "300px" }} className={classes.gradientBody} onClick={handleUser}>
                <CardHeader
                    title="User"
                    titleTypographyProps={{ color: "white", variant: "subtitle" }}
                    className={classes.gradientHeader}
                    action={
                        <Badge badgeContent={1} color="error">
                            <GppMaybe />
                        </Badge>
                    }
                />
                <Divider />
                <CardContent sx={{ position: "relative" }}>
                    <BackgroundIcon component={PersonAdd} bottom={50} />
                    <Box display="flex" flexDirection="column" flexGrow={1} gap={1}>
                        <Typography variant="body" color="white">
                            Peter Parker
                        </Typography>
                        <Typography variant="body" color="white">
                            peter@abc.com
                        </Typography>

                        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={4}>
                            <Box display="flex" flexDirection="row" justifyContent="flex-start">
                                <IconButton sx={{ color: "white" }}>
                                    <Search />
                                </IconButton>
                                <IconButton sx={{ color: "white" }}>
                                    <Edit />
                                </IconButton>
                                <IconButton sx={{ color: "white" }}>
                                    <Delete />
                                </IconButton>
                            </Box>
                            <Button size="small" variant="contained" color="secondary">
                                Add User
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            <Drawer variant="temporary" anchor="right" open={showUserEdit} onClose={() => setShowUserEdit(false)}>
                <Box sx={{ width: "50vw", p: 2 }}>
                    <PersonEdit />
                </Box>
            </Drawer>
        </>
    );
}
