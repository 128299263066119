import { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Fade,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import { GridLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { CheckCircleOutline, MoreVert, ShoppingCart } from "@mui/icons-material";

import { Pricing, Products, getCodelist } from "../../constants/Codelist";

export default function PricingTable() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();

    const [selected, setSelected] = useState(null);

    useEffect(() => {

        // Get the selected option 
        const recommended = getCodelist(Pricing).find((product) => product.recommended === true);
        setSelected(recommended);
        

    }, []);

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <Box display="flex" flexDirection="row" justifyContent="center" gap={4} mt={1} mb={10}>
            {getCodelist(Pricing).map((pkg) => {
                return (
                    <Fade key={pkg.id} in={true} timeout={1000} unmountOnExit>
                        <Card elevation={1} 
                            sx={{ width: "40%", p: 0, 
                                bgcolor: pkg?.id===selected?.id ? "secondary.light" : "#eee",
                                //color: pkg?.id===selected?.id ? "#fff" : "inherit"  
                            }}
                            onClick={() => setSelected(pkg)}
                            >
                            <CardHeader
                                title={pkg.name}
                                titleTypographyProps={{ variant: "headline", color: "primary" }}
                                //subheader={pkg?.recommended ? "Recommended" : ""}
                                sx={{ textAlign: "center" }}
                            />

                            <CardContent sx={{ textAlign: "center", p: 0, mx: 0.5, my: 0 }}>
                                <Typography variant="h3" color="primary" gutterBottom>
                                    {pkg?.pricing?.value}
                                </Typography>
                                <Typography variant="subtitle" gutterBottom>
                                    {pkg?.pricing?.per} /{pkg?.pricing?.frequency}
                                </Typography>
                                <Typography color="text.secondary" gutterBottom>
                                    {pkg?.pricing?.discount}
                                </Typography>
                                <Typography sx={{ my: 2, fontSize: "1.1rem", fontWeight: "bold", color: "grey" }} gutterBottom>
                                    {pkg?.description}
                                </Typography>

                                <List sx={{ bgcolor: "inherit" }}>
                                    {pkg.features.map((feature) => {
                                        return (
                                            <ListItem
                                                key={feature.id}
                                                secondaryAction={
                                                    <Typography variant="body" color="text.secondary">
                                                        {feature?.quantity}
                                                    </Typography>
                                                }
                                            >
                                                <ListItemIcon>
                                                    <CheckCircleOutline variant="contained" color={feature?.special ? "success" : "primary"} />
                                                </ListItemIcon>
                                                <ListItemText primary={feature?.name} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </CardContent>
                        </Card>
                    </Fade>
                );
            })}
        </Box>
    );
}
