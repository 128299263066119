import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc } from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { updateAudit } from "../utils/utils";

// Create a new permission
export const createPermission = async (uid, permission) => {
    const docRef = doc(collection(db, DBConstants.DB_PERMISSIONS));
    permission.id = docRef.id;
    updateAudit(uid, permission);
    await setDoc(docRef, permission, { merge: true });
    return await findPermission(permission.id);
};

// update an existing permission
export const updatePermission = async (uid, permission) => {
    updateAudit(uid, permission);
    const docRef = doc(db, DBConstants.DB_PERMISSIONS, permission.id);
    await setDoc(docRef, { ...permission }, { merge: true });
    return await findPermission(permission.id);
};

// Create a new permission or update an existing one
export const savePermission = async (uid, tenantId, permission) => {
    permission.tenantId = tenantId;
    if (permission.id) {
        return await updatePermission(uid, permission);
    } else {
        return await createPermission(uid, permission);
    }
};

// Delete an permission
export const deletePermission = async (permissionId) => {
    // Delete the permission
    const docRef = doc(db, DBConstants.DB_PERMISSIONS, permissionId);
    await deleteDoc(docRef);
};

// Find an permission with id
export const findPermission = async (id) => {
    const docRef = doc(db, DBConstants.DB_PERMISSIONS, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
};

// Find all permissions for a tenant
export const findAllPermissions = async (tenantId) => {
    let results = [];
    const q = query(
        collection(db, DBConstants.DB_PERMISSIONS)
        //where("tenantId", "==", tenantId)
    );
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

