import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { removeEmptyJsonValues, updateAudit } from "../utils/utils";

// Get a profile with their ID
export const findProfileByUserId = async (uid) => {
  const q = query(
    collection(db, DBConstants.DB_PROFILES),
    where("uid", "==", uid),
    limit(1)
  );
  const snapshot = await getDocs(q);
  let profile = null;
  if (!snapshot.empty) {
    profile = snapshot.docs[0];
  }
  return profile ? profile.data() : null;
};

// Find a profile with id
export const findProfile = async (id) => {
  const docRef = doc(db, DBConstants.DB_PROFILES, id);
  const docSnap = await getDoc(docRef);
  const data = docSnap.exists() ? docSnap.data() : null;
  return data;
};

// Save a Profile
export const saveProfile = async (uid, obj) => {
 //console.log ('Save profile called: ', uid, ' obj: ', obj)

  let docRef = null;
  let profile = null;

  updateAudit(uid, obj);

  if (obj && obj.id) {
    docRef = doc(db, DBConstants.DB_PROFILES, obj.id);
    obj = removeEmptyJsonValues(obj)
    await setDoc(docRef, obj, { merge: true });
  } else {
    // Save the new doc
    docRef = doc(collection(db, DBConstants.DB_PROFILES));
    obj.id = docRef.id;
    obj = removeEmptyJsonValues(obj);
    await setDoc(docRef, obj, { merge: true });
  }
  profile = await findProfile(obj.id);
  return profile;
};

export const deleteProfile = async (uid) => {
  const ref = doc(db, DBConstants.DB_PROFILES, uid);
  await deleteDoc(ref);
};
