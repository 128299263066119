import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { AddCircle, Cancel, Delete, Edit, OfflineBoltSharp, Save } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { deletePerson, savePerson } from "../../services/PersonService";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { GridLoader } from "react-spinners";
import { checkPermission } from "../../utils/AuthenticationUtils";
import * as Authority from "../../constants/Authorities";
import { Link, useNavigate } from "react-router-dom";
import StatusMessage from "../../components/StatusMessage";

export default function PersonTable({ people, isTab }) {
    const navigate = useNavigate();
    const [rowModesModel, setRowModesModel] = useState({});
    const [rows, setRows] = useState([]);
    const { user, person } = useAuthContext();
    const [loading, setLoading] = useState(false);

    // Warning states
    const [selectedId, setSelectedId] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [openMultipleDelete, setOpenMultipleDelete] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    useEffect(() => {
        setRows(people);
    }, [people]);

    const columns = [
        {
            field: "firstName",
            headerName: "First Name",
            width: "150",
            editable: true,
            renderCell: (cell) => {
                return <Link to={`/imag-ai/people/${cell.id}`}>{cell.row.firstName}</Link>;
            },
        },
        {
            field: "lastName",
            headerName: "Last Name",
            width: "150",
            editable: true,
            renderCell: (cell) => {
                return <Link to={`/imag-ai/people/${cell.id}`}>{cell.row.lastName}</Link>;
            },
        },
        {
            field: "email",
            headerName: "Email",
            width: 250,
            editable: true,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 200,
            cellClassName: "actions",

            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                return [
                    <GridActionsCellItem icon={<Edit color="primary" />} label="Edit" onClick={handleEditPerson(id)}/>,
                    <GridActionsCellItem icon={<Delete color="secondary" />} label="Delete" onClick={() => handleOpenDelete(id)} />,
                ];
            },
        },
    ];

    const handleAddPerson = () => {
        
        navigate("/imag-ai/people/new");
    };

    const handleEditPerson = (id) => () => {
        // console.log("clicked Edit", id);
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        navigate(`/imag-ai/people/edit/${id}`);
    };

    const handleDeletePerson = (id) => async () => {
        setRows(rows.filter((row) => row.id !== id));
        await deletePerson(id);
        handleCloseDelete();
        setStatus({ open: true, type: "success", message: "Deleted Person" });
    };

    const handleDeleteMultiple = () => {
        if (selectedRows) {
            selectedRows.map(async (id) => {
                await deletePerson(id);
            });
            handleCloseMultipleDelete();
            setStatus({
                open: true,
                type: "success",
                message: "Deleted Person(s)",
            });
        }
    };

    const handleProcessRowUpdate = async (updatedRow, originalRow) => {
        const s = await savePerson(person.id, updatedRow);
        setStatus({ open: true, type: "success", message: "Saved Person" });
        return s;
    };
    const handleProcessRowUpdateError = (err) => {
        console.log(err);
        setStatus({ open: true, type: "error", message: err });
    };

    const handleSelectionModelChange = (selectedIds) => {
        setSelectedRows(selectedIds);
    };

    // Returns a custom tool bar for the person table
    function PersonTableToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <GridToolbarQuickFilter />

                <Box sx={{ marginRight: 2 }}>
                    <GridToolbarExport />
                    {checkPermission(person?.role, Authority.PERMISSION_PERSON_CAN_DELETE) && (
                        <Button
                            startIcon={<Delete color="secondary" />}
                            onClick={handleOpenMultipleDelete}
                            disabled={selectedRows && selectedRows.length > 0 ? false : true}
                        >
                            Delete
                        </Button>
                    )}
                    {checkPermission(person?.role, Authority.PERMISSION_PERSON_CAN_CREATE) && (
                        <Button startIcon={<AddCircle />} onClick={handleAddPerson}>
                            Add User
                        </Button>
                    )}
                    
                </Box>
            </GridToolbarContainer>
        );
    }

    function NoRowsOverlay() {
        return (
            <Box display="flex" flexDirection="column" justifyContent={"center"} alignItems={"center"}>
                <Typography sx={{ padding: 2 }}>No people found</Typography>
            </Box>
        );
    }

    const handleOpenDelete = (id) => {
        setSelectedId(id);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setSelectedId(null);
    };
    const handleOpenMultipleDelete = () => {
        setOpenMultipleDelete(true);
    };

    const handleCloseMultipleDelete = () => {
        setOpenMultipleDelete(false);
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color="#36d7b7" />
        </Box>
    ) : (
        <div style={{ width: "100%" }}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                checkboxSelection
                disableRowSelectionOnClick
                autoHeight
                slots={{
                    toolbar: PersonTableToolbar,
                    noRowsOverlay: NoRowsOverlay,
                }}
                sx={{
                    border: 0,
                    boxShadow: 0,
                    fontSize: "1rem",
                    
                }}
                processRowUpdate={handleProcessRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                // Called on checkbox selection
                selectionModel={selectedRows}
                onRowSelectionModelChange={handleSelectionModelChange}
            />

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDelete} onClose={handleCloseDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Delete person ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting a person will remove them from the system. Are you sure?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDelete} color="inherit">
                        No
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleDeletePerson(selectedId)} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Multiple Confirmation Dialog */}
            <Dialog
                open={openMultipleDelete}
                onClose={handleCloseMultipleDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete people?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting people will remove them from the system. Are you sure?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseMultipleDelete} color="inherit">
                        No
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleDeleteMultiple} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
