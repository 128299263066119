import { Box } from '@mui/material';
import React, { useState } from 'react'
import ReactFlow from 'reactflow'
import 'reactflow/dist/style.css';
import EmployeeCard from './EmployeeCard';

export default function TeamsTab() {
  const initialNodes = [
    {
      id: 'cio',
      data: { label: <EmployeeCard name="Tony Stark" title="CIO" />},
      position: { x: 400, y: 0 },
      style: { border: '0px'},
    },
    {
      id: 'director',
      data: { label: <EmployeeCard name="Director" title="CISO" />},
      position: { x: 400, y: 150 },
      style: { border: '0px'},
    },
    {
      id: 'user',
      data: { label: <EmployeeCard name="Peter Parker" title="Information Security Manager" department="Engineering" location="123213" showLocation={true} showContact={true} color='gradient'/>},
      position: { x: 400, y: 300 },
      style: { border: '0px'},
    },
    
   
  ];
  
  const initialEdges = [
    { id: 'cio-director', source: 'cio', target: 'director', type: 'simplebezier' },
    { id: 'director-user', source: 'director', target: 'user', type: 'simplebezier' },
    
  ];
  const defaultEdgeOptions = { animated: false };
  const proOptions = { hideAttribution: true };

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  return (
      <Box width="1200px" height="600px">
        <ReactFlow nodes={nodes} edges={edges} defaultEdgeOptions={defaultEdgeOptions} proOptions={proOptions}/>
      </Box>
  )
}
