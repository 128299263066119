import { AppBar, Badge, Box, Hidden, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from "../hooks/useLogout";
import CompanyLogo from "../assets/images/logo.png";
import { AccountCircle, Logout, MoreVert, ShoppingCart } from "@mui/icons-material";
import { personName } from "../utils/utils";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../config/FirebaseConfig";
import * as DBConstants from "../constants/DBConstants";

export default function Header({ toggleDrawer }) {
    const navigate = useNavigate();
    const { logout } = useLogout();
    const { user, person } = useAuthContext();
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [cartCount, setCartCount] = useState(0);  

    const [anchorEl, setAnchorEl] = useState(null);
    const handleSettingToggle = (event) => setAnchorEl(event.currentTarget);
    const handleCloseMenu = () => setAnchorEl(null);

    const handleDrawerToggle = () => {
        toggleDrawer();
    };
    const handleShowCart = () => {
        navigate("/cart");
    }

    useEffect(() => {
        // Get a count of the Cart items for the current user. subscribe to the snapshot such that the count is updated when the cart changes   
        const q = query(collection(db, DBConstants.DB_CARTS), where("uid", "==", person.id)); 
        const unsub = onSnapshot(q, (snapshot) => {
            let count = 0;
            snapshot.docs.forEach((doc) => {
                count += doc.data().items.length;
            });
            setCartCount(count);
        }
        );
        return () => unsub();

    }, [])

    return (
        <AppBar elevation={0} sx={{ backgroundColor: "black" }}>
            <Toolbar>
                <Hidden mdUp>
                    <IconButton color="inherit" onClick={handleDrawerToggle}>
                        <Menu />
                    </IconButton>
                </Hidden>

                <Box>
                    <img src={CompanyLogo} alt="Logo" onClick={() => navigate("/")} />
                </Box>
                <Box ml={4} flexGrow={1}>
                    <Typography variant="h6">{process.env.REACT_APP_TITLE}</Typography>
                    <Typography variant="body1">{process.env.REACT_APP_TAGLINE}</Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", marginRight: "40px" }}>
                    <Typography variant="body1">Welcome, {person && personName(person)} </Typography>
                    {person && <Typography variant="subtitle">{person.role}</Typography>}
                </Box>
                <IconButton
                    color="inherit"
                    onClick={handleShowCart}
                >
                    <Badge badgeContent={cartCount} color="secondary" showZero>
                        <ShoppingCart />
                    </Badge>
                </IconButton>
                <IconButton
                    aria-label="User Settings"
                    aria-owns={anchorEl ? "user-menu" : null}
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleSettingToggle}
                >
                    <MoreVert />
                </IconButton>
            </Toolbar>

            <Menu id="user-menu" anchorEl={anchorEl} open={Boolean(anchorEl || false)} onClose={handleCloseMenu}>
                <MenuItem onClick={handleCloseMenu}>
                    <ListItemIcon>
                        <AccountCircle />
                    </ListItemIcon>
                    <Link to="/settings/profile">
                        <ListItemText primary="My Profile" />
                    </Link>
                </MenuItem>

                {user && (
                    <MenuItem onClick={logout}>
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </MenuItem>
                )}
            </Menu>
        </AppBar>
    );
}
