import React, { useEffect, useState } from "react";
import {
    Button,
    Tabs,
    Tab,
    Box,
    Typography,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Stack,
    Stepper,
    StepLabel,
    Step,
    CardActions,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useAuthContext } from "../../hooks/useAuthContext";
import { GridLoader } from "react-spinners";
import RegulationTable from "./RegulationTable";
import { CheckCircle, Reply, Reviews } from "@mui/icons-material";
import RecommendationTable from "./RecommendationTable";
import PricingTable from "./PricingTable";
import {
    findAssessment,
    findLatestAssessmentByTenant,
    getUnselectedCapabilities,
    getUnselectedGovernanceAreas,
} from "../../services/AssessmentService";
import { AuditorTypes, getCodelist } from "../../constants/Codelist";
import GapAnalysisSnapshot from "./GapAnalysisSnapshot";
import { deleteResultsByAssessmentId, getGapAnalysis, getRegulations } from "../../services/AssessmentResultService";
import { ASSESSMENT_TYPE_GAP, IAMCapabilityCategories, ProgramGovernance, getIAMCodelist } from "../../constants/AssessmentConstants";
import { getObjectById } from "../../utils/utils";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        fontStyle: "italic",
    },
}));

export default function AssessmentResult() {
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // const mod = false
    const mod = queryParams.get("mod") === "true";
    const tab = queryParams.get("tab");

    // read the id parameter from the URL
    const { id: assessmentId } = useParams();
    const [assessment, setAssessment] = useState(null);
    const [selectedTab, setSelectedTab] = useState(tab ? parseInt(tab) : 0);
    const [auditor, setAuditor] = useState(null);
    const { user, person } = useAuthContext();

    // status for regulations, gaps and recommendations
    const [regulationsStatus, setRegulationsStatus] = useState(false);
    const [gapsStatus, setGapsStatus] = useState(false);
    const [recommendationsStatus, setRecommendationsStatus] = useState(false);

    const classes = useStyles();
    const stepContent = [
        "Based on your context, the following regulations that apply to your company and products.",
        "The following gaps currently exist within your IAM capability matrix. Addressing these gaps will strengthen your security framework and ensure comprehensive Identity and Access Management.",
        "To address the identified gaps in your security context, we recommend implementing a multi-layered authentication system and conducting regular security audits. These steps will fortify your defenses and ensure robust protection against potential threats.",
        "Our pricing model is designed with transparency in mind. We're committed to delivering unparalleled value for your investment, ensuring you get the most out of our services. Join us with confidence, knowing that your budget is well-spent on quality solutions.",
    ];
    const [activeStep, setActiveStep] = useState(0);
    const steps = ["Regulations", "Gap Analysis", "Recommendations", "Pricing"];

    useEffect(() => {
       getAssessmentResult();
    }, [assessmentId, mod, tab]);

    //Get the latest assessment if assessmentId is null
    const getAssessmentResult = async(reassess) => {
        setLoading(true);
        let result = null;
        if (!assessmentId) {
            result = await findLatestAssessmentByTenant(person?.tenant?.id);
        } else {
            result = await findAssessment(assessmentId);
        }
        setAssessment(result);

        // Get the regulation regardless
        console.log ('Getting regulations...')
        //const regs = await getRegulations(person, result, null);

        // Set the base prompt and persona of the auditor
        const auditor = getCodelist(AuditorTypes).find((auditor) => auditor.id === result?.auditor.id);
        const persona = `You are an expert Security Auditor, ${auditor?.name}. ${auditor?.name}'s style towards security assessments is ${auditor?.style}. ${auditor?.name} says, "${auditor?.approach} ${auditor?.focus}"`;
        setAuditor(persona);

        // wait for all promises to complete
        if (mod || reassess) {
            const p1 = getRegulations(person, result, persona);
            const p2 = analyzeGaps(person, result, persona);
            const p3 = generateRecommendations();
            // check if p1, p2 and p3 are iterable and add them to a list of promises
            let promises = [];
            if (Symbol.iterator in Object(p1)) {
                promises.push(p1);
            }
            if (Symbol.iterator in Object(p2)) {
                promises.push(p2);
            }
            if (Symbol.iterator in Object(p3)) {
                promises.push(p3);
            }
            // wait for all promises to complete
            await Promise.all(promises);
            delay(2000);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    const handleTabChange = (event, index) => {
        setSelectedTab(index);
    };

    function delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    // Change the step
    const handleStepClick = (stepIndex) => {
        setActiveStep(stepIndex);
    };

    const handleNext = async () => {
        // Last step - save the assessment
        if (activeStep === steps.length - 1) {
            await handleSubmit();
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleSubmit = async () => {
        navigate("/cart");
    };

    const analyzeGaps = async (person, assessment, persona) => {
        // Delete all the previous gap results if mod is true and selected capabilities is zero
        if (assessment?.capabilityCategories.length === 0) {
            await deleteResultsByAssessmentId(assessment.id, ASSESSMENT_TYPE_GAP);
        }

        let promises = [];
        const govPromises = analyzeGovernanceGaps(person, assessment, persona);
        const capPromises = analyzeCapabilityGaps(person, assessment, persona);
        if (Symbol.iterator in Object(govPromises)) {
            promises.push(govPromises);
        }
        if (Symbol.iterator in Object(capPromises)) {
            promises.push(capPromises);
        }
        const results = await Promise.all(promises);
        setGapsStatus(true);
    };

    const analyzeGovernanceGaps = async (person, assessment, persona) => {
        const missingGovernanceCategories = await getUnselectedGovernanceAreas(assessment);
        console.log ('Missing governance categories: ', missingGovernanceCategories)
        // Get the gap analysis for the missing governance categories
        const promises = missingGovernanceCategories?.map((cap) => {
            try {
            let category = {
                id: 0,
                name: "Program Governance",
                code: ProgramGovernance,
            };
            return getGapAnalysis(person, assessment, persona, category, cap);
            } catch (error) {
                console.log("Error: ", error);
                return Promise.resolve()
            }
        });
        return promises;
    };

    const analyzeCapabilityGaps = async (person, assessment, persona) => {
        const missingCapabilities = await getUnselectedCapabilities(assessment);
        console.log ('Missing capabilities: ', missingCapabilities)
        const promises = missingCapabilities?.map((cap) => {
            try {
                let cat = getObjectById(getIAMCodelist(IAMCapabilityCategories), cap.categoryId);

                const capabilities = getIAMCodelist(cat.code);
                let capability = getObjectById(capabilities, cap.capabilityId);
                return getGapAnalysis(person, assessment, persona, cat, capability);
            } catch (error) {
                console.log("Error: ", error);
                return Promise.resolve()
            }
        });
        return promises;
    };

    // Generate all recommendations
    const generateRecommendations = async () => {
        await delay(2000);
        setRecommendationsStatus(true);
    };

    const handleReAssess = async () => {
        console.log ('Handle re-assess')
        await getAssessmentResult(true);
    }

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <Box width="50%" display="flex" flexDirection="column" gap={4} sx={{ p: 4, bgcolor: "white", boxShadow: 4 }}>
                <Typography variant="headline2">We are generating your assessment results...</Typography>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
                    {regulationsStatus ? <CheckCircle color="success" /> : <GridLoader color={theme.palette.primary.light} size="8px" />}
                    <Typography variant="subtitle1">Analyzing Regulations...</Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
                    {gapsStatus ? <CheckCircle color="success" /> : <GridLoader color={theme.palette.primary.light} size="8px" />}
                    <Typography variant="subtitle1">Analyzing Gaps...</Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
                    {recommendationsStatus ? <CheckCircle color="success" /> : <GridLoader color={theme.palette.primary.light} size="8px" />}
                    <Typography variant="subtitle1">Generating Recommendations...</Typography>
                </Stack>
            </Box>
        </Box>
    ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }}>
            <Card>
                <CardHeader
                    title="Your Assessment Results"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="Based on your answers, we have determined the following:"
                    action={
                        <>
                        <Button variant="contained" color="primary" startIcon={<Reviews />} onClick={handleReAssess}>
                            Re-assess
                        </Button>
                        <Button startIcon={<Reply />} onClick={handleBack} color="info">
                            Back
                        </Button>
                        </>
                    }
                />
                <Divider />
                <CardContent>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>
                                        <Link to="#" onClick={() => handleStepClick(index)}>
                                            {label}
                                        </Link>
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <Box mt={4}>
                        {activeStep === steps.length ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "70vw",
                                    height: "70vh",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <GridLoader color={theme.palette.primary.light} />
                            </Box>
                        ) : (
                            <div>
                                <Typography className={classes.instructions}>{stepContent[activeStep]}</Typography>

                                {activeStep === 0 && assessment && person && <RegulationTable assessment={assessment} auditor={auditor} mod={mod} />}
                                {activeStep === 1 && assessment && person && (
                                    <GapAnalysisSnapshot assessment={assessment} auditor={auditor} mod={mod} />
                                )}
                                {activeStep === 2 && assessment && person && (
                                    <RecommendationTable assessment={assessment} auditor={auditor} mod={mod} />
                                )}
                                {activeStep === 3 && assessment && person && <PricingTable assessment={assessment} auditor={auditor} mod={mod} />}
                            </div>
                        )}
                    </Box>
                </CardContent>
                <CardActions
                    //disableSpacing
                    sx={{
                        alignSelf: "stretch",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        paddingRight: 4,
                    }}
                >
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                        Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                </CardActions>
            </Card>
        </Box>
    );
}
