import { Alert, Box, Button, Card, CardContent, CardHeader, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import CompanyLogo from "../../assets/images/logo-text.png";
import { Link, useLocation } from "react-router-dom";
import { Codelist } from "../../constants/Codelist";
import { useLogin } from "../../hooks/useLogin";
import { Lock } from "@mui/icons-material";
import SignInInfo from "./SignInInfo";

export default function SignIn() {
    const { login, error, isPending } = useLogin();
    const { state } = useLocation();
    const [from, setFrom] = useState((state && state.from) || null);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const [invitation, setInvitation] = useState();

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState({
        email: "",
        password: "",
    });

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        // Validate email field
        if (formData.email.trim() === "") {
            newErrors.email = "Email is required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid email format";
            isValid = false;
        } else {
            newErrors.email = "";
        }

        // Validate password field
        if (formData.password.trim() === "") {
            newErrors.password = "Password is required";
            isValid = false;
        } else if (formData.password.length < 6) {
            newErrors.password = "Password must be at least 6 characters";
            isValid = false;
        } else {
            newErrors.password = "";
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            await login(formData.email, formData.password, code, from);
        }
    };

    const handleChange = (e) => {
        //const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            {/* Info Box */}
            <SignInInfo />

            {/* Signin Box */}
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="50%" height="100%">
                <Stack direction="row" justifyContent="start" spacing={2} ml={4} mt={4} width="100%">
                    <Typography variant="headline" ml={4} mt={4}>
                        Deployment Region:{" "}
                    </Typography>

                    <Select labelId="regionId" name="regionId" variant="standard" value="1">
                        {Codelist.RegionCodes.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        flexGrow: 1,
                    }}
                >
                    <Typography variant="headline">Get started for free</Typography>
                    <Card elevation={0}>
                        <CardHeader
                            // title="CyberShield"
                            // titleTypographyProps={{ color: "primary.main", variant: "title" }}
                            subheader="Sign in with your credentials"
                            subheaderTypographyProps={{ color: "primary.main", variant: "subtitle1" }}
                            sx={{ textAlign: "center" }}
                        ></CardHeader>
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    name="email"
                                    label="Email"
                                    required
                                    fullWidth
                                    variant="standard"
                                    value={formData.email ?? ""}
                                    onChange={handleChange}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                />
                                <TextField
                                    name="password"
                                    label="Password"
                                    type="password"
                                    required
                                    fullWidth
                                    variant="standard"
                                    value={formData.password ?? ""}
                                    onChange={handleChange}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    margin="normal"
                                />

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        marginTop: 2,
                                    }}
                                >
                                    <Button
                                        startIcon={<Lock />}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={isPending && isPending ? true : false}
                                    >
                                        {isPending && isPending ? "Loading..." : "Sign In"}
                                    </Button>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        marginTop: 2,
                                    }}
                                >
                                    {error && <Alert severity="error">{error}</Alert>}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Link to={code ? `/forgot-password?code=${code}` : "/forgot-password"}>
                                        <Button>Forgot password?</Button>
                                    </Link>
                                    {/* <Link to={code ? `/signup?code=${code}` : "/signup"}>
                                        <Button>Create new account</Button>
                                    </Link> */}
                                </Box>
                            </form>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
}
