import { Box, Button, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

export default function OrgProfileView({ organization }) {
    const { user, person } = useAuthContext();
    const [people, setPeople] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box m={1} sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                    <Typography variant="body1" fontWeight={"bold"}>
                        Name
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {organization.name}
                    </Typography>
                    <Typography variant="body1" fontWeight={"bold"}>
                        Alternate Name
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {organization.dba ?? "NA"}
                    </Typography>
                    <Typography variant="body1" fontWeight={"bold"}>
                        Address
                    </Typography>
                    <Typography variant="body1">{organization.addressLine1}</Typography>
                    <Typography variant="body1">
                        {organization.city} {organization.state} {organization.zipcode}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {organization.country}
                    </Typography>
                </Box>
            </Box>
        </form>
    );
}
