import { Security } from "@mui/icons-material";
import { sortObjectsByOrder } from "../utils/utils";

export const ProgramGovernance = "ProgramGovernance";
export const ProgramGovernanceAreas = "ProgramGovernanceAreas";
export const IAMCapabilityCategories = "IAMCapabilityCategories";
export const IdentityManagement = "IdentityManagement";
export const AccessManagement = "AccessManagement";
export const PrivilegedAccessManagement = "PrivilegedAccessManagement";
export const AccessGovernance = "AccessGovernance";
export const OmniChannel = "OmniChannel";
export const AuditCompliance = "AuditAndCompliance";
export const ReportingAndMonitoring = "ReportingAndMonitoring";

export const OTHER_ID = 9999;

// Assment result types
export const ASSESSMENT_TYPE_REGULATION = "Regulation";
export const ASSESSMENT_TYPE_GAP = "Gap";
export const ASSESSMENT_TYPE_RECOMMENDATION = "Recommendation";
export const ASSESSMENT_TYPE_PRICING = "Pricing";

// Gap settings 
export const ASSESSMENT_GAP_PEOPLE_NUMBER = 3
export const ASSESSMENT_GAP_PROCESS_NUMBER = 3
export const ASSESSMENT_GAP_TECHNOLOGY_NUMBER = 3


export function getIAMCodelist(name) {
    let list = IAMCodelist[name];
    if (!list) {
        console.error("Codelist not found: ", name);
        return [];
    }
    list = sortObjectsByOrder(list);
    return list;
}

export const IAMCodelist = {
    [ProgramGovernance]: [
        { id: 1, name: "No formal program", order: 1, default: false },
        { id: 2, name: "Informal program", order: 2, default: true },
        { id: 3, name: "Formal program with dedicated team and executive sponsorship", order: 3, default: false },
    ],
    [ProgramGovernanceAreas]: [
        { id: 1, name: "Business & IT Strategy", order: 1, default: false, image: <Security />  },
        { id: 2, name: "Architecture & Technology", order: 2, default: false, image: <Security />  },
        { id: 3, name: "Compliance & Audit", order: 3, default: false, image: <Security />  },
        { id: 4, name: "Policies & Standards", order: 4, default: false, image: <Security />  },
        { id: 5, name: "Business Enablement & Onboarding", order: 5, default: false, image: <Security />  },
        { id: 6, name: "Digital Transformation", order: 6, default: false, image: <Security />  },
        { id: 7, name: "Enterprise Risk Management", order: 7, default: false, image: <Security />  },
        { id: 8, name: "Program Management Office", order: 8, default: false, image: <Security />  },
    ],
    [IAMCapabilityCategories]: [
        { id: 1, name: "Identity Management", code: IdentityManagement, order: 1, default: false, image: <Security />  },
        { id: 2, name: "Access Management", code: AccessManagement, order: 2, default: false, image: <Security />  },
        { id: 3, name: "Access Governance", code: AccessGovernance, order: 3, default: false, image: <Security />  },
        { id: 4, name: "Privileged Access", code: PrivilegedAccessManagement, order: 4, default: false, image: <Security />  },
        { id: 5, name: "Omni-Channel", code: OmniChannel, order: 5, default: false, image: <Security />  },
        { id: 6, name: "Audit & Compliance", code: AuditCompliance, order: 6, default: false, image: <Security />  },
        { id: 7, name: "Reporting & Monitoring", code: ReportingAndMonitoring, order: 7, default: false, image: <Security />  },
    ],
    [IdentityManagement]: [
        {
            id: 1,
            name: "Identity Registration",
            order: 1,
            default: false,
            image: <Security />, 
            description:
                "Identity Registration is the initial step of enrolling and verifying a user's digital identity within a system. Does your company have specific security controls in place to manage this crucial process?",
        },
        {
            id: 2,
            name: "Unique Identifier",
            order: 2,
            default: false,
            image: <Security />,
            description:
                "A Unique Identifier distinguishes one user from all others in a system. Has your organization set up appropriate mechanisms to ensure uniqueness?",
        },
        {
            id: 3,
            name: "Identity Lifecycle",
            order: 3,
            default: false,
            image: <Security />,
            description:
                "Identity Lifecycle manages the various stages a user's digital identity goes through in its duration. Is there a well-defined lifecycle management process in place?",
        },
        {
            id: 4,
            name: "Account Reconciliation",
            order: 4,
            default: false,
            image: <Security />,
            description:
                "Account Reconciliation ensures all system accounts align with known and valid identities. Does your system periodically reconcile accounts?",
        },
        {
            id: 5,
            name: "Identity Verification",
            order: 5,
            default: false,
            image: <Security />,
            description:
                "Identity Verification confirms a user's claimed identity matches their true identity. Has your organization adopted methods to verify user identities?",
        },
        {
            id: 6,
            name: "Identity Proofing",
            order: 6,
            default: false,
            image: <Security />,
            description:
                "Identity Proofing collects and verifies information from a user to confirm their identity. How robust is your company's identity proofing process?",
        },
        {
            id: 7,
            name: "Directory Services",
            order: 7,
            default: false,
            image: <Security />,
            description:
                "Directory Services maintain and organize user information for access and identity management. Are directory services implemented securely in your company?",
        },
        {
            id: 8,
            name: "Access Request",
            order: 8,
            default: false,
            image: <Security />,
            description:
                "Access Request is the process by which users seek permission to access certain resources. How streamlined is this process at your organization?",
        },
        {
            id: 9,
            name: "Access Approval",
            order: 9,
            default: false,
            image: <Security />,
            description:
                "Access Approval is the process of granting or denying user access requests. Are there checks and balances in place for this in your company?",
        },
        {
            id: 10,
            name: "Delegated Administration",
            order: 10,
            default: false,
            image: <Security />,
            description:
                "Delegated Administration allows some users to manage others within defined limits. Does your organization utilize this and is it controlled?",
        },
        {
            id: 11,
            name: "Credential Management",
            order: 11,
            default: false,
            image: <Security />,
            description:
                "Credential Management involves handling user credentials securely. How does your company ensure the safety of user credentials?",
        },
        {
            id: 12,
            name: "Identity Provisioning",
            order: 12,
            default: false,
            image: <Security />,
            description:
                "Identity Provisioning automates the creation, modification, and deletion of user identities. How efficient is provisioning in your system?",
        },
        {
            id: 13,
            name: "Self-Service",
            order: 13,
            default: false,
            image: <Security />,
            description: "Self-Service allows users to manage aspects of their identity. How empowered are your users with self-service options?",
        },
        {
            id: 14,
            name: "Identity Warehouse",
            order: 14,
            default: false,
            image: <Security />,
            description:
                "Identity Warehouse stores identity data and often includes logs and historical information. How comprehensive is your identity data storage?",
        },
    ],
    [AccessManagement]: [
        {
            id: 1,
            name: "Authentication",
            order: 1,
            default: false,
            image: <Security />,
            description:
                "Authentication verifies a user's identity before granting access. Does your organization utilize strong authentication mechanisms?",
        },
        {
            id: 2,
            name: "Authorization",
            order: 2,
            default: false,
            image: <Security />,
            description:
                "Authorization determines what actions or resources a verified user can access. Is the authorization model in your company granular and well-defined?",
        },
        {
            id: 3,
            name: "Entitlements Management",
            order: 3,
            default: false,
            image: <Security />,
            description:
                "Entitlements Management governs user rights and permissions. How robustly does your system manage and track user entitlements?",
        },
        {
            id: 4,
            name: "Role Lifecycle",
            order: 4,
            default: false,
            image: <Security />,
            description:
                "Role Lifecycle handles the various stages of a user role within a system. Has your company established a clear lifecycle for user roles?",
        },
        {
            id: 5,
            name: "Role Mining",
            order: 5,
            default: false,
            image: <Security />,
            description:
                "Role Mining uncovers and refines roles based on user activity patterns. Does your organization periodically perform role mining to enhance security?",
        },
        {
            id: 6,
            name: "Segregation of Duties",
            order: 6,
            default: false,
            image: <Security />,
            description:
                "Segregation of Duties ensures no individual has conflicting responsibilities. Has your company set clear boundaries to avoid potential conflicts?",
        },
        {
            id: 7,
            name: "Session Management",
            order: 7,
            default: false,
            image: <Security />,
            description:
                "Session Management controls user sessions to ensure security and usability. Are there mechanisms in place to manage and secure user sessions effectively?",
        },
        {
            id: 8,
            name: "Token Management",
            order: 8,
            default: false,
            image: <Security />,
            description:
                "Token Management involves the secure creation, distribution, and validation of tokens. How does your company manage tokens to ensure they remain secure?",
        },
    ],
    [AccessGovernance]: [
        {
            id: 1,
            name: "Ownership & Accountability",
            order: 1,
            default: false,
            image: <Security />,
            description:
                "Ownership & Accountability ensures individuals are responsible for their access rights. Does your company clearly define and assign ownership for access rights?",
        },
        {
            id: 2,
            name: "Access Certification",
            order: 2,
            default: false,
            image: <Security />,
            description:
                "Access Certification validates that users have the appropriate permissions. How frequently does your organization conduct access reviews?",
        },
        {
            id: 3,
            name: "Entitlements Attestations",
            order: 3,
            default: false,
            image: <Security />,
            description:
                "Entitlements Attestations verify the necessity of granted entitlements. Are managers in your company actively involved in the attestation process?",
        },
        {
            id: 4,
            name: "Access Remediation",
            order: 4,
            default: false,
            image: <Security />,
            description:
                "Access Remediation corrects inappropriate or excessive access. When discrepancies are found, how promptly does your organization act?",
        },
        {
            id: 5,
            name: "Workflow Management",
            order: 5,
            default: false,
            image: <Security />,
            description:
                "Workflow Management automates the processes related to access governance. Does your system offer streamlined and automated workflows for access requests and reviews?",
        },
        {
            id: 6,
            name: "Certificate Campaign Management",
            order: 6,
            default: false,
            image: <Security />,
            description:
                "Certificate Campaign Management organizes and oversees access certification campaigns. Is there a structured approach to managing certification campaigns in your company?",
        },
    ],
    [PrivilegedAccessManagement]: [
        {
            id: 1,
            name: "Privileged Account Discovery",
            order: 1,
            default: false,
            image: <Security />,
            description:
                "Privileged Account Discovery identifies all privileged accounts across the environment. Does your company have automated tools to discover these sensitive accounts?",
        },
        {
            id: 2,
            name: "Privileged Account Vault",
            order: 2,
            default: false,
            image: <Security />,
            description:
                "Privileged Account Vault securely stores sensitive account credentials. Is your company using a centralized and encrypted repository for these credentials?",
        },
        {
            id: 3,
            name: "Privileged Session Management",
            order: 3,
            default: false,image: <Security />,
            description:
                "Privileged Session Management controls and restricts privileged user access. How does your organization oversee privileged user activities?",
        },
        {
            id: 4,
            name: "Password Rotation",
            order: 4,
            default: false,
            image: <Security />,
            description:
                "Password Rotation automatically changes privileged account passwords. Does your company enforce regular password changes for these accounts?",
        },
        {
            id: 5,
            name: "Session Brokering",
            order: 5,
            default: false,
            image: <Security />,
            description:
                "Session Brokering directs privileged sessions to appropriate resources. Is there a mechanism in your company to manage and route privileged sessions securely?",
        },
        {
            id: 6,
            name: "Session Recording",
            order: 6,
            default: false,
            image: <Security />,
            description:
                "Session Recording captures activities during privileged sessions. Are all privileged activities in your organization documented and stored?",
        },
        {
            id: 7,
            name: "Session Monitoring",
            order: 7,
            default: false,
            image: <Security />,
            description:
                "Session Monitoring actively watches over privileged user actions. Does your company have real-time monitoring for privileged sessions?",
        },
        {
            id: 8,
            name: "Host Based Access Control",
            order: 8,
            default: false,
            image: <Security />,
            description:
                "Host Based Access Control defines access on a per-host basis. How does your organization ensure specific host-level access restrictions?",
        },
    ],
    [OmniChannel]: [
        {
            id: 1,
            name: "Federation",
            order: 1,
            default: false,
            image: <Security />,
            description:
                "Federation allows identity information to be shared across different organizations or systems. Does your company leverage federated authentication for streamlined access?",
        },
        {
            id: 2,
            name: "API Access",
            order: 2,
            default: false,
            image: <Security />,
            description:
                "API Access provides seamless interactions between systems through application interfaces. How does your organization manage secure access to APIs?",
        },
        {
            id: 3,
            name: "Resource Management",
            order: 3,
            default: false,
            image: <Security />,
            description:
                "Resource Management is about overseeing and controlling digital resources. How efficient is your company's approach to managing online resources?",
        },
        {
            id: 4,
            name: "Just-in-Time Access",
            order: 4,
            default: false,
            image: <Security />,
            description:
                "Just-in-Time Access grants permissions on an as-needed basis. Does your organization provide temporary access for immediate requirements?",
        },
        {
            id: 5,
            name: "Cloud-Native IAM",
            order: 5,
            default: false,
            image: <Security />,
            description:
                "Cloud-Native IAM ensures identity and access management in cloud environments. How has your company adapted IAM for cloud deployments?",
        },
        {
            id: 6,
            name: "Digital Identity",
            order: 6,
            default: false,
            image: <Security />,
            description:
                "Digital Identity represents an entity's online persona. How comprehensive is your company's digital identity management strategy?",
        },
        {
            id: 7,
            name: "Delegation Frameworks",
            order: 7,
            default: false,
            image: <Security />,
            description:
                "Delegation Frameworks allow users to grant permission to others. Has your company set frameworks to allow such delegations securely?",
        },
    ],
    [AuditCompliance]: [
        {
            id: 1,
            name: "Controls Management",
            order: 1,
            default: false,
            image: <Security />,
            description:
                "Controls Management involves defining and maintaining security controls. Does your organization have a structured approach for this vital aspect of compliance?",
        },
        {
            id: 2,
            name: "Controls Testing",
            order: 2,
            default: false,
            image: <Security />,
            description:
                "Controls Testing ensures that security controls are effective and functioning. How frequently does your company test and evaluate its security controls?",
        },
        {
            id: 3,
            name: "Issue Management",
            order: 3,
            default: false,
            image: <Security />,
            description:
                "Issue Management is about addressing and rectifying compliance-related concerns. Is your organization proactive in identifying and mitigating compliance issues?",
        },
        {
            id: 4,
            name: "Policy Management & Enforcement",
            order: 4,
            default: false,
            image: <Security />,
            description:
                "Policy Management & Enforcement ensures adherence to set policies. How robust are your company's policy formulation and enforcement mechanisms?",
        },
        {
            id: 5,
            name: "Training & Awareness",
            order: 5,
            default: false,
            image: <Security />,
            description:
                "Training & Awareness programs educate staff about compliance requirements. Does your organization prioritize regular compliance training sessions for its employees?",
        },
    ],
    [ReportingAndMonitoring]: [
        {
            id: 1,
            name: "Dashboard",
            order: 1,
            default: false,
            image: <Security />,
            description:
                "A Dashboard provides a visual overview of key metrics and performance indicators. Does your organization utilize dashboards for real-time insights into security metrics?",
        },
        {
            id: 2,
            name: "Identity Analytics",
            order: 2,
            default: false,
            image: <Security />,
            description:
                "Identity Analytics focuses on analyzing user access patterns. Has your company adopted solutions to gain insights into identity-related activities?",
        },
        {
            id: 3,
            name: "SIEM Integration",
            order: 3,
            default: false,
            image: <Security />,
            description:
                "SIEM Integration helps in centralized logging and real-time analysis. Does your organization's IAM solution integrate seamlessly with SIEM for improved monitoring?",
        },
        {
            id: 4,
            name: "User Activity Monitoring",
            order: 4,
            default: false,
            image: <Security />,
            description:
                "User Activity Monitoring tracks and reviews user actions. Is your company proactive in monitoring user activities to detect suspicious behavior?",
        },
        {
            id: 5,
            name: "Anomaly Detection",
            order: 5,
            default: false,
            image: <Security />,
            description:
                "Anomaly Detection identifies unusual patterns that may indicate threats. How effective is your organization's mechanism to detect and alert on anomalies?",
        },
        {
            id: 6,
            name: "IAM Log Collection",
            order: 6,
            default: false,
            image: <Security />,
            description:
                "IAM Log Collection gathers logs related to identity activities. Does your company have a structured approach to collect and store IAM-related logs?",
        },
        {
            id: 7,
            name: "Operational Reporting",
            order: 7,
            default: false,
            image: <Security />,
            description:
                "Operational Reporting provides insights into the daily operations. How often does your organization review operational reports to ensure smooth IAM processes?",
        },
        {
            id: 8,
            name: "Compliance Reporting",
            order: 8,
            default: false,
            image: <Security />,
            description:
                "Compliance Reporting ensures adherence to regulatory standards. Does your company have robust mechanisms to generate and review compliance reports?",
        },
    ],
};
