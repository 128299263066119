import React from "react";
import { Collapse, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMountEffect } from "../hooks/useMountEffect";
import { capitalize } from "../utils/utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { NavLink } from "react-router-dom";
import * as Constants from "../constants/Constants";

const useStyles = makeStyles((theme) => ({
    badge: {
        width: "20px",
        height: "20px",
        display: "flex",
        zIndex: 1,
        flexWrap: "wrap",
        fontSize: "0.75rem",
        alignItems: "center",
        borderRadius: "50%",
        alignContent: "center",
        flexDirection: "row",
        justifyContent: "center",
    },
    menuLink: {
        position: "relative",
        display: "block",
    },
    menuItem: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: theme.spacing(1) * 1.5,
        paddingBottom: theme.spacing(1) * 1.5,
    },
    menuIcon: {
        marginLeft: theme.spacing(1) * 2,
        marginRight: theme.spacing(1) * 2,
    },
    menuSubItem: {
        paddingLeft: "55px",
        paddingRight: "55px",
        paddingTop: theme.spacing(1) * 1.5,
        paddingBottom: theme.spacing(1) * 1.5,
    },
    menuCollapsed: {
        backgroundColor: theme.palette.action.hover,
    },
    menuActive: {
        backgroundColor: theme.palette.action.hover,
    },
    menuClosed: {
        backgroundColor: "transparent",
    },
    caret: {
        marginLeft: theme.spacing(1) * 2,
        marginRight: theme.spacing(1) * 2,
        minWidth: 0,
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
}));

export default function SidebarItem({ route, index, activeRoute, toggleMenu, currentPath }) {
    const classes = useStyles();

    useMountEffect(() => {
        if (!currentPath || activeRoute === index || route.path === "/") return;
        toggleMenu(index);
    });

    const badge = (badge) => {
        if (!badge) return;
        return (
            <Typography className={classes.badge} component="div">
                {badge.value}
            </Typography>
        );
    };

    if (route.type === Constants.ROUTE_EXTERNAL) {
        return (
            <a href={route.path} target="_blank" rel="noopener noreferrer" key={index} className={classes.menuLink}>
                <ListItem className={classes.menuItem} button>
                    <ListItemIcon>
                        <route.icon className={classes.menuIcon} color="primary" />
                    </ListItemIcon>
                    <Typography variant="body1" className="flexSpacer">
                        {capitalize(route.name)}
                    </Typography>
                    {badge(route.badge)}
                </ListItem>
            </a>
        );
    }

    if (route.type === Constants.ROUTE_SUBMENU) {
        return (
            <div className={activeRoute === index ? classes.menuCollapsed : classes.menuClosed}>
                <ListItem className={classes.menuItem} button key={index} onClick={() => toggleMenu(index)}>
                    <ListItemIcon>
                        <route.icon className={classes.menuIcon} color="primary" />
                    </ListItemIcon>
                    <Typography variant="body1" className="flexSpacer">
                        {capitalize(route.name)}
                    </Typography>
                    {badge(route.badge)}
                    <ListItemIcon className={classes.caret}>{activeRoute === index ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</ListItemIcon>
                </ListItem>
                <Collapse in={activeRoute === index ? true : false} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        {route.children.map((subitem, index) => {
                            //console.log("Subitem: ", subitem);
                            if (subitem.type !== Constants.ROUTE_CUSTOM) {
                                return (
                                    <NavLink
                                        to={`${route.path ? route.path : ""}${subitem.path ? subitem.path : ""}`}
                                        exact="true"
                                        className={({ isActive, isPending }) => (isPending ? classes.menuLink : isActive ? classes.menuActive : "")}
                                        key={index}
                                    >
                                        <ListItem className={classes.menuSubItem} button>
                                            {/* <ListItemIcon>
                                                <subitem.icon className={classes.menuIcon} color="primary" />
                                            </ListItemIcon> */}
                                            <Typography variant="body1" className="flexSpacer" sx={{ marginLeft: 2 }}>
                                                {capitalize(subitem.name)}
                                            </Typography>
                                            {badge(subitem.badge)}
                                        </ListItem>
                                    </NavLink>
                                );
                            }
                        })}
                    </List>
                </Collapse>
            </div>
        );
    }

    return (
        <NavLink
            to={route.path}
            exact="true"
            className={({ isActive, isPending }) => (isPending ? classes.menuLink : isActive ? classes.menuActive : "")}
            key={index}
        >
            <ListItem className={classes.menuItem} button onClick={() => toggleMenu(index)}>
                <ListItemIcon>
                    <route.icon className={classes.menuIcon} color="primary" />
                </ListItemIcon>
                <Typography variant="body1" className="flexSpacer">
                    {capitalize(route.name)}
                </Typography>
                {badge(route.badge)}
            </ListItem>
        </NavLink>
    );
}
