import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Fade, IconButton, Stack, Typography } from "@mui/material";
import { GridLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { AdminPanelSettings, Apps, Assessment, Delete, Edit, Policy, Reply, Search, Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { countPeopleByTenantId } from "../../services/PersonService";
import { makeStyles } from "@mui/styles";
import { BackgroundIcon, BackgroundImage } from "../../utils/UIStyles";
import AnnouncementSlider from "../imag-ai/AnnouncementSlider";
import { getObjectById } from "../../utils/utils";
import { Controls } from "../../samples/controls";
import ControlTable from "./ControlTable";

const useStyles = makeStyles((theme) => ({
    gradientBody: {
        background: `linear-gradient(to right bottom, black, ${theme.palette.primary.light})`,
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
    gradientBody1: {
        background: `linear-gradient(to right bottom, ${theme.palette.secondary.light}, black)`,
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
}));

export default function AuditAIHome() {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const { user, person } = useAuthContext();

    const [stats, setStats] = useState({
        numberOfAssessments: 0,
        numberOfPolicies: 0,
        numberOfControls: 0,
        numberOfCertifications: 0,
        numberOfUsers: 0,
    });

    const [regulations, setRegulations] = useState([
        {
            id: 1,
            abbreviation: "GDPR",
            displayName: "General Data Protection Regulation",
            image: require("../../assets/images/regulatory/gdpr.png"),
            controlCode: "gdpr",
        },
        {
            id: 2,
            abbreviation: "NIST",
            displayName: "National Institute of Standards and Technology",
            image: require("../../assets/images/regulatory/nist-csf.png"),
            controlCode: "nist",
        },
        {
            id: 3,
            abbreviation: "ISO 27001",
            displayName: "Information Security Management",
            image: require("../../assets/images/regulatory/iso-27001.png"),
            controlCode: "iso27001",
        },
        {
            id: 4,
            abbreviation: "CCPA",
            displayName: "California Consumer Privacy Act",
            image: require("../../assets/images/regulatory/ccpa.png"),
            controlCode: "ccpa",
        },
    ]);

    const [regulation, setRegulation] = useState(null);
    const [showControls, setShowControls] = useState(false);
    const [controls, setControls] = useState([]);

    useEffect(() => {
        async function getStats() {
            setLoading(true);
            let users = await countPeopleByTenantId(person?.tenant?.id, []);
            //console.log ('Users: ', users)
            setStats({
                numberOfAssessments: 10,
                numberOfPolicies: 20,
                numberOfControls: 80,
                numberOfCertifications: 2,
                numberOfUsers: users,
            });
            setLoading(false);
        }

        if (person) {
            setTimeout(() => {
                getStats();
            }, 1000);
        }
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleShowControls = (id) => {
        console.log("Clicked on: ", id);
        // Get the regulation
        const reg = getObjectById(regulations, id);
        setRegulation(reg);
        console.log("Regulation: ", reg);

        // Get the controls for the selected regulation
        console.log("All controls: ", Controls);
        const controls = Controls[reg.controlCode];
        console.log("Controls: ", controls);
        setControls(controls);
        setShowControls(true);
    };

    const handleHideControls = () => {
        setShowControls(false);
        setRegulation(null);
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }}>
            <Card>
                <CardHeader
                    title="AuditAI Dashboard"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    //subheader="Your Central Hub for Real-Time Insights and Actionable Data"
                    action={
                        <Button
                            startIcon={<Reply />}
                            //onClick={handleBack}
                            color="info"
                        >
                            Back
                        </Button>
                    }
                />
                <Divider />
                <CardContent sx={{ bgcolor: "#f9f9f9", px: 0 }}>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={{ position: "relative", width: "24%", p: 2 }}
                            gap={1}
                            className={classes.gradientBody}
                        >
                            <BackgroundIcon component={Assessment} bottom={50} />
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={1}>
                                <Typography variant="subtitle" color="white">
                                    Assessment
                                </Typography>
                                <Typography variant="body" color="white">
                                    Gap Analysis,Recommendations and Remediation
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box display="flex" flexDirection="row" justifyContent="flex-start">
                                    <IconButton sx={{ color: "white" }}>
                                        <Search />
                                    </IconButton>
                                    <IconButton sx={{ color: "white" }}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton sx={{ color: "white" }}>
                                        <Delete />
                                    </IconButton>
                                </Box>
                                <Button size="small" variant="contained" color="secondary">
                                    Start
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={{ position: "relative", width: "24%", p: 2 }}
                            gap={1}
                            className={classes.gradientBody}
                        >
                            <BackgroundIcon component={Policy} bottom={50} />
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={1}>
                                <Typography variant="subtitle" color="white">
                                    Policies and Controls
                                </Typography>
                                <Typography variant="body" color="white">
                                    Definition, Mapping, Publish and Enforcement
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box display="flex" flexDirection="row" justifyContent="flex-start">
                                    <IconButton sx={{ color: "white" }}>
                                        <Search />
                                    </IconButton>
                                    <IconButton sx={{ color: "white" }}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton sx={{ color: "white" }}>
                                        <Delete />
                                    </IconButton>
                                </Box>
                                <Button size="small" variant="contained" color="secondary">
                                    Manage
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={{ position: "relative", width: "24%", p: 2 }}
                            gap={1}
                            className={classes.gradientBody}
                        >
                            <BackgroundIcon component={AdminPanelSettings} bottom={50} />
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={1}>
                                <Typography variant="subtitle" color="white">
                                    Audit Management
                                </Typography>
                                <Typography variant="body" color="white">
                                    Risk Management, Reports and Evidence
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box display="flex" flexDirection="row" justifyContent="flex-start">
                                    <IconButton sx={{ color: "white" }}>
                                        <Search />
                                    </IconButton>
                                    <IconButton sx={{ color: "white" }}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton sx={{ color: "white" }}>
                                        <Delete />
                                    </IconButton>
                                </Box>
                                <Button size="small" variant="contained" color="secondary">
                                    Manage
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={{ position: "relative", width: "24%", p: 2 }}
                            gap={1}
                            className={classes.gradientBody}
                        >
                            <BackgroundIcon component={Apps} bottom={50} />
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={1}>
                                <Typography variant="subtitle" color="white">
                                    Reports
                                </Typography>
                                <Typography variant="body" color="white">
                                    Business and Operational Analytics
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box display="flex" flexDirection="row" justifyContent="flex-start">
                                    <IconButton sx={{ color: "white" }}>
                                        <Search />
                                    </IconButton>
                                </Box>
                                <Button size="small" variant="contained" color="secondary">
                                    View
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <AnnouncementSlider />

            <Box display="flex" flexDirection="row" flexWrap="wrap" sx={{ bgcolor: "#f9f9f9", p: 0 }} gap={1} mt={1} p={1}>
                {regulations.map((regulation) => {
                    return (
                        <Box
                            key={regulation.id}
                            display="flex"
                            flexDirection="column"
                            sx={{ position: "relative", width: "24%", p: 2, boxShadow: 2 }}
                            gap={1}
                            //className={classes.gradientBody}
                            onClick={() => handleShowControls(regulation.id)}
                        >
                            <BackgroundImage path={regulation.image} opacity={0.5} bottom={10} />
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={1}>
                                <Typography variant="subtitle">{regulation.abbreviation}</Typography>
                                <Typography variant="body">{regulation.displayName}</Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" width="60%">
                                <Stack direction="row" justifyContent="space-between" sx={{ color: "primary.main" }}>
                                    <Typography variant="body">Security Controls</Typography>
                                    <Typography variant="subtitle" sx={{ textDecoration: "underline" }} gutterBottom>
                                        10
                                    </Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" sx={{ color: "primary.main" }}>
                                    <Typography variant="body">Recommendations</Typography>
                                    <Typography variant="subtitle" sx={{ textDecoration: "underline" }} gutterBottom>
                                        2
                                    </Typography>
                                </Stack>
                            </Box>
                        </Box>
                    );
                })}
            </Box>

            {showControls && controls && (
                <Fade in={showControls} timeout={1000}>
                    <Card elevation={1}>
                        <CardHeader
                            title={`${regulation.abbreviation} Controls`}
                            titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                            subheader={`Important security controls for ${regulation.displayName}`}
                            action={
                                <Button variant="contained" startIcon={<Close />} onClick={handleHideControls} color="info">
                                    Close
                                </Button>
                            }
                        />
                        <Divider />
                        <ControlTable controls={controls} title="Controls" />
                    </Card>
                </Fade>
            )}
        </Box>
    );
}
