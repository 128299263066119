import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import ContactEditForm from "./ContactEditForm";

export default function OrganizationEditForm({ formData, errors, handleChange, includeAddress = true, includePhone = false, includeEmail = false, includeWebsite=true }) {
    return (
        <Box display="flex" flexDirection="column">
            <TextField
                name="name"
                label="Name"
                required
                fullWidth
                variant="standard"
                value={formData.name ?? ""}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
            />
            <TextField
                name="dba"
                label="Doing Business As/ Trade Name"
                fullWidth
                variant="standard"
                value={formData.dba ?? ""}
                onChange={handleChange}
                error={!!errors.dba}
                helperText={errors.dba}
            />
            <ContactEditForm formData={formData} errors={errors} handleChange={handleChange} includeAddress={includeAddress} includeWebsite={includeWebsite} />
        </Box>
    );
}
