import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Workspace from "../components/Workspace";
import Footer from "../components/Footer";
import { useAuthContext } from "../hooks/useAuthContext";
import { Route, Routes, useLocation } from "react-router-dom";
import * as routes from "../routes";
import * as Constants from "../constants/Constants";

// Create Home Component with a function and use Material ui to create header, content and footer sections. The styles must be responsive.

export default function Home() {
    const { user, profile } = useAuthContext();
    const [opened, setOpened] = useState(false);
    const [notificationsOpen, setNotificationsOpen] = useState(false);

    const resizeDispatch = () => {
        if (typeof Event === "function") {
            window.dispatchEvent(new Event("resize"));
        } else {
            const evt = window.document.createEvent("UIEvents");
            evt.initUIEvent("resize", true, false, window, 0);
            window.dispatchEvent(evt);
        }
    };

    const handleDrawerToggle = () => {
        console.log ('Toggle Drawer')
        setOpened(!opened);
        resizeDispatch();
    };

    const handleNotificationToggle = () => setNotificationsOpen(!notificationsOpen);

    const getRoutes = (
        <Routes>
            {routes.routePaths.map((item, index) => {
                const routeProps = {
                    exact: true,
                    path: item.path,
                    element: item.component,
                    name: item.name,
                    key: index,
                };

                if (item.type === Constants.ROUTE_EXTERNAL) {
                    return <Route {...routeProps} />;
                }

                if (item.type === Constants.ROUTE_SUBMENU) {
                    const submenuRoutes = item.children.map((subItem, subIndex) => (
                        <Route {...routeProps} path={`${item.path}${subItem.path}`} element={subItem.component} name={subItem.name} key={subIndex} />
                    ));
                    // Add the top level route
                    return [<Route {...routeProps} key={index} />, ...submenuRoutes];
                }

                return <Route {...routeProps} />;
            })}
        </Routes>
    );

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", width: "100vw" }}>
            <Header toggleDrawer={handleDrawerToggle} />
            <Box display="flex" flexDirection="row" alignItems="top" flexGrow={1}  gap={1} mt={8} mb={0.5} mx={1}>
                <Sidebar routes={routes.routePaths} opened={opened} toggleDrawer={handleDrawerToggle} />
                <Workspace >{getRoutes}</Workspace>
            </Box>
            <Footer />
        </Box>
    );
}
