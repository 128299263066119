import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { AccountCircle } from "@mui/icons-material";
import { findInvitation } from "../../services/InvitationService";
import { useSignup } from "../../hooks/useSignup";
import SignUpInfo from "./SignUpInfo";

export default function SignUp() {
    const { signup, error, isPending } = useSignup();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const [invitation, setInvitation] = useState();

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        terms: false,
    });
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        terms: "",
    });

    useEffect(() => {
        async function getInvitation() {
            if (code) {
                const invite = await findInvitation(code);
                //        console.log(invite);
                if (invite) {
                    setFormData({
                        ...formData,
                        firstName: invite.person.firstName,
                        lastName: invite.person.lastName,
                        email: invite.email,
                    });
                    setInvitation(invite);
                }
            }
        }
        getInvitation();
    }, [code]);

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        // Validate firstName field
        if (formData.firstName.trim() === "") {
            newErrors.firstName = "First name is required";
            isValid = false;
        } else {
            newErrors.firstName = "";
        }

        // Validate lastName field
        if (formData.lastName.trim() === "") {
            newErrors.lastName = "Last name is required";
            isValid = false;
        } else {
            newErrors.lastName = "";
        }

        // Validate email field
        if (formData.email.trim() === "") {
            newErrors.email = "Email is required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid email format";
            isValid = false;
        } else {
            newErrors.email = "";
        }

        // Validate password field
        if (formData.password.trim() === "") {
            newErrors.password = "Password is required";
            isValid = false;
        } else if (formData.password.length < 6) {
            newErrors.password = "Password must be at least 6 characters";
            isValid = false;
        } else {
            newErrors.password = "";
        }

        // Validate terms checkbox
        if (!formData.terms) {
            newErrors.terms = "You must accept the terms and conditions";
            isValid = false;
        } else {
            newErrors.terms = "";
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Form is valid, submit or perform further actions
            //console.log('Form submitted:', formData);
            await signup(formData.email, formData.password, formData.firstName, formData.lastName, invitation);
            //console.log ('Signup Process comple. Check for profile here.')
        }
    };

    const handleChange = (e) => {
        //const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            {/* Info Box */}
            <SignUpInfo />

            {/* Signin Box */}
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="50%" height="100%">
                
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        flexGrow: 1,
                    }}
                >
                    <Card elevation={0}>
                        <CardHeader
                            // title="CyberShield"
                            // titleTypographyProps={{ color: "primary.main", variant: "title" }}
                            subheader="Sign up for an account"
                            subheaderTypographyProps={{ color: "primary.main", variant: "headline" }}
                            sx={{ textAlign: "center" }}
                        ></CardHeader>
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                {code && (
                                    <>
                                        <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                                            {invitation && invitation.tenant.name} requests you to join the network.
                                        </Typography>
                                        <Typography variant="body1" fontWeight={"bold"}>
                                            Name
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {formData.firstName} {formData.lastName}
                                        </Typography>
                                        <Typography variant="body1" fontWeight={"bold"}>
                                            Email
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {formData.email}
                                        </Typography>
                                    </>
                                )}
                                {!code && (
                                    <>
                                        <TextField
                                            name="firstName"
                                            label="First Name"
                                            required
                                            fullWidth
                                            variant="standard"
                                            value={formData.firstName ?? ""}
                                            onChange={handleChange}
                                            error={!!errors.firstName}
                                            helperText={errors.firstName}
                                        />
                                        <TextField
                                            name="lastName"
                                            label="Last Name"
                                            required
                                            fullWidth
                                            variant="standard"
                                            value={formData.lastName ?? ""}
                                            onChange={handleChange}
                                            error={!!errors.lastName}
                                            helperText={errors.lastName}
                                        />
                                        <TextField
                                            name="email"
                                            label="Email"
                                            required
                                            fullWidth
                                            variant="standard"
                                            value={formData.email ?? ""}
                                            onChange={handleChange}
                                            error={!!errors.email}
                                            helperText={errors.email}
                                        />
                                    </>
                                )}

                                <TextField
                                    name="password"
                                    label="Password"
                                    type="password"
                                    required
                                    fullWidth
                                    variant="standard"
                                    value={formData.password}
                                    onChange={handleChange}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="terms"
                                            //checked={formData.terms}
                                            onChange={handleChange}
                                            color="primary"
                                        />
                                    }
                                    label="I accept the terms and conditions"
                                />
                                {errors.terms && <FormHelperText error>{errors.terms}</FormHelperText>}
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        marginTop: 2,
                                    }}
                                >
                                    <Button
                                        startIcon={<AccountCircle />}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={isPending && isPending ? true : false}
                                    >
                                        {isPending && isPending ? "Loading..." : "Create your account"}
                                    </Button>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        marginTop: 2,
                                    }}
                                >
                                    {error && <Alert severity="error">{error}</Alert>}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Link to={code ? `/forgot-password?code=${code}` : "/forgot-password"}>
                                        <Button>Forgot password?</Button>
                                    </Link>
                                    <Link to={code ? `/signin?code=${code}` : "/signin"}>
                                        <Button>Access your account</Button>
                                    </Link>
                                </Box>
                            </form>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
}
