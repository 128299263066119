export const Permissions = [
    {
        name: "Read",
        description: "Allows viewing or reading the content of files, directories, or resources.",
    },
    {
        name: "Write",
        description: "Grants the ability to modify, edit, or create new content in files, directories, or resources.",
    },
    {
        name: "Execute",
        description: "Enables the execution of scripts and executable files.",
    },
    {
        name: "Delete",
        description: "Allows the removal or deletion of files, folders, or records.",
    },
    {
        name: "Create",
        description: "Provides the ability to create new files, folders, or records in a system.",
    },
    {
        name: "Modify",
        description: "Allows making changes to existing content, including editing.",
    },
    {
        name: "Append",
        description: "Enables data to be added to the end of a file or resource without altering existing content.",
    },
    {
        name: "Full Control",
        description: "Grants complete access and control over a resource, including permission and ownership changes.",
    },
    {
        name: "Ownership",
        description: "Determines administrative control and authority over a resource, including permission and deletion rights.",
    },
    {
        name: "Read-Only",
        description: "Restricts users or processes to only viewing or reading content without making changes.",
    },
    {
        name: "Write-Only",
        description: "Allows users or processes to write data to a resource without reading its content.",
    },
    {
        name: "No Access",
        description: "Denies any form of access to the resource, effectively locking it from view or use.",
    },
];
