// Collection Constants 
export const DB_TENANTS = "tenants"
export const DB_PEOPLE = "people"
export const DB_PROFILES = "profiles"
export const DB_RELATIONS = "relations"
export const DB_INVITATIONS = "invitations"
export const DB_CARTS = "carts"
export const DB_ORDERS = "orders"

// assessments
export const DB_ASSESSMENTS = "assessments"
export const DB_ASSESSMENT_RESULTS = "assessment_results"

// application launch 
export const DB_APPLICATIONS = "applications"

// request management
export const DB_REQUESTS = "requests"


export const DB_POLICIES = "policies"
export const DB_CONTROLS = "controls"
export const DB_EVIDENCE = "evidence"
export const DB_AUDITS = "audits"
export const DB_NOTIFICATIONS = "notifications"

// admin 
export const DB_USERS = "users"
export const DB_ROLES = "roles"
export const DB_PERMISSIONS = "permissions"
export const DB_GROUPS = "groups"
export const DB_ASSETS = "assets"
export const DB_DEPARTMENTS = "departments"



