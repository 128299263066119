import { useEffect, useState } from "react";
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Divider, Fade, Stack, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { GridLoader } from "react-spinners";

//import * as SampleGap from "../../samples/SampleGap";
import { Close, LockClock, Person, PlaylistAddCheck, Radar, Reply, Restore, Reviews, Security, ShoppingCart, Visibility } from "@mui/icons-material";
import { ASSESSMENT_TYPE_GAP } from "../../constants/AssessmentConstants";
import { findGapAssessmentResult, getGapAnalysis } from "../../services/AssessmentResultService";
import { useTheme } from "@emotion/react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { generateRandomInteger } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

export default function GapAnalysis({ assessment, category, capability, setShowGap, auditor }) {
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const { person } = useAuthContext();
    const [capabilityCategories, setCapabilityCategories] = useState(null);
    const [capabilities, setCapabilities] = useState(null);

    const [analysis, setAnalysis] = useState(null);

    const gapCategories = [
        { name: "technology", altName: "technologyGaps", title: "Technology Gaps", color: "black" },
        { name: "process", altName: "processGaps", title: "Process Gaps", color: "secondary.main" },
        { name: "people", altName: "peopleGaps", title: "People Gaps", color: "primary.main" },
    ];

    useEffect(() => {
        getGapAnalysisResult();
    }, [assessment]);

    const getGapAnalysisResult = async () => {
        const saved = await findGapAssessmentResult(assessment?.id, ASSESSMENT_TYPE_GAP, category?.id, capability?.id);
        setAnalysis(saved);
    };

    const getPriorityIcon = (priority) => {
        let color = "success.main";

        switch (priority) {
            case "High":
                color = "error.light";
                break;
            case "Medium":
                color = "info.light";
                break;
            case "Low":
                color = "info.light";
                break;
            default:
                color = "success.light";
        }
        return <Typography sx={{ color: color, fontWeight: "bold" }}>{priority}</Typography>;
    };

    const getCsfFunction = (fn) => {
        switch (fn) {
            case "Identify":
                return (
                    <Tooltip title={fn}>
                        <Visibility />
                        <Typography>{fn}</Typography>
                    </Tooltip>
                );
            case "Protect":
                return (
                    <Tooltip title={fn}>
                        <LockClock />
                        <Typography>{fn}</Typography>
                    </Tooltip>
                );
            case "Detect":
                return (
                    <Tooltip title={fn}>
                        <Radar />
                        <Typography>{fn}</Typography>
                    </Tooltip>
                );
            case "Respond":
                return (
                    <Tooltip title={fn}>
                        <PlaylistAddCheck />
                        <Typography>{fn}</Typography>
                    </Tooltip>
                );
            case "Recover":
                return (
                    <Tooltip title={fn}>
                        <Restore />
                        <Typography>{fn}</Typography>
                    </Tooltip>
                );
            default:
                return (
                    <Tooltip title={fn}>
                        <Security />
                        <Typography>{fn}</Typography>
                    </Tooltip>
                );
        }
    };

    const renderGapCard = (gap) => {
        return (
            <Card key={generateRandomInteger(1, 1000)} sx={{ m: 1 }}>
                <CardHeader
                    display="flex"
                    title={gap.name}
                    titleTypographyProps={{ color: "primary.main", variant: "subtitle" }}
                    avatar={getCsfFunction(gap.coreFunction)}
                    action={getPriorityIcon(gap.riskPosture)}
                    sx={{ textAlign: "center" }}
                />
                <CardContent sx={{ p: 1, m: 0 }}>
                    <Typography variant="body"> {gap.impact} </Typography>
                </CardContent>
                <Divider />
                <CardActions
                    //disableSpacing
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingRight: 4,
                    }}
                >
                    {gap?.categories?.map((category, index) => {
                        return (
                            <Typography key={index} variant="body">
                                {category}
                            </Typography>
                        );
                    })}
                </CardActions>
            </Card>
        );
    };

    const handleAssessGap = async () => {
        setLoading(true);
        console.log("Assessment: ", assessment?.id, "Category: ", category?.id, "Capability: ", capability?.id);
        await getGapAnalysis(person, assessment, auditor, category, capability);

        // Now get the gap analysis result
        await getGapAnalysisResult();
        setLoading(false);
    };

    const handleBack = () => {
        // Navigate to the Gap Analysis page
        setShowGap(false);
        navigate(`/audit-ai/assessments/${assessment?.id}/result?mod=false&tab=1`);
    };

    const getGapTitle = () => {
        const category = capabilityCategories[category ?? 0];
        const capability = capabilities[capability ?? 0];
        return `Gaps in ${category.name} / ${capability.name}`;
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
            <Typography variant="subtitle" mt={4}>
                Performing gap analysis based on your security posture...
            </Typography>
        </Box>
    ) : (
        <Box display="flex" flexDirection="column" mt={2}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle">Lack of {capability.name}</Typography>
                <span className="flexSpacer" />
                <Button variant="contained" startIcon={<Reviews />} onClick={handleAssessGap} color="primary">
                    Re-assess
                </Button>
                <Button startIcon={<Close />} onClick={handleBack} color="warning">
                    Close
                </Button>
            </Stack>

            <Box display="flex" flexDirection="row" gap={2} mt={2}>
                {gapCategories.map((category, index) => {
                    return (
                        <Fade key={index} in={true} timeout={1000} unmountOnExit>
                            <Box width="33%" display="flex" flexDirection="column" sx={{ boxShadow: 1, bgcolor: category?.color }} textAlign="center">
                                <Typography variant="subtitle" color="white" m={2}>
                                    {category.title}
                                </Typography>

                                {analysis?.gaps?.[category.name].map((gap, index) => {
                                    return renderGapCard(gap);
                                })}
                            </Box>
                        </Fade>
                    );
                })}
            </Box>

            <Typography variant="subtitle" p={2}>
                Recommended for you
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap" sx={{ boxShadow: 2 }} gap={1} m={1}>
                <Card elevation={4} sx={{ maxWidth: "50%", bgcolor: "#fff", p: 1 }}>
                    <CardHeader
                        title="Alisha says"
                        titleTypographyProps={{ variant: "subtitle" }}
                        // subheader="The best way to fix security vulnerabilities is to adopt a proactive and holistic approach that includes regular assessments, prompt patching, continuous monitoring, and comprehensive employee training. Additionally, staying informed about emerging threats and evolving security practices is essential to effectively mitigate risks and safeguard your organization's assets."
                        // subheaderTypographyProps={{ fontSize: "0.8rem" }}
                    />
                    <CardContent sx={{display:"flex", flexDirection:"row", justifyContent: "center", alignItems: "center"}}>
                        <img src={require("../../assets/images/alisha.png")} />
                        <Typography variant="smallbody">
                            The best way to fix security vulnerabilities is to adopt a proactive and holistic approach that includes regular
                            assessments, prompt patching, continuous monitoring, and comprehensive employee training. Additionally, staying informed
                            about emerging threats and evolving security practices is essential to effectively mitigate risks and safeguard your
                            organization's assets.
                        </Typography>
                    </CardContent>
                </Card>
                <Box display="flex" flexDirection="row" justifyContent="space-evenly" gap={1} flexShrink={0}>
                    <Fade in={true} timeout={1000} unmountOnExit>
                        <Card elevation={4} sx={{ maxWidth: "32%", bgcolor: "#fff", p: 1 }}>
                            <CardHeader
                                title="Steve Rodgers"
                                titleTypographyProps={{ variant: "subtitle" }}
                                subheader="Your AI Defender"
                                subheaderTypographyProps={{ fontSize: "0.8rem" }}
                            />
                            <CardContent>
                                <Avatar sx={{ bgcolor: "primary.main", width: 100, height: 100 }}>
                                    <Person sx={{ width: 80, height: 80 }} />
                                </Avatar>
                            </CardContent>
                            <CardActions
                                sx={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                    paddingRight: 2,
                                }}
                            >
                                <Button startIcon={<ShoppingCart />} variant="contained" color="secondary">
                                    Add
                                </Button>
                            </CardActions>
                        </Card>
                    </Fade>
                    <Fade in={true} timeout={1000} unmountOnExit>
                        <Card elevation={4} sx={{ maxWidth: "32%", bgcolor: "#fff", p: 1 }}>
                            <CardHeader
                                title="Tony Stark"
                                titleTypographyProps={{ variant: "subtitle" }}
                                subheader="Chief Security Officer"
                                subheaderTypographyProps={{ fontSize: "0.8rem" }}
                            />
                            <CardContent>
                                <Avatar sx={{ bgcolor: "primary.main", width: 100, height: 100 }}>
                                    <Person sx={{ width: 80, height: 80 }} />
                                </Avatar>
                            </CardContent>
                            <CardActions
                                sx={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                    paddingRight: 2,
                                }}
                            >
                                <Button startIcon={<ShoppingCart />} variant="contained" color="secondary">
                                    Add
                                </Button>
                            </CardActions>
                        </Card>
                    </Fade>
                    <Fade in={true} timeout={1000} unmountOnExit>
                        <Card elevation={4} sx={{ maxWidth: "32%", bgcolor: "#fff", p: 1 }}>
                            <CardHeader
                                title="T'Challa"
                                titleTypographyProps={{ variant: "subtitle" }}
                                subheader="Offshore Coordinator"
                                subheaderTypographyProps={{ fontSize: "0.8rem" }}
                            />
                            <CardContent>
                                <Avatar sx={{ bgcolor: "primary.main", width: 100, height: 100 }}>
                                    <Person sx={{ width: 80, height: 80 }} />
                                </Avatar>
                            </CardContent>
                            <CardActions
                                sx={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                    paddingRight: 2,
                                }}
                            >
                                <Button startIcon={<ShoppingCart />} variant="contained" color="secondary">
                                    Add
                                </Button>
                            </CardActions>
                        </Card>
                    </Fade>
                </Box>
            </Box>
        </Box>
    );
}
