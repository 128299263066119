import { Fingerprint, PersonAdd } from "@mui/icons-material";
import { Avatar, Card, CardContent, CardHeader, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { BackgroundIcon } from "../../utils/UIStyles";

const useStyles = makeStyles((theme) => ({
    gradientHeader: {
        background: `linear-gradient(to right bottom, ${theme.palette.primary.light}, black)`,
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
    gradientBody: {
        background: `linear-gradient(to right bottom, black, ${theme.palette.primary.light})`,
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
}));

export default function StatCard({ type, title, value, icon, color }) {
    const classes = useStyles();

    return (
        <Card elevation={1} >
            <CardHeader
                title={value}
                titleTypographyProps={{ color: "white", fontSize: "1.6rem" }}
                subheader={title}
                subheaderTypographyProps={{ color: "#F5F5F5", fontSize: "1rem" }}
                sx={{ textAlign: "center", minHeight: 100 }}
                className={classes.gradientHeader}
                avatar={
                    <Avatar sx={{ bgcolor: "black" }}>
                        {icon}
                    </Avatar>
                }
            />
            
            
        </Card>
        
    );
}
