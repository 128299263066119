import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { deleteInvitation, saveInvitation } from "../../services/InvitationService";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { GridLoader } from "react-spinners";
import { checkPermission } from "../../utils/AuthenticationUtils";
import * as Authority from "../../constants/Authorities";
import { Link, useNavigate } from "react-router-dom";
import StatusMessage from "../../components/StatusMessage";
import { formatDate, formatDateTime } from "../../utils/utils";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import { useTheme } from "@emotion/react";

export default function InvitationTable({ invitations, isTab }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [rowModesModel, setRowModesModel] = useState({});
    const [rows, setRows] = useState([]);
    const { user, invitation } = useAuthContext();
    const [loading, setLoading] = useState(false);

    // Warning states
    const [selectedId, setSelectedId] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [openMultipleDelete, setOpenMultipleDelete] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    useEffect(() => {
        setRows(invitations);
    }, [invitations]);

    const columns = [
        {
          field: "createdDate",
          headerName: "Date",
          width: "200",
          valueGetter: (params) => {
              return params.row.createdDate ? formatDateTime(params.row.createdDate) : "";
          },
      },
        
        {
          field: "notificationType",
          headerName: "Type",
          width: 100,
        },
        {
          field: "displayName",
          headerName: "Sent To",
          width: "150",
          valueGetter: (params) => {
            return params.row.person.displayName
          }
        },
        {
          field: "to",
          headerName: "Channel",
          width: "200",
        },
        {
          field: "expirationDate",
          headerName: "Expires on",
          width: "150",
          valueGetter: (params) => {
            return params.row.expirationDate ? formatDate(params.row.expirationDate) : "";
          }
        },
        // {
        //   field: "completed",
        //   headerName: "Completed",
        //   width: "100",
        //   valueGetter: (params) => {
        //     return params.row.completed ? "Yes" : "No";
        //   }
        // },
        {
          field: "actions",
          type: "actions",
          headerName: "Actions",
          width: 200,
          cellClassName: "actions",
    
          getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    
            return [
              <GridActionsCellItem
                icon={<Delete color="secondary" />}
                label="Delete"
                onClick={handleDeleteInvitation(id)}
                color="inherit"
              />,
            ];
          },
        },
      ];

    const handleAddInvitation = () => {
        
        navigate("/imag-ai/invitations/new");
    };

    const handleEditInvitation = (id) => () => {
        // console.log("clicked Edit", id);
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        navigate(`/imag-ai/invitations/edit/${id}`);
    };

    const handleDeleteInvitation = (id) => async () => {
        setRows(rows.filter((row) => row.id !== id));
        await deleteInvitation(id);
        handleCloseDelete();
        setStatus({ open: true, type: "success", message: "Deleted Invitation" });
    };

    const handleDeleteMultiple = () => {
        console.log ('Deleting multiple invitations')
        if (selectedRows) {
            console.log ('Selected rows: ', selectedRows)
            selectedRows.map(async (id) => {
                await deleteInvitation(id);
            });
            handleCloseMultipleDelete();
            setStatus({
                open: true,
                type: "success",
                message: "Deleted Invitation(s)",
            });
        }
    };

    const handleProcessRowUpdate = async (updatedRow, originalRow) => {
        const s = await saveInvitation(invitation.id, updatedRow);
        setStatus({ open: true, type: "success", message: "Saved Invitation" });
        return s;
    };
    const handleProcessRowUpdateError = (err) => {
        console.log(err);
        setStatus({ open: true, type: "error", message: err });
    };

    const handleSelectionModelChange = (selectedIds) => {
        setSelectedRows(selectedIds);
    };

    // Returns a custom tool bar for the invitation table
    function InvitationTableToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <GridToolbarQuickFilter />

                <Box sx={{ marginRight: 2 }}>
                    <GridToolbarExport />
                    {checkPermission(invitation?.role, Authority.PERMISSION_INVITATION_CAN_DELETE) && (
                        <Button
                            startIcon={<Delete color="secondary" />}
                            onClick={handleOpenMultipleDelete}
                            disabled={selectedRows && selectedRows.length > 0 ? false : true}
                        >
                            Delete
                        </Button>
                    )}
                    
                </Box>
            </GridToolbarContainer>
        );
    }

    function NoRowsOverlay() {
        return (
            <Box display="flex" flexDirection="column" justifyContent={"center"} alignItems={"center"}>
                <Typography sx={{ padding: 2 }}>No invitations found</Typography>
            </Box>
        );
    }

    const handleOpenDelete = (id) => {
        setSelectedId(id);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setSelectedId(null);
    };
    const handleOpenMultipleDelete = () => {
        setOpenMultipleDelete(true);
    };

    const handleCloseMultipleDelete = () => {
        setOpenMultipleDelete(false);
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <div style={{ width: "100%" }}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={DEFAULT_PAGE_SIZE}
                checkboxSelection
                disableRowSelectionOnClick
                autoHeight
                slots={{
                    toolbar: InvitationTableToolbar,
                    noRowsOverlay: NoRowsOverlay,
                }}
                sx={{
                    border: 0,
                    boxShadow: 0,
                    fontSize: "1rem",
                    
                }}
                processRowUpdate={handleProcessRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                // Called on checkbox selection
                selectionModel={selectedRows}
                onRowSelectionModelChange={handleSelectionModelChange}
            />

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDelete} onClose={handleCloseDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Delete invitation ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting a invitation will remove them from the system. Are you sure?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDelete} color="inherit">
                        No
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleDeleteInvitation(selectedId)} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Multiple Confirmation Dialog */}
            <Dialog
                open={openMultipleDelete}
                onClose={handleCloseMultipleDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete invitations?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting invitations will remove them from the system. Are you sure?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseMultipleDelete} color="inherit">
                        No
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleDeleteMultiple} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
