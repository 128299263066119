import { Reply } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { findAllApplications } from "../../services/ApplicationService";
import ApplicationTable from "./ApplicationTable";

export default function ApplicationList() {
    const navigate = useNavigate();
    const [applications, setApplications] = useState([]);
    const { state } = useLocation();
    const { refresh } = { ...state };


    useEffect(() => {
        async function getApplications() {
            const results = await findAllApplications();
            setApplications(results);
        }

        getApplications();
    }, [refresh]);

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Card>
                <CardHeader
                    title="Applications"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="View your applications or start a new one"
                    action={
                        <Button startIcon={<Reply />} onClick={handleBack} color="info">
                            Back
                        </Button>
                    }
                />
                <Divider />
                {applications && <CardContent>{applications && <ApplicationTable applications={applications} title="Applications" />}</CardContent>}
            </Card>
        </Box>
    );
}
