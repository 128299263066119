import { Delete, Reply, ShoppingCart } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { findCartByUserId, placeOrder, removeItemFromCart } from "../../services/CartService";
import UnderConstructionImage from "../../assets/images/under-construction.gif";
import { useTheme } from "@emotion/react";
import { GridLoader } from "react-spinners";
import StatusMessage from "../../components/StatusMessage";

export default function Cart() {
    const navigate = useNavigate();
    const [cart, setCart] = useState(null);
    const { person } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    useEffect(() => {
        async function getUserCart() {
            setLoading(true);
            // console.log("Get user cart for user: ", person.id);
            const c = await findCartByUserId(person.id);
            setCart(c);
            setLoading(false);
        }

        getUserCart();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handlePlaceOrder = async () => {
        setLoading(true);
        // Update the order as placed and remove the items in cart.
        try {
            await placeOrder(person?.id, person?.tenant?.id);
            setStatus({
                open: true,
                type: "success",
                message: "Your order has been placed successfully. We will get back to you within 24 hours.",
            });
            setTimeout(() => {
                navigate(-1);
            }, 2000);
        } catch (error) {
            console.error(error);
            setStatus({
                open: true,
                type: "error",
                message: "Could not complete your order. Please try again later.",
            });
        }
        setLoading(false);
    };

    const handleDeleteItem = async (itemId) => {
        console.log("Remove from cart item: ", itemId);
        await removeItemFromCart(person.id, itemId);
        const c = await findCartByUserId(person.id);
        setCart(c);
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <Card elevation={1}>
                <CardHeader
                    title="Your Cart"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    action={
                        <Button startIcon={<Reply />} onClick={handleBack} color="info">
                            Back
                        </Button>
                    }
                />
                <Divider />
                <CardContent sx={{ bgcolor: "#f9f9f9" }}>
                    <Box display="flex" flexDirection="row" gap={2}>
                        <Box width="55%" sx={{ bgcolor: "#fff" }}>
                            <Typography variant="subtitle">1. Select Payment Method</Typography>
                            <List>
                                <ListItem
                                    sx={{ gap: 4 }}
                                    secondaryAction={
                                        <Box sx={{ marginRight: 2 }}>
                                            <Link to="#">
                                                <Button>Change</Button>
                                            </Link>
                                        </Box>
                                    }
                                >
                                    <ListItemText
                                        primary="PayPal"
                                        primaryTypographyProps={{ fontWeight: "bold" }}
                                        secondary="Your preferred method of payment"
                                    />
                                </ListItem>
                            </List>

                            <Typography variant="subtitle">2. Review Items</Typography>
                            <List>
                                {cart?.items?.length === 0 && <Typography variant="subtitle">No items in your cart</Typography>}
                                {cart?.items?.map((item) => {
                                    return (
                                        <ListItem
                                            key={item.productId}
                                            sx={{ gap: 4 }}
                                            secondaryAction={
                                                <Box sx={{ marginRight: 2 }}>
                                                    <Button startIcon={<Delete color="warning" />} onClick={() => handleDeleteItem(item?.productId)}>
                                                        Remove
                                                    </Button>
                                                </Box>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <img src={UnderConstructionImage} alt="Product" width={60} height={60} />
                                            </ListItemAvatar>

                                            <ListItemText primary={item.productName} secondary={`Quantity: ${item?.quantity}`} />
                                            <Divider />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Box>
                        {cart?.items?.length > 0 && (
                            <Box
                                width="45%"
                                sx={{ bgcolor: "#fff" }}
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                gap={4}
                                p={2}
                            >
                                <Typography variant="headline" color="primary">
                                    Lets make it official
                                </Typography>
                                <Typography variant="subtitle1">
                                    Proceed with the checkout now and seal the deal on your desired services. Our team will respond to you within 24
                                    hours.
                                </Typography>

                                <Button variant="contained" startIcon={<ShoppingCart />} color="secondary" onClick={handlePlaceOrder}>
                                    Place Order
                                </Button>
                            </Box>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}
