import { Box, Button, Card, CardContent, CardHeader, Divider, List, ListItem, ListItemText, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Applications } from "../../samples/applications";
import { Add, Close } from "@mui/icons-material";
import { useAuthContext } from "../../hooks/useAuthContext";
import { saveAsset } from "../../services/AssetService";

const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
        <div application="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

export default function ApplicationKB({ toggleView }) {
    const { person } = useAuthContext();
    const [applications, setApplications] = useState([]);
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, index) => {
        setTab(index);
    };

    const handleClose = () => {
        toggleView(false);
    };

    const handleAdd = async (key, asset) => {
        // Set the type of asset as application
        asset.assetType = "Application";
        await saveAsset(person.uid, person.tenant.id, asset);
        // // Remove the item from the list of applications
        const newApplications = applications[key].filter((p) => p.id !== asset.id);
        applications[key] = newApplications;
        setApplications({ ...applications });
    };

    useEffect(() => {
        setApplications(Applications);
    }, []);
    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Card>
                <CardHeader
                    title="Applications"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="Search and add applications from the knowledge base. These have been categorized by business function."
                    action={
                        <Button variant="contained" startIcon={<Close />} onClick={handleClose} color="info">
                            Close
                        </Button>
                    }
                />
                <Divider />
                <CardContent>
                        <Tabs value={tab} onChange={handleTabChange} indicatorColor="primary" variant="scrollable" scrollButtons="auto">
                            {applications && Object.keys(applications).map((key, index) => <Tab key={index} label={key} />)}
                        </Tabs>
                    {applications &&
                        Object.keys(applications).map((key, index) => (
                            <TabPanel key={index} value={tab} index={index}>
                                <List>
                                    {applications[key].map((application, index) => (
                                        <ListItem
                                            key={index}
                                            secondaryAction={
                                                <Button variant="outlined" startIcon={<Add />} onClick={() => handleAdd(key, application)} color="info">
                                                    Add
                                                </Button>
                                            }
                                        >
                                            <ListItemText primary={application.name} secondary={application.description} />
                                        </ListItem>
                                    ))}
                                </List>
                            </TabPanel>
                        ))}
                </CardContent>
            </Card>
        </Box>
    );
}
