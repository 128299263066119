import { Reply } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { handleFormChange } from "../../utils/FormUtils";
import { useTheme } from "@mui/material/styles";
import { GridLoader } from "react-spinners";
import { useAuthContext } from "../../hooks/useAuthContext";
import StatusMessage from "../../components/StatusMessage";
import { findOrganization } from "../../services/OrganizationService";
import { addYears, formatISO, set, startOfDay } from "date-fns";
import { makeStyles } from "@mui/styles";
import PersonEditForm from "../../components/PersonEditForm";
import OrganizationEditForm from "../../components/OrganizationEditForm";
import { findPerson } from "../../services/PersonService";
import { saveTenant } from "../../services/TenantService";

const useStyles = makeStyles((theme) => ({
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        fontStyle: "italic",
    },
}));

export default function TenantEdit() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { person } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const theme = useTheme();

    // Determine if its a new or existing tenant based on the id from the URL
    const { id: tenantId } = useParams();

    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    const [organizationFormData, setOrganizationFormData] = useState({
        name: "",
        dba: "",
        country: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipcode: "",
        subscriptionStart: startOfDay(new Date()),
        subscriptionEnd: startOfDay(addYears(new Date(), 1)),
        website: "",
    });

    const [personFormData, setPersonFormData] = useState({
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
        phone: "",
        email: "",
    });

    const [errors, setErrors] = useState({
        name: "",
    });

    // Step functions
    const stepContent = ["Complete the organization details below. ", "Primary contact for the organization.", "That's it! We are done. "];
    const [activeStep, setActiveStep] = useState(0);
    const steps = ["Organization Details", "Contact", "Complete"];

    useEffect(() => {
        async function getTenant() {
            const data = await findOrganization(tenantId);
            setOrganizationFormData(data);
            if (data?.primaryContact){
                const contact = await findPerson(data.primaryContact.id);
                setPersonFormData(contact);
            }


        }

        if (tenantId) getTenant();
    }, [tenantId]);

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const orgValid = validateOrganizationForm();
        const contactValid = validateContactForm();
        // if both are valid, then the form is valid
        isValid = orgValid && contactValid;
        setErrors(newErrors);
        return isValid;

    };

    const validateOrganizationForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        // Validate name field
        if (organizationFormData.name.trim() === "") {
            newErrors.name = "Tenant name is required";
            isValid = false;
        } else {
            newErrors.name = "";
        }

        setErrors(newErrors);
        return isValid;
    };

    const validateContactForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        // Validate contact 
        if (personFormData.firstName.trim() === "") {
            newErrors.firstName = "First name is required";
            isValid = false;
        } else {
            newErrors.firstName = "";
        }

        if (personFormData.lastName.trim() === "") {
            newErrors.lastName = "Last name is required";
            isValid = false;
        }   else {
            newErrors.lastName = "";
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async () => {
        //e.preventDefault();
        setIsPending(true);
        setError(null);

        try {
            // Save the tenant
            if (validateForm()) {
                const savedTenant = await saveTenant(person.id, personFormData, organizationFormData);
                setOrganizationFormData(savedTenant);
                const savedPerson = await findPerson(savedTenant.primaryContact.id);
                setPersonFormData(savedPerson);
                setStatus({
                    open: true,
                    type: "success",
                    message: `Updated tenant details`,
                });
            }else {
                console.log ('Validation failed')
            }

            setError(null);
            setIsPending(false);
            navigate(-1);
        } catch (err) {
            setError(err.message);
            setIsPending(false);
        }
    };

    const handleNext = async () => {
        if (activeStep === 0) {
            setError(null);
            if (validateOrganizationForm()) {
                setError(null);
                setActiveStep(activeStep + 1);
            }
        } else if (activeStep === 1) {
            setError(null);
            if (validateContactForm()) {
                setError(null);
                setActiveStep(activeStep + 1);
            }
        } 
        else {
            setActiveStep(activeStep + 1);
        }

        // Last step - save the organization
        if (activeStep === steps.length - 1) {
            await handleSubmit();
        }
    };

    const handleCancel = (e) => {
        navigate(-1);
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <Card>
                <CardHeader
                    title={tenantId ? `Edit ${organizationFormData.name}` : "Add Tenant"}
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="Complete the details for the tenant"
                    action={
                        <Button startIcon={<Reply />} onClick={handleBack} color="info">
                            Back
                        </Button>
                    }
                />
                <Divider />
                <CardContent>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <Box mt={4}>
                        {activeStep === steps.length ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "70vw",
                                    height: "70vh",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <GridLoader color={theme.palette.primary.light} />
                            </Box>
                        ) : (
                            <div>
                                <Typography className={classes.instructions}>{stepContent[activeStep]}</Typography>
                                {activeStep === 0 && (
                                    <Box display="flex" flexDirection="column" width="50%">
                                        <OrganizationEditForm formData={organizationFormData} errors={errors} handleChange={(e) => handleFormChange (setOrganizationFormData, e)} />
                                    </Box>
                                )}

                                {activeStep === 1 && (
                                    <Box display="flex" flexDirection="column" width="50%">
                                        <PersonEditForm formData={personFormData} errors={errors} handleChange={(e) => handleFormChange (setPersonFormData, e)} includeAddress={false}/>
                                    </Box>
                                )}
                            </div>
                        )}
                    </Box>
                </CardContent>
                <CardActions
                    //disableSpacing
                    sx={{
                        alignSelf: "stretch",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        paddingRight: 4,
                    }}
                >
                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                        Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                        {activeStep === steps.length - 1 ? "Done" : "Next"}
                    </Button>
                </CardActions>
            </Card>
        </Box>
    );
}
