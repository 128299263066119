import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc } from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { updateAudit } from "../utils/utils";

// Create a new department
export const createDepartment = async (uid, department) => {
    const docRef = doc(collection(db, DBConstants.DB_DEPARTMENTS));
    department.id = docRef.id;
    updateAudit(uid, department);
    await setDoc(docRef, department, { merge: true });
    return await findDepartment(department.id);
};

// update an existing department
export const updateDepartment = async (uid, department) => {
    updateAudit(uid, department);
    const docRef = doc(db, DBConstants.DB_DEPARTMENTS, department.id);
    await setDoc(docRef, { ...department }, { merge: true });
    return await findDepartment(department.id);
};

// Create a new department or update an existing one
export const saveDepartment = async (uid, tenantId, department) => {
    department.tenantId = tenantId;
    if (department.id) {
        return await updateDepartment(uid, department);
    } else {
        return await createDepartment(uid, department);
    }
};

// Delete an department
export const deleteDepartment = async (departmentId) => {
    // Delete the department
    const docRef = doc(db, DBConstants.DB_DEPARTMENTS, departmentId);
    await deleteDoc(docRef);
};

// Find an department with id
export const findDepartment = async (id) => {
    const docRef = doc(db, DBConstants.DB_DEPARTMENTS, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
};

// Find all departments for a tenant
export const findAllDepartments = async (tenantId) => {
    let results = [];
    const q = query(
        collection(db, DBConstants.DB_DEPARTMENTS)
        //where("tenantId", "==", tenantId)
    );
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

