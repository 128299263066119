import {
    AccountBalance,
    AccountCircle,
    AltRoute,
    Apps,
    AssuredWorkload,
    BusinessCenter,
    Cancel,
    Category,
    CellTower,
    Check,
    DashboardCustomize,
    DynamicForm,
    ImportantDevices,
    LiveTv,
    LocalHospital,
    LocationCity,
    Luggage,
    PrecisionManufacturing,
    School,
    Security,
    Speed,
    Storefront,
} from "@mui/icons-material";
import { sortObjectsByOrder } from "../utils/utils";
import { AI_AUDITOR_STYLE_PROGRESSIVE, AI_AUDITOR_STYLE_BALANCED, AI_AUDITOR_STYLE_CONSERVATIVE } from "./AIConstants";

export const RegionCodes = "RegionCodes";
export const CountryCodes = "CountryCodes";
export const USStateCodes = "USStateCodes";
export const IndustryCodes = "IndustryCodes";
export const RegulationCodes = "RegulationCodes";
export const RequestTypes = "RequestTypes";
export const OrganizationSizeCodes = "OrganizationSizeCodes";
export const IAMTeamSizeCodes = "IAMTeamSizeCodes";
export const IAMSolutionReasonCodes = "IAMSolutionReasonCodes";
export const IAMSolutionCapabilityCodes = "IAMSolutionCapabilityCodes";
export const IAMSolutionsTypes = "IAMSolutionsTypes";
export const YesNoCodes = "YesNo";
export const AuditorTypes = "AuditorTypes";
export const Products = "Products";
export const Pricing = "Pricing";

export const AssetType = "AssetType";

export const OTHER_ID = 9999;

export function getCodelist(name) {
    let list = Codelist[name];
    if (!list) {
        console.error("Codelist not found: ", name);
        return [];
    }
    list = sortObjectsByOrder(list);
    return list;
}

export const Codelist = {
    [YesNoCodes]: [
        { id: 1, name: "Yes", order: 1, default: false, image: <Check sx={{ fontSize: "4rem" }} /> },
        { id: 2, name: "No", order: 2, default: true, image: <Cancel sx={{ fontSize: "4rem" }} /> },
    ],
    [RequestTypes]: [{ id: 1, name: "Tenant Onboarding", code: "NewTenant" }],
    [RegionCodes]: [
        { id: 1, name: "North America", code: "NA", order: 1, default: true, image: "regions/north_america.png" },
        { id: 2, name: "South America", code: "SA", order: 2, default: false, image: "regions/south_america.png" },
        { id: 3, name: "Europe", code: "EU", order: 3, default: false, image: "regions/europe.png" },
        { id: 4, name: "Asia", code: "AS", order: 4, default: false, image: "regions/asia.png" },
        { id: 5, name: "Africa", code: "AF", order: 5, default: false, image: "regions/africa.png" },
        { id: 6, name: "Australia", code: "OC", order: 6, default: false, image: "regions/australia.png" },
        { id: OTHER_ID, name: "Other", code: "", order: OTHER_ID, default: false, image: "regions/other.png" },
    ],
    [IndustryCodes]: [
        { id: 1, name: "Financial Services", code: "finance", order: 1, default: true, image: <AccountBalance sx={{ fontSize: "4rem" }} /> },
        { id: 2, name: "Healthcare and Life Sciences", code: "health", order: 2, default: false, image: <LocalHospital sx={{ fontSize: "4rem" }} /> },
        { id: 3, name: "Retail and Consumer Goods", code: "retail", order: 3, default: false, image: <Storefront sx={{ fontSize: "4rem" }} /> },
        {
            id: 4,
            name: "Manufacturing and Industrial",
            code: "manufacturing",
            order: 4,
            default: false,
            image: <PrecisionManufacturing sx={{ fontSize: "4rem" }} />,
        },
        { id: 5, name: "Media and Entertainment", code: "entertainment", order: 5, default: false, image: <LiveTv sx={{ fontSize: "4rem" }} /> },
        { id: 6, name: "Energy and Utilities", code: "energy", order: 6, default: false, image: <DynamicForm sx={{ fontSize: "4rem" }} /> },
        { id: 7, name: "Technology", code: "technology", order: 7, default: false, image: <ImportantDevices sx={{ fontSize: "4rem" }} /> },
        { id: 8, name: "Telecommunications", code: "telecom", order: 8, default: false, image: <CellTower sx={{ fontSize: "4rem" }} /> },
        {
            id: 9,
            name: "Government and Public Sector",
            code: "government",
            order: 9,
            default: false,
            image: <LocationCity sx={{ fontSize: "4rem" }} />,
        },
        { id: 10, name: "Education", code: "education", order: 10, default: false, image: <School sx={{ fontSize: "4rem" }} /> },
        {
            id: 11,
            name: "Transportation and Hospitality",
            code: "transport",
            order: 11,
            default: false,
            image: <Luggage sx={{ fontSize: "4rem" }} />,
        },
        { id: OTHER_ID, name: "Other", code: "other", order: OTHER_ID, default: false, image: <BusinessCenter sx={{ fontSize: "4rem" }} /> },
    ],
    [OrganizationSizeCodes]: [
        { id: 1, name: "Small", description: "1-100", order: 1, default: true },
        { id: 2, name: "Medium", description: "101-500", order: 2, default: false },
        { id: 3, name: "Large", description: "501-1000", order: 3, default: false },
        { id: 4, name: "Enterprise", description: "1000+", order: 4, default: false },
    ],
    [IAMTeamSizeCodes]: [
        { id: 1, name: "Small", description: "1-5", order: 1, default: true },
        { id: 2, name: "Medium", description: "6-15", order: 2, default: false },
        { id: 3, name: "Large", description: "16-30", order: 3, default: false },
        { id: 4, name: "Very Large", description: "30+", order: 4, default: false },
        { id: 5, name: "None", description: "No IAM team", order: 4, default: false },
    ],
    [IAMSolutionReasonCodes]: [
        { id: 1, name: "Enhance security", order: 1, default: true, image: <Security sx={{ fontSize: "4rem" }} /> },
        { id: 2, name: "Simplify user authentication", order: 2, default: false, image: <AccountCircle sx={{ fontSize: "4rem" }} /> },
        { id: 3, name: "Increase operational efficiency", order: 3, default: false, image: <Speed sx={{ fontSize: "4rem" }} /> },
        { id: 4, name: "Improve compliance", order: 4, default: false, image: <AssuredWorkload sx={{ fontSize: "4rem" }} /> },
        { id: OTHER_ID, name: "Other", order: OTHER_ID, default: false, image: <AltRoute sx={{ fontSize: "4rem" }} /> },
    ],
    [IAMSolutionCapabilityCodes]: [
        { id: 1, name: "Single Sign-on (SSO)", order: 1, default: true, image: <Security sx={{ fontSize: "4rem" }} /> },
        { id: 2, name: "Multi-factor Authentication (MFA)", order: 2, default: false, image: <Security sx={{ fontSize: "4rem" }} /> },
        { id: 3, name: "User Provisioning", order: 3, default: false, image: <Security sx={{ fontSize: "4rem" }} /> },
        { id: 4, name: "Access Management", order: 4, default: false, image: <Security sx={{ fontSize: "4rem" }} /> },
        { id: 5, name: "Privileged Access Management (PAM)", order: 5, default: false, image: <Security sx={{ fontSize: "4rem" }} /> },
        { id: 6, name: "Identity Governance and Administration (IGA)", order: 6, default: false, image: <Security sx={{ fontSize: "4rem" }} /> },
        { id: 7, name: "Customer Identity and Access Management (CIAM)", order: 7, default: false, image: <Security sx={{ fontSize: "4rem" }} /> },
        { id: 8, name: "Auditing and Reporting", order: 8, default: false, image: <Security sx={{ fontSize: "4rem" }} /> },
        { id: OTHER_ID, name: "Other", order: OTHER_ID, default: false, image: <Security sx={{ fontSize: "4rem" }} /> },
    ],
    [IAMSolutionsTypes]: [
        { id: 1, name: "Market Solutions (ex: Azure AD, Okta, etc.)", order: 1, default: false, image: <Apps sx={{ fontSize: "4rem" }} /> },
        { id: 2, name: "Custom Solutions", order: 2, default: false, image: <DashboardCustomize sx={{ fontSize: "4rem" }} /> },
        { id: 3, name: "Both Market and Custom Solutions", order: 3, default: true, image: <Category sx={{ fontSize: "4rem" }} /> },
    ],
    [RegulationCodes]: [
        {
            id: 1,
            name: "NIST CSF",
            displayName: "NIST Cybersecurity Framework",
            description:
                "The NIST CSF is a comprehensive framework applicable to your organization, Cybercodee, in both North America and Europe. It is highly recommended to enhance overall cybersecurity resilience.",
            regions: ["NA"],
            industries: [
                "finance",
                "health",
                "retail",
                "manufacturing",
                "entertainment",
                "energy",
                "technology",
                "telecom",
                "government",
                "education",
                "transport",
                "other",
            ],
        },
        {
            id: 2,
            name: "NIST SP 800-53",
            displayName: "NIST Special Publication 800-53",
            description:
                "NIST SP 800-53 provides essential security controls and guidelines for federal information systems and organizations. Compliance with this standard is vital for regulatory adherence and risk mitigation.",
            regions: ["NA"],
            industries: [
                "finance",
                "health",
                "retail",
                "manufacturing",
                "entertainment",
                "energy",
                "technology",
                "telecom",
                "government",
                "education",
                "transport",
                "other",
            ],
        },
        {
            id: 3,
            name: "ISO 27001",
            displayName: "ISO/IEC 27001:2013",
            description:
                "ISO 27001 is a globally recognized standard for information security management systems. It is essential for your organization's commitment to cybersecurity and risk management.",
            regions: ["NA", "EU"],
            industries: [
                "finance",
                "health",
                "retail",
                "manufacturing",
                "entertainment",
                "energy",
                "technology",
                "telecom",
                "government",
                "education",
                "transport",
                "other",
            ],
        },
        {
            id: 4,
            name: "GDPR",
            displayName: "General Data Protection Regulation",
            description:
                "GDPR applies to your organization's operations in Europe. It imposes stringent data protection and privacy requirements, especially critical when dealing with personal data of European residents.",
            regions: ["NA", "EU"],
            industries: [
                "finance",
                "health",
                "retail",
                "manufacturing",
                "entertainment",
                "energy",
                "technology",
                "telecom",
                "government",
                "education",
                "transport",
                "other",
            ],
        },
        {
            id: 5,
            name: "CCPA",
            displayName: "California Consumer Privacy Act",
            description:
                "CCPA applies to your data collection activities in North America, particularly concerning California residents. It provides data privacy rights and protections.",
            regions: ["NA"],
            industries: [
                "finance",
                "health",
                "retail",
                "manufacturing",
                "entertainment",
                "energy",
                "technology",
                "telecom",
                "government",
                "education",
                "transport",
                "other",
            ],
        },
        {
            id: 6,
            name: "HIPAA",
            displayName: "Health Insurance Portability and Accountability Act",
            description:
                "HIPAA is required for your healthcare operations in North America. It ensures the security and privacy of healthcare information, including electronic health records.",
            regions: ["NA", "ES", "EU", "AS", "AF", "OC"],
            industries: ["health", "other"],
        },
        {
            id: 6,
            name: "SOX",
            displayName: "Sarbanes-Oxley Act",
            description:
                "SOX is crucial for your financial services operations in both North America and Europe. It mandates strict controls and reporting requirements to prevent corporate fraud and financial misconduct.",
            regions: ["NA"],
            industries: [
                "finance",
                "health",
                "retail",
                "manufacturing",
                "entertainment",
                "energy",
                "technology",
                "telecom",
                "government",
                "education",
                "transport",
                "other",
            ],
        },
        {
            id: 7,
            name: "PCI-DSS",
            displayName: "Payment Card Industry Data Security Standard",
            description:
                "PCI-DSS is essential for securing payment card data, which is critical for your financial services operations. Compliance is a necessity to protect sensitive financial information.",
            regions: ["NA"],
            industries: ["finance", "retail", "other"],
        },
        {
            id: 9,
            name: "FISMA",
            displayName: "Federal Information Security Management Act",
            description:
                "FISMA is required for your North American operations. It sets information security standards for federal agencies and their contractors.",
            regions: ["NA"],
            industries: [
                "finance",
                "health",
                "retail",
                "manufacturing",
                "entertainment",
                "energy",
                "technology",
                "telecom",
                "government",
                "education",
                "transport",
            ],
        },
        {
            id: 10,
            name: "FERPA",
            displayName: "Family Educational Rights and Privacy Act",
            description:
                "FERPA is relevant to your educational operations, particularly in North America. It safeguards student education records and privacy rights.",
            regions: ["NA"],
            industries: ["government"],
        },
        {
            id: 11,
            name: "COPPA",
            displayName: "Children's Online Privacy Protection Act",
            description:
                "COPPA is important for your online operations. It sets requirements for websites and online services regarding the privacy of children under 13.",
            regions: ["NA"],
            industries: ["education"],
        },
        {
            id: 12,
            name: "GLBA",
            displayName: "Gramm-Leach-Bliley Act",
            description:
                "GLBA applies to your financial services operations in North America. It mandates safeguards for protecting consumer financial information.",
            regions: ["NA"],
            industries: ["finance"],
        },
        {
            id: 13,
            name: "CMMC",
            displayName: "Cybersecurity Maturity Model Certification",
            description:
                "CMMC is important for your organization's cybersecurity readiness and compliance with U.S. Department of Defense contracts.",
            regions: ["NA"],
            industries: ["technology"],
        },
        {
            id: 14,
            name: "NYDFS",
            displayName: "New York Department of Financial Services Regulations",
            description:
                "NYDFS regulations apply to your financial services operations in New York. They focus on cybersecurity and data protection measures.",
            regions: ["NA"],
            industries: ["finance"],
        },
        {
            id: 15,
            name: "NY SHIELD",
            displayName: "Stop Hacks and Improve Electronic Data Security (SHIELD) Act",
            description:
                "The NY SHIELD Act applies to your data collection activities in New York. It enhances data security and data breach notification requirements.",
            regions: ["NA"],
            industries: ["finance"],
        },
        {
            id: 16,
            name: "PSD-2",
            description: "Payment Services Directive",
            regions: ["EU"],
            industries: [
                "finance",
                "health",
                "retail",
                "manufacturing",
                "entertainment",
                "energy",
                "technology",
                "telecom",
                "government",
                "education",
                "transport",
            ],
        },
        { id: OTHER_ID, name: "Other", description: "Other" },
    ],
    [AuditorTypes]: [
        {
            id: 1,
            name: "Ethan Bradley",
            style: AI_AUDITOR_STYLE_CONSERVATIVE,
            approach: "I am cautious and prioritize strict adherence to established security standards, regulations, and industry guidelines.",
            focus: "My focus is on compliance and risk mitigation and minimizing potential threats and vulnerabilities to ensure the organization's security posture is well-protected against any potential issues.",
            avatar: require("../assets/images/ethan.png"),
            order: 1,
            default: false,
        },
        {
            id: 2,
            name: "Alisha Richardson",
            style: AI_AUDITOR_STYLE_BALANCED,
            approach: "I take a middle-ground approach, weighing both the potential risks and rewards of different security strategies.",
            focus: "My focus is on maintaining an optimal balance between security and operational efficiency by assessessing risks in context of the business requirements",
            avatar: require("../assets/images/alisha.png"),
            order: 2,
            default: true,
        },
        {
            id: 3,
            name: "Robert Clarke",
            style: AI_AUDITOR_STYLE_PROGRESSIVE,
            approach:
                "I am proactive and seek to address risks with innovative solutions and strategies to address risks, even if they involve more advanced or cutting-edge approaches.",
            focus: "My focus is on pushing the organization to adopt robust security measures and technologies to address risks in a forward-thinking manner.",
            avatar: require("../assets/images/robert.png"),
            order: 3,
            default: false,
        },
    ],
    // Reference: https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/all/all.csv
    // Conver to CSV: https://csvjson.com/csv2json
    [CountryCodes]: [
        {
            name: "United States of America",
            "alpha-2": "US",
            "alpha-3": "USA",
            "country-code": 840,
            "iso_3166-2": "ISO 3166-2:US",
            region: "North America",
            "sub-region": "Northern America",
            "intermediate-region": "",
            "region-code": "019",
            "sub-region-code": "021",
            "intermediate-region-code": "",
        },
        {
            name: "Canada",
            "alpha-2": "CA",
            "alpha-3": "CAN",
            "country-code": 124,
            "iso_3166-2": "ISO 3166-2:CA",
            region: "North America",
            "sub-region": "Northern America",
            "intermediate-region": "",
            "region-code": "019",
            "sub-region-code": "021",
            "intermediate-region-code": "",
        },
        {
            name: "India",
            "alpha-2": "IN",
            "alpha-3": "IND",
            "country-code": 356,
            "iso_3166-2": "ISO 3166-2:IN",
            region: "Asia",
            "sub-region": "Southern Asia",
            "intermediate-region": "",
            "region-code": 142,
            "sub-region-code": "034",
            "intermediate-region-code": "",
        },
        {
            name: "Germany",
            "alpha-2": "DE",
            "alpha-3": "DEU",
            "country-code": 276,
            "iso_3166-2": "ISO 3166-2:DE",
            region: "Europe",
            "sub-region": "Western Europe",
            "intermediate-region": "",
            "region-code": 150,
            "sub-region-code": 155,
            "intermediate-region-code": "",
        },
    ],
    [USStateCodes]: [
        { state: "Alabama", abbreviation: "AL", order: 1, default: false },
        { state: "Alaska", abbreviation: "AK", order: 2, default: false },
        { state: "Arizona", abbreviation: "AZ", order: 3, default: false },
        { state: "Arkansas", abbreviation: "AR", order: 4, default: false },
        { state: "California", abbreviation: "CA", order: 5, default: false },
        { state: "Colorado", abbreviation: "CO", order: 6, default: false },
        { state: "Connecticut", abbreviation: "CT", order: 7, default: false },
        { state: "Delaware", abbreviation: "DE", order: 8, default: false },
        { state: "Florida", abbreviation: "FL", order: 9, default: false },
        { state: "Georgia", abbreviation: "GA", order: 10, default: false },
        { state: "Hawaii", abbreviation: "HI", order: 11, default: false },
        { state: "Idaho", abbreviation: "ID", order: 12, default: false },
        { state: "Illinois", abbreviation: "IL", order: 13, default: false },
        { state: "Indiana", abbreviation: "IN", order: 14, default: false },
        { state: "Iowa", abbreviation: "IA", order: 15, default: false },
        { state: "Kansas", abbreviation: "KS", order: 16, default: false },
        { state: "Kentucky", abbreviation: "KY", order: 17, default: false },
        { state: "Louisiana", abbreviation: "LA", order: 18, default: false },
        { state: "Maine", abbreviation: "ME", order: 19, default: false },
        { state: "Maryland", abbreviation: "MD", order: 20, default: false },
        { state: "Massachusetts", abbreviation: "MA", order: 21, default: false },
        { state: "Michigan", abbreviation: "MI", order: 22, default: false },
        { state: "Minnesota", abbreviation: "MN", order: 23, default: false },
        { state: "Mississippi", abbreviation: "MS", order: 24, default: false },
        { state: "Missouri", abbreviation: "MO", order: 25, default: false },
        { state: "Montana", abbreviation: "MT", order: 26, default: false },
        { state: "Nebraska", abbreviation: "NE", order: 27, default: false },
        { state: "Nevada", abbreviation: "NV", order: 28, default: false },
        { state: "New Hampshire", abbreviation: "NH", order: 29, default: false },
        { state: "New Jersey", abbreviation: "NJ", order: 30, default: false },
        { state: "New Mexico", abbreviation: "NM", order: 31, default: false },
        { state: "New York", abbreviation: "NY", order: 32, default: false },
        { state: "North Carolina", abbreviation: "NC", order: 33, default: false },
        { state: "North Dakota", abbreviation: "ND", order: 34, default: false },
        { state: "Ohio", abbreviation: "OH", order: 35, default: false },
        { state: "Oklahoma", abbreviation: "OK", order: 36, default: false },
        { state: "Oregon", abbreviation: "OR", order: 37, default: false },
        { state: "Pennsylvania", abbreviation: "PA", order: 38, default: false },
        { state: "Rhode Island", abbreviation: "RI", order: 39, default: false },
        { state: "South Carolina", abbreviation: "SC", order: 40, default: false },
        { state: "South Dakota", abbreviation: "SD", order: 41, default: false },
        { state: "Tennessee", abbreviation: "TN", order: 42, default: false },
        { state: "Texas", abbreviation: "TX", order: 43, default: false },
        { state: "Utah", abbreviation: "UT", order: 44, default: false },
        { state: "Vermont", abbreviation: "VT", order: 45, default: false },
        { state: "Virginia", abbreviation: "VA", order: 46, default: false },
        { state: "Washington", abbreviation: "WA", order: 47, default: false },
        { state: "West Virginia", abbreviation: "WV", order: 48, default: false },
        { state: "Wisconsin", abbreviation: "WI", order: 49, default: false },
        { state: "Wyoming", abbreviation: "WY", order: 50, default: false },
        { state: "American Samoa", abbreviation: "AS", order: 51, default: false },
        { state: "District of Columbia", abbreviation: "DC", order: 52, default: false },
        { state: "Federated States of Micronesia", abbreviation: "FM", order: 53, default: false },
        { state: "Guam", abbreviation: "GU", order: 54, default: false },
        { state: "Marshall Islands", abbreviation: "MH", order: 55, default: false },
        { state: "Northern Mariana Islands", abbreviation: "MP", order: 56, default: false },
        { state: "Palau", abbreviation: "PW", order: 57, default: false },
        { state: "Puerto Rico", abbreviation: "PR", order: 58, default: false },
        { state: "Virgin Islands", abbreviation: "VI", order: 59, default: false },
    ],
    [Products]: [
        {
            id: 1,
            name: "iMag",
            code: "IM",
            shortDescription: "IAM and GRC",
            description:
                "Elevate your security strategy with iMag. Seamlessly integrate Identity and Access Management (IAM) and Governance, Risk, and Compliance (GRC) functionalities for a robust shield against cyber threats.",
        },
        {
            id: 2,
            name: "AppLaunch",
            code: "AL",
            shortDescription: "Application Onboarding",
            description:
                "Streamline your application journey with AppLaunch. Effortlessly onboard and integrate new applications, enhancing efficiency and accelerating your digital transformation.",
        },
        {
            id: 3,
            name: "AuditAI",
            code: "AI",
            shortDescription: "Audit Planning and Execution",
            description:
                "Amplify your audit prowess with AuditAI. Leverage advanced AI-driven tools for meticulous audit planning and execution, ensuring compliance excellence with unmatched precision.",
        },
        {
            id: 4,
            name: "vCISO",
            code: "VC",
            shortDescription: "Professional Servics",
            description:
                "Harness the expertise of vCISO. Access top-tier professional services led by seasoned experts, providing strategic guidance that propels your organization towards unparalleled security and success.",
        },
    ],
    [Pricing]: [
        {
            id: 1,
            name: "Essential",
            description: "Essential features to get started with the product",
            recommended: false,
            pricing: {
                value: "$5",
                per: "per user",
                frequency: "monthly",
                currency: "USD",
                discount: "5% less with annual subscription",
            },
            features: [
                { id: 1, name: "Number of Assessments", quantity: "1", included: true, special: false },
                { id: 2, name: "Active Users", quantity: "50", included: true, special: false },
                { id: 3, name: "App Onboarding", quantity: "10", included: true, special: false },
                { id: 4, name: "Security Controls", quantity: "NIST, ISO 27001", included: true, special: false },
                { id: 5, name: "Access Reviews", quantity: "Quarterly", included: true, special: false },
            ],
        },
        {
            id: 2,
            name: "Business",
            description: "Best suited for enterprises with customizations",
            recommended: true,
            pricing: {
                value: "$4",
                per: "per user",
                frequency: "monthly",
                currency: "USD",
                discount: "10% less with annual subscription",
            },
            features: [
                { id: 1, name: "All features in Essential", quantity: "Pay per use", included: true, special: false },
                { id: 2, name: "Compliance", quantity: "HIPAA, CCPA", included: true, special: true },
                { id: 3, name: "Multi-Region", quantity: "Included", included: true, special: true },
                { id: 4, name: "Evidence Collection", quantity: "Quarterly", included: true, special: true },
                { id: 5, name: "Something wonderful", quantity: "Quarterly", included: true, special: true },
            ],
        },
    ],
    [AssetType]: [
        { id: 1, name: "Application", order: 1, default: true },
        { id: 2, name: "Database", order: 2, default: false },
        { id: 3, name: "Server", order: 3, default: false },
        { id: 4, name: "Location", order: 4, default: false },
    ],
};
