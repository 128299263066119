import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    limit,
    query,
    setDoc,
    where,
  } from "firebase/firestore";
  import * as DBConstants from "../constants/DBConstants";
  import { db } from "../config/FirebaseConfig";
  import { updateAudit } from "../utils/utils";
  
  // Get a order with their ID
  export const findOrderByUserId = async (uid) => {
    const q = query(
      collection(db, DBConstants.DB_ORDERS),
      where("uid", "==", uid),
      limit(1)
    );
    const snapshot = await getDocs(q);
    let order = null;
    if (!snapshot.empty) {
      order = snapshot.docs[0];
    }
    return order ? order.data() : null;
  };
  
  // Find a order with id
  export const findOrder = async (id) => {
    const docRef = doc(db, DBConstants.DB_ORDERS, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
  };
  
  // Save a Order
  export const saveOrder = async (uid, obj) => {
   //console.log ('Save order called: ', uid, ' obj: ', obj)
  
    let docRef = null;
    let order = null;
  
    updateAudit(uid, obj);
  
    if (obj && obj.id) {
      docRef = doc(db, DBConstants.DB_ORDERS, obj.id);
      await setDoc(docRef, obj, { merge: true });
    } else {
      // Save the new doc
      docRef = doc(collection(db, DBConstants.DB_ORDERS));
      obj.id = docRef.id;
      await setDoc(docRef, obj, { merge: true });
    }
    order = await findOrder(obj.id);
    return order;
  };
  
  export const deleteOrder = async (uid) => {
    const ref = doc(db, DBConstants.DB_ORDERS, uid);
    await deleteDoc(ref);
  };
  
  // Add item to order
export const addItemToOrder = async (uid, item) => {
    // Check if the user has a order, create one if not
    let order = await findOrderByUserId(uid);
    console.log ('Order: ', order )
    if (!order) {
        order = await saveOrder(uid, {
          id: uid,
          uid: uid,
          items: [],
      });
      console.log ('Saved order: ', order)
    }

    order.items.push(item);
    return await saveOrder(uid, order);
}

// Remove item from order
export const removeItemFromOrder = async (uid, productId) => {
    let order = await findOrderByUserId(uid);
    order.items = order.items.filter(i => i.productId !== productId);
    return await saveOrder(uid, order);
}

// Update item in order
export const updateItemInOrder = async (uid, item) => {
    let order = await findOrderByUserId(uid);
    order.items = order.items.map(i => i.productId === item.productId ? item : i);
    return await saveOrder(uid, order);
}
