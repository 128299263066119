import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc } from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { updateAudit } from "../utils/utils";

// Create a new group
export const createGroup = async (uid, group) => {
    const docRef = doc(collection(db, DBConstants.DB_GROUPS));
    group.id = docRef.id;
    updateAudit(uid, group);
    await setDoc(docRef, group, { merge: true });
    return await findGroup(group.id);
};

// update an existing group
export const updateGroup = async (uid, group) => {
    updateAudit(uid, group);
    const docRef = doc(db, DBConstants.DB_GROUPS, group.id);
    await setDoc(docRef, { ...group }, { merge: true });
    return await findGroup(group.id);
};

// Create a new group or update an existing one
export const saveGroup = async (uid, tenantId, group) => {
    group.tenantId = tenantId;
    if (group.id) {
        return await updateGroup(uid, group);
    } else {
        return await createGroup(uid, group);
    }
};

// Delete an group
export const deleteGroup = async (groupId) => {
    // Delete the group
    const docRef = doc(db, DBConstants.DB_GROUPS, groupId);
    await deleteDoc(docRef);
};

// Find an group with id
export const findGroup = async (id) => {
    const docRef = doc(db, DBConstants.DB_GROUPS, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
};

// Find all groups for a tenant
export const findAllGroups = async (tenantId) => {
    let results = [];
    const q = query(
        collection(db, DBConstants.DB_GROUPS)
        //where("tenantId", "==", tenantId)
    );
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

