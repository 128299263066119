import { Apps, ArrowCircleRight, ArrowForward, Person } from "@mui/icons-material";
import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material";
import React from "react";

export default function ProfileTab() {
    return (
        <Box display="flex" flexDirection="row" gap={1}>
            <Box display="flex" flexDirection="column" sx={{ width: "50%" }} gap={1}>
                <Card>
                    <CardHeader
                        title="Basic Information"
                        titleTypographyProps={{ color: "primary.main", variant: "subtitle" }}
                        sx={{ bgcolor: "#f9f9f9" }}
                    />
                    <Divider />
                    <CardContent>
                        <Box display="flex" flexDirection="row" gap={1}>
                            <Box display="flex" flexDirection="column" sx={{ width: "50%" }}>
                                <Typography variant="body">Name</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    Peter Parker
                                </Typography>
                                <Typography variant="body">Employee Id</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    E-234363
                                </Typography>
                                <Typography variant="body">Employee Type</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    Regular
                                </Typography>
                                <Typography variant="body">Location Id</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    R-12334
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" sx={{ width: "50%" }}>
                                <Typography variant="body">Manager</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    Tony Stark
                                </Typography>
                                <Typography variant="body">Job Title</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    VP of Engineering
                                </Typography>
                                <Typography variant="body">Hire Date</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    10/10/2010
                                </Typography>
                                <Typography variant="body">Status</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    Active
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader
                        title="Job Information"
                        titleTypographyProps={{ color: "primary.main", variant: "subtitle" }}
                        sx={{ bgcolor: "#f9f9f9" }}
                    />
                    <Divider />
                    <CardContent>
                        <Box display="flex" flexDirection="row" gap={1}>
                            <Box display="flex" flexDirection="column" sx={{ width: "50%" }}>
                                <Typography variant="body">Division</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    Information Technology
                                </Typography>
                                <Typography variant="body">Department</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    Engineering
                                </Typography>
                                <Typography variant="body">Cost Center</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    78344656
                                </Typography>
                                <Typography variant="body">Cost Center Name</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    Information Technology
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" sx={{ width: "50%" }}>
                                <Typography variant="body">Team</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    Research and Development
                                </Typography>
                                <Typography variant="body">Role</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    Sr Manager
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Box display="flex" flexDirection="column" sx={{ width: "50%" }} gap={1}>
                <Card>
                    <CardHeader
                        title="Contact Information"
                        titleTypographyProps={{ color: "primary.main", variant: "subtitle" }}
                        sx={{ bgcolor: "#f9f9f9" }}
                    />
                    <Divider />
                    <CardContent>
                        <Box display="flex" flexDirection="row" gap={1}>
                            <Box display="flex" flexDirection="column" sx={{ width: "50%" }}>
                                <Typography variant="body">Email</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    peter@abc.com
                                </Typography>
                                <Typography variant="body">Phone</Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    111-111-1111
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" sx={{ width: "50%" }}>
                                <Typography variant="body">Address</Typography>
                                <Typography variant="body1" fontWeight="bold">
                                    111 Main St
                                </Typography>
                                <Typography variant="body1" fontWeight="bold" gutterBottom>
                                    Raleigh, NC 27518
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader
                        title="Frequently Used Roles"
                        titleTypographyProps={{ color: "primary.main", variant: "subtitle" }}
                        sx={{ bgcolor: "#f9f9f9" }}
                    />
                    <Divider />
                    <CardContent>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
                                <Avatar sx={{ bgcolor: "secondary.main" }}>
                                    <Apps color="primary" />
                                </Avatar>
                                <Typography variant="smallbody">Web Developer</Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
                                <Avatar sx={{ bgcolor: "secondary.main" }}>
                                    <Apps color="primary" />
                                </Avatar>
                                <Typography variant="smallbody">Building Jumper</Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader
                        title="Delegations"
                        titleTypographyProps={{ color: "primary.main", variant: "subtitle" }}
                        sx={{ bgcolor: "#f9f9f9" }}
                    />
                    <Divider />
                    <CardContent>
                        <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                            <Stack direction="row" justifyContent="center" alignItems="center" gap={2} sx={{ border: "1px solid #e0e0e0", p: 2 }}>
                                <Avatar sx={{ width: 20, height: 20 }}>
                                    <Person color="primary" />
                                </Avatar>
                                <Typography variant="body">Peter Parker</Typography>
                            </Stack>
                            <ArrowCircleRight color="primary" />
                            <Stack direction="row" justifyContent="center" alignItems="center" gap={2} sx={{ border: "1px solid #e0e0e0", p: 2 }}>
                                <Typography variant="body">Gwen Stacy</Typography>
                                <Avatar sx={{ width: 20, height: 20 }}>
                                    <Person color="primary" />
                                </Avatar>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
}
