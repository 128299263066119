import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import CompanyLogo from "../../assets/images/logo-text.png";
import { CheckCircle} from "@mui/icons-material";

export default function SignUpInfo() {
    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" width="50%" height="100%" bgcolor={"black"}>
            <Box display="flex" flexDirection="row" mx={1} my={1}>
                <Link to="https://www.cybercodee.com" target="_blank">
                    <img src={CompanyLogo} alt="Logo" />
                </Link>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={10}>
                <Typography variant="h4" color="primary">
                    Security assessments made easy
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircle color="success" />
                        </ListItemIcon>
                        <ListItemText sx={{ color: 'white' }} primary="Conduct a comprehensive IAM assessments." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircle color="success" />
                        </ListItemIcon>
                        <ListItemText sx={{ color: 'white' }} primary="Align industry standards and compliance requirements (e.g., GDPR, CCPA)." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircle color="success" />
                        </ListItemIcon>
                        <ListItemText sx={{ color: 'white' }} primary="Develop roadmaps and action plans based on assessment findings and business priorities." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircle color="success" />
                        </ListItemIcon>
                        <ListItemText sx={{ color: 'white' }} primary="Prioritize high-risk areas and critical applications" />
                    </ListItem>
                </List>
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={10}>
                <Typography variant="subtitle" color="secondary">
                    Powered by CyberCodee, LLC.
                </Typography>
            </Box>
        </Box>
    );
}
