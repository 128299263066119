import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { CountryCodes, USStateCodes, getCodelist } from "../constants/Codelist";

export default function ContactEditForm({ formData, errors, setErrors, handleChange, includeAddress, includePhone, includeEmail, includeWebsite }) {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
            {includeEmail && (
                <TextField
                    name="email"
                    label="Email"
                    //required
                    fullWidth
                    variant="standard"
                    value={formData.email ?? ""}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                />
            )}
            {includePhone && (
                <TextField
                    name="phone"
                    label="Phone"
                    //required
                    fullWidth
                    variant="standard"
                    value={formData.phone ?? ""}
                    onChange={handleChange}
                    error={!!errors.phone}
                    helperText={errors.phone}
                />
            )}
            {includeAddress && (
                <>
                    <TextField
                        name="addressLine1"
                        label="Street or Road"
                        //required
                        fullWidth
                        variant="standard"
                        value={formData.addressLine1 ?? ""}
                        onChange={handleChange}
                        error={!!errors.addressLine1}
                        helperText={errors.addressLine1}
                        //value={(address && address.addressLine1 )|| ''}
                    />
                    <TextField
                        name="addressLine2"
                        label="Apt or suite"
                        fullWidth
                        variant="standard"
                        value={formData.addressLine2 ?? ""}
                        onChange={handleChange}
                        error={!!errors.addressLine2}
                        helperText={errors.addressLine2}
                    />
                    <FormControl sx={{ marginTop: 2 }}>
                        <InputLabel id="country">Country</InputLabel>
                        <Select
                            labelId="country"
                            name="country"
                            //required
                            variant="standard"
                            value={formData.country ?? ""}
                            onChange={handleChange}
                        >
                            <MenuItem value="" key="country">
                                --select--
                            </MenuItem>

                            {getCodelist(CountryCodes).map((t) => {
                                return (
                                    <MenuItem value={t["alpha-2"]} key={t["alpha-2"]}>
                                        {t["name"]}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {errors.country && <FormHelperText error>{errors.country}</FormHelperText>}
                    </FormControl>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2, marginTop: 2 }}>
                        <TextField
                            name="city"
                            label="City"
                            //required
                            fullWidth
                            variant="standard"
                            value={formData.city ?? ""}
                            onChange={handleChange}
                            error={!!errors.city}
                            helperText={errors.city}
                        />

                        <FormControl fullWidth>
                            <InputLabel id="state">State</InputLabel>
                            <Select
                                labelId="state"
                                name="state"
                                //required
                                variant="standard"
                                value={formData.state ?? ""}
                                onChange={handleChange}
                            >
                                <MenuItem value="" key="state">
                                    --select--
                                </MenuItem>

                                {getCodelist(USStateCodes).map((t) => {
                                    return (
                                        <MenuItem value={t["abbreviation"]} key={t["abbreviation"]}>
                                            {t["state"]}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {errors.state && <FormHelperText error>{errors.state}</FormHelperText>}
                        </FormControl>

                        <TextField
                            name="zipcode"
                            label="Zipcode"
                            //required
                            fullWidth
                            variant="standard"
                            value={formData.zipcode ?? ""}
                            onChange={handleChange}
                            error={!!errors.zipcode}
                            helperText={errors.zipcode}
                        />
                    </Box>
                </>
            )}

            {includeWebsite && (
                <TextField
                    name="website"
                    label="Website"
                    placeholder="https://"
                    fullWidth
                    variant="standard"
                    value={formData.website ?? ""}
                    onChange={handleChange}
                    error={!!errors.website}
                    helperText={errors.website}
                />
            )}
        </Box>
    );
}
