import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, LinearProgress, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GridLoader } from "react-spinners";
import { Edit, Reply } from "@mui/icons-material";
import { findOrganization } from "../../services/OrganizationService";
import { stringAvatar } from "../../utils/utils";
import OrgProfileView from "../profile/OrgProfileView";
import OrgProfile from "../profile/OrgProfile";

export default function MyOrganization() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { person } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const [organization, setOrganization] = useState(null);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        async function getOrganization() {
            setLoading(true);
            if (person?.tenant) {
                const tenant = await findOrganization(person.tenant.id);
                setOrganization(tenant)
            }

            setLoading(false);
        }
        
        getOrganization();
    }, [person]);

    const handleProfileEdit = (e) => {
      setEdit(true);
      navigate("/settings/organization/edit", { state: { organization: organization } })
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setEdit(false);

    // let p = {
    //     ...person,
    //     firstName: e.target.firstName.value,
    //     lastName: e.target.lastName.value,
    //     addressLine1: e.target.addressLine1.value,
    //     addressLine2: e.target.addressLine2.value,
    //     city: e.target.city.value,
    //     state: e.target.state.value,
    //     zipcode: e.target.zipcode.value,
    //     email: e.target.email.value,
    //     phone: e.target.phone.value,
    // };

    // p = await savePerson(user.uid, p, person.tenantId, person.role);
    // await dispatch({
    //     type: "PERSON_CHANGED",
    //     person: p,
    // });
};

    const handleBack = () => {
        navigate(-1);
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }}>
            <Card>
                <CardHeader
                    title="My Organization"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="Update your organization details,  contact information, and profile, to ensure it is accurate and up to date."
                    action={
                        <Button startIcon={<Reply />} onClick={handleBack} color="info">
                            Back
                        </Button>
                    }
                />
                <Divider />
                <CardContent>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        {person && !person.tenant && (
                            <Typography sx={{ marginTop: 4, marginBottom: 4 }} color="warning.main">
                                Your organization is not setup yet. Please contact your administrator.
                            </Typography>
                        )}
                        {person && organization && (
                          <Box display="flex" flexDirection="row" >
                            <Box width="30%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            {person && <Avatar {...stringAvatar(organization.name)} sx={{ width: 100, height: 100, mb: 4 }} />}
                                <Typography variant="subtitle1">Profile Completion (40%)</Typography>
                                <Box width="80%">
                                    <LinearProgress variant="determinate" value={40} />
                                </Box>

                              </Box>
                              <Box width="70%">
                              <Card sx={{ flexGrow: 1 }} elevation={0}>
                                    <CardHeader
                                        title="Profile Details"
                                        titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                                        action={
                                            <Tooltip title="Edit Profile">
                                                <Edit color="primary" onClick={handleProfileEdit} />
                                            </Tooltip>
                                        }
                                    />

                                    {/* <Divider /> */}

                                    {organization && !edit && <OrgProfileView organization={organization}/>}

                                    {/* {organization && edit && <OrgProfile organization={organization}/>} */}
                                    

                                    {/* {organization && edit && <ProfileEdit updateProfile={handleProfileUpdate} handleCancel={handleCancel} />} */} 
                                </Card>
                                </Box>  
                            </Box>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}
