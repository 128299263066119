export const Departments = {
    Common: [
        {
            name: "Human Resources",
            coreFunction: "Manages workforce, recruitment, and employee relations.",
        },
        {
            name: "Finance",
            coreFunction: "Handles financial management, budgeting, and analysis.",
        },
        {
            name: "Information Technology",
            coreFunction: "Manages technology infrastructure, systems, and support.",
        },
        {
            name: "Operations",
            coreFunction: "Oversees day-to-day business operations and processes.",
        },
        {
            name: "Marketing",
            coreFunction: "Promotes products, services, and brand awareness.",
        },
        {
            name: "Sales",
            coreFunction: "Drives revenue generation through sales and client interactions.",
        },
        {
            name: "Customer Service",
            coreFunction: "Provides support and assistance to customers and clients.",
        },
        {
            name: "Research and Development",
            coreFunction: "Innovates and develops new products, services, and technologies.",
        },
        {
            name: "Quality Assurance",
            coreFunction: "Ensures products and processes meet quality standards.",
        },
        {
            name: "Administration",
            coreFunction: "Manages administrative tasks, facilities, and logistics.",
        },
    ],
    FinancialServices: [
        {
            name: "Risk Management",
            coreFunction: "Assesses and manages financial and operational risks.",
        },
        {
            name: "Investment Banking",
            coreFunction: "Provides advisory and financial services for investment transactions.",
        },
        {
            name: "Compliance",
            coreFunction: "Ensures adherence to regulatory and legal requirements.",
        },
        {
            name: "Wealth Management",
            coreFunction: "Manages and advises on high net worth clients' financial assets.",
        },
        {
            name: "Asset Management",
            coreFunction: "Manages investment portfolios and financial assets.",
        },
        {
            name: "Retail Banking",
            coreFunction: "Offers banking services to individuals and small businesses.",
        },
        {
            name: "Corporate Finance",
            coreFunction: "Handles financial activities for corporations and businesses.",
        },
        {
            name: "Mergers and Acquisitions",
            coreFunction: "Manages and facilitates company mergers and acquisitions.",
        },
        {
            name: "Financial Planning",
            coreFunction: "Provides financial advice and planning for clients' goals.",
        },
        {
            name: "Insurance",
            coreFunction: "Offers insurance policies and risk management services.",
        },
    ],
    "Healthcare and Life Sciences": [
        {
            name: "Medical Services",
            coreFunction: "Provides medical care, diagnosis, and treatment.",
        },
        {
            name: "Research and Development",
            coreFunction: "Conducts scientific research and develops medical advancements.",
        },
        {
            name: "Nursing",
            coreFunction: "Delivers patient care and assists medical professionals.",
        },
        {
            name: "Pharmacy",
            coreFunction: "Dispenses medications and provides pharmaceutical services.",
        },
        {
            name: "Health Information Management",
            coreFunction: "Manages patient records, data, and information systems.",
        },
        {
            name: "Clinical Operations",
            coreFunction: "Oversees clinical trials and research studies.",
        },
        {
            name: "Quality Assurance",
            coreFunction: "Ensures medical services and products meet quality standards.",
        },
        {
            name: "Patient Services",
            coreFunction: "Provides patient support, communication, and coordination.",
        },
        {
            name: "Laboratory Services",
            coreFunction: "Performs medical testing and analysis.",
        },
        {
            name: "Medical Imaging",
            coreFunction: "Provides diagnostic imaging services like X-rays and MRIs.",
        },
    ],
    Technology: [
        {
            name: "Software Development",
            coreFunction: "Designs and develops software applications and systems.",
        },
        {
            name: "Information Technology",
            coreFunction: "Manages technology infrastructure, systems, and support.",
        },
        {
            name: "Data Science",
            coreFunction: "Analyzes and interprets data to extract insights.",
        },
        {
            name: "UI/UX Design",
            coreFunction: "Creates user interfaces and experiences for digital products.",
        },
        {
            name: "Cybersecurity",
            coreFunction: "Protects computer systems and networks from security threats.",
        },
        {
            name: "DevOps",
            coreFunction: "Combines software development and IT operations for efficiency.",
        },
        {
            name: "Project Management",
            coreFunction: "Manages projects and ensures timely delivery of solutions.",
        },
        {
            name: "Cloud Services",
            coreFunction: "Provides and manages cloud-based infrastructure and solutions.",
        },
        {
            name: "Database Management",
            coreFunction: "Manages and maintains databases for data storage.",
        },
        {
            name: "Network Administration",
            coreFunction: "Manages computer networks and communication systems.",
        },
    ],
    "Government and Public Administration": [
        {
            name: "Public Safety",
            coreFunction: "Ensures the safety and security of the community.",
        },
        {
            name: "Human Services",
            coreFunction: "Provides social support and assistance to citizens in need.",
        },
        {
            name: "Finance and Budget",
            coreFunction: "Manages government finances, budgets, and fiscal planning.",
        },
        {
            name: "Health Services",
            coreFunction: "Offers public health programs and medical services.",
        },
        {
            name: "Education",
            coreFunction: "Oversees public education and educational institutions.",
        },
        {
            name: "Environmental Services",
            coreFunction: "Manages and protects the environment and natural resources.",
        },
        {
            name: "Transportation",
            coreFunction: "Manages public transportation systems and infrastructure.",
        },
        {
            name: "Urban Planning",
            coreFunction: "Develops and manages urban and regional development plans.",
        },
        {
            name: "Legal and Judicial",
            coreFunction: "Oversees legal processes, courts, and judicial proceedings.",
        },
        {
            name: "Administration",
            coreFunction: "Manages administrative tasks and government operations.",
        },
    ],
    Retail: [
        {
            name: "Sales",
            coreFunction: "Drives revenue generation through sales and customer interactions.",
        },
        {
            name: "Marketing",
            coreFunction: "Promotes products, services, and brand awareness.",
        },
        {
            name: "Supply Chain and Logistics",
            coreFunction: "Manages the movement and distribution of goods.",
        },
        {
            name: "Merchandising",
            coreFunction: "Plans and manages product assortment and presentation.",
        },
        {
            name: "Customer Service",
            coreFunction: "Provides support and assistance to customers and clients.",
        },
        {
            name: "Store Operations",
            coreFunction: "Manages the daily operations of retail stores.",
        },
        {
            name: "E-commerce",
            coreFunction: "Manages online sales and digital commerce platforms.",
        },
        {
            name: "Visual Merchandising",
            coreFunction: "Creates appealing store displays to attract customers.",
        },
        {
            name: "Product Development",
            coreFunction: "Designs and develops new products and offerings.",
        },
        {
            name: "Inventory Management",
            coreFunction: "Manages stock levels and inventory control.",
        },
    ],
    Manufacturing: [
        {
            name: "Production",
            coreFunction: "Manufactures and produces goods and products.",
        },
        {
            name: "Quality Assurance",
            coreFunction: "Ensures products and processes meet quality standards.",
        },
        {
            name: "Research and Development",
            coreFunction: "Innovates and develops new products and technologies.",
        },
        {
            name: "Supply Chain and Logistics",
            coreFunction: "Manages the movement and distribution of materials and goods.",
        },
        {
            name: "Engineering",
            coreFunction: "Designs and develops products and production processes.",
        },
        {
            name: "Maintenance",
            coreFunction: "Performs equipment maintenance and repairs.",
        },
        {
            name: "Health and Safety",
            coreFunction: "Ensures workplace safety and compliance with regulations.",
        },
        {
            name: "Operations",
            coreFunction: "Oversees day-to-day business operations and processes.",
        },
        {
            name: "Manufacturing Technology",
            coreFunction: "Applies technology for efficient production processes.",
        },
        {
            name: "Environmental Sustainability",
            coreFunction: "Focuses on sustainable and eco-friendly manufacturing.",
        },
    ],
    "Media and Entertainment": [
        {
            name: "Content Creation",
            coreFunction: "Creates and develops media content, such as videos and articles.",
        },
        {
            name: "Production",
            coreFunction: "Produces media content, including filming and editing.",
        },
        {
            name: "Marketing and Promotion",
            coreFunction: "Promotes media content and engages with the audience.",
        },
        {
            name: "Distribution",
            coreFunction: "Distributes media content through various channels.",
        },
        {
            name: "Public Relations",
            coreFunction: "Manages relationships with the public and media outlets.",
        },
        {
            name: "Broadcasting",
            coreFunction: "Transmits media content through radio, TV, or online platforms.",
        },
        {
            name: "Event Management",
            coreFunction: "Plans and coordinates media and entertainment events.",
        },
        {
            name: "Creative Design",
            coreFunction: "Designs visuals and graphics for media content.",
        },
        {
            name: "Digital Media",
            coreFunction: "Manages and produces digital media content.",
        },
        {
            name: "Talent Management",
            coreFunction: "Manages artists, performers, and talent relationships.",
        },
    ],
    Energy: [
        {
            name: "Power Generation",
            coreFunction: "Generates electricity through various sources.",
        },
        {
            name: "Transmission and Distribution",
            coreFunction: "Transmits and distributes electricity to consumers.",
        },
        {
            name: "Renewable Energy",
            coreFunction: "Develops and operates renewable energy projects.",
        },
        {
            name: "Oil and Gas Exploration",
            coreFunction: "Explores and extracts oil and gas resources.",
        },
        {
            name: "Environmental Services",
            coreFunction: "Manages environmental impact and compliance.",
        },
        {
            name: "Energy Trading and Risk Management",
            coreFunction: "Manages energy trading and financial risks.",
        },
        {
            name: "Utilities Services",
            coreFunction: "Provides essential utility services to consumers.",
        },
        {
            name: "Engineering and Operations",
            coreFunction: "Manages operations and maintenance of energy facilities.",
        },
        {
            name: "Safety and Compliance",
            coreFunction: "Ensures safety and regulatory compliance in operations.",
        },
        {
            name: "Smart Grid Solutions",
            coreFunction: "Implements advanced technologies for energy distribution.",
        },
    ],
    Telecommunications: [
        {
            name: "Network Operations",
            coreFunction: "Manages and maintains communication networks.",
        },
        {
            name: "Sales and Marketing",
            coreFunction: "Promotes and sells telecommunications services.",
        },
        {
            name: "Customer Service",
            coreFunction: "Provides support and assistance to customers and clients.",
        },
        {
            name: "Research and Development",
            coreFunction: "Innovates and develops new communication technologies.",
        },
        {
            name: "Product Management",
            coreFunction: "Manages telecommunications product portfolios.",
        },
        {
            name: "Engineering",
            coreFunction: "Designs and develops communication infrastructure.",
        },
        {
            name: "Billing and Revenue Assurance",
            coreFunction: "Manages billing processes and revenue tracking.",
        },
        {
            name: "Regulatory Affairs",
            coreFunction: "Manages compliance with telecommunications regulations.",
        },
        {
            name: "Network Security",
            coreFunction: "Protects communication networks from security threats.",
        },
        {
            name: "Business Development",
            coreFunction: "Identifies new business opportunities and partnerships.",
        },
    ],
    Education: [
        {
            name: "Academic Affairs",
            coreFunction: "Oversees curriculum development and academic programs.",
        },
        {
            name: "Student Services",
            coreFunction: "Provides support and services to students, including counseling and advising.",
        },
        {
            name: "Administration",
            coreFunction: "Manages administrative tasks and school operations.",
        },
        {
            name: "Faculty and Instruction",
            coreFunction: "Manages teaching staff and instructional methods.",
        },
        {
            name: "Research and Development",
            coreFunction: "Conducts educational research and develops new teaching approaches.",
        },
        {
            name: "Enrollment and Admissions",
            coreFunction: "Manages student enrollment and admissions processes.",
        },
        {
            name: "Technology and E-Learning",
            coreFunction: "Integrates technology and e-learning methods into education.",
        },
        {
            name: "Student Affairs",
            coreFunction: "Coordinates student life activities and services.",
        },
        {
            name: "Library Services",
            coreFunction: "Manages library resources and services.",
        },
        {
            name: "Special Education",
            coreFunction: "Provides educational support for students with special needs.",
        },
    ],
    Transportation: [
        {
            name: "Front Office",
            coreFunction: "Manages guest services and front desk operations.",
        },
        {
            name: "Housekeeping",
            coreFunction: "Maintains cleanliness and hygiene of guest accommodations.",
        },
        {
            name: "Food and Beverage",
            coreFunction: "Manages dining and culinary services for guests.",
        },
        {
            name: "Event Planning",
            coreFunction: "Plans and coordinates events, conferences, and functions.",
        },
        {
            name: "Transportation Services",
            coreFunction: "Manages transportation and logistics for guests and goods.",
        },
        {
            name: "Sales and Marketing",
            coreFunction: "Promotes the business and attracts guests and clients.",
        },
        {
            name: "Operations",
            coreFunction: "Oversees day-to-day operations of the hospitality establishment.",
        },
        {
            name: "Revenue Management",
            coreFunction: "Optimizes pricing and revenue strategies for bookings.",
        },
        {
            name: "Customer Service",
            coreFunction: "Provides guest support and assistance throughout their stay.",
        },
        {
            name: "Facility Management",
            coreFunction: "Manages maintenance and facilities of the establishment.",
        },
    ],
};
