import React, { useState } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import OrgProfile from "./OrgProfile";
import AssessmentWizard from "../assessment/AssessmentWizard";

const useStyles = makeStyles((theme) => ({
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        fontStyle: "italic",
    },
}));

export default function ProfileCheck() {
    const classes = useStyles();
    const { user, person, profile } = useAuthContext();
    const navigate = useNavigate();

    // Step functions
    const stepContent = [
        "Let's set some context for your business. Understanding your industry, target audience, and unique value proposition lays the foundation for effective strategies that propel your success forward.",
        "Share your mission, values, and what makes your solution unique. Your insights help us align our efforts with your goals, creating a partnership that drives innovation and meaningful impact.",
        "Help us understand your team structure and how you work to grasp their roles in safeguarding your digital fortress. IAM keeps the gates secure, managing who enters, while the Risk team maps the terrain of potential threats. Together, they form an impenetrable shield, ensuring your data remains in safe hands.",
        "Your regulatory landscape is like a navigational map for compliance. It outlines the rules, guidelines, and requirements specific to your industry, ensuring you sail smoothly in the complex sea of regulations.",
    ];
    const [activeStep, setActiveStep] = useState(0);
    const steps = ["Business Context", "IAM Needs and Objectives", "IAM and Risk Teams", "Results"];

    // Status and error message
    const [error, setError] = useState(null);

    const [isPending, setIsPending] = useState(false);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    // Form data
    const [formData, setFormData] = useState({
        name: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
        phone: "",
        email: "",
        website: "",
        assessment: {
            businessContext: {
                industry: "",
                compliance: "",
                numberOfApplications: "",
            },
        },
    });
    const [errors, setErrors] = useState({
        name: "",
    });

    const handleCancel = () => {
        navigate(-1);
    };

    const handleNext = async () => {
        //console.log ('handleNext')
        if (activeStep === 0) {
            setError(null);
            //console.log ('validateForm: ', validateForm())
            if (validateForm()) {
                setError(null);
                setActiveStep(activeStep + 1);
            }
        } else {
            setActiveStep(activeStep + 1);
        }

        // Last step - save the person
        if (activeStep === steps.length - 1) {
            await handleSubmit();
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        // Validate firstName field
        if (formData.name.trim() === "") {
            newErrors.name = "Name is required";
            isValid = false;
        } else {
            newErrors.name = "";
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async () => {
        setIsPending(true);
        setError(null);

        try {
            console.log("Submit the form data:  ", formData);
            if (validateForm()) {
                // Form is valid, submit or perform further actions
                // const tenant = await saveTenant(person?.id, formData);
                setStatus({
                    open: true,
                    type: "success",
                    message: "Updated organization details",
                });
            }
            setError(null);
            setIsPending(false);
            navigate(-1);
        } catch (err) {
            console.log(err);
            setError(err);
            setIsPending(false);
        }
    };

    const handleChange = (e) => {
        //console.log ('handleChange: ', e.target.name, e.target.value)
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", width: "100vw" }}>
            <Header />
            <Box display="flex" flexDirection="column" alignItems="top" flexGrow={1} gap={1} mt={8} mb={0.5} mx={1}>
                {person && !person.tenant && <OrgProfile /> }
                {person && person.tenant && <AssessmentWizard />   }
            </Box>
            <Footer />
        </Box>
    );
}
