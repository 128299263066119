import { Alert, Box, Button, Card, CardContent, CardHeader, Checkbox, FormControlLabel, FormHelperText, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { AccountCircle, Lock } from "@mui/icons-material";
import { findInvitation } from "../../services/InvitationService";
import { useSignup } from "../../hooks/useSignup";
import ForgotPasswordInfo from "./ForgotPasswordInfo";
import { initiatePasswordReset } from "../../services/UserService";
import { translateAuthCodeToMessage } from "../../utils/AuthenticationUtils";

export default function ForgotPassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const [error, setError] = useState(null);
    const [info, setInfo] = useState(null);
    const [formData, setFormData] = useState({
        email: "",
    });
    const [errors, setErrors] = useState({
        email: "",
    });

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        // Validate email field
        if (formData.email.trim() === "") {
            newErrors.email = "Email is required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid email format";
            isValid = false;
        } else {
            newErrors.email = "";
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const p = await initiatePasswordReset(formData.email);
                // Redirect to Verification page
                setInfo("A reset link has been sent to your registered email or phone. Please verify the request in the next step. Redirecting...");
                setTimeout(() => {
                    setInfo("");
                    navigate(`/verify-password?pid=${p.id}`);
                }, 3000);
            } catch (err) {
                console.error("Error creating user:", err.code, err.message);
                var msg = translateAuthCodeToMessage(err.code);
                setError(msg);
            }
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            {/* Info Box */}
            <ForgotPasswordInfo />

            {/* Signin Box */}
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="50%" height="100%">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        flexGrow: 1,
                    }}
                >
                    <Card elevation={0}>
                        <CardHeader
                            // title="CyberShield"
                            // titleTypographyProps={{ color: "primary.main", variant: "title" }}
                            subheader="Reset your password"
                            subheaderTypographyProps={{ color: "primary.main", variant: "headline" }}
                            sx={{ textAlign: "center" }}
                        ></CardHeader>
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    name="email"
                                    label="Email"
                                    required
                                    fullWidth
                                    variant="standard"
                                    value={formData.email ?? ""}
                                    onChange={handleChange}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                />

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        marginTop: 2,
                                    }}
                                >
                                    <Button startIcon={<Lock />} variant="contained" color="primary" type="submit">
                                        Reset Password
                                    </Button>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        marginTop: 2,
                                    }}
                                >
                                    {error && <Alert severity="error">{error}</Alert>}
                                    {info && <Alert severity="info">{info}</Alert>}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Link to={code ? `/signin?code=${code}` : "/signin"}>
                                        <Button>Access your account</Button>
                                    </Link>
                                    <Link to={code ? `/signup?code=${code}` : "/signup"}>
                                        <Button>Create new account</Button>
                                    </Link>
                                </Box>
                            </form>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
}
