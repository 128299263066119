import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import CompanyLogo from "../../assets/images/logo-text.png";
import { CheckCircle} from "@mui/icons-material";

export default function InvitationInfo() {
    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" width="50%" height="100%" bgcolor={"black"}>
            <Box display="flex" flexDirection="row" mx={1} my={1}>
                <Link to="https://www.cybercodee.com" target="_blank">
                    <img src={CompanyLogo} alt="Logo" />
                </Link>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={10}>
                <Typography variant="h4" color="primary">
                    Welcome to {process.env.REACT_APP_TITLE}!
                </Typography>
                
                <Typography variant="h5" color="white">
                    Unlocking a world of possibilities.
                    </Typography>
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={10}>
                <Typography variant="subtitle" color="secondary">
                    Powered by CyberCodee, LLC.
                </Typography>
            </Box>
        </Box>
    );
}
