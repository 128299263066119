import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { deleteOrganization, saveOrganization } from "../../services/OrganizationService";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { GridLoader } from "react-spinners";
import { checkPermission } from "../../utils/AuthenticationUtils";
import * as Authority from "../../constants/Authorities";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StatusMessage from "../../components/StatusMessage";
import { formatDate, formatDateTime } from "../../utils/utils";
import { useTheme } from "@emotion/react";

export default function TenantTable({ tenants, isTab }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [rowModesModel, setRowModesModel] = useState({});
    const [rows, setRows] = useState([]);
    const { user, person } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const location = useLocation()

    // Warning states
    const [selectedId, setSelectedId] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [openMultipleDelete, setOpenMultipleDelete] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    useEffect(() => {
        setRows(tenants);
    }, [tenants]);

    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: "150",
            editable: true,
            renderCell: (cell) => {
                return <Link to={`/imag-ai/tenants/${cell.id}`}>{cell.row.name}</Link>;
            },
        },
        {
            field: "location",
            headerName: "Location",
            width: "150",
            editable: true,
            renderCell: (cell) => {
                return `${cell.row.city}, ${cell.row.state}`
            },
        },
        {
            field: "contact",
            headerName: "Primary Contact",
            width: "150",
            editable: true,
            renderCell: (cell) => {
                return `${cell.row.primaryContact.name}`
            },
        },
        {
            field: "createdDate",
            headerName: "Since",
            width: "150",
            valueGetter: (params) => {
                return params.row.createdDate ? formatDate(params.row.createdDate) : "";
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 200,
            cellClassName: "actions",

            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                return [
                    <GridActionsCellItem
                        icon={<Edit color="primary" />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditTenant(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem icon={<Delete color="warning" />} label="Delete" onClick={() => handleOpenDelete(id)} color="inherit" />,
                ];
            },
        },
    ];

    const handleAddTenant = () => {
        navigate("/imag-ai/tenants/new");
    };

    const handleEditTenant = (id) => () => {
        console.log("clicked Edit:", id);
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        navigate(`/imag-ai/tenants/edit/${id}`);
    };

    const handleDeleteTenant = (id) => async () => {
        setRows(rows.filter((row) => row.id !== id));
        await deleteOrganization(person?.id, id);
        handleCloseDelete();
        setStatus({ open: true, type: "success", message: "Deleted Tenant" });
    };

    const handleDeleteMultiple = async () => {
        if (selectedRows) {
            const promises = selectedRows.map(async (id) => {
                await deleteOrganization(person?.id, id);
            });
            await Promise.all(promises)
            handleCloseMultipleDelete();
            setStatus({
                open: true,
                type: "success",
                message: "Deleted Tenant(s)",
            });
            navigate (location.pathname,  {state: {refresh: Math.random() }} );
        }
    };

    const handleProcessRowUpdate = async (updatedRow, originalRow) => {
        const s = await saveOrganization(person.id, updatedRow);
        setStatus({ open: true, type: "success", message: "Saved Tenant" });
        return s;
    };
    const handleProcessRowUpdateError = (err) => {
        console.log(err);
        setStatus({ open: true, type: "error", message: err });
    };

    const handleSelectionModelChange = (selectedIds) => {
        setSelectedRows(selectedIds);
    };

    // Returns a custom tool bar for the tenant table
    function TenantTableToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <GridToolbarQuickFilter />

                <Box sx={{ marginRight: 2 }}>
                    <GridToolbarExport />
                    {checkPermission(person.tenant, Authority.PERMISSION_TENANT_CAN_DELETE) && (
                        <Button
                            startIcon={<Delete color="warning" />}
                            onClick={handleOpenMultipleDelete}
                            disabled={selectedRows && selectedRows.length > 0 ? false : true}
                        >
                            Delete
                        </Button>
                    )}
                    {checkPermission(person.tenant, Authority.PERMISSION_TENANT_CAN_CREATE) && (
                        <Button startIcon={<AddCircle />} onClick={handleAddTenant}>
                            Add Tenant
                        </Button>
                    )}
                    <Button
                            startIcon={<Delete color="warning" />}
                            onClick={handleOpenMultipleDelete}
                            disabled={selectedRows && selectedRows.length > 0 ? false : true}
                        >
                            Delete
                        </Button>
                    <Button startIcon={<AddCircle />} onClick={handleAddTenant}>
                            Add Tenant
                        </Button>
                    
                </Box>
            </GridToolbarContainer>
        );
    }

    function NoRowsOverlay() {
        return (
            <Box display="flex" flexDirection="column" justifyContent={"center"} alignItems={"center"}>
                <Typography sx={{ padding: 2 }}>No tenants found</Typography>
            </Box>
        );
    }

    const handleOpenDelete = (id) => {
        setSelectedId(id);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setSelectedId(null);
    };
    const handleOpenMultipleDelete = () => {
        setOpenMultipleDelete(true);
    };

    const handleCloseMultipleDelete = () => {
        setOpenMultipleDelete(false);
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <div style={{ width: "100%" }}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                checkboxSelection
                disableRowSelectionOnClick
                autoHeight
                slots={{
                    toolbar: TenantTableToolbar,
                    noRowsOverlay: NoRowsOverlay,
                }}
                sx={{
                    border: 0,
                    boxShadow: 0,
                    fontSize: "1rem",
                    "& .MuiDataGrid-cell:hover": {
                        color: "primary.main",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                    },
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                }}
                processRowUpdate={handleProcessRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                // Called on checkbox selection
                selectionModel={selectedRows}
                onRowSelectionModelChange={handleSelectionModelChange}
            />

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDelete} onClose={handleCloseDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Delete tenant ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting a tenant may have unintended consequences. Are you sure?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDelete} color="inherit">
                        No
                    </Button>
                    <Button variant="contained" color="warning" onClick={handleDeleteTenant(selectedId)} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Multiple Confirmation Dialog */}
            <Dialog
                open={openMultipleDelete}
                onClose={handleCloseMultipleDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete tenants?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting tenants may have unintended consequences. Are you sure?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseMultipleDelete} color="inherit">
                        No
                    </Button>
                    <Button variant="contained" color="warning" onClick={handleDeleteMultiple} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
