import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Divider } from "@mui/material";
import { Reply } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { and, collection, limit, onSnapshot, query, where } from "firebase/firestore";

import { db } from "../../config/FirebaseConfig";
import * as DBConstants from "../../constants/DBConstants";
import { useTheme } from "@mui/material/styles";
import { GridLoader } from "react-spinners";
import AuditTable from "./AuditTable";

export default function AuditList() {
    const navigate = useNavigate();
    const theme = useTheme()
    const { user, person } = useAuthContext();
    const [audits, setAudits] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getAudits() {
            setLoading(true);
            const constraints = [];
            // if (person?.tenant) {
            //     constraints.push(where("tenant.id", "==", person.tenant.id));
            // }
            const ref = query(collection(db, DBConstants.DB_AUDITS), and(...constraints), limit(50));
            const unsub = onSnapshot(ref, (snapshot) => {
                let results = [];
                snapshot.docs.forEach((doc) => {
                    results.push({ ...doc.data(), id: doc.id });
                });
                
                setAudits(results);
            });
            setLoading(false);
            // cleanup function
            return () => unsub();
        }

        getAudits();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    return loading ? (
      <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
          <GridLoader color={theme.palette.primary.light} />
      </Box>
  ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Card>
                <CardHeader
                    title="Audits"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="View your human identities here."
                    action={
                        <Button startIcon={<Reply />} onClick={handleBack} color="info">
                            Back
                        </Button>
                    }
                />
                <Divider />

                {audits && <CardContent>{audits && <AuditTable audits={audits} title="Audits" />}
                </CardContent>}
            </Card>
        </Box>
    );
}
