// Formats 
export const DATE_FORMAT = process.env.REACT_APP_DATE_FORMAT
export const DATETIME_FORMAT = process.env.REACT_APP_DATETIME_FORMAT

export const ENTITY_PERSON = "Person"
export const ENTITY_ORGANIZATION = "Organization"

// MOCK 
export const STATIC_PASSWORD = "password"

// Verification keys
export const VERIFICATION_CODE_LENGTH = 8
export const VERIFICATION_CODE_SPLIT = 4
export const VERIFICATION_CODE_ALPHANUMERIC = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789' // No confusing chars such as 'O' and 0 or I and 1. 
export const VERIFICATION_CODE_NUMERIC = '1234567890' // All numbers. 

// Genders
export const GENDER_MALE='Male'
export const GENDER_FEMALE='Female'
export const GENDER_OTHER='Other'

// Custom Menu
export const ROUTE_CUSTOM = "custom"
export const ROUTE_LINK = "link"
export const ROUTE_SUBMENU = "submenu"
export const ROUTE_EXTERNAL = "external"

// Status 
export const STATUS_ACTIVE = "Active"
export const STATUS_INACTIVE = "Inactive"
export const STATUS_PENDING = "Pending"
export const STATUS_DELETED = "Deleted"

// Default Page size for tables 
export const DEFAULT_PAGE_SIZE = 20

// Static text 
export const KNOWLEDGE_BASE = "Knowledge Base"
