import React, { useState } from "react";
import { Box, Hidden, List, ListItem, ListItemIcon, Paper, SwipeableDrawer, Toolbar } from "@mui/material";
import { useAuthContext } from "../hooks/useAuthContext";
import { checkPermission } from "../utils/AuthenticationUtils";
import SidebarItem from "./SidebarItem";
import * as Constants from "../constants/Constants";
import { useNavigate } from "react-router-dom";
import { Home } from "@mui/icons-material";

export default function Sidebar({ opened, toggleDrawer, routes }) {
    const { person } = useAuthContext();
    const [activeRoute, setActiveRoute] = useState(undefined);
    const navigate = useNavigate();
    //const toggleMenu = (index) => setActiveRoute(activeRoute === index ? undefined : index);

    // Expand the menu if the current path is a child of the menu item
    const toggleMenu = (index) => {
        if (activeRoute !== index) {
            if (routes[index].component) navigate(routes[index].path);
        }
        setActiveRoute(activeRoute === index ? undefined : index);
    };

    const menu = (
        <List component="div">
            {/* <ListItem>
                <ListItemIcon>
                    <Home onClick={ ()=> navigate ('/')} />
                </ListItemIcon>
            </ListItem> */}
            {routes.map((route, index) => {
                const isCurrentPath = false;
                let show = true;
                //let show = checkPermission(person.role, route.permission)
                //console.log ('Route name: ', route.name ,'Route type: ', route.type)
                if (show && route.type !== Constants.ROUTE_CUSTOM) {
                    return (
                        <SidebarItem
                            key={index}
                            index={index}
                            route={route}
                            activeRoute={activeRoute}
                            toggleMenu={toggleMenu}
                            currentPath={isCurrentPath}
                        />
                    );
                }
            })}
        </List>
    );

    return (
        <Box p={0.5} width="240px" sx={{ display: "flex", flexDirection: "row", alignItems: "top", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
            <Hidden smDown>{menu}</Hidden>
            <Hidden mdUp>
                <SwipeableDrawer
                    variant="temporary"
                    anchor="left"
                    open={opened}
                    onClose={toggleDrawer}
                    onOpen={toggleDrawer}
                    onBackdropClick={toggleDrawer}
                    PaperProps={{
                        sx: {
                            backgroundColor: "white",
                        },
                    }}
                >
                    <Toolbar />
                    <Paper elevation={2}>{menu}</Paper>
                </SwipeableDrawer>
            </Hidden>
        </Box>
    );
}
