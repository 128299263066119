// Prompt messages and roles
export const AI_ROLE_SYSTEM = "system"; //sets the personality of the assistant
export const AI_ROLE_ASSISTANT = "assistant"; // Store previous assistant responses
export const AI_ROLE_USER = "user"; // user messages provide requests or comments for the assistant to respond to


// Auditor styles 
export const AI_AUDITOR_STYLE_CONSERVATIVE = "Conservative";
export const AI_AUDITOR_STYLE_BALANCED = "Balanced";
export const AI_AUDITOR_STYLE_PROGRESSIVE = "Progressive";

export const AI_AUDITOR_SYSTEM_PROMPT =
    "You are an expert security auditor with deep knowledge in Standards and Regulations such as GDPR, ISO 27001, PCI DSS, HIPAA, CCPA, etc. " +
    " You are responsible for assessing the compliance of the organization with the relevant standards and regulations. " +
    " I will provide you with a set of criteria comprising of countries, industries, size of the company etc. Analyze these requirements " +
    " to assess the relavant standards and regulations that apply to the organization. " +
    " Return all responses as JSON objects. ";
