import * as EmailTemplates from "./EmailTemplates";

// Events Category
export const EVENT_CATEGORY_BUSINESS = "Business";
export const EVENT_CATEGORY_APPLICATION = "Application";
export const EVENT_CATEGORY_OPERATIONAL = "Operational";
export const EVENT_CATEGORY_SECURITY = "Security";

// Application Events
export const EVENT_INVITE_TO_SIGNUP = "SignUp";
export const EVENT_PASSWORD_RESET = "Reset Password";

export const NOTIFICATION_EVENTS = [
    {
        type: EVENT_INVITE_TO_SIGNUP,
        category: EVENT_CATEGORY_APPLICATION,
        display: "Invite to join",
        email: true,
        emailTemplate: EmailTemplates.SIGNUP_EMAIL_TEMPLATE_ID,
        expirationDays: 7,
        expirationMinutes: 0,
        expirationSeconds: 0
    },
    
];

