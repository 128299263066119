import {
    Badge,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    Divider,
    Drawer,
    Fade,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IAMCapabilityCategories, ProgramGovernance, ProgramGovernanceAreas, getIAMCodelist } from "../../constants/AssessmentConstants";
import { useAuthContext } from "../../hooks/useAuthContext";
import { AddShoppingCart, ExpandLess, ExpandMore, PlusOne, Security, ShoppingCart } from "@mui/icons-material";
import { checkIdExists, deleteObjectById, getIdName } from "../../utils/utils";
import { getAllIAMCapabilities } from "../../services/AssessmentService";
import { useTheme } from "@emotion/react";
import { GridLoader } from "react-spinners";
import GapAnalysis from "./GapAnalysis";
import StatusMessage from "../../components/StatusMessage";

export default function GapAnalysisSnapshot({ assessment, auditor, mod }) {
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const { person } = useAuthContext();
    const [capabilityCategories, setCapabilityCategories] = useState(null);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCapability, setSelectedCapability] = useState(null);

    const [showGap, setShowGap] = useState(false);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    useEffect(() => {
        getIAMCapabilities();
    }, []);

    // Get all IAM categories with their associated capabilities
    const getIAMCapabilities = async () => {
        setLoading(true);
        const categories = await getAllIAMCapabilities();
        setCapabilityCategories(categories);
        setLoading(false);
    };

    const handleCapabilitySelection = (category, capability) => {
        // console.log("handleCapabilitySelection", category, capability);
        if (checkIdExists(assessment?.capabilityCategories, `${category.id}-${capability.id}`)) {
            setStatus({
                open: true,
                type: "success",
                message: "You have indicated that you have this capability",
            });
            setShowGap(false);
        } else {
            setSelectedCategory(category);
            setSelectedCapability(capability);
            setShowGap(true);
        }
    };

    const handleGovernanceAreaSelection = (category) => {
        console.log("handleGovernanceAreaSelection", category);
        if (checkIdExists(assessment?.governanceCategories, category.id)) {
            setStatus({
                open: true,
                type: "success",
                message: "You have indicated that you have this capability",
            });
            setShowGap(false);
        }else {
            let gov = {
                id: 0,
                name: "Program Governance",
                code: ProgramGovernance,
            };
            setSelectedCategory(gov);
            setSelectedCapability(category);
            setShowGap(true);
        }
    }

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <Box display="flex" flexDirection="row" gap={0} sx={{ boxShadow: 0.5 }}>
                <Card sx={{ bgcolor: "#eee", flexGrow: 1, m: 0, p: 0 }}>
                    <CardHeader
                        subheader="Program Governance"
                        subheaderTypographyProps={{ color: "white", fontSize: "1rem", fontWeight: "bold" }}
                        sx={{ textAlign: "center", bgcolor: "black" }}
                        action={
                            <ShoppingCart color="secondary"/>
                        }
                    />
                    <Divider />

                    <CardContent sx={{ p: 0, m: 0 }}>
                        <List
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                gap: 1,
                            }}
                        >
                            {getIAMCodelist(ProgramGovernanceAreas).map((category) => {
                                return (
                                    <ListItem
                                        key={category.id}
                                        alignItems="center"
                                        gap={1}
                                        sx={{
                                            bgcolor: checkIdExists(assessment?.governanceCategories, category.id) ? "secondary.main" : "#fff",
                                            width: "11%",
                                            ml: 1,
                                            boxShadow: 4,
                                            textAlign: "center",
                                        }}
                                        onClick={() => handleGovernanceAreaSelection(category)}
                                    >
                                        <ListItemText
                                            primary={category.name}
                                            primaryTypographyProps={{
                                                fontSize: "0.9rem",
                                                fontWeight: "bold",
                                                color: checkIdExists(assessment?.governanceCategories, category.id) ? "black" : "grey",
                                                noWrap: false,
                                            }}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </CardContent>
                    <CardActions sx={{ m: 0, p: 0 }} />
                </Card>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="flex-start"
                gap={1}
                sx={{ bgcolor: "#eee", boxShadow: 1, py: 2 }}
            >
                {capabilityCategories &&
                    capabilityCategories.map((category) => {
                        return (
                            <Card sx={{ width: "13%", bgcolor: "transparent" }} elevation={0} key={category.id}>
                                <CardHeader
                                    subheader={category.name}
                                    subheaderTypographyProps={{ color: "white", fontSize: "1rem", fontWeight: "bold" }}
                                    sx={{ textAlign: "center", bgcolor: "black", height: "4rem" }}
                                    action={
                                        <ShoppingCart color="secondary"/>
                                    }
                                />

                                <CardContent sx={{ p: 0, m: 0 }}>
                                    <List
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            gap: 1,
                                            p: 1,
                                        }}
                                    >
                                        {category.capabilities.map((capability) => {
                                            return (
                                                <ListItem
                                                    key={capability.id}
                                                    alignItems="center"
                                                    gap={0}
                                                    sx={{
                                                        bgcolor: checkIdExists(assessment?.capabilityCategories, `${category.id}-${capability.id}`)
                                                            ? "secondary.main"
                                                            : "#fff",
                                                        boxShadow: 4,
                                                        textAlign: "center",
                                                    }}
                                                    onClick={() => handleCapabilitySelection(category, capability)}
                                                    
                                                >
                                                    <ListItemText
                                                        primary={capability.name}
                                                        primaryTypographyProps={{
                                                            fontSize: "0.9rem",
                                                            fontWeight: "bold",
                                                            color: checkIdExists(assessment?.capabilityCategories, `${category.id}-${capability.id}`)
                                                                ? "black"
                                                                : "grey",
                                                            noWrap: false,
                                                        }}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </CardContent>
                                <CardActions sx={{ m: 0, p: 0 }} />
                            </Card>
                        );
                    })}
            </Box>

            <Drawer variant="temporary" anchor="right" open={showGap} onClose={() => setShowGap(false)}>
                <Box sx={{ width: "70vw", height: "70vh", p: 2 }}>
                    <GapAnalysis assessment={assessment} category={selectedCategory} capability={selectedCapability} setShowGap={setShowGap} auditor={auditor}/>
                </Box>
            </Drawer>
        </Box>
    );
}
