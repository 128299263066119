import { collection, deleteDoc, doc, getCountFromServer, getDoc, getDocs, limit, orderBy, query, setDoc, where } from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { generateVerificationCode, personName, updateAudit } from "../utils/utils";
import { findOrganization } from "./OrganizationService";
import { addDays, endOfDay } from "date-fns";
import { NOTIFICATION_EVENTS } from "../constants/EventTypes";

// Get all invitations
export const findAllInvitations = async (uid) => {
    let results = [];
    const q = query(collection(db, DBConstants.DB_INVITATIONS), where("createdBy", "==", uid));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

export const findInvitationsByTenantId = async (tenantId) => {
    let results = [];
    const q = query(collection(db, DBConstants.DB_INVITATIONS), where("tenant.id", "==", tenantId));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

export const countInvitationsByTenantId = async (tenantId) => {
    const q = query(collection(db, DBConstants.DB_INVITATIONS), where("tenant.id", "==", tenantId));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
};

// Find a invitation with id
export const findInvitation = async (id) => {
    const docRef = doc(db, DBConstants.DB_INVITATIONS, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
};

export const findInvitationByIds = async (invitationIds) => {
    let results = [];
    const q = query(collection(db, DBConstants.DB_INVITATIONS), where("id", "in", invitationIds), orderBy("createdDate", "desc"));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

export const findInvitationByCode = async (code) => {
    const q = query(collection(db, DBConstants.DB_INVITATIONS), where("verificationCode", "==", code), limit(1));
    const snapshot = await getDocs(q);
    if (!snapshot.empty) {
        return snapshot.docs[0].data();
    }
};

// Save a Invitation
export const saveInvitationRaw = async (id, invitation) => {
    let docRef
    if(invitation.id) {
      docRef = doc(collection(db, DBConstants.DB_INVITATIONS), invitation.id);
    }else {
      docRef = doc(collection(db, DBConstants.DB_INVITATIONS));
      invitation.id = docRef.id;
    }
  
    updateAudit(id, invitation);
    await setDoc(docRef, invitation, { merge: true });
    return await findInvitation(invitation.id);
  
  };


export const saveInvitation = async (uid, tenantId, invitation) => {
    //console.log ('Saving invitation: ', invitation)
    let docRef = null;
    const tenant = await findOrganization(tenantId);

    if (invitation && invitation.id) {
        docRef = doc(db, DBConstants.DB_INVITATIONS, invitation.id);
    } else {
        // Save the new doc
        docRef = doc(collection(db, DBConstants.DB_INVITATIONS));
        invitation.id = docRef.id;
        await setDoc(docRef, invitation, { merge: true });
    }

    updateAudit(uid, invitation);
    await setDoc(docRef, invitation, { merge: true });

    invitation = await findInvitation(invitation.id);
    return invitation;
};

// Delete a invitation
export const deleteInvitation = async (id) => {
    const ref = doc(db, DBConstants.DB_INVITATIONS, id);
    await deleteDoc(ref);
};


export const createSignUpInvitation = async (fromPerson, p, notificationType) => {
    //console.log ('From: ', fromPerson, ', to ', p)

    const selected = NOTIFICATION_EVENTS.find((t) => t.type === notificationType);
    const verificationCode = generateVerificationCode();
    // Pre-create an id for the document 
    const docRef = doc(collection(db, DBConstants.DB_INVITATIONS));

    let invite = {
        id: docRef.id,
        to: p.email,
        subject: `${fromPerson.tenant.name} invites you to join ${process.env.REACT_APP_TITLE}`,
        templateId: selected.emailTemplate,
        appName: process.env.REACT_APP_TITLE,
        email: p.email,
        person: {
            id: p.id || '',
            firstName: p.firstName,
            lastName: p.lastName,
            displayName: personName(p),
        },
        verificationCode: verificationCode,
        notificationType: notificationType,
        completed: false,
        expirationDate: endOfDay(addDays(new Date(), selected.expirationDays)),
        invitationUrl: `${window.location.origin}/invitations/${docRef.id}`
    };
    if (fromPerson.school) {
        invite.school = {
            id: fromPerson.school.id,
            name: fromPerson.school.name,
        };
    }
    if (fromPerson.tenant) {
        invite.tenant = {
            id: fromPerson.tenant.id,
            name: fromPerson.tenant.name,
        };
    }

    return await saveInvitation(fromPerson.id, fromPerson.tenant?.id, invite);
};


