import { Edit, Reply } from "@mui/icons-material";
import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Grid, LinearProgress, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { savePerson } from "../../services/PersonService";
import ProfileEdit from "./ProfileEdit";
import ProfileView from "./ProfileView";
import { personName, stringAvatar } from "../../utils/utils";
import ProfilePasswordChange from "./ProfilePasswordChange";
import DeleteAccount from "./DeleteAccount";

export default function MyProfile() {
    //const classes = useStyles();
    const { user, person, profile, dispatch } = useAuthContext();
    const navigate = useNavigate();
    const [edit, setEdit] = useState(false);

    const handleProfileEdit = (e) => {
        setEdit(true);
    };

    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        setEdit(false);

        let p = {
            ...person,
            firstName: e.target.firstName.value,
            lastName: e.target.lastName.value,
            addressLine1: e.target.addressLine1.value,
            addressLine2: e.target.addressLine2.value,
            city: e.target.city.value,
            state: e.target.state.value,
            zipcode: e.target.zipcode.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
        };

        p = await savePerson(user.uid, p, person.tenantId, person.role);
        await dispatch({
            type: "PERSON_CHANGED",
            person: p,
        });
    };

    const handleCancel = () => {
        setEdit(false);
        navigate("/settings/profile");
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }}>
            <Card>
                <CardHeader
                    title="My Profile"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="Update your details,contact information, and preferences, to ensure your profile is accurate and up to date."
                    action={
                        <Button startIcon={<Reply />} onClick={handleBack} color="primary">
                            Back
                        </Button>
                    }
                />
                <Divider />
                <CardContent>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        {profile && !profile.complete && (
                            <>
                                <Typography sx={{ marginTop: 4, marginBottom: 4 }} color="error">
                                    Your profile is incomplete. Please fill your personal details
                                </Typography>
                                <Divider />
                            </>
                        )}
                        <Box display="flex" flexDirection="row" >
                            <Box width="30%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                {person && <Avatar {...stringAvatar(personName(person))} sx={{ width: 100, height: 100, mb: 4 }} />}
                                <Typography variant="subtitle1">Profile Completion (40%)</Typography>
                                <Box width="80%">
                                    <LinearProgress variant="determinate" value={40} />
                                </Box>
                            </Box>
                            <Box width="70%">
                                <Card sx={{ flexGrow: 1 }} elevation={0}>
                                    <CardHeader
                                        title="Profile Details"
                                        titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                                        action={
                                            <Tooltip title="Edit Profile">
                                                <Edit color="primary" onClick={handleProfileEdit} />
                                            </Tooltip>
                                        }
                                    />

                                    
                                    {profile && !edit && <ProfileView />}

                                    {profile && edit && <ProfileEdit updateProfile={handleProfileUpdate} handleCancel={handleCancel} />}
                                </Card>
                            </Box>
                        </Box>

                        
                    </Box>
                </CardContent>
            </Card>

            <ProfilePasswordChange />
            <DeleteAccount />
        </Box>
    );
}
