import { useState } from "react";
import { loadUserDetail } from "../services/UserService";
import { useAuthContext } from "./useAuthContext";
import { auth } from "../config/FirebaseConfig";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { translateAuthCodeToMessage } from "../utils/AuthenticationUtils";
import { saveInvitation } from "../services/InvitationService";
import { useNavigate } from "react-router-dom";

export const useSignup = () => {
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const { dispatch } = useAuthContext();
    const navigate = useNavigate()

    const signup = async (email, password, firstName, lastName, invitation) => {
        setError(null);
        setIsPending(true);
        //try {
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (res) => {
                const user = res.user;
                //console.log("User Created: ", user.uid);

                // Add display name to the user
                const displayName = firstName + " " + lastName;
                await updateProfile(res.user, { displayName });
                //console.log(" Profile Updated with displayName: ", displayName);

                // If there is an invitation, use the person id to create the user id
                const detail = await loadUserDetail(res.user.uid, email ? email : res.user.email, firstName, lastName, invitation?.person?.id);
                //console.log("Loading user detail: ", detail);
                // Update the invitation
                if (invitation) {
                    invitation.completed = true;
                    invitation.completedDate = new Date();
                    saveInvitation(user.uid, invitation?.tenant?.id, invitation);
                }

                await dispatch({
                    type: "LOGIN",
                    payload: res.user,
                    person: detail.person,
                    profile: detail.profile,
                });

                setError(null);
                setIsPending(false);

                navigate('/')
            })
            .catch((err) => {
                // User creation failed
                //console.error("Error creating user:", err.code, err.message);
                var msg = translateAuthCodeToMessage(err.code);
                setError(msg);
                setIsPending(false);
            });

    };
    return { signup, error, isPending };
};
