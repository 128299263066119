import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc } from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { updateAudit } from "../utils/utils";

// Create a new role
export const createRole = async (uid, role) => {
    const docRef = doc(collection(db, DBConstants.DB_ROLES));
    role.id = docRef.id;
    updateAudit(uid, role);
    await setDoc(docRef, role, { merge: true });
    return await findRole(role.id);
};

// update an existing role
export const updateRole = async (uid, role) => {
    updateAudit(uid, role);
    const docRef = doc(db, DBConstants.DB_ROLES, role.id);
    await setDoc(docRef, { ...role }, { merge: true });
    return await findRole(role.id);
};

// Create a new role or update an existing one
export const saveRole = async (uid, tenantId, role) => {
    role.tenantId = tenantId;
    if (role.id) {
        return await updateRole(uid, role);
    } else {
        return await createRole(uid, role);
    }
};

// Delete an role
export const deleteRole = async (roleId) => {
    // Delete the role
    const docRef = doc(db, DBConstants.DB_ROLES, roleId);
    await deleteDoc(docRef);
};

// Find an role with id
export const findRole = async (id) => {
    const docRef = doc(db, DBConstants.DB_ROLES, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
};

// Find all roles for a tenant
export const findAllRoles = async (tenantId) => {
    let results = [];
    const q = query(
        collection(db, DBConstants.DB_ROLES)
        //where("tenantId", "==", tenantId)
    );
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

