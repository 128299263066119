import { createContext, useReducer, useEffect, useContext } from "react";
import { useMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { amber, deepOrange, green, indigo, orange, red } from "@mui/material/colors";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

export const AppContext = createContext();

const reducer = (state, action) => {
    switch (action.type) {
        case "direction":
            const newState = {
                ...state,
                direction: state.direction === "ltr" ? "rtl" : "ltr",
            };
            return newState;
        case "type":
            return { ...state, type: state.type === "light" ? "dark" : "light" };
        default:
            return state;
    }
};

export const AppThemeProvider = ({ children }) => {
    const prefersDarkMode = useMediaQuery("@media (prefers-color-scheme: dark)");
    const [state, dispatch] = useReducer(reducer, {
        type: prefersDarkMode ? "dark" : "light",
        direction: "ltr",
    });

    const theme = createTheme({
        direction: state.direction,
        // Add global styles for body color and background
        components: {
            MuiCssBaseline: {
                "@global": {
                    body: {
                        color: "#333",
                        backgroundColor: "#000000",
                    },
                },
            },
            MuiStepIcon: {
                styleOverrides: {
                    root: {
                        "&$completed": {
                            color: "primary",
                        },
                        "&$active": {
                            color: "secondary.main",
                        },
                      },
                    },
                  },
             MuiDataGrid: {
                styleOverrides: {
                    root: {
                        "& .MuiDataGrid-cell:hover": {
                            color: "primary.main",
                        },
                        "& .MuiDataGrid-columnSeparator": {
                            display: "none",
                        },
                        "& .MuiDataGrid-root": {
                            color: "#000",
                            border: "none"
                        },  
                        
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root:{
                        "&.MuiTab-root": {
                            fontSize: "1rem",
                            fontWeight: "500",
                            color: "grey",
                        }
                    }
                }
            }     
        },

        palette: {
            type: state.type,
            primary: indigo,
            secondary: amber,
            error: red,
            warning: deepOrange,
            info: indigo,
            success: green,
        },
        card: {
            position: "relative",
            clear: "both",
        },
        typography: {
            fontFamily: ["Roboto", "Helvetica Neue", "Arial", "sans-serif"].join(","),
            title: {
                fontSize: "2rem",
                fontWeight: 500,
            },
            headline: {
                fontSize: "1.2rem",
                fontWeight: "bold",
                color: "grey",
            },
            headline2: {
                fontSize: "1.2rem",
                color: "grey",
            },
            subtitle: {
                fontSize: "1.1rem",
                fontWeight: "bold",
                color: "grey",
            },
            subtitle1: {
                fontSize: "1.1rem",
                color: "grey",
            },
            subtitle2: {
                fontSize: "1.1rem",
                color: "lightgrey",
            },
            body: {
                fontSize: "1rem",
                color: "#333",
            },
            
            body2: {
                fontSize: "1rem",
                color: "#333",
            },
            smalltitle: {
                fontSize: "0.9rem",
                fontWeight: "bold",
                color: "grey",
            },
            smallbody: {
                fontSize: "0.9rem",
                color: "grey",
            },

            button: {
                fontWeight: 400,
                textTransform: "initial",
            },
        },
        
        shape: {
            borderRadius: 4,
        },
        transitions: {
            duration: {
                shortest: 150,
                shorter: 200,
                short: 250,
                // most basic recommended timing
                standard: 300,
                // this is to be used in complex animations
                complex: 375,
                // recommended when something is entering screen
                enteringScreen: 225,
                // recommended when something is leaving screen
                leavingScreen: 195,
            },
            easing: {
                // This is the most common easing curve.
                easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
                // Objects enter the screen at full velocity from off-screen and
                // slowly decelerate to a resting point.
                easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
                // Objects leave the screen at full velocity. They do not decelerate when off-screen.
                easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
                // The sharp curve is used by objects that may return to the screen at any time.
                sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
              },
        },
    });

    useEffect(() => {
        document.body.dir = state.direction;
    }, [state.direction]);

    //console.log('AppContext state:', state)

    return (
        <ThemeProvider theme={theme}>
            <AppContext.Provider value={{ ...state, dispatch }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                {children}
                </LocalizationProvider>
                </AppContext.Provider>
        </ThemeProvider>
    );
};

export default AppThemeProvider;
export const useAppState = () => useContext(AppContext);
