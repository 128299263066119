import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useAuthContext } from "../../hooks/useAuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Reply, WatchLater } from "@mui/icons-material";
import { GridLoader } from "react-spinners";
import ObjectivesForm from "./ObjectivesForm";
import TeamsEditForm from "./TeamsEditForm";
import BusinessContextForm from "./BusinessContextForm";
import AssessmentReview from "./AssessmentReview";
import { useTheme } from "@mui/material/styles";
import StatusMessage from "../../components/StatusMessage";
import { findLatestAssessmentByTenant, saveAssessment } from "../../services/AssessmentService";
import { stringifyWithSortedProperties } from "../../utils/FormUtils";
import { noAudit } from "../../utils/utils";
import CapabilityAssessment from "./CapabilityAssessment";
import { findLatestAssessmentResultByTenant } from "../../services/AssessmentResultService";
import { saveProfile } from "../../services/ProfileService";

const useStyles = makeStyles((theme) => ({
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        fontStyle: "italic",
    },
}));

export default function AssessmentWizard({ assessment, updateAssessment, originalAssessment }) {
    const theme = useTheme();
    const classes = useStyles();
    const { user, person, profile, dispatch } = useAuthContext();
    const navigate = useNavigate();
    const location = useLocation();

    // Step functions
    const stepContent = [
        "Let's set some context for your business. Understanding your industry, target audience, and unique value proposition lays the foundation for effective strategies that propel your success forward.",
        "Share your mission, values, and what makes your solution unique. Your insights help us align our efforts with your goals, creating a partnership that drives innovation and meaningful impact.",
        "Help us with your current security program. By understanding these aspects, we ensure a robust security framework that safeguards our operations and data. ",
        "Help us understand your team structure and how you work to grasp their roles in safeguarding your digital fortress. IAM keeps the gates secure, managing who enters, while the Risk team maps the terrain of potential threats. Together, they form an impenetrable shield, ensuring your data remains in safe hands.",
        "Review and choose your auditor. This is the final step before submitting your assessment. ",
    ];
    const [activeStep, setActiveStep] = useState(0);
    const steps = ["Business Context", "IAM Needs and Objectives", "Capability Assessment", "IAM and Risk Teams", "Review"];

    // Status and error message
    const [error, setError] = useState(null);

    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    const handleCancel = () => {
        navigate(-1);
    };

    // Change the step
    const handleStepClick = (stepIndex) => {
        setActiveStep(stepIndex);
    };

    const handleNext = async () => {
        console.log("Assessment: ", assessment);
        // Last step - save the assessment
        if (activeStep === steps.length - 1) {
            await handleSubmit();
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleSkip = async () => {
        // Update the profile.complete flag to true.
        profile.complete = true;
        await saveProfile(person?.id, profile);
        // Dispatch the event to update the profile
        await dispatch({
            type: "PROFILE_CHANGED",
            profile: profile,
        });
        navigate("/");
    };

    const handleSubmit = async () => {
        setError(null);
        // Save the assessment
        try {
            // Detect if the assessment conditions have changed by comparing the hashes
            let param = "mod=false";
            let latest = await findLatestAssessmentByTenant(person?.tenant?.id);
            console.log("Original: ", originalAssessment);
            console.log("Modified: ", assessment);

            const org = stringifyWithSortedProperties(noAudit(latest));
            const modified = stringifyWithSortedProperties(noAudit(assessment));
            console.log("Original: ", org);
            console.log("Modified: ", modified);
            if (org !== modified) {
                param = "mod=true";
            } else {
                // Check if the results are not present
                const result = await findLatestAssessmentResultByTenant(person?.tenant?.id);
                console.log("Result : ", result);
                if (result === null) {
                    param = "mod=true";
                }
            }
            console.log("Modified?: ", param);

            const updatedAssessment = await saveAssessment(person.id, person.tenant?.id, assessment);
            updateAssessment(updatedAssessment);
            setStatus({
                open: true,
                type: "success",
                message: "Assessment saved successfully",
            });
            navigate(`/audit-ai/assessments/${updatedAssessment.id}/result?${param}`);
        } catch (error) {
            console.error(error);
            setError(error);
            setStatus({
                open: true,
                type: "error",
                message: "Error saving assessment",
            });
        }
    };

    return (
        <Card>
            <StatusMessage status={status} changeStatus={setStatus} />
            <CardHeader
                title="Let's asses your security posture"
                titleTypographyProps={{ color: "primary.main" }}
                action={
                    <>
                        <Button startIcon={<WatchLater />} onClick={handleSkip} color="info">
                            Skip
                        </Button>
                        <Button startIcon={<Reply />} onClick={handleCancel} color="info">
                            Back
                        </Button>
                    </>
                }
            />

            <CardContent>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        return (
                            <Step key={label}>
                                <StepLabel>
                                    <Link to="#" onClick={() => handleStepClick(index)}>
                                        {label}
                                    </Link>
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Box mt={4}>
                    {activeStep === steps.length ? (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "70vw",
                                height: "70vh",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <GridLoader color={theme.palette.primary.light} />
                        </Box>
                    ) : (
                        <div>
                            <Typography className={classes.instructions}>{stepContent[activeStep]}</Typography>

                            {activeStep === 0 && <BusinessContextForm assessment={assessment} updateAssessment={updateAssessment} />}

                            {activeStep === 1 && <ObjectivesForm assessment={assessment} updateAssessment={updateAssessment} />}

                            {activeStep === 2 && <CapabilityAssessment assessment={assessment} updateAssessment={updateAssessment} />}

                            {activeStep === 3 && <TeamsEditForm assessment={assessment} updateAssessment={updateAssessment} />}

                            {activeStep === 4 && <AssessmentReview assessment={assessment} updateAssessment={updateAssessment} />}
                        </div>
                    )}
                </Box>
            </CardContent>
            <CardActions
                //disableSpacing
                sx={{
                    alignSelf: "stretch",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    paddingRight: 4,
                }}
            >
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                    Back
                </Button>
                <Button variant="contained" color="primary" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
            </CardActions>
        </Card>
    );
}
