import { Delete, Edit, Person, Reply, Search, SensorOccupied } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { BackgroundIcon } from "../../utils/UIStyles";

const useStyles = makeStyles((theme) => ({
    gradientHeader: {
        background: `linear-gradient(90deg, ${theme.palette.primary.light}, ${theme.palette.primary.light})`,
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
    gradientBody: {
        background: `linear-gradient(to right bottom, ${theme.palette.primary.light}, ${theme.palette.primary.light})`,
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
}));

export default function EntitlementCard() {
    const classes = useStyles();

    return (
        <Card elevation={1} sx={{ width: "300px" }} className={classes.gradientBody}>
            <CardHeader
                title="Entitlements"
                titleTypographyProps={{ color: "white", variant: "subtitle" }}
                sx={{ p: 0 }}
                className={classes.gradientHeader}
            />
            <Divider />
            <CardContent sx={{ position: "relative" }}>
                <BackgroundIcon component={SensorOccupied} bottom={50} />
                <Box display="flex" flexDirection="column" flexGrow={1} gap={1}>
                    <Typography variant="body" color="white">
                        5 Entitlements
                    </Typography>

                    <Box display="flex" flexDirection="row" justifyContent="space-between" mt={4}>
                        <Box display="flex" flexDirection="row" justifyContent="flex-start">
                            <IconButton sx={{ color: "white" }}>
                                <Search />
                            </IconButton>
                            <IconButton sx={{ color: "white" }}>
                                <Edit />
                            </IconButton>
                            <IconButton sx={{ color: "white" }}>
                                <Delete />
                            </IconButton>
                        </Box>
                        <Button size="small" variant="contained" color="secondary">Add Entitlements</Button>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}
