import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { AddCircle, AddLocation, BugReport, Delete, Edit, LocationOn, Quiz } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { GridLoader } from "react-spinners";
import { checkPermission } from "../../utils/AuthenticationUtils";
import * as Authority from "../../constants/Authorities";
import { useNavigate } from "react-router-dom";
import StatusMessage from "../../components/StatusMessage";
import { useTheme } from "@emotion/react";

const getBackgroundColorForState = (state) => {
    switch (state) {
        case "Critical":
            return "warning.main"; // Dark background for Critical state
        case "High":
            return "secondary.dark"; // Orange background for High state
        case "Medium":
            return "secondary.light"; // Yellow background for Medium state
        default:
            return "grey"; // Default background color
    }
};

const getColorForState = (state) => {
    switch (state) {
        case "Critical":
            return "white"; // Dark background for Critical state
        case "High":
            return "white"; // Orange background for High state
        case "Medium":
            return "black"; // Yellow background for Medium state
        default:
            return "black"; // Default background color
    }
};

const CustomCriticalityCell = (params) => {
    const backgroundColor = getBackgroundColorForState(params.value);
    const color = getColorForState(params.value);

    return <Box sx={{ bgcolor: backgroundColor, color: color, p: "8px", borderRadius: "16px", width: 80, textAlign: "center" }}>{params.value}</Box>;
};

export default function ControlTable({ controls, isTab }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [rowModesModel, setRowModesModel] = useState({});
    const [rows, setRows] = useState([]);
    const { user, person } = useAuthContext();
    const [loading, setLoading] = useState(false);

    // Warning states
    const [selectedId, setSelectedId] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [openMultipleDelete, setOpenMultipleDelete] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    useEffect(() => {
        setRows(controls);
    }, [controls]);

    const columns = [
        {
            field: "name",
            headerName: "Control Name",
            width: "200",
        },
        {
            field: "description",
            headerName: "Description",
            width: "400",
        },
        {
            field: "criticality",
            headerName: "Criticality",
            width: "100",
            renderCell: CustomCriticalityCell,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 300,
            cellClassName: "actions",

            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                return [
                    <GridActionsCellItem icon={<Edit color="primary" />} label="Manage" color="inherit" />,
                    <GridActionsCellItem icon={<LocationOn color="primary" />} label="Map" color="inherit" />,
                    <GridActionsCellItem icon={<BugReport color="primary" />} label="Test" color="inherit" />,
                    <GridActionsCellItem icon={<Delete color="warning" />} label="Delete" color="inherit" />,
                ];
            },
        },
    ];

    function NoRowsOverlay() {
        return (
            <Box display="flex" flexDirection="column" justifyContent={"center"} alignItems={"center"}>
                <Typography sx={{ padding: 2 }}>No controls found</Typography>
            </Box>
        );
    }

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <div style={{ width: "100%" }}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                }}
                sx={{
                    border: 0,
                    boxShadow: 0,
                    fontSize: "1rem",
                    "& .MuiDataGrid-cell:hover": {
                        color: "primary.main",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                    },
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                }}
            />
        </div>
    );
}
