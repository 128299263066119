import { Box, Typography } from "@mui/material";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BackgroundBox } from "../../utils/UIStyles";

export default function AnnouncementSlider() {
    const announcementTitles = [
        "Exciting News! Introducing Our Next-Gen IAM Solution",
        "Upcoming IAM Webinar: Mastering Identity and Access Management",
        "IAM Product Update: Enhanced User Experience",
        "IAM Security Summit: Explore the Future of Identity Protection",
        "New IAM App Release: Access Control at Your Fingertips",
        "Customer Spotlight: How IAM Transformed Security for XYZ Corporation",
    ];
    const announcements = [
        "We're thrilled to announce the launch of our cutting-edge Identity and Access Management (IAM) solution. With advanced features powered by the latest technologies, it's never been easier to secure your organization's digital assets. Stay tuned for a live demo to discover how our new IAM solution can transform your security strategy.",
        "Join us for an insightful webinar on IAM best practices and the latest trends in cybersecurity. Our expert speakers will share valuable insights to help you optimize your IAM strategy, enhance security, and streamline access management. Reserve your spot today and stay ahead in the world of identity security.",
        "We're excited to announce a major update to our IAM platform! Our latest release focuses on delivering a seamless user experience, with improved authentication methods and simplified access control. Existing customers can upgrade now to enjoy these enhancements, and new users can explore how our IAM solution can benefit their organization.",
        "Join us at our upcoming IAM Security Summit, where industry experts and thought leaders will converge to discuss the evolving landscape of identity protection. Discover cutting-edge strategies, best practices, and the latest technologies shaping the future of IAM. Secure your spot and stay ahead of the curve!",
        "We're thrilled to introduce the latest version of our IAM mobile app, putting access control and identity management right at your fingertips. With a sleek, user-friendly interface and enhanced security features, managing identities has never been this easy. Upgrade or download now to experience the future of IAM on your device.",
        "In our latest customer spotlight, we delve into the success story of XYZ Corporation and how our IAM solution revolutionized their security posture. Learn firsthand how our platform helped XYZ Corporation enhance user experiences, strengthen access controls, and fortify their overall security framework. Discover the transformative power of IAM in action.",
    ];

    const bgImages = [
        require("../../assets/images/modern-data-center.jpg"),
        require("../../assets/images/artificial-intelligence-ai.jpg"),
        require("../../assets/images/database-cloud-computing.jpg"),
        require("../../assets/images/modern-data-center.jpg"),
        require("../../assets/images/artificial-intelligence-ai.jpg"),
        require("../../assets/images/database-cloud-computing.jpg"),
    ];

    return (
        <Carousel autoPlay={true} infiniteLoop={true} interval={3000} showIndicators={false} useKeyboardArrows={true} showThumbs={false}>
            {announcements.map((announcement, index) => (
                <Box
                    key={index}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ position: "relative", bgcolor: "primary.main", color: "white", minHeight: 300 }}
                >
                    <BackgroundBox path={bgImages[index]} />
                    <Typography variant="h6" sx={{ color: "white" }} gutterBottom>
                        {announcementTitles[index]}
                    </Typography>
                    <Typography variant="body1" sx={{ color: "white" }} gutterBottom>
                        {announcement}
                    </Typography>
                </Box>
            ))}
        </Carousel>
    );
}
