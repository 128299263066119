import { Alert, Box, Button, Card, CardContent, CardHeader, Grid, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { ArrowRight, Lock } from "@mui/icons-material";
import { initiatePasswordReset } from "../../services/UserService";
import { translateAuthCodeToMessage } from "../../utils/AuthenticationUtils";
import InvitationInfo from "./InvitationInfo";
import { useAuthContext } from "../../hooks/useAuthContext";
import { findInvitation } from "../../services/InvitationService";
import { EVENT_INVITE_TO_SIGNUP } from "../../constants/EventTypes";
import { isAfter } from "date-fns";
import QRCode from "react-qr-code";
import { formatDate, formatDateTime } from "../../utils/utils";

export default function InvitationView() {

    const navigate = useNavigate();
    const location = useLocation();
    const { user, person } = useAuthContext();
    const [invitation, setInvitation] = useState();
    const [loading, setLoading] = useState(false);

    const { id: invitationId } = useParams();
    // const params = new URLSearchParams(location.search);
    // const code = params.get("code");
    const [error, setError] = useState(null);
    const [info, setInfo] = useState(null);

    useEffect(() => {
        async function getInvitation() {
            setLoading(true);
            const data = await findInvitation(invitationId);
            setInvitation(data);
            setLoading(false);
        }
        getInvitation();
    }, [invitationId, user]);

    const handleNotificationType = () => {
        switch (invitation.notificationType) {
            case EVENT_INVITE_TO_SIGNUP:
                navigate(`/signup?code=${invitationId}`);
                break;
            default:
                navigate("/");
        }
    };

    const renderInvitationRules = () => {
        // Email is not addressed to this person
        if (person && invitation.email !== person.email) {
            setTimeout(() => {
                setError(true);
            }, 1 * 1000);
            return (
                <Alert severity="error">
                    <Typography gutterBottom>The invitation does not seem to be addressed to you. </Typography>
                    <Typography>
                        If you are using a different email, please contact your Administrator and request a new invitation to your registered email "
                        {person.email}"
                    </Typography>
                </Alert>
            );
        }

        // Check if invitation has not expired
        else if (isAfter(new Date(), invitation.expirationDate.toDate())) {
            setTimeout(() => {
                setError(true);
            }, 1 * 1000);
            return (
                <Alert severity="error">
                    <Typography gutterBottom>The invitation you are using has expired.</Typography>
                    <Typography>Please check your email for the relevant invitation code. If you are not sure or seek a different operation, please contact your Administrator and request a new invitation.</Typography>
                </Alert>
            );
        } else if (invitation.completed) {
            setTimeout(() => {
                setError(true);
            }, 1 * 1000);

            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                    gap={8}
                >
                    <Alert severity="info">
                        <Typography gutterBottom>You have already accepted the invitation.</Typography>
                        <Typography>
                            If you are not sure or seek a different operation, please contact your Administrator and request a new invitation.
                        </Typography>
                    </Alert>
                    <Button startIcon={<Lock />} color="primary" variant="contained" style={{ fontSize: "14px", width: 300, alignSelf: 'center' }} onClick={() => navigate("/")}>
                        Access your account
                    </Button>
                </Box>
            );
        } else {
            console.log("No error, render instructions...");
            return (
                <>
                    <Typography variant="headline" sx={{ marginLeft: 3, marginTop: 2 }}>
                        Next Steps:
                    </Typography>
                    <List>
                    <ListItem>
                            <ListItemIcon>
                                <ArrowRight color="success" />
                            </ListItemIcon>
                            <ListItemText primary={`Open the ${process.env.REACT_APP_TITLE} App on your mobile device`} />
                        </ListItem>
                        
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRight color="success" />
                            </ListItemIcon>
                            <ListItemText primary="Point your device's camera towards the QR Code displayed here. " />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRight color="success" />
                            </ListItemIcon>
                            <ListItemText primary="Once the QR Code is successfully scanned, enter the Verification Code and any other necessary steps to complete your operation. " />
                        </ListItem>
                    </List>
                </>
            );
        }
    };



    const [formData, setFormData] = useState({
        email: "",
    });
    const [errors, setErrors] = useState({
        email: "",
    });

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        // Validate email field
        if (formData.email.trim() === "") {
            newErrors.email = "Email is required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid email format";
            isValid = false;
        } else {
            newErrors.email = "";
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const p = await initiatePasswordReset(formData.email);
                // Redirect to Verification page
                setInfo("A reset link has been sent to your registered email or phone. Please verify the request in the next step. Redirecting...");
                setTimeout(() => {
                    setInfo("");
                    navigate(`/verify-password?pid=${p.id}`);
                }, 3000);
            } catch (err) {
                console.error("Error creating user:", err.code, err.message);
                var msg = translateAuthCodeToMessage(err.code);
                setError(msg);
            }
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            {/* Info Box */}
            <InvitationInfo />

            {/* Invitation Box */}
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="80%" height="100%">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        flexGrow: 1,
                    }}
                >
                    <Card elevation={0}>
                        {/* <CardHeader
                            // title="CyberShield"
                            // titleTypographyProps={{ color: "primary.main", variant: "title" }}
                            subheader="Reset your password"
                            subheaderTypographyProps={{ color: "primary.main", variant: "headline" }}
                            sx={{ textAlign: "center" }}
                        ></CardHeader> */}
                        <CardContent>
                        {invitation && (
                            <Grid container>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <Box display="flex" flexDirection="column" gap={1} sx={{ height: "auto", marginLeft: 4, width: "70%" }}>
                                        <QRCode
                                            value={invitation.id}
                                            size={128}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            viewBox={`0 0 128 128`}
                                        />
                                        <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                                            Expires: {formatDate(invitation.expirationDate)}
                                        </Typography>
                                        <Button
                                            startIcon={<Lock />}
                                            color="primary"
                                            variant="outlined"
                                            style={{ fontSize: "14px" }}
                                            onClick={handleNotificationType}
                                        >
                                            Sign-in with your browser
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9}>
                                    <Box display="flex" flexDirection="column" gap={0} sx={{ boxShadow: 0 }}>
                                        {renderInvitationRules()}
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
}
