import { Box, Button, Card, CardContent, CardHeader, Divider, List, ListItem, ListItemText, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Departments } from "../../samples/departments";
import { Add, Close } from "@mui/icons-material";
import { saveDepartment } from "../../services/DepartmentService";
import { useAuthContext } from "../../hooks/useAuthContext";

const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
        <div department="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

export default function DepartmentKB({ toggleView }) {
    const { person } = useAuthContext();
    const [departments, setDepartments] = useState([]);
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, index) => {
        setTab(index);
    };

    const handleClose = () => {
        toggleView(false);
    };

    const handleAdd = async (key, department) => {
        await saveDepartment(person.uid, person.tenant.id, department);
        // // Remove the item from the list of departments
        const newDepartments = departments[key].filter((p) => p.id !== department.id);
        departments[key] = newDepartments;
        setDepartments({ ...departments });
    };

    useEffect(() => {
        setDepartments(Departments);
    }, []);
    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Card>
                <CardHeader
                    title="Departments"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="Search and add departments from the knowledge base. These have been categorized by industry"
                    action={
                        <Button variant="contained" startIcon={<Close />} onClick={handleClose} color="info">
                            Close
                        </Button>
                    }
                />
                <Divider />
                <CardContent>
                        <Tabs value={tab} onChange={handleTabChange} indicatorColor="primary" variant="scrollable" scrollButtons="auto">
                            {departments && Object.keys(departments).map((key, index) => <Tab key={index} label={key} />)}
                        </Tabs>
                    {departments &&
                        Object.keys(departments).map((key, index) => (
                            <TabPanel key={index} value={tab} index={index}>
                                <List>
                                    {departments[key].map((department, index) => (
                                        <ListItem
                                            key={index}
                                            secondaryAction={
                                                <Button variant="outlined" startIcon={<Add />} onClick={() => handleAdd(key, department)} color="info">
                                                    Add
                                                </Button>
                                            }
                                        >
                                            <ListItemText primary={department.name} secondary={department.coreFunction} />
                                        </ListItem>
                                    ))}
                                </List>
                            </TabPanel>
                        ))}
                </CardContent>
            </Card>
        </Box>
    );
}
