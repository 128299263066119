import {
  addDoc,
  and,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  or,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { updateAudit } from "../utils/utils";

// Get all relations
export const findAllRelations = async (uid) => {
  let results = [];
  const q = query(
    collection(db, DBConstants.DB_RELATIONS),
    where("createdBy", "==", uid)
  );
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
    results.push({ ...doc.data(), id: doc.id });
  });
  return results;
};

// Find a relation with id
export const findRelation = async (id) => {
  const docRef = doc(db, DBConstants.DB_RELATIONS, id);
  const docSnap = await getDoc(docRef);
  const data = docSnap.exists() ? docSnap.data() : null;
  return data;
};

export const findRelationByType = async (uid, name, num) => {
  //console.log ("Finding relation : ", name+ ' for ', uid)
  let results = [];
  const q = query(
    collection(db, DBConstants.DB_RELATIONS),
    and(
      where("relation", "==", name),
      or(where("from", "==", uid), where("to", "==", uid))
    ),
    limit(num)
  );
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
    results.push({ ...doc.data(), id: doc.id });
  });

  return results;
};

export const findRelationForEntity = async (entityId) => {
  let results = [];
  const q = query(
    collection(db, DBConstants.DB_RELATIONS),
    or(where("from", "==", entityId), where("to", "==", entityId))
  );
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
    results.push({ ...doc.data(), id: doc.id });
  });
  return results;
};

export const findRelationBetweenEntities = async (fromEntityId, toEntityId, role) => {
  //console.log ('Search relation from ', fromEntityId, ' to ', toEntityId, ' with role ', role)
  let results = [];
  const q = query(
    collection(db, DBConstants.DB_RELATIONS),
    or(
    and(where("from", "==", fromEntityId), 
        where("to", "==", toEntityId),
        where("relation","==", role)
        ),
    and(where("to", "==", fromEntityId), 
        where("from", "==", toEntityId),
        where("relation","==", role)
        )
        )
  );
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
    results.push({ ...doc.data(), id: doc.id });
  });
  return results;
};

export const findAllRelationBetweenEntities = async (fromEntityId, toEntityId) => {
  //console.log ('Search relation from ', fromEntityId, ' to ', toEntityId, ' with role ', role)
  let results = [];
  const q = query(
    collection(db, DBConstants.DB_RELATIONS),
    or(
    and(where("from", "==", fromEntityId), 
        where("to", "==", toEntityId)
        ),
    and(where("to", "==", fromEntityId), 
        where("from", "==", toEntityId)
        )
        )
  );
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
    results.push({ ...doc.data(), id: doc.id });
  });
  return results;
};

// Save a Relation
export const saveRelation = async (id, obj) => {
  let docRef = null;
  let relation = null;
  console.log ('Saving relationship: ', obj)

  // See if an existing relation exists 
  const relations = await findRelationBetweenEntities (obj.from, obj.to, obj.relation)
  if (relations && relations.length>0) {
    const rel = relations[0]
    obj.id = rel.id
    //console.log ('Found relation with id ', obj.id)
  }

  if (obj && obj.id) {
    docRef = doc(db, DBConstants.DB_RELATIONS, obj.id);
  } else {
    // Create a new document reference
    docRef = await addDoc(collection(db, DBConstants.DB_RELATIONS), obj);
    obj.id = docRef.id;
  }

  // console.log ("Update audit with ", id, " and ", obj)
  updateAudit(id, obj);
  await setDoc(docRef, obj, { merge: true });
  relation = await findRelation(obj.id);
  return relation;
};

export const saveRelationRaw = async (id, rel) => {
  let docRef
  if(rel.id) {
    docRef = doc(collection(db, DBConstants.DB_RELATIONS), rel.id);
  }else {
    docRef = doc(collection(db, DBConstants.DB_RELATIONS));
    rel.id = docRef.id;
  }

  updateAudit(id, rel);
  await setDoc(docRef, rel, { merge: true });
  return await findRelation(rel.id);

};

// Delete a relation
export const deleteRelation = async (id) => {
  const ref = doc(db, DBConstants.DB_RELATIONS, id);
  await deleteDoc(ref);
};

export const findRelationByInvitationRef = async (code) => {
  const q = query(collection(db, DBConstants.DB_RELATIONS), where("invitationRef", "==", code), limit(1));
  const snapshot = await getDocs(q);
  if (!snapshot.empty) {
      return snapshot.docs[0].data();
  }
};