import { Apps } from "@mui/icons-material";
import { Avatar, Box, Card, CardContent, Divider, List, ListItem, ListItemAvatar, ListItemText, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import ProfileTab from "./ProfileTab";
import AccessTab from "./AccessTab";
import TeamsTab from "./TeamsTab";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    gradientBody: {
        background: `linear-gradient(to right bottom, black, ${theme.palette.primary.light})`,
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
    backgroundImage: {
        position: "absolute",
        right: "0px",
        bottom: 10,
        fontSize: 100,
        opacity: 0.2, 
    },
}));

export default function  MyAccessList () {
    const [tab, setTab] = useState(0);
    const classes = useStyles();

    const handleTabChange = (event, index) => {
        setTab(index);
    };

    return <Box display="flex" flexDirection="column">
        <List
            sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 1,
            }}
        >
            <ListItem sx={{ width: "32%"}} className={classes.gradientBody}>
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "black" }}>
                        <Apps color="secondary" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary="Name"
                    secondary="Peter Parker"
                    primaryTypographyProps={{ variant: "subtitle1", color: "white" }}
                    secondaryTypographyProps={{ color: "white", variant: "subtitle"  }} />
            </ListItem>


            <ListItem sx={{ width: "32%"}} className={classes.gradientBody}>
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "black" }}>
                        <Apps color="secondary" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary="Employee Id"
                    secondary="E-234363"
                    primaryTypographyProps={{ variant: "subtitle1", color: "white" }}
                    secondaryTypographyProps={{ color: "white", variant: "subtitle"  }} />
            </ListItem>

            <ListItem sx={{ width: "32%"}} className={classes.gradientBody}>
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "black" }}>
                        <Apps color="secondary" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary="Job Title"
                    secondary="Information Security Manager"
                    primaryTypographyProps={{ variant: "subtitle1", color: "white" }}
                    secondaryTypographyProps={{ color: "white", variant: "subtitle"  }} />
            </ListItem>
        </List>

        <Card>
            <CardContent >
                <Tabs value={tab}  onChange={handleTabChange} indicatorColor="primary">
                    <Tab label="Profile" />
                    <Tab label="Access" />
                    <Tab label="Teams" />
                </Tabs>
                {tab === 0 && (
                    <ProfileTab/>
                )}
                {tab === 1 && (
                   <AccessTab />
                )}
                {tab === 2 && (
                   <TeamsTab />
                )}
            </CardContent>
        </Card>
        
    </Box>;
}
