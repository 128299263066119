import { Avatar, Box, Button, Fade, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import AuditorImage from "../../assets/images/alisha.png";
import { AuditorTypes, getCodelist } from "../../constants/Codelist";
import { getIdName } from "../../utils/utils";

export default function AssessmentReview({ assessment, updateAssessment }) {
    //console.log ("AssessmentReview: assessment = ", assessment)

    const handleSelectAuditor = (auditorId) => {
        const auditor = getCodelist(AuditorTypes).find((auditor) => auditor.id === auditorId);
        let newAssessment = { ...assessment };
        newAssessment.auditor = getIdName(auditor);
        updateAssessment(newAssessment);
    };

    return (
        <Box display="flex" flexDirection="row" gap={2} mt={2}>
            <Fade in={true} timeout={1000} unmountOnExit>
                <Box width="30%">
                    <Typography variant="headline">Your preliminary information</Typography>
                    <Box display="flex" flexDirection="column" mt={2}>
                        <Typography variant="body1" fontWeight={"bold"} mt={2}>
                            Regions
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {assessment?.regions?.map((region, index) => {
                                return (
                                    <span key={region.id}>
                                        {region.name}
                                        {index < assessment?.regions?.length - 1 ? ", " : ""}
                                    </span>
                                );
                            })}
                        </Typography>
                        <Typography variant="body1" fontWeight={"bold"}>
                            Industry
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {assessment?.industry?.name}
                        </Typography>
                            
                        <Typography variant="body1" fontWeight={"bold"}>
                            Organization Size
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {assessment?.organizationSize?.name}
                        </Typography>

                        <Typography variant="body1" fontWeight={"bold"}>
                            Assessment Objectives
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {assessment?.iamSolutionReasons?.map((reason, index) => {
                                return (
                                    <span key={reason.id}>
                                        {reason.name}
                                        {index < assessment?.iamSolutionReasons?.length - 1 ? ", " : ""}
                                    </span>
                                );
                            })}
                        </Typography>

                        <Typography variant="body1" fontWeight={"bold"}>
                            Capabilities
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {assessment?.iamCapabilities?.map((capability, index) => {
                                return (
                                    <span key={capability.id}>
                                        {capability.name}
                                        {index < assessment?.iamCapabilities?.length - 1 ? ", " : ""}
                                    </span>
                                );
                            })}
                        </Typography>

                        <Typography variant="body1" fontWeight={"bold"}>
                            Dedicated Risk Team
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {assessment?.dedicatedRiskTeam?.name}
                        </Typography>

                        <Typography variant="body1" fontWeight={"bold"}>
                            Existing IAM Solutions
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {assessment?.haveIAMSolution?.name}
                            {assessment?.haveIAMSolution?.name === "Yes" && <span>, </span>}
                            {assessment?.haveIAMSolution?.name === "Yes" && assessment?.iamSolutionType?.name}
                        </Typography>
                    </Box>
                </Box>
            </Fade>
            <Box width="70%">
                <Typography variant="headline">Please select the type of auditor that best matches your enterprise.</Typography>
                <Box display="flex" flexDirection="row" gap={2} mt={2}>
                    {getCodelist(AuditorTypes).map((auditor) => {
                        return (
                            <Fade key={auditor.id} in={true} timeout={1000} unmountOnExit>
                                <Box
                                    width="33%"
                                    flexDirection="column"
                                    p={1}
                                    sx={{ boxShadow: 1, bgcolor: assessment?.auditor?.id === auditor.id ? "secondary.light" : "#eee" }}
                                    textAlign="center"
                                    onClick={(e) => handleSelectAuditor(auditor.id)}
                                >
                                    <Typography variant="subtitle" color="primary">
                                        {auditor.style}
                                    </Typography>

                                    <Box sx={{ boxShadow: 0 }} mt={2} display="flex" justifyContent="center" alignItems="center">
                                        <Avatar src={auditor.avatar} sx={{ width: 200, height: 200 }} />
                                    </Box>
                                    <Box mt={2}>
                                        {assessment?.auditor?.id !== auditor.id && (
                                            <Button variant="contained" onClick={(e) => handleSelectAuditor(auditor.id)}>
                                                {auditor.name}
                                            </Button>
                                        )}
                                        {assessment?.auditor?.id === auditor.id && (
                                            <Button variant="contained" sx={{ bgcolor: "black", color: "white" }}>
                                                {auditor.name}
                                            </Button>
                                        )}
                                    </Box>
                                    <Box mt={2} textAlign="left">
                                        <Typography variant="body1" fontWeight={"bold"}>
                                            Approach
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {auditor.approach}
                                        </Typography>
                                        <Typography variant="body1" fontWeight={"bold"}>
                                            Focus
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {auditor.focus}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Fade>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
}
