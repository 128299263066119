import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Divider,
    Fade,
    IconButton,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IAMCapabilityCategories, ProgramGovernanceAreas, getIAMCodelist } from "../../constants/AssessmentConstants";
import { useAuthContext } from "../../hooks/useAuthContext";
import { ExpandLess, ExpandMore, Security } from "@mui/icons-material";
import { checkIdExists, deleteObjectById, getIdName } from "../../utils/utils";

export default function CapabilityAssessment({ assessment, updateAssessment }) {
    const [loading, setLoading] = useState(false);
    const { person } = useAuthContext();
    const [capabilityCategories, setCapabilityCategories] = useState(null);
    const [isProgramGovernanceExpanded, setIsProgramGovernanceExpanded] = useState(false);

    useEffect(() => {
        getAllIAMCapabilities();
    }, []);

    // Get all IAM categories with their associated capabilities
    const getAllIAMCapabilities = async () => {
        const categories = getIAMCodelist(IAMCapabilityCategories);
        // for each category, get the capabilities
        categories.forEach((category) => {
            category.expanded = false;
            const capabilities = getIAMCodelist(category.code);
            category.capabilities = capabilities;
            capabilities.forEach((capability) => {
                capability.selected = false;
            });
        });
        setCapabilityCategories(categories);
    };

    const toggleProgramGovernance = () => {
        setIsProgramGovernanceExpanded(!isProgramGovernanceExpanded);
    };

    const handleProgramGovernanceSelection = (program) => {
        let newAssessment = { ...assessment };
        // Check if the program is already selected
        if (!checkIdExists(newAssessment?.governanceCategories, program.id)) {
            newAssessment?.governanceCategories.push(getIdName(program));
        } else {
            newAssessment.governanceCategories = deleteObjectById(newAssessment?.governanceCategories, program.id);
        }
        updateAssessment(newAssessment);
    };

    const toggleCapabilityCategory = (category) => {
        category.expanded = !category.expanded;
        setCapabilityCategories([...capabilityCategories]);
    };

    const handleCapabilitySelection = (category, capability) => {
        let newAssessment = { ...assessment };
        const cap = {
            id: `${category.id}-${capability.id}`,
            categoryId: category.id,
            categoryName: category.name,
            capabilityId: capability.id,
            capabilityName: capability.name,
        };
        if (!checkIdExists(newAssessment?.capabilityCategories, cap.id)) {
            newAssessment?.capabilityCategories.push(cap);
        } else {
            newAssessment.capabilityCategories = deleteObjectById(newAssessment?.capabilityCategories, cap.id);
        }
        updateAssessment(newAssessment);
    };

    return (
        <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" flexDirection="row" gap={0} sx={{ boxShadow: 1 }}>
                <Fade in={true} timeout={1000} unmountOnExit>
                    <Card sx={{ background: "#eee", flexGrow: 1 }} elevation={1}>
                        <CardHeader
                            subheader="Program Governance"
                            subheaderTypographyProps={{ color: "white", fontSize: "1rem", fontWeight: "bold" }}
                            sx={{ textAlign: "center", bgcolor: "black" }}
                            onClick={toggleProgramGovernance}
                            action={
                                <Button
                                    startIcon={
                                        isProgramGovernanceExpanded ? <ExpandLess sx={{ color: "white" }} /> : <ExpandMore sx={{ color: "white" }} />
                                    }
                                    onClick={toggleProgramGovernance}
                                ></Button>
                            }
                        />
                        <Divider />

                        <Collapse in={isProgramGovernanceExpanded} timeout={"auto"} unmountOnExit>
                            <CardContent>
                                <List
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        gap: 1,
                                    }}
                                >
                                    {getIAMCodelist(ProgramGovernanceAreas).map((category) => {
                                        return (
                                            <ListItem
                                                key={category.id}
                                                alignItems="center"
                                                gap={1}
                                                sx={{
                                                    bgcolor: checkIdExists(assessment?.governanceCategories, category.id)
                                                        ? "secondary.main"
                                                        : "#eee",
                                                    width: "24%",
                                                    boxShadow: 1,
                                                }}
                                                onClick={() => handleProgramGovernanceSelection(category)}
                                            >
                                                <Box sx={{ boxShadow: 0 }} display="flex" justifyContent="center" alignItems="center">
                                                    <IconButton sx={{ color: "black" }}>{category.image}</IconButton>
                                                </Box>
                                                <ListItemText
                                                    primary={category.name}
                                                    primaryTypographyProps={{
                                                        fontSize: "1rem",
                                                        fontWeight: "bold",
                                                        color: checkIdExists(assessment?.governanceCategories, category.id) ? "white" : "primary.main",
                                                        noWrap: false,
                                                    }}
                                                />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </CardContent>
                        </Collapse>
                    </Card>
                </Fade>
            </Box>
            <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="flex-start" gap={1}>
                {capabilityCategories && capabilityCategories.map((category) => {
                    return (
                        <Fade key={category.id} in={true} timeout={1000} unmountOnExit>
                            <Card sx={{ width: "24.5%", background: "#eee" }} elevation={1}>
                                <CardHeader
                                    subheader={category.name}
                                    subheaderTypographyProps={{ color: "white", fontSize: "1rem", fontWeight: "bold" }}
                                    sx={{ textAlign: "center", bgcolor: "black" }}
                                    onClick={() => toggleCapabilityCategory(category)}
                                    action={
                                        <Button
                                            startIcon={
                                                category.expanded ? <ExpandLess sx={{ color: "white" }} /> : <ExpandMore sx={{ color: "white" }} />
                                            }
                                            onClick={() => toggleCapabilityCategory(category)}
                                        ></Button>
                                    }
                                />
                                <Collapse in={category?.expanded} timeout="auto" unmountOnExit>
                                    <CardContent>
                                        <List
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                flexWrap: "wrap",
                                                gap: 1,
                                            }}
                                        >
                                            {category.capabilities.map((capability) => {
                                                return (
                                                    <ListItem
                                                        key={capability.id}
                                                        alignItems="center"
                                                        gap={0}
                                                        sx={{
                                                            bgcolor: checkIdExists(assessment?.capabilityCategories, `${category.id}-${capability.id}`)
                                                        ? "secondary.main"
                                                        : "#eee",
                                                            boxShadow: 1,
                                                        }}
                                                        onClick={() => handleCapabilitySelection(category, capability)}
                                                    >
                                                        <Box sx={{ boxShadow: 0 }} display="flex" justifyContent="center" alignItems="center">
                                                            <IconButton sx={{ color: "black" }}>{category.image}</IconButton>
                                                        </Box>
                                                        <ListItemText
                                                            primary={capability.name}
                                                            primaryTypographyProps={{ fontSize: "1rem", fontWeight: "bold", 
                                                            color: checkIdExists(assessment?.capabilityCategories, `${category.id}-${capability.id}`) ? "white" : "primary.main",
                                                            noWrap: false }}
                                                        />
                                                        {/* <FormControlLabel control={<Checkbox name="capability" />} /> */}
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </CardContent>
                                </Collapse>
                            </Card>
                        </Fade>
                    );
                })}
            </Box>
        </Box>
    );
}
