import { Delete, Edit, Reply } from "@mui/icons-material";
import { Avatar, Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";

import { useTheme } from "@mui/material/styles";
import { GridLoader } from "react-spinners";
import { deleteOrganization, findOrganization } from "../../services/OrganizationService";
import StatusMessage from "../../components/StatusMessage";
import { formatDate, generateAcronym, personName, stringAvatar } from "../../utils/utils";
import { findPerson } from "../../services/PersonService";
import { parseISO } from "date-fns";
import * as Constants  from "../../constants/Constants";

export default function OrganizationDetail() {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const { person } = useAuthContext();
    const [organization, setOrganization] = useState(null);
    // Get the organization id from the url
    const { id: organizationId } = useParams();
    const [contact, setContact] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });
    // Warning dialog
    const [openDelete, setOpenDelete] = useState(false);

    const handleOpenDelete = (id) => {
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleDeleteTenant = async () => {
        await deleteOrganization(person?.id, organization.id);
        handleCloseDelete();
        setStatus({ open: true, type: "success", message: "Deleted Tenant" });
        navigate(-1);
    }
    useEffect(() => {
        async function getOrganization(id) {
            setLoading(true);
            const org = await findOrganization(organizationId);
            setOrganization(org);
            if (org.primaryContact) {
                const p = await findPerson(org.primaryContact.id);
                setContact(p);
            }
            setLoading(false);
        }

        getOrganization(organizationId);
    }, [organizationId]);

    const handleEditOrganization = () => {
        navigate(`/imag-ai/tenants/edit/${organization.id}`);
    };

    const handleBack = () => {
        navigate(-1);
    };



    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <Card>
                <CardHeader
                    title={`${organization?.name}`}
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="View tenant details and contact information"
                    action={
                        <>
                        <Button startIcon={<Delete />} onClick={handleOpenDelete} color="warning">
                            Delete
                        </Button>
                        <Button startIcon={<Edit />} onClick={handleEditOrganization} color="primary">
                            Edit
                        </Button>
                        <Button startIcon={<Reply />} onClick={handleBack} color="primary">
                            Back
                        </Button>
                        </>
                    }
                />
                <Divider />
                <CardContent>
                    {organization && (
                        <Box display="flex" flexDirection="row">
                            <Box width="20%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                {<Avatar sx={{ width: 100, height: 100, mb: 4 }}>
                                    {generateAcronym(organization.name)} 
                                </Avatar>
                                    }
                            </Box>
                            <Box width="40%" display="flex" flexDirection="column">
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Name
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {organization.name}
                                </Typography>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Alternate Name
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {organization.dba ?? "NA"}
                                </Typography>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Address
                                </Typography>
                                <Typography variant="body1">{organization.addressLine1 ?? "NA"}</Typography>
                                <Typography variant="body1">
                                    {organization.city} {organization.state} {organization.zipcode}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {organization.country}
                                </Typography>
                            </Box>
                            <Box width="40%" display="flex" flexDirection="column">
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Subscription Start
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    { formatDate(organization.subscriptionStart) ?? "NA"}
                                </Typography>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Subscription End
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                     { formatDate(organization.subscriptionEnd) ?? "NA"}
                                </Typography>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Created
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {formatDate(organization.createdDate) ?? "NA"}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </CardContent>
            </Card>

            {contact && (
                <Card>
                    <CardHeader title="Primary Contact" titleTypographyProps={{ color: "primary.main", variant: "subtitle" }} />
                    <CardContent>
                        <Box display="flex" flexDirection="row">
                            <Box width="20%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                {<Avatar {...stringAvatar(personName(contact))} sx={{ width: 100, height: 100, mb: 4 }} />}
                            </Box>
                            <Box width="40%" display="flex" flexDirection="column">
                                <Typography variant="body1" fontWeight={"bold"}>
                                    First Name
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {contact.firstName}
                                </Typography>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Last Name
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {contact.lastName}
                                </Typography>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Address
                                </Typography>
                                <Typography variant="body1">{contact.addressLine1}</Typography>
                                <Typography variant="body1" gutterBottom>
                                    {contact.city} {contact.state} {contact.zipcode}
                                </Typography>
                            </Box>
                            <Box width="40%" display="flex" flexDirection="column">
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Email
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {contact.email} ({contact.email.emailVerified ? "Verified" : "Not Verified"})
                                </Typography>
                                <Typography variant="body1" fontWeight={"bold"}>
                                    Phone
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {contact.phone}
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            )}

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDelete} onClose={handleCloseDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Delete tenant ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting a tenant may have unintended consequences. Are you sure?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDelete} color="inherit">
                        No
                    </Button>
                    <Button variant="contained" color="warning" onClick={handleDeleteTenant} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
