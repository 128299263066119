import { Reply } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";

import { useTheme } from "@mui/material/styles";
import { GridLoader } from "react-spinners";
import { findGroup } from "../../services/GroupService";
import StatusMessage from "../../components/StatusMessage";

export default function GroupDetail() {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const { person } = useAuthContext();
    const [group, setGroup] = useState(null);
    // Get the group id from the url
    const { id: groupId } = useParams();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    useEffect(() => {
        async function getGroup(id) {
            setLoading(true);
            const p = await findGroup(groupId);
            setGroup(p);
            setLoading(false);
        }

        getGroup(groupId);
    }, [groupId]);

    const handleAddGroup = () => {
        console.log("To be implemented");
    };

    const handleEditGroup = () => {
        navigate(`/imag-ai/groups/edit/${group.id}`);
    };

    const handleBack = () => {
        navigate(-1);
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <Card>
                <CardHeader
                    title={`${group?.name} Details`}
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    //subheader="View details,contact information, and preferences, to ensure your profile is accurate and up to date."
                    action={
                        <Button startIcon={<Reply />} onClick={handleBack} color="primary">
                            Back
                        </Button>
                    }
                />
                <Divider />
                <CardContent>
                    {group && (
                        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                            <Typography variant="body1" fontWeight={"bold"}>
                                Name
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {group.name}
                            </Typography>
                            <Typography variant="body1" fontWeight={"bold"}>
                                Description
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {group.description}
                            </Typography>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
}
