import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc } from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { updateAudit } from "../utils/utils";

// Create a new application
export const createApplication = async (uid, application) => {
    const docRef = doc(collection(db, DBConstants.DB_APPLICATIONS));
    application.id = docRef.id;
    updateAudit(uid, application);
    await setDoc(docRef, application, { merge: true });
    return await findApplication(application.id);
};

// update an existing application
export const updateApplication = async (uid, application) => {
    updateAudit(uid, application);
    const docRef = doc(db, DBConstants.DB_APPLICATIONS, application.id);
    await setDoc(docRef, { ...application }, { merge: true });
    return await findApplication(application.id);
};

// Create a new application or update an existing one
export const saveApplication = async (uid, tenantId, application) => {
    application.tenantId = tenantId;
    if (application.id) {
        return await updateApplication(uid, application);
    } else {
        return await createApplication(uid, application);
    }
};

// Delete an application
export const deleteApplication = async (applicationId) => {
    // Delete the application
    const docRef = doc(db, DBConstants.DB_APPLICATIONS, applicationId);
    await deleteDoc(docRef);
};

// Find an application with id
export const findApplication = async (id) => {
    const docRef = doc(db, DBConstants.DB_APPLICATIONS, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
};

// Find all applications for a tenant
export const findAllApplications = async (tenantId) => {
    let results = [];
    const q = query(
        collection(db, DBConstants.DB_APPLICATIONS)
        //where("tenantId", "==", tenantId)
    );
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

