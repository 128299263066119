import { AddCircle, Edit, Mail, Reply } from "@mui/icons-material";
import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Grid, LinearProgress, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";

import { db } from "../../config/FirebaseConfig";
import * as DBConstants from "../../constants/DBConstants";
import { useTheme } from "@mui/material/styles";
import { GridLoader } from "react-spinners";
import { doc, getDoc } from "firebase/firestore";
import { findPerson } from "../../services/PersonService";
import { personName, stringAvatar } from "../../utils/utils";
import StatusMessage from "../../components/StatusMessage";
import { createSignUpInvitation } from "../../services/InvitationService";
import { EVENT_INVITE_TO_SIGNUP } from "../../constants/EventTypes";
import * as Constants from "../../constants/Constants";
import { ROLE_INTERNAL_USER } from "../../constants/Authorities";
import { saveRelation } from "../../services/RelationService";

export default function PersonDetail() {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const { person: currentUser } = useAuthContext();
    // Get the person id from the url
    const { id: personId } = useParams();
    const [person, setPerson] = useState();
    const [roles, setRoles] = useState([])
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    useEffect(() => {
        async function getPerson(id) {
            setLoading(true);
            const p = await findPerson(personId);
            setPerson(p);
            setLoading(false);
        }

        getPerson(personId);
    }, [personId]);

    const handleAddRole = () => {
        console.log ('To be implemented')
    }

    const handleInviteContact = async () => {
        if (person?.email) {
            const invite = await createSignUpInvitation(currentUser, person, EVENT_INVITE_TO_SIGNUP);
            console.log("Invitation: ", invite);
            let rel = {
                from: person?.id,
                fromName: personName(person),
                fromEntity: Constants.ENTITY_PERSON,
                to: currentUser?.tenant.id,
                toName: currentUser?.tenant.name,
                toEntity: Constants.ENTITY_ORGANIZATION,
                relation: ROLE_INTERNAL_USER,
                status: Constants.STATUS_ACTIVE,
                
            }
            rel.invitationRef = invite.id;
            await saveRelation(currentUser.id, rel);
            setStatus({
                open: true,
                type: "success",
                message: `Sent Invitation to user`,
            });

        }else {
            setStatus({
                open: true,
                type: "error",
                message: `User does not have an email address`,
            });
        }
        

    };

    const handleEditPerson = () => {
        navigate(`/imag-ai/people/edit/${person.id}`, { state: { personId: person.id, role: person.role } });
    };

    const handleBack = () => {
        navigate(-1);
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <Card>
                <CardHeader
                    title={person && personName(person)}
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    //subheader="View details,contact information, and preferences, to ensure your profile is accurate and up to date."
                    action={
                        <>
                            <Button startIcon={<Mail />} onClick={handleInviteContact} color="primary">
                                Send Invitation
                            </Button>
                            <Button startIcon={<Reply />} onClick={handleBack} color="primary">
                                Back
                            </Button>
                        </>
                    }
                />
                <Divider />
                <CardContent>
                    {person && (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Box display="flex" flexDirection="row">
                                <Box width="30%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                    {person && <Avatar {...stringAvatar(personName(person))} sx={{ width: 100, height: 100, mb: 4 }} />}
                                    <Typography variant="subtitle1">Profile Completion (40%)</Typography>
                                    <Box width="80%">
                                        <LinearProgress variant="determinate" value={40} />
                                    </Box>
                                </Box>
                                <Box width="70%">
                                    <Card sx={{ flexGrow: 1 }} elevation={0}>
                                        <CardHeader
                                            title="General Details"
                                            titleTypographyProps={{ variant: "subtitle" }}
                                            action={
                                                <Tooltip title="Edit Profile">
                                                    <Edit color="primary" onClick={handleEditPerson} />
                                                </Tooltip>
                                            }
                                        />

                                        
                                        <Box m={1} sx={{ display: "flex", flexDirection: "row" }}>
                                            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    First Name
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    {person.firstName}
                                                </Typography>
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    Last Name
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    {person.lastName}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    Address
                                                </Typography>
                                                <Typography variant="body1">{person.addressLine1}</Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    {person.city} {person.state} {person.zipcode}
                                                </Typography>
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    Email
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    {person.email}
                                                </Typography>
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    Phone
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    {person.phone}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Card>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </CardContent>
            </Card>

            <Card>
                <CardHeader
                    title="Roles"
                    titleTypographyProps={{ variant: "subtitle" }}
                    //subheader="View details,contact information, and preferences, to ensure your profile is accurate and up to date."
                    action={
                        <>
                            <Button startIcon={<AddCircle />} onClick={handleAddRole} color="primary">
                                Add
                            </Button>
                            <Button startIcon={<Edit />} onClick={handleEditPerson} color="primary">
                                Edit
                            </Button>
                        </>
                    }
                />
                
                <CardContent>
                    Show Roles
                </CardContent>
            </Card>

            <Card>
                <CardHeader
                    title="Groups"
                    titleTypographyProps={{ variant: "subtitle" }}
                    //subheader="View details,contact information, and preferences, to ensure your profile is accurate and up to date."
                    action={
                        <>
                            <Button startIcon={<AddCircle />} onClick={handleAddRole} color="primary">
                                Add
                            </Button>
                            <Button startIcon={<Edit />} onClick={handleEditPerson} color="primary">
                                Edit
                            </Button>
                        </>
                    }
                />
                
                <CardContent>
                    Show Groups
                </CardContent>
            </Card>

            <Card>
                <CardHeader
                    title="Entitlements"
                    titleTypographyProps={{ variant: "subtitle" }}
                    //subheader="View details,contact information, and preferences, to ensure your profile is accurate and up to date."
                    action={
                        <>
                            <Button startIcon={<AddCircle />} onClick={handleAddRole} color="primary">
                                Add
                            </Button>
                            <Button startIcon={<Edit />} onClick={handleEditPerson} color="primary">
                                Edit
                            </Button>
                        </>
                    }
                />
                
                <CardContent>
                    Show Entitlements
                </CardContent>
            </Card>
        </Box>
    );
}
