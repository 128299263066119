import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline } from "@mui/material";
import { AuthContextProvider } from "./context/AuthContext";
import "./assets/styles/app.css"

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    // Removed the React.StrictMode wrapper as its calling events twice
    <>
        <CssBaseline />
        <AuthContextProvider>
            <App />
        </AuthContextProvider>
    </>
);


reportWebVitals();
