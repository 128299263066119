export const Applications = {
    "Human Resources": [
        {
            name: "Workday",
            description: "Cloud-based HR management software for human capital management and financial management.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "BambooHR",
            description: "HR software for managing employee information, onboarding, and performance.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Regulatory",
            deploymentModel: "Cloud",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "LinkedIn Talent Solutions",
            description: "Recruitment platform for sourcing, attracting, and hiring candidates.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Regulatory",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "ADP Workforce Now",
            description: "Integrated HR solution for payroll, benefits, time tracking, and more.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Greenhouse",
            description: "Recruiting software for managing the hiring process and candidate experience.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Regulatory",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "SAP SuccessFactors",
            description: "Cloud-based HR software for managing employee performance, compensation, and learning.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Regulatory",
            deploymentModel: "Cloud",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "UltiPro",
            description: "Cloud-based HR, payroll, and talent management software.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "iCIMS",
            description: "Talent acquisition platform for recruiting, applicant tracking, and onboarding.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Regulatory",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Oracle HCM Cloud",
            description: "Integrated HR and talent management solution in the cloud.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Regulatory",
            deploymentModel: "Cloud",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Cornerstone OnDemand",
            description: "Learning and talent management software for employee development and training.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Regulatory",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "PeopleSoft HRMS",
            description: "Comprehensive HR management system for payroll, benefits, and workforce management.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Regulatory",
            deploymentModel: "On-Prem",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Legacy",
            tierRating: "Tier 0",
        },
    ],
    Marketing: [
        {
            name: "HubSpot",
            description: "Inbound marketing and sales platform for lead generation and customer engagement.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Google Analytics",
            description: "Web analytics service for tracking website traffic and user behavior.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Mailchimp",
            description: "Email marketing platform for sending marketing emails, newsletters, and campaigns.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Salesforce Marketing Cloud",
            description: "Platform for creating personalized customer journeys and managing marketing campaigns.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Adobe Creative Cloud",
            description: "Suite of creative software for design, photography, video editing, and more.",
            type: "Desktop",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Hootsuite",
            description: "Social media management platform for scheduling and monitoring social media posts.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "WordPress",
            description: "Content management system for creating and managing websites and blogs.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Canva",
            description: "Graphic design platform for creating visuals, presentations, and marketing materials.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Buffer",
            description: "Social media scheduling and publishing tool for managing social media accounts.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Trello",
            description: "Visual collaboration tool for project and task management.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Google Ads",
            description: "Online advertising platform for creating and managing ad campaigns.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Moz",
            description: "SEO software for keyword research, rank tracking, and link analysis.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Sprout Social",
            description: "Social media management and engagement platform for businesses.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Adobe Marketing Cloud",
            description: "Marketing automation and analytics platform for personalized customer experiences.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Asana",
            description: "Task and project management tool for teams to collaborate and organize work.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Adobe Spark",
            description: "Content creation tool for making graphics, web pages, and videos.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Cision",
            description: "PR and media software for managing communications, media lists, and campaigns.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Microsoft Dynamics 365 Marketing",
            description: "Marketing automation and customer engagement platform for businesses.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "SEMrush",
            description: "SEO and digital marketing software for competitive analysis and keyword research.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Slack",
            description: "Collaboration hub for team communication and messaging.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    Finance: [
        {
            name: "SAP S/4HANA",
            description: "Integrated ERP system for financial planning, accounting, and procurement.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "Hybrid",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Oracle Financials Cloud",
            description: "Cloud-based financial management solution for accounting, reporting, and analytics.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "QuickBooks Online",
            description: "Cloud-based accounting software for small businesses and financial management.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Bloomberg Terminal",
            description: "Financial information platform for real-time market data, news, and analytics.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "On-Prem",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Tableau",
            description: "Data visualization and business intelligence tool for analyzing financial data.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "On-Prem",
            externalInternal: "Internal",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Salesforce Financial Services Cloud",
            description: "CRM platform tailored for financial services with client management and analytics.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Microsoft Dynamics 365 Finance",
            description: "Cloud-based financial management solution for budgeting, forecasting, and reporting.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Thomson Reuters Eikon",
            description: "Financial information platform with market data, analytics, and trading tools.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "On-Prem",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Addepar",
            description: "Investment management platform for tracking and analyzing investment portfolios.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Anaplan",
            description: "Connected planning platform for financial modeling, budgeting, and scenario analysis.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Cognos Analytics",
            description: "Business intelligence and reporting tool for analyzing financial data and performance.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Workiva",
            description: "Cloud platform for financial reporting, compliance, and audit management.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Intuit ProConnect Tax Online",
            description: "Cloud-based tax preparation software for tax professionals and accountants.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "RiskWatch",
            description: "Enterprise risk management software for identifying, assessing, and mitigating risks.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "FactSet",
            description: "Financial data and analytics platform for investment professionals.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "On-Prem",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Refinitiv Workspace",
            description: "Financial desktop platform for real-time market data, analysis, and trading.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Murex",
            description: "Trading and risk management software for capital markets and treasury operations.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Money Moving",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Xero",
            description: "Cloud-based accounting software for small businesses and financial management.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Money Moving",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Power BI",
            description: "Business analytics service for creating interactive data visualizations and reports.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Mint",
            description: "Personal finance management tool for budgeting, expense tracking, and financial goals.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    Sales: [
        {
            name: "Salesforce",
            description: "Customer relationship management (CRM) platform for sales and marketing teams.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "HubSpot CRM",
            description: "CRM and sales management software with contact tracking, lead generation, and analytics.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Zendesk Sell",
            description: "Sales force automation and CRM software for managing leads, deals, and customer relationships.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Microsoft Dynamics 365 Sales",
            description: "CRM and sales automation solution for managing customer interactions and sales processes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Pipedrive",
            description: "Sales pipeline management software for organizing and tracking deals and contacts.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Zoho CRM",
            description: "Cloud-based CRM software for sales and marketing automation, leads, and analytics.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Freshsales",
            description: "Sales CRM software for lead management, contact tracking, and email campaigns.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Closer",
            description: "Sales CRM and communication software for managing leads, calls, and contacts.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Close",
            description: "Inside sales CRM software for managing communication, leads, and follow-ups.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Insightly",
            description: "CRM and project management software for tracking leads, contacts, and opportunities.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Monday.com",
            description: "Work operating system for managing sales pipelines, projects, and team collaboration.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "External",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Freshworks CRM",
            description: "Customer engagement software for sales, marketing, and support teams.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Base",
            description: "Sales automation and CRM software for managing leads, contacts, and deals.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Bitrix24",
            description: "Collaboration and communication platform with CRM, task management, and social intranet.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Nimble",
            description: "Social CRM software for managing contacts, communications, and relationship insights.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "SugarCRM",
            description: "Customer experience and relationship management software for sales, marketing, and service.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Zaius",
            description: "Marketing automation platform for understanding and engaging customers across channels.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Salesflare",
            description: "CRM software for automating sales tasks, tracking communications, and managing leads.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Pipeliner CRM",
            description: "Sales CRM software with visual sales pipeline management and analytics.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "OnePageCRM",
            description: "Sales CRM software focused on action-oriented contact management and follow-ups.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    Operations: [
        {
            name: "SAP ERP",
            description: "Enterprise resource planning software for managing business operations and processes.",
            type: "Web",
            businessCriticality: "Critical",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Oracle E-Business Suite",
            description: "Integrated suite of applications for managing various business functions and operations.",
            type: "Web",
            businessCriticality: "Critical",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Microsoft Dynamics 365 Supply Chain Management",
            description: "ERP software for managing manufacturing, logistics, and supply chain operations.",
            type: "Web",
            businessCriticality: "Critical",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "NetSuite",
            description: "Cloud-based ERP system for managing financials, operations, and customer relationships.",
            type: "Web",
            businessCriticality: "Critical",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "WMS Systems (Warehouse Management Systems)",
            description: "Software for managing and optimizing warehouse operations, inventory, and distribution.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "CMMS (Computerized Maintenance Management System)",
            description: "Software for planning, scheduling, and managing maintenance operations and assets.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "EAM Systems (Enterprise Asset Management Systems)",
            description: "Software for optimizing the management of an organization's physical assets and equipment.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Jira Service Management",
            description: "IT service management software for incident, problem, and change management.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Kronos Workforce Central",
            description: "Workforce management software for time and attendance tracking, scheduling, and labor analytics.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "DocuWare",
            description: "Document management and workflow automation software for streamlining business processes.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "SAP Ariba",
            description: "Procurement and supply chain collaboration software for procurement processes and supplier management.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Microsoft Power Automate",
            description: "Workflow automation tool for creating automated processes and integrating applications.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Infor M3",
            description: "Enterprise resource planning (ERP) software for managing complex manufacturing operations.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Smartsheet",
            description: "Collaboration and work management platform for planning, tracking, and automating workflows.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Blue Yonder (formerly JDA Software)",
            description: "Supply chain and retail planning software for optimizing inventory, demand, and distribution.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Workday Human Capital Management",
            description: "Cloud-based HR and human capital management software for managing workforce and HR operations.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "ServiceNow",
            description: "IT service management platform for automating service workflows and processes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    "Customer Service": [
        {
            name: "Salesforce Service Cloud",
            description: "Customer service platform for managing cases, inquiries, and interactions with customers.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Zendesk",
            description: "Customer support and engagement platform for managing tickets, emails, and customer communication.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Freshdesk",
            description: "Cloud-based helpdesk software for managing customer inquiries and support tickets.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Oracle Service Cloud (RightNow)",
            description: "Customer experience management software for providing personalized support and assistance.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "LiveChat",
            description: "Live chat and customer messaging platform for real-time customer engagement.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Genesys Cloud",
            description: "Cloud-based contact center and customer experience platform for omnichannel communication.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Kustomer",
            description: "Customer service CRM platform for personalized support and case management.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "HubSpot Service Hub",
            description: "Customer service software for managing tickets, feedback, and customer satisfaction.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Help Scout",
            description: "Customer service and helpdesk software for managing emails, chats, and customer interactions.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Intercom",
            description: "Customer messaging platform for proactive engagement, support, and communication.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Talkdesk",
            description: "Cloud-based contact center platform for call routing, analytics, and customer interactions.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Service Cloud by Zoho",
            description: "Customer support and engagement software for managing interactions and resolving issues.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Kayako",
            description: "Helpdesk and customer support software for managing requests, tickets, and customer inquiries.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Groove",
            description: "Helpdesk and customer support platform for managing emails, tickets, and support workflows.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Crisp",
            description: "Live chat and customer engagement platform for real-time interactions and support.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    "Research & Development": [
        {
            name: "MATLAB",
            description: "High-level programming language and environment for technical computing and simulations.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Legacy",
            tierRating: "Tier 3",
        },
        {
            name: "LabVIEW",
            description: "System design software for visual programming and hardware interfacing.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Legacy",
            tierRating: "Tier 3",
        },
        {
            name: "Autodesk Inventor",
            description: "3D CAD software for product design, simulation, and visualization.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Legacy",
            tierRating: "Tier 3",
        },
        {
            name: "ANSYS",
            description: "Simulation software for engineering, design, and analysis of complex systems.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "SolidWorks",
            description: "3D CAD design software for mechanical and industrial product development.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "RStudio",
            description: "Open-source integrated development environment (IDE) for R programming language.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Wolfram Mathematica",
            description: "Mathematical computation software for symbolic, numeric, and visual computations.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Siemens Teamcenter",
            description: "Product lifecycle management (PLM) software for managing product development and data.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Mendeley",
            description: "Reference manager and academic social network for organizing research papers and collaboration.",
            type: "Desktop",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Altium Designer",
            description: "PCB design software for electronic design automation and product development.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Agilent OpenLab",
            description: "Scientific laboratory software for managing instruments, experiments, and data analysis.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "COMSOL Multiphysics",
            description: "Multiphysics simulation software for physics-based modeling and engineering analysis.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "ChemDraw",
            description: "Chemical drawing and visualization software for creating molecular structures and reactions.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "MATLAB Simulink",
            description: "Model-based design and simulation software for multidomain dynamic systems.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Autodesk Fusion 360",
            description: "Cloud-based 3D CAD, CAM, and CAE software for product design and development.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "NVivo",
            description: "Qualitative data analysis software for organizing and analyzing unstructured data.",
            type: "Desktop",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Minitab",
            description: "Statistical software for data analysis, quality improvement, and process optimization.",
            type: "Desktop",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Mathematica",
            description: "Mathematical computation software for symbolic, numeric, and visual computations.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "IBM SPSS",
            description: "Statistical software for data analysis and predictive modeling.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "LabArchives",
            description: "Electronic lab notebook (ELN) software for recording and managing research data.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    "Quality Assurance": [
        {
            name: "Selenium",
            description: "Open-source testing framework for automating web applications for testing purposes.",
            type: "API",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "External",
            vendorType: "Open Source",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "JIRA",
            description: "Issue and project tracking software used for bug tracking, issue tracking, and project management.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "TestRail",
            description: "Test case management software for organizing, managing, and tracking software testing efforts.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Tricentis Tosca",
            description: "Continuous testing platform for end-to-end testing and test automation for a variety of applications.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "QTest",
            description: "Agile project management and test case management software for quality assurance teams.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Micro Focus UFT",
            description: "Automated functional testing software for testing various types of applications.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Legacy",
            tierRating: "Tier 3",
        },
        {
            name: "Applitools",
            description: "Visual testing and monitoring platform for detecting and fixing UI and layout issues.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "LoadRunner",
            description: "Performance testing software for assessing application performance under load.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Legacy",
            tierRating: "Tier 3",
        },
        {
            name: "Katalon Studio",
            description: "Test automation solution for web, mobile, API, and desktop applications.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Appium",
            description: "Open-source mobile application automation tool for testing native, hybrid, and mobile web apps.",
            type: "API",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "External",
            vendorType: "Open Source",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Ranorex",
            description: "Test automation tool for web, mobile, and desktop applications.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Postman",
            description: "Collaboration platform for API development and testing.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "BrowserStack",
            description: "Cloud-based cross-browser testing platform for web applications.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "SmartBear TestComplete",
            description: "Automated UI testing tool for web, mobile, and desktop applications.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Zephyr",
            description: "Test management solution for tracking and managing software testing efforts.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Snyk",
            description: "Developer-first security platform for finding and fixing vulnerabilities in open-source libraries.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Rapise",
            description: "Automated testing platform for web, desktop, and mobile applications.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "TestPlant eggPlant",
            description: "AI-driven testing platform for automated testing of mobile, web, and desktop applications.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Xray",
            description: "Test management tool for JIRA to manage, track, and report software testing efforts.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Kibana",
            description: "Open-source data visualization platform for analyzing and visualizing data logs.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Open Source",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    Administration: [
        {
            name: "Microsoft Office Suite",
            description: "Collection of productivity applications including Word, Excel, PowerPoint, and more.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Google Workspace",
            description: "Cloud-based productivity suite including Gmail, Docs, Sheets, and more.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Adobe Acrobat",
            description: "PDF reader and editor software for viewing, creating, and editing PDF documents.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Dropbox Business",
            description: "File storage and collaboration platform for sharing files and folders within teams.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Zoom",
            description: "Video conferencing and online meeting platform for remote communication.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Slack",
            description: "Collaboration and messaging platform for team communication and file sharing.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Microsoft Teams",
            description: "Chat-based collaboration platform integrated with Office 365 apps and services.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Trello",
            description: "Web-based project management tool for organizing tasks and projects.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Asana",
            description: "Web-based work management platform for tracking tasks and projects.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "OneDrive",
            description: "Cloud storage platform for storing, sharing, and syncing files and documents.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Low",
            applicationType: "Standard",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
    ],
    "Investment Banking": [
        {
            name: "Bloomberg Terminal",
            description: "Financial data and analytics platform providing real-time market data, news, and analytics.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Legacy",
            tierRating: "Tier 3",
        },
        {
            name: "Thomson Reuters Eikon",
            description: "Financial markets desktop platform with news, data, analytics, and trading capabilities.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Legacy",
            tierRating: "Tier 3",
        },
        {
            name: "Dealogic",
            description: "Platform providing investment banking data, analytics, and workflow solutions.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "FactSet",
            description: "Integrated financial data and analytics platform for investment professionals.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "ION MarketView",
            description: "Real-time market data and analytics platform for fixed income and FX markets.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Mergermarket",
            description: "M&A intelligence and analysis platform providing information on mergers and acquisitions.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Murex",
            description: "Trading and risk management platform for investment banking and capital markets.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Capital IQ",
            description: "Financial research and analysis platform providing data, analytics, and research.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Legacy",
            tierRating: "Tier 3",
        },
        {
            name: "Charles River IMS",
            description: "Investment management and trading system for portfolio management and order execution.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Legacy",
            tierRating: "Tier 3",
        },
        {
            name: "Tradair",
            description: "Electronic trading platform for foreign exchange and fixed income markets.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Calypso",
            description: "Capital markets and investment management platform for trading and risk management.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Refinitiv Workspace",
            description: "Financial desktop platform for data, analytics, and trading for investment professionals.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "FIS Quantum",
            description: "Risk management and analytics platform for investment management and treasury operations.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "IRESS",
            description: "Financial markets and wealth management software for trading and financial planning.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "QRM",
            description: "Risk management platform for interest rate, liquidity, and market risk management.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Aladdin",
            description: "Investment management and trading platform for portfolio managers and institutional investors.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Temenos WealthSuite",
            description: "Integrated wealth management platform for investment advisors and wealth managers.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Misys FusionCapital",
            description: "Capital markets and investment banking platform for trading, risk, and operations.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Finastra FusionRisk",
            description: "Risk management and compliance platform for financial institutions and investment banks.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "FlexTrade FlexTRADER",
            description: "Multi-asset trading platform for investment managers and institutional traders.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    Compliance: [
        {
            name: "RSA Archer",
            description: "Integrated risk management platform for managing regulatory compliance, risk, and audits.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "MetricStream",
            description: "Governance, risk, and compliance (GRC) platform for managing regulatory and operational risks.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "NICE Actimize",
            description: "Financial crime prevention and compliance platform for anti-money laundering and fraud detection.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Specialized",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Compliance 360",
            description: "Compliance management software for tracking and managing regulatory compliance processes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "SAP GRC",
            description: "Governance, risk, and compliance solution for managing enterprise risk and regulatory compliance.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Specialized",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Wolters Kluwer OneSumX",
            description: "Integrated risk management platform for financial services institutions for compliance and risk.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Verafin",
            description: "Financial crime management platform for anti-money laundering (AML) and fraud detection.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "IBM OpenPages",
            description: "Governance, risk, and compliance (GRC) platform for managing risk and regulatory challenges.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "ACA Compliance Group",
            description: "Regulatory compliance software and consulting services for financial services firms.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "SAS Anti-Money Laundering",
            description: "AML compliance solution using advanced analytics and machine learning to detect financial crime.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "ACL GRC",
            description: "Governance, risk management, and compliance platform for data analysis and risk assessment.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Legacy",
            tierRating: "Tier 3",
        },
        {
            name: "CogniCor",
            description: "AI-powered chatbot for providing customer support and answering compliance-related queries.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Symantec Data Loss Prevention",
            description: "Data loss prevention solution for identifying and protecting sensitive information.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Security",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "LogicGate",
            description: "GRC and workflow automation platform for managing risk and compliance processes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "BlackLine",
            description: "Finance controls and automation platform for improving financial close and compliance processes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Finance",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "IBM RegTech",
            description: "Regulatory technology platform for managing financial risk, compliance, and reporting.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Trulioo",
            description: "Global identity verification and AML compliance platform for financial services.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Security",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Wolters Kluwer CASH Suite",
            description: "Risk management and finance solution for financial institutions to manage credit risk and lending.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Finance",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "CyberArk Privileged Access Security",
            description: "Privileged access management solution for securing and managing privileged accounts.",
            type: "Security",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Security",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Aravo",
            description: "Supplier risk and performance management platform for regulatory compliance and risk mitigation.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Specialized",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    Insurance: [
        {
            name: "Guidewires InsuranceSuite",
            description: "Integrated core system for property and casualty insurance operations.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Core System",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Duck Creek Claims",
            description: "Claims management software for insurers to handle and process insurance claims.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Core System",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Vlocity Insurance",
            description: "Industry-specific CRM and sales platform for insurance providers.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Sales and CRM",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "InsurancePro",
            description: "Agency management system for insurance agencies to manage policy sales and customer information.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Agency Management",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Sapiens IDITSuite",
            description: "End-to-end solution for property and casualty insurance management.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Core System",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Vertafore AMS360",
            description: "Agency management system for insurance agencies to streamline operations and client service.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Agency Management",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Insurity Policy Decisions",
            description: "Policy administration system for managing insurance policy lifecycle and underwriting.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Policy Administration",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Pega Claims for Insurance",
            description: "Claims management solution using AI and automation to streamline insurance claims processing.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Claims Management",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Accenture Duck Creek Billing",
            description: "Billing and payment solution for insurance providers to manage billing operations.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Billing",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Insurity Claims Decisions",
            description: "Claims management system for insurance claims processing and analytics.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Claims Management",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "EbixExchange",
            description: "Insurance exchange platform for insurance brokers, agents, and carriers.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Sales and CRM",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "SuretyWave",
            description: "Digital surety bond platform for insurance professionals and surety agents.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Policy Administration",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Vertafore Sagitta",
            description: "Insurance agency management software for property and casualty insurance providers.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Agency Management",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Insurance Systems Inc.",
            description: "Customizable software solutions for insurance carriers and agencies.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Specialized",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Custom",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "IBM Insurance Platform",
            description: "End-to-end platform for insurance providers to manage policy, claims, and billing.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Core System",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "PolicyCenter",
            description: "Policy administration system for insurance companies to manage policy lifecycle and underwriting.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Policy Administration",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "eBaoTech GeneralSystem",
            description: "General insurance platform for insurers to manage policy, claims, and billing.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Core System",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "BriteCore",
            description: "Cloud-based core software platform for property and casualty insurers.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Core System",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Insureon",
            description: "Online insurance platform for small business insurance coverage.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Medium",
            applicationType: "Sales and CRM",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Quick Insured",
            description: "Online insurance platform for quoting and purchasing insurance coverage.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Medium",
            applicationType: "Sales and CRM",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    Pharmacy: [
        {
            name: "Pharmacy Management System",
            description: "System for managing medication orders, dispensing, and patient information.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Pharmacy Management",
            deploymentModel: "On-Prem",
            externalInternal: "Custom",
            vendorType: "Custom",
            status: "Legacy",
            tierRating: "Tier 3",
        },
        {
            name: "E-Prescribing Software",
            description: "Electronic prescription system for healthcare professionals to send prescriptions to pharmacies.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "E-Prescribing",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Medication Dispensing System",
            description: "Automated system for dispensing medication doses accurately and efficiently.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Medication Dispensing",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Pharmacy Inventory Management",
            description: "Software for tracking medication inventory levels, expiration dates, and restocking.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Inventory Management",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Pharmacy Workflow Automation",
            description: "Automation platform for streamlining pharmacy processes, including prescription filling.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Workflow Automation",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Clinical Decision Support",
            description: "Supportive software for healthcare professionals to make clinical decisions based on patient data.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Clinical Support",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Telepharmacy Software",
            description: "Remote pharmacy services platform for patient counseling, medication review, and dispensing.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Telepharmacy",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Medication Therapy Management",
            description: "Software for optimizing patient medication regimens and improving therapy outcomes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Therapy Management",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Pharmacy Billing System",
            description: "Billing and reimbursement system for pharmacy services provided to patients.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Billing",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Pharmacy Analytics Platform",
            description: "Data analytics tool for monitoring and improving pharmacy performance and outcomes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Analytics",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    "Health Information Management": [
        {
            name: "Electronic Health Record (EHR) System",
            description: "Digitalized patient health record system for healthcare professionals to manage patient information.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Health Record",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Health Information Exchange (HIE)",
            description: "Interoperable platform for sharing and exchanging patient health information among healthcare organizations.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Interoperability",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Clinical Documentation Improvement (CDI)",
            description: "Software for enhancing the accuracy and completeness of clinical documentation for coding and billing.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Documentation",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Release of Information (ROI) System",
            description: "System for processing and fulfilling requests for patient health information release.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Health Information",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Medical Coding Software",
            description: "Software for assigning accurate medical codes to patient diagnoses and procedures for billing purposes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Medical Coding",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Health Data Analytics Platform",
            description: "Data analytics tool for extracting insights from large volumes of health data.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Analytics",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Health Information Management System (HIMS)",
            description: "Integrated system for managing patient health information, coding, and billing processes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Health Information",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Clinical Data Registry",
            description: "Database for collecting and analyzing clinical data to measure and improve patient outcomes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Registry",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Document Imaging System",
            description: "System for capturing, storing, and retrieving digital images of paper documents.",
            type: "Desktop",
            businessCriticality: "Medium",
            businessRisk: "Medium",
            applicationType: "Imaging",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Patient Portal",
            description: "Online platform for patients to access their health records, schedule appointments, and communicate with providers.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Patient Engagement",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    "Clinical Operations": [
        {
            name: "Clinical Trial Management System (CTMS)",
            description: "Platform for planning, tracking, and managing clinical trial activities.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Clinical Trials",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Electronic Data Capture (EDC) System",
            description: "Software for collecting, managing, and reporting clinical trial data electronically.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Data Capture",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Clinical Data Management System",
            description: "System for storing, cleaning, and analyzing clinical trial data to ensure data quality.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Data Management",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Electronic Lab Notebook (ELN)",
            description: "Digital platform for recording, managing, and sharing laboratory research data.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Lab Notebook",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Clinical Supply Chain Management",
            description: "System for managing the logistics and distribution of clinical trial supplies and medications.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Supply Chain Management",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Protocol Management System",
            description: "Software for designing, authoring, and managing clinical trial protocols.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Protocol Management",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Patient Recruitment System",
            description: "System for identifying and recruiting suitable patients for clinical trials.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Patient Recruitment",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Clinical Trial Analytics Platform",
            description: "Analytics tool for monitoring and analyzing clinical trial data to improve decision-making.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Analytics",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Regulatory Compliance Software",
            description: "Software for managing and ensuring compliance with regulatory requirements in clinical trials.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Regulatory Compliance",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Clinical Trial Budget Management",
            description: "System for planning, tracking, and managing budgets for clinical trials.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Budget Management",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    "Patient Services": [
        {
            name: "Patient Portal",
            description: "Online platform for patients to access their medical records, schedule appointments, and communicate with providers.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Patient Engagement",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Appointment Scheduling System",
            description: "System for patients to schedule and manage their medical appointments conveniently.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Appointment Scheduling",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Telehealth Platform",
            description: "Virtual care platform for patients to receive medical consultation and treatment remotely.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Telehealth",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Patient Education Resources",
            description: "Digital resources and materials for patients to learn about medical conditions, treatments, and self-care.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Patient Education",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 3",
        },
        {
            name: "Remote Monitoring App",
            description: "Mobile app for patients to track and report their health metrics to healthcare providers.",
            type: "Mobile",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Remote Monitoring",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Prescription Refill App",
            description: "Mobile app for patients to request and manage prescription refills from their healthcare providers.",
            type: "Mobile",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Prescription Management",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Patient Feedback Platform",
            description: "System for collecting patient feedback and satisfaction surveys to improve healthcare services.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Patient Feedback",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 3",
        },
        {
            name: "Health Tracking App",
            description: "Mobile app for patients to monitor and manage their health conditions, diet, and exercise.",
            type: "Mobile",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Health Tracking",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 3",
        },
        {
            name: "Patient Engagement Platform",
            description:
                "Comprehensive platform for engaging patients in their healthcare journey through education, communication, and participation.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Patient Engagement",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Healthcare Mobile Wallet",
            description: "Mobile app for patients to store and manage their medical records, insurance information, and appointments.",
            type: "Mobile",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Mobile Wallet",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    "Laboratory Services": [
        {
            name: "Laboratory Information Management System (LIMS)",
            description: "Software for managing laboratory samples, workflows, data, and results.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Laboratory Management",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Electronic Lab Notebook (ELN)",
            description: "Digital platform for recording, managing, and sharing laboratory research data.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Lab Notebook",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Sample Tracking System",
            description: "System for tracking and managing the lifecycle of laboratory samples.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Sample Tracking",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Analytical Instrument Software",
            description: "Software for controlling and analyzing data from laboratory instruments.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Instrument Software",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Chromatography Data System",
            description: "Software for managing and analyzing chromatography data in analytical laboratories.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Chromatography",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Mass Spectrometry Data Analysis Software",
            description: "Software for processing and interpreting mass spectrometry data in proteomics and metabolomics research.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Mass Spectrometry",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Laboratory Automation System",
            description: "System for automating routine laboratory tasks and processes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Laboratory Automation",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Pathology Information System",
            description: "Software for managing and interpreting pathology test results and reports.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Pathology",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Molecular Diagnostics Software",
            description: "Software for analyzing and interpreting molecular diagnostic test results.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Molecular Diagnostics",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Laboratory Workflow Management System",
            description: "System for optimizing and managing the workflow of laboratory processes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Laboratory Workflow",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    "Supply Chain and Logistics ": [
        {
            name: "ShipEasy Logistics Platform",
            description: "Comprehensive platform for optimizing transportation and logistics operations.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Supply Chain",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "WarehousePro Management System",
            description: "Advanced system for managing warehouse operations, inventory, and fulfillment processes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Supply Chain",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "InventoryOptimize Software",
            description: "Powerful software for tracking, managing, and optimizing inventory levels and stock levels.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Supply Chain",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "DemandSense Forecasting Software",
            description: "Cutting-edge software for analyzing historical data and trends to predict future demand for products.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Supply Chain",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "SupplierConnect Management System",
            description: "Comprehensive system for managing relationships with suppliers, sourcing, and procurement processes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Supply Chain",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "LastMileXpress Delivery System",
            description: "Innovative system for optimizing and managing the last leg of product delivery to customers.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Supply Chain",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "FleetPro Management Software",
            description: "Advanced software for managing and optimizing vehicle fleets, maintenance, and routing.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Supply Chain",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "RouteOptima Solution",
            description: "Innovative solution for optimizing delivery routes and schedules to minimize time and costs.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Supply Chain",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "GlobalTradeEase Management System",
            description: "Complete system for managing international trade, customs compliance, and global supply chain operations.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Supply Chain",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "ReverseFlow Logistics System",
            description: "Efficient system for managing the return, refurbishment, and disposal of products and assets.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Supply Chain",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    DevOps: [
        {
            name: "Jenkins",
            description: "An open-source automation server that enables building, testing, and deploying code automatically.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "DevOps",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Ansible",
            description: "An open-source automation tool for configuring, managing, and deploying applications and infrastructure.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "DevOps",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Docker",
            description: "An open-source platform for developing, shipping, and running applications in containers.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "DevOps",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Kubernetes",
            description: "An open-source container orchestration platform for automating deployment, scaling, and management of applications.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "DevOps",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Terraform",
            description: "An open-source infrastructure-as-code tool for provisioning and managing infrastructure resources.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "DevOps",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "Prometheus",
            description: "An open-source monitoring and alerting toolkit for tracking the health and performance of systems and applications.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "DevOps",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Grafana",
            description: "An open-source platform for monitoring and observability with customizable dashboards and data visualization.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "DevOps",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "GitLab",
            description: "An open-source platform for version control, continuous integration, and continuous delivery.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "DevOps",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Spinnaker",
            description: "An open-source, multi-cloud continuous delivery platform for deploying applications with confidence.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "DevOps",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "Puppet",
            description: "An open-source configuration management tool for automating the provisioning and management of infrastructure.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "DevOps",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
    ],
    Energy: [
        {
            name: "EnergyGrid Pro",
            description: "Comprehensive system for monitoring, controlling, and optimizing energy generation and distribution.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Energy",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "RenewableWatch",
            description: "Advanced platform for monitoring and managing renewable energy sources like solar and wind farms.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Energy",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "DemandSense",
            description: "Solution for optimizing energy consumption and demand response strategies for industrial facilities.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Energy",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "SmartMeter Analytics",
            description: "Platform for analyzing data from smart meters to optimize energy usage and billing accuracy.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Energy",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "EnergyEfficiency Plus",
            description: "Software for identifying and implementing energy efficiency measures in commercial and residential buildings.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Energy",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "PowerPlant Optimizer",
            description: "Suite of applications for optimizing the performance and efficiency of power generation plants.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Energy",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "GridGuardian",
            description: "Solution for securing energy grids and protecting against cyber threats and unauthorized access.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Critical",
            applicationType: "Energy",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "EnergyTrade Pro",
            description: "Platform for facilitating energy trading and market transactions among utilities and suppliers.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Energy",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "AssetHealth Monitor",
            description: "System for monitoring and predicting the health and performance of energy assets like turbines and generators.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Energy",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "EnergyExpense Tracker",
            description: "Application for tracking and managing energy expenses and billing for residential and commercial customers.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Medium",
            applicationType: "Energy",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
    ],
    Education: [
        {
            name: "CurriculumMaster",
            description: "Comprehensive platform for designing, managing, and assessing academic curricula.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Academic",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "AssessmentGenius",
            description: "Platform for creating, administering, and analyzing student assessments and exams.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Academic",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "GradingHub",
            description: "Centralized system for faculty to input, manage, and communicate grades and evaluations.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Academic",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "LearningAnalytics",
            description: "Advanced analytics platform for tracking and optimizing student learning outcomes and performance.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Academic",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "FacultyCollaborate",
            description: "Collaboration platform for faculty members to share resources, ideas, and research.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Academic",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "SyllabusBuilder",
            description: "Tool for creating and managing course syllabi, schedules, and materials.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Academic",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "AdvisingAssist",
            description: "Application for academic advisors to manage student advising sessions and track progress.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Academic",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "CourseEvaluation",
            description: "System for collecting and analyzing student feedback on courses and instructors.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Academic",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "OnlineLearningPlatform",
            description: "Platform for delivering online courses, lectures, and educational content.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Academic",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "AccreditationManager",
            description: "Application for managing and documenting institutional and programmatic accreditation processes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Academic",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
    ],
    "Student Services": [
        {
            name: "StudentPortal",
            description: "Centralized platform for students to access academic records, schedules, and services.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Student Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "CourseRegistration",
            description: "System for students to browse and register for courses, manage schedules, and view prerequisites.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Student Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "FinancialAidPortal",
            description: "Platform for students to apply for and manage financial aid, scholarships, and grants.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Student Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "CounselingAppointment",
            description: "Application for students to schedule appointments with academic advisors and counselors.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Student Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "StudentFeedbackSystem",
            description: "System for collecting and analyzing student feedback on services, campus facilities, and programs.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Student Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "ResidenceManagement",
            description: "System for managing student housing applications, assignments, and facilities.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Student Services",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "CareerServicesPlatform",
            description: "Platform for students to explore career opportunities, search for jobs, and access resources.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Student Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "HealthServicesPortal",
            description: "Portal for students to schedule appointments, access health resources, and manage medical records.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Student Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "TutoringManagement",
            description: "System for students to schedule and manage tutoring sessions, view available tutors, and resources.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Student Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "CampusEventsCalendar",
            description: "Calendar application for students to view and RSVP for campus events, lectures, and activities.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Student Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    "Faculty and Instruction": [
        {
            name: "LMS",
            description: "Learning Management System for delivering course content, assignments, and communication.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Faculty and Instruction",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "VirtualClassroom",
            description: "Platform for hosting virtual classrooms, lectures, discussions, and live collaboration.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Faculty and Instruction",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "PlagiarismChecker",
            description: "Tool for detecting and preventing plagiarism in student assignments and research papers.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Faculty and Instruction",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "VideoLectureRecorder",
            description: "Software for recording and sharing video lectures, demonstrations, and presentations.",
            type: "Desktop",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Faculty and Instruction",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "CourseMaterialRepository",
            description: "Repository for storing and organizing course materials, syllabi, and resources.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Faculty and Instruction",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "CollaborativeResearchTool",
            description: "Platform for faculty and researchers to collaborate on interdisciplinary projects and research.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Faculty and Instruction",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "DigitalAssessmentTool",
            description: "Software for creating and administering digital assessments, quizzes, and exams.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Faculty and Instruction",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "PeerReviewPlatform",
            description: "System for facilitating peer review of research papers and academic articles.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Faculty and Instruction",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "OnlineOfficeHours",
            description: "Virtual platform for faculty to hold online office hours, consultations, and discussions.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Faculty and Instruction",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "FacultyFeedbackSystem",
            description: "System for collecting and analyzing feedback from students on faculty members and courses.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Faculty and Instruction",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    "Enrollment and Admissions": [
        {
            name: "ApplicationPortal",
            description: "Online portal for prospective students to apply for admission, submit documents, and track status.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Enrollment and Admissions",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "ProspectCRM",
            description: "Customer relationship management system for managing and tracking prospective student interactions.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Enrollment and Admissions",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "ApplicationReview",
            description: "Platform for admissions staff to review and evaluate student applications and documents.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Enrollment and Admissions",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "RecruitmentTool",
            description: "Software for targeting, attracting, and engaging prospective students through marketing campaigns.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Enrollment and Admissions",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "OnboardingSystem",
            description: "Platform for admitted students to complete enrollment tasks, submit documents, and register for classes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Enrollment and Admissions",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "CRMIntegration",
            description: "Integration tool for connecting admissions CRM with student information system and other applications.",
            type: "API",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Enrollment and Admissions",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "FinancialAidAutomation",
            description: "Automation platform for evaluating and awarding financial aid packages to admitted students.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Enrollment and Admissions",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "AdmissionAnalytics",
            description: "Analytics tool for monitoring and optimizing enrollment and admissions processes.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Enrollment and Admissions",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "StudentRecruit",
            description: "Online platform for prospective students to explore programs, events, and campus life.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Enrollment and Admissions",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "AdmissionsChatbot",
            description: "Chatbot for answering inquiries, guiding students through the application process, and providing information.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Enrollment and Admissions",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    "Library Services": [
        {
            name: "LibraryManagementSystem",
            description: "Integrated system for cataloging, organizing, and managing library collections and resources.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Library Services",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "DigitalRepository",
            description: "Platform for archiving and providing access to digital collections, journals, and research papers.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Library Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "InterlibraryLoanSystem",
            description: "System for requesting and borrowing materials from other libraries and institutions.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Library Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "ReferenceManagementTool",
            description: "Software for collecting, organizing, and citing research references and bibliographic data.",
            type: "Desktop",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Library Services",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "LibraryPortal",
            description: "Online portal for patrons to access e-books, journals, articles, and library services.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Library Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "DiscoveryService",
            description: "Search and discovery tool for accessing print and digital resources across multiple repositories.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Library Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 1",
        },
        {
            name: "BookRenewalSystem",
            description: "System for renewing and managing borrowed physical materials, such as books and DVDs.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Library Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "LibraryApp",
            description: "Mobile app for patrons to search the library catalog, access e-resources, and receive notifications.",
            type: "Mobile",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Library Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "DocumentDeliverySystem",
            description: "System for requesting and delivering physical and digital documents to library users.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Library Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "LibraryAnalytics",
            description: "Analytics tool for monitoring library usage, resource popularity, and user behaviors.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Low",
            applicationType: "Library Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
    ],
    Transportation: [
        {
            name: "FleetManagementSystem",
            description: "System for managing and tracking a company's fleet of vehicles, including maintenance and fuel.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Transportation Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "RoutePlanningSoftware",
            description: "Software for optimizing and planning efficient routes for delivery trucks and vehicles.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Transportation Services",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "DispatchSystem",
            description: "System for dispatching and coordinating transportation resources, drivers, and assignments.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Transportation Services",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "VehicleTrackingSystem",
            description: "Real-time tracking and monitoring of vehicles, shipments, and assets in transit.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Transportation Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "LogisticsManagement",
            description: "Comprehensive platform for managing all aspects of logistics, supply chain, and transportation operations.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Transportation Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "VehicleMaintenanceSoftware",
            description: "Software for tracking and managing vehicle maintenance schedules, repairs, and service history.",
            type: "Desktop",
            businessCriticality: "Medium",
            businessRisk: "Medium",
            applicationType: "Transportation Services",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "CargoTracking",
            description: "System for tracking and managing cargo shipments, including handling, storage, and documentation.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Transportation Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "TransportationAnalytics",
            description: "Analytics tool for analyzing transportation data, optimizing routes, and identifying cost-saving opportunities.",
            type: "Web",
            businessCriticality: "Medium",
            businessRisk: "Medium",
            applicationType: "Transportation Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "ElectronicProofOfDelivery",
            description: "Electronic solution for capturing, storing, and verifying proof of delivery for shipments.",
            type: "Mobile",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Transportation Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "LoadOptimizationSoftware",
            description: "Software for optimizing the loading of vehicles to maximize capacity and reduce transportation costs.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Transportation Services",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "DriverManagementSystem",
            description: "System for managing driver information, qualifications, training, and compliance records.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Transportation Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "FreightBillingSoftware",
            description: "Software for automating freight billing, invoicing, and payment processes for transportation services.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Transportation Services",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "ContainerTrackingSystem",
            description: "System for tracking and managing shipping containers, including location, status, and contents.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Transportation Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "VehicleTelematics",
            description: "Telematics solution for monitoring vehicle performance, driver behavior, and safety metrics.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Transportation Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "TollCollectionSystem",
            description: "System for collecting toll payments electronically through RFID technology and payment gateways.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Transportation Services",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Emerging",
            tierRating: "Tier 1",
        },
        {
            name: "AirportManagementSystem",
            description: "Integrated system for managing airport operations, including flights, passengers, baggage, and security.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Transportation Services",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "RailwayReservationSystem",
            description: "Online platform for booking and managing railway tickets and reservations.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Transportation Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "CargoHandlingSystem",
            description: "System for managing and tracking cargo handling operations at ports, terminals, and warehouses.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Transportation Services",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "AirTrafficControl",
            description: "Advanced system for monitoring, coordinating, and managing air traffic at airports and in airspace.",
            type: "Desktop",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Transportation Services",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "CruiseReservationSystem",
            description: "Online platform for booking and managing cruise reservations, cabins, and onboard services.",
            type: "Web",
            businessCriticality: "High",
            businessRisk: "Medium",
            applicationType: "Transportation Services",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
    ],
    Cybersecurity: [
        {
            name: "Veracode",
            description:
                "Application security testing platform that offers static, dynamic, and software composition analysis to identify vulnerabilities in code.",
            type: "Application Security",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Cybersecurity",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Fortify",
            description:
                "Application security platform that provides static and dynamic analysis, as well as interactive application security testing.",
            type: "Application Security",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Cybersecurity",
            deploymentModel: "On-Prem",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Checkmarx",
            description: "Static application security testing solution that identifies and mitigates security vulnerabilities in source code.",
            type: "Application Security",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Cybersecurity",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Synopsys",
            description:
                "Application security testing tools that cover static analysis, dynamic analysis, and software composition analysis to identify and remediate vulnerabilities.",
            type: "Application Security",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Cybersecurity",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Duo Security",
            description: "Multi-factor authentication and security platform that verifies the identities of users and devices.",
            type: "Authentication",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Cybersecurity",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "RSA SecurID",
            description: "Authentication solution that provides two-factor authentication using a time-based one-time password algorithm.",
            type: "Authentication",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Cybersecurity",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Okta",
            description: "Identity and access management solution that provides secure single sign-on, multi-factor authentication, and more.",
            type: "Authentication",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Cybersecurity",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Google Authenticator",
            description: "Two-step verification application that generates time-based one-time passwords on mobile devices.",
            type: "Authentication",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Cybersecurity",
            deploymentModel: "Mobile",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Azure Active Directory",
            description:
                "Microsoft's cloud-based identity and access management service that provides single sign-on and multi-factor authentication.",
            type: "Authentication",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Cybersecurity",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Ping Identity",
            description:
                "Identity and access management platform that provides secure single sign-on, multi-factor authentication, and API security.",
            type: "Authentication",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Cybersecurity",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "Okta",
            description: "Identity and access management solution that offers single sign-on, multi-factor authentication, and user provisioning.",
            type: "Authentication",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Cybersecurity",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "ForgeRock",
            description:
                "Identity management and access management platform that offers authentication, authorization, and user management capabilities.",
            type: "Authentication",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Cybersecurity",
            deploymentModel: "Hybrid",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
        {
            name: "OneLogin",
            description: "Identity and access management solution that offers single sign-on, multi-factor authentication, and user provisioning.",
            type: "Authentication",
            businessCriticality: "High",
            businessRisk: "High",
            applicationType: "Cybersecurity",
            deploymentModel: "Cloud",
            externalInternal: "Vendor",
            vendorType: "Vendor",
            status: "Recommended",
            tierRating: "Tier 0",
        },
    ],
};
