import { Reply } from "@mui/icons-material";
import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, Divider, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { handleFormChange } from "../../utils/FormUtils";
import { useTheme } from "@mui/material/styles";
import { GridLoader } from "react-spinners";
import { findPermission, savePermission } from "../../services/PermissionService";
import { useAuthContext } from "../../hooks/useAuthContext";
import StatusMessage from "../../components/StatusMessage";

export default function PermissionEdit() {
    const navigate = useNavigate();
    const { person } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const theme = useTheme();

    // Determine if its a new or existing permission based on the id from the URL
    const { id: permissionId } = useParams();

    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    const [formData, setFormData] = useState({
        name: "",
        description: "",
    });
    const [errors, setErrors] = useState({
        name: "",
        lastName: "",
    });

    useEffect(() => {
        async function getPermission() {
            const data = await findPermission(permissionId);
            setFormData(data);
        }

        if (permissionId) getPermission();
    }, [permissionId]);

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        // Validate name field
        if (formData.name.trim() === "") {
            newErrors.name = "Permission name is required";
            isValid = false;
        } else {
            newErrors.name = "";
        }
        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsPending(true);
        setError(null);

        try {
            if (validateForm()) {
                const savedPermission = await savePermission(person.id, person.tenant.id, formData);
                setStatus({
                    open: true,
                    type: "success",
                    message: `Updated permission details`,
                });
            }

            setError(null);
            setIsPending(false);
            navigate(-1);
        } catch (err) {
            setError(err.message);
            setIsPending(false);
        }
    };

    const handleCancel = (e) => {
        navigate(-1);
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <Card>
                <CardHeader
                    title={permissionId ? `Edit ${formData.name}` : "Add Permission"}
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="Add a new permission"
                    action={
                        <Button startIcon={<Reply />} onClick={handleBack} color="info">
                            Back
                        </Button>
                    }
                />
                <Divider />

                <form onSubmit={handleSubmit}>
                    <CardContent>
                        <Box display="flex" flexDirection="column">
                            <TextField
                                name="name"
                                label="Name"
                                required
                                fullWidth
                                variant="standard"
                                value={formData.name ?? ""}
                                onChange={(e) => handleFormChange(setFormData, e)}
                                error={!!errors.firstName}
                                helperText={errors.firstName}
                            />
                            <TextField
                                name="description"
                                label="Description"
                                fullWidth
                                variant="standard"
                                value={formData.description ?? ""}
                                onChange={(e) => handleFormChange(setFormData, e)}
                                error={!!errors.lastName}
                                helperText={errors.lastName}
                            />
                        </Box>
                    </CardContent>
                    <CardActions
                        //disableSpacing
                        sx={{
                            alignSelf: "stretch",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-start",
                            paddingRight: 4,
                        }}
                    >
                        <Button color="inherit" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" type="submit" disabled={isPending && isPending ? true : false}>
                            {isPending && isPending ? <GridLoader color={theme.palette.primary.light} /> : "Save"}
                        </Button>

                        {error && <Alert severity="error">{error}</Alert>}
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}
