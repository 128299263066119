import { addDoc, collection, doc, or, setDoc } from "firebase/firestore";
import * as Constants from "../constants/Constants";
import * as Authority from "../constants/Authorities";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { personName, updateAudit } from "../utils/utils";
import { findPerson, savePerson } from "./PersonService";
import { saveRelation } from "./RelationService";
import { findOrganization, saveOrganization } from "./OrganizationService";

// Save a Tenant
export const saveTenant = async (uid, contact, organization) => {
    console.log("saveTenant: ", uid, contact, organization);
    // Step 1: Create or update the organization
    let organizationRef;
    if (organization?.id) {
        organizationRef = doc(db, DBConstants.DB_TENANTS, organization.id);
    } else {
        organizationRef = doc(collection(db, DBConstants.DB_TENANTS));
        organization.id = organizationRef.id;
    }
    updateAudit(uid, organization);
    await setDoc(organizationRef, { ...organization }, { merge: true });
    organization = await findOrganization(organization.id);

    // Step 2: Create or update the contact
    let contactRef;
    if(contact?.id) {
        contactRef = doc(db, DBConstants.DB_PEOPLE, contact.id);
    } else {
        contactRef = doc(collection(db, DBConstants.DB_PEOPLE));
        contact.id = contactRef.id;
    }
    // Step 3: Set the tenant id on the contact
    contact.tenant = {
        id: organization.id,
        name: organization.name,
    };
    updateAudit(uid, contact);
    await setDoc(contactRef, { ...contact }, { merge: true });
    contact = await findPerson(contact.id);

    // Step 4: Set the primary contact on the organization 
    organization.primaryContact = {
        id: contact.id,
        name: personName(contact),
    };
    updateAudit(uid, organization);
    await setDoc(organizationRef, { ...organization }, { merge: true });
    organization = await findOrganization(organization.id);
    

    // Step 5: Save the relationship with the user
    const rel = {
        from: contact.id,
        fromName: personName(contact),
        fromEntity: Constants.ENTITY_PERSON,
        to: organization.id,
        toName: organization.name,
        toEntity: Constants.ENTITY_ORGANIZATION,
        relation: Authority.ROLE_TENANT_ADMIN,
    };
    await saveRelation(uid, rel);

    organization = await findOrganization(organization.id);
    return organization;
};
