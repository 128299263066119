import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { handleFormChange } from "../../utils/FormUtils";
import { Search } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

export default function RequestSearch() {
    const [formData, setFormData] = useState({
        search: "",
        type: "",
        dateRange: "",
    });

    const [errors, setErrors] = useState({
        search: "",
        type: "",
    });

    const searchRequests = () => {
        console.log("searchRequests");
    };

    return (
        <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={4} sx={{bgcolor: 'white', p: 2}}>
            <TextField
                name="search"
                label="Search"
                //fullWidth
                variant="standard"
                value={formData.search ?? ""}
                onChange={handleFormChange}
                error={!!errors.search}
                helperText={errors.search}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={searchRequests}>
                                <Search color="primary" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                sx={{ width: "30%" }}
            />
            <FormControl sx={{ width: "20%" }}>
                <InputLabel id="type">Type</InputLabel>
                <Select labelId="type" name="type" variant="standard" value={formData.type ?? ""} onChange={handleFormChange}>
                    <MenuItem value="" key="country">
                        --select--
                    </MenuItem>

                    <MenuItem value="1" key="1">
                        Access Request
                    </MenuItem>
                </Select>
                {errors.type && <FormHelperText error>{errors.type}</FormHelperText>}
            </FormControl>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2} sx={{ width: "30%" }}>
                <DatePicker label="From" slotProps={{ textField: { variant: "standard" } }} />
                <DatePicker label="To" slotProps={{ textField: { variant: "standard" } }} />
            </Stack>
        </Stack>
    );
}
