
// Roles 
export const ROLE_SUPERADMIN = "Super Admin"
export const ROLE_TENANT_ADMIN = "Tenant Administrator"
export const ROLE_AUDITOR = "Auditor"
export const ROLE_USER = "User"
export const ROLE_INTERNAL_USER = "Internal User"
export const ROLE_EXTERNAL_USER = "External User"


// Permissions 
export const PERMISSION_DASHBOARD_CAN_VIEW = "dashboard.canView"
export const PERMISSION_ADMIN_ALL = "admin.all"

export const PERMISSION_ACTIONS_BULK = "actions.bulk"

export const PERMISSION_TENANT_MY = "tenant.my"
export const PERMISSION_TENANT_ALL = "tenant.all"
export const PERMISSION_TENANT_CAN_CREATE = "tenant.canCreate"
export const PERMISSION_TENANT_CAN_VIEW = "tenant.canView"
export const PERMISSION_TENANT_CAN_UPDATE = "tenant.canUpdate"
export const PERMISSION_TENANT_CAN_DELETE = "tenant.canDelete"

export const PERMISSION_PERSON_ALL = "person.all"
export const PERMISSION_PERSON_CAN_CREATE = "person.canCreate"
export const PERMISSION_PERSON_CAN_VIEW = "person.canView"
export const PERMISSION_PERSON_CAN_UPDATE = "person.canUpdate"
export const PERMISSION_PERSON_CAN_DELETE = "person.canDelete"

export const PERMISSION_ROLE_ALL = "role.all"
export const PERMISSION_ROLE_CAN_CREATE = "role.canCreate"
export const PERMISSION_ROLE_CAN_VIEW = "role.canView"
export const PERMISSION_ROLE_CAN_UPDATE = "role.canUpdate"
export const PERMISSION_ROLE_CAN_DELETE = "role.canDelete"

export const PERMISSION_GROUP_ALL = "group.all"
export const PERMISSION_GROUP_CAN_CREATE = "group.canCreate"
export const PERMISSION_GROUP_CAN_VIEW = "group.canView"
export const PERMISSION_GROUP_CAN_UPDATE = "group.canUpdate"
export const PERMISSION_GROUP_CAN_DELETE = "group.canDelete"

export const PERMISSION_PERMISSION_ALL = "permission.all"
export const PERMISSION_PERMISSION_CAN_CREATE = "permission.canCreate"
export const PERMISSION_PERMISSION_CAN_VIEW = "permission.canView"
export const PERMISSION_PERMISSION_CAN_UPDATE = "permission.canUpdate"
export const PERMISSION_PERMISSION_CAN_DELETE = "permission.canDelete"

export const PERMISSION_DEPARTMENT_ALL = "department.all"
export const PERMISSION_DEPARTMENT_CAN_CREATE = "department.canCreate"
export const PERMISSION_DEPARTMENT_CAN_VIEW = "department.canView"
export const PERMISSION_DEPARTMENT_CAN_UPDATE = "department.canUpdate"
export const PERMISSION_DEPARTMENT_CAN_DELETE = "department.canDelete"

export const PERMISSION_PROFILE_ALL = "profile.all"
export const PERMISSION_PROFILE_CAN_CREATE = "profile.canCreate"
export const PERMISSION_PROFILE_CAN_VIEW = "profile.canView"
export const PERMISSION_PROFILE_CAN_UPDATE = "profile.canUpdate"
export const PERMISSION_PROFILE_CAN_DELETE = "profile.canDelete"

export const PERMISSION_SETTINGS_ALL = "settings.all"
export const PERMISSION_SETTINGS_CAN_CREATE = "settings.canCreate"
export const PERMISSION_SETTINGS_CAN_VIEW = "settings.canView"
export const PERMISSION_SETTINGS_CAN_UPDATE = "settings.canUpdate"
export const PERMISSION_SETTINGS_CAN_DELETE = "settings.canDelete"

export const PERMISSION_ASSESSMENT_ALL = "assessment.all"
export const PERMISSION_ASSESSMENT_CAN_CREATE = "assessment.canCreate"
export const PERMISSION_ASSESSMENT_CAN_VIEW = "assessment.canView"
export const PERMISSION_ASSESSMENT_CAN_UPDATE = "assessment.canUpdate"
export const PERMISSION_ASSESSMENT_CAN_DELETE = "assessment.canDelete"

export const PERMISSION_CONTROL_ALL = "control.all"
export const PERMISSION_CONTROL_CAN_CREATE = "control.canCreate"
export const PERMISSION_CONTROL_CAN_VIEW = "control.canView"
export const PERMISSION_CONTROL_CAN_UPDATE = "control.canUpdate"
export const PERMISSION_CONTROL_CAN_DELETE = "control.canDelete"

export const PERMISSION_POLICY_ALL = "policy.all"
export const PERMISSION_POLICY_CAN_CREATE = "policy.canCreate"
export const PERMISSION_POLICY_CAN_VIEW = "policy.canView"
export const PERMISSION_POLICY_CAN_UPDATE = "policy.canUpdate"
export const PERMISSION_POLICY_CAN_DELETE = "policy.canDelete"

export const PERMISSION_EVIDENCE_ALL = "evidence.all"
export const PERMISSION_EVIDENCE_CAN_CREATE = "evidence.canCreate"
export const PERMISSION_EVIDENCE_CAN_VIEW = "evidence.canView"
export const PERMISSION_EVIDENCE_CAN_UPDATE = "evidence.canUpdate"
export const PERMISSION_EVIDENCE_CAN_DELETE = "evidence.canDelete"


export const PERMISSION_REQUEST_ALL = "request.all"
export const PERMISSION_REQUEST_CAN_CREATE = "request.canCreate"
export const PERMISSION_REQUEST_CAN_VIEW = "request.canView"
export const PERMISSION_REQUEST_CAN_UPDATE = "request.canUpdate"
export const PERMISSION_REQUEST_CAN_DELETE = "request.canDelete"

export const PERMISSION_APPLAUNCH_ALL = "applaunch.all"

export const PERMISSION_APPLICATION_ALL = "application.all"
export const PERMISSION_APPLICATION_CAN_CREATE = "application.canCreate"
export const PERMISSION_APPLICATION_CAN_VIEW = "application.canView"
export const PERMISSION_APPLICATION_CAN_UPDATE = "application.canUpdate"
export const PERMISSION_APPLICATION_CAN_DELETE = "application.canDelete"


export const PERMISSION_AUDIT_ALL = "audit.all"
export const PERMISSION_AUDIT_CAN_CREATE = "audit.canCreate"
export const PERMISSION_AUDIT_CAN_VIEW = "audit.canView"
export const PERMISSION_AUDIT_CAN_UPDATE = "audit.canUpdate"
export const PERMISSION_AUDIT_CAN_DELETE = "audit.canDelete"

export const PERMISSION_NOTIFICATION_ALL = "notification.all"
export const PERMISSION_NOTIFICATION_CAN_CREATE = "notification.canCreate"
export const PERMISSION_NOTIFICATION_CAN_VIEW = "notification.canView"
export const PERMISSION_NOTIFICATION_CAN_UPDATE = "notification.canUpdate"
export const PERMISSION_NOTIFICATION_CAN_DELETE = "notification.canDelete"

export const PERMISSION_INVITATION_ALL = "invitation.all"
export const PERMISSION_INVITATION_CAN_CREATE = "invitation.canCreate"
export const PERMISSION_INVITATION_CAN_VIEW = "invitation.canView"
export const PERMISSION_INVITATION_CAN_UPDATE = "invitation.canUpdate"
export const PERMISSION_INVITATION_CAN_DELETE = "invitation.canDelete"

export const PERMISSION_REPORT_ALL = "report.all"
export const PERMISSION_REPORT_CAN_CREATE = "report.canCreate"
export const PERMISSION_REPORT_CAN_VIEW = "report.canView"
export const PERMISSION_REPORT_CAN_UPDATE = "report.canUpdate"
export const PERMISSION_REPORT_CAN_DELETE = "report.canDelete"


export const PERMISSION_ORDER_ALL = "order.all"
export const PERMISSION_ORDER_CAN_CREATE = "order.canCreate"
export const PERMISSION_ORDER_CAN_VIEW = "order.canView"
export const PERMISSION_ORDER_CAN_UPDATE = "order.canUpdate"
export const PERMISSION_ORDER_CAN_DELETE = "order.canDelete"

export const PERMISSION_CAN_MOCK = "mock.enable"
