import { Reply } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PermissionTable from "./PermissionTable";
import { useTheme } from "@emotion/react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { and, collection, limit, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../config/FirebaseConfig";
import * as DBConstants from "../../constants/DBConstants";
import { GridLoader } from "react-spinners";

export default function PermissionList() {
    const navigate = useNavigate();
    const theme = useTheme()
    const [loading, setLoading] = useState(false);
    const { person } = useAuthContext();
    const [permissions, setPermissions] = useState([]);
    const { state } = useLocation();
    const { refresh } = { ...state };


    useEffect(() => {
        async function getPermissions() {
            setLoading(true);
            const constraints = [];
            if (person?.tenant) {
                //  console.log("Tenant: ", person?.tenant)
                constraints.push(where("tenantId", "==", person.tenant.id));
            }
            const ref = query(collection(db, DBConstants.DB_PERMISSIONS), and(...constraints), limit(50));
            const unsub = onSnapshot(ref, (snapshot) => {
                let results = [];
                snapshot.docs.forEach((doc) => {
                    results.push({ ...doc.data(), id: doc.id });
                });
                
                setPermissions(results);
            });
            setLoading(false);
            // cleanup function
            return () => unsub();

        }
        // console.log ('Person: ', person)

        getPermissions();

    }, []);


    const handleBack = () => {
        navigate(-1);
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Card>
                <CardHeader
                    title="Permissions"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="View your permissions or start a new one"
                    action={
                        <Button startIcon={<Reply />} onClick={handleBack} color="info">
                            Back
                        </Button>
                    }
                />
                <Divider />
                {permissions && <CardContent>{permissions && <PermissionTable permissions={permissions} title="Permissions" />}</CardContent>}
            </Card>
        </Box>
    );
}
