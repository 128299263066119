import { ArrowCircleRight } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import MyRequestList from "./MyRequestList";
import MyAccessList from "./MyAccessList";


export default function RequestTabs() {
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, index) => {
        setTab(index);
    };

    return (
        <Card>
            <CardContent sx={{ bgcolor: '#f9f9f9'}}>
                <Tabs value={tab}  onChange={handleTabChange} indicatorColor="primary">
                    <Tab label="My Request" />
                    <Tab label="My Access" />
                </Tabs>
                {tab === 0 && (
                    <MyRequestList />
                )}
                {tab === 1 && (
                    <MyAccessList />
                )}
            </CardContent>
        </Card>
    );
}

