import { collection, deleteDoc, doc, getCountFromServer, getDoc, getDocs, limit, orderBy, query, setDoc, where } from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { findOrganization } from "./OrganizationService";
import { addDays, endOfDay } from "date-fns";
import { NOTIFICATION_EVENTS } from "../constants/EventTypes";
import { updateAudit } from "../utils/utils";

// Get all notifications
export const findAllNotifications = async (uid) => {
    let results = [];
    const q = query(collection(db, DBConstants.DB_INVITATIONS), where("createdBy", "==", uid));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

export const findNotificationsByTenantId = async (tenantId) => {
    let results = [];
    const q = query(collection(db, DBConstants.DB_INVITATIONS), where("tenant.id", "==", tenantId));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

export const countNotificationsByTenantId = async (tenantId) => {
    const q = query(collection(db, DBConstants.DB_INVITATIONS), where("tenant.id", "==", tenantId));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
};

// Find a notification with id
export const findNotification = async (id) => {
    const docRef = doc(db, DBConstants.DB_INVITATIONS, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
};

export const findNotificationByIds = async (notificationIds) => {
    let results = [];
    const q = query(collection(db, DBConstants.DB_INVITATIONS), where("id", "in", notificationIds), orderBy("createdDate", "desc"));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};


// Save a Notification
export const saveNotificationRaw = async (id, notification) => {
    let docRef
    if(notification.id) {
      docRef = doc(collection(db, DBConstants.DB_INVITATIONS), notification.id);
    }else {
      docRef = doc(collection(db, DBConstants.DB_INVITATIONS));
      notification.id = docRef.id;
    }
  
    updateAudit(id, notification);
    await setDoc(docRef, notification, { merge: true });
    return await findNotification(notification.id);
  
  };


export const saveNotification = async (uid, tenantId, notification) => {
    console.log ('Saving notification: ', notification)
    let docRef = null;
    const tenant = await findOrganization(tenantId);

    if (notification && notification.id) {
        docRef = doc(db, DBConstants.DB_INVITATIONS, notification.id);
    } else {
        // Save the new doc
        docRef = doc(collection(db, DBConstants.DB_INVITATIONS));
        notification.id = docRef.id;
        await setDoc(docRef, notification, { merge: true });
    }

    updateAudit(uid, notification);
    await setDoc(docRef, notification, { merge: true });

    notification = await findNotification(notification.id);
    return notification;
};

// Delete a notification
export const deleteNotification = async (id) => {
    const ref = doc(db, DBConstants.DB_INVITATIONS, id);
    await deleteDoc(ref);
};

