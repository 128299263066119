import React, { useState } from "react";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useAuthContext } from "../../hooks/useAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { saveOrganization } from "../../services/OrganizationService";
import { Reply } from "@mui/icons-material";
import { GridLoader } from "react-spinners";
import { CountryCodes, getCodelist } from "../../constants/Codelist";
import ContactEditForm from "../../components/ContactEditForm";
import StatusMessage from "../../components/StatusMessage";
import { useTheme } from '@mui/material/styles';
import { findPerson } from "../../services/PersonService";

const useStyles = makeStyles((theme) => ({
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        fontStyle: "italic",
    },
}));

export default function OrgProfile( ) {
    const theme = useTheme();
    const classes = useStyles();
    const { user, person, profile, dispatch } = useAuthContext();
    const navigate = useNavigate();
    const location = useLocation();
    const organization = location.state?.organization;


    // Step functions
    const stepContent = [
        "We need to know more about your organization. Please complete in the details below.",
        "Kindly share your business contact details and location information for our records. Your accurate information will ensure seamless communication and service delivery.",
        "That's it! We are done. Remember, you can update or modify this information at any time in the future from your settings page.",
    ];
    const [activeStep, setActiveStep] = useState(0);
    const steps = ["Basic Details", "Contact", "Complete"];

    // Status and error message
    const [error, setError] = useState(null);

    const [isPending, setIsPending] = useState(false);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    // Form data
    const [formData, setFormData] = useState({
        id: organization?.id || "",
        name: organization?.name || "",
        dba: organization?.dba || "",
        country: organization?.country || "",
        addressLine1: organization?.addressLine1 || "",
        addressLine2: organization?.addressLine2 || "",
        city: organization?.city || "",
        state: organization?.state || "",
        zipcode: organization?.zipcode || "",
        website: organization?.website || "",
    });
    const [errors, setErrors] = useState({
        name: "",
    });

    const handleCancel = () => {
        navigate(-1);
    };

    const handleNext = async () => {
        //console.log ('handleNext')
        if (activeStep === 0) {
            setError(null);
            //console.log ('validateForm: ', validateForm())
            if (validateForm()) {
                setError(null);
                setActiveStep(activeStep + 1);
            }
        } else {
            setActiveStep(activeStep + 1);
        }

        // Last step - save the organization
        if (activeStep === steps.length - 1) {
            await handleSubmit();
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        // Validate firstName field
        if (formData.name.trim() === "") {
            newErrors.name = "Name is required";
            isValid = false;
        } else {
            newErrors.name = "";
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async () => {
        setIsPending(true);
        setError(null);

        try {
            //console.log("Submit the form data:  ", formData);
            if (validateForm()) {
                // Form is valid, submit or perform further actions
                const tenant = await saveOrganization(person?.id, formData);
                // Read person again 
                const p = await findPerson(person?.id);
                console.log ('Dispatch Person changed: ', p)
                setTimeout(async () => {
                    await dispatch({
                        type: "PERSON_CHANGED",
                        person: p,
                    });
                }, 1000);
                
                setStatus({
                    open: true,
                    type: "success",
                    message: "Updated organization details",
                });
            }
            setError(null);
            setIsPending(false);
            console.log ('Organization: ', organization)
            if(organization){
                navigate(-1);
            }else {
                navigate("/");
            }
            
        } catch (err) {
            console.log(err);
            setError(err);
            setIsPending(false);
        }
    };

    const handleChange = (e) => {
        //console.log ('handleChange: ', e.target.name, e.target.value)
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Card sx={{width: "100%"}}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <CardHeader
                title="Organization Setup"
                titleTypographyProps={{ color: "primary.main" }}
                action={
                    <Button startIcon={<Reply />} onClick={handleCancel} color="info">
                        Back
                    </Button>
                }
            />
            {/* <Divider /> */}
            <CardContent>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Box mt={4}>
                    {activeStep === steps.length ? (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "70vw",
                                height: "70vh",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <GridLoader color={theme.palette.primary.light}/>
                        </Box>
                    ) : (
                        <div>
                            <Typography className={classes.instructions}>{stepContent[activeStep]}</Typography>
                            {activeStep === 0 && (
                                <Box display="flex" flexDirection="column" width="50%">
                                    <TextField
                                        name="name"
                                        label="Company Name"
                                        required
                                        fullWidth
                                        variant="standard"
                                        value={formData.name ?? ""}
                                        onChange={handleChange}
                                        error={!!errors.name}
                                        helperText={errors.name}
                                    />
                                    <TextField
                                        name="dba"
                                        label="Doing Business As/ Trade Name"
                                        fullWidth
                                        variant="standard"
                                        value={formData.dba ?? ""}
                                        onChange={handleChange}
                                        error={!!errors.dba}
                                        helperText={errors.dba}
                                    />
                                    <FormControl sx={{ marginTop: 2 }}>
                                        <InputLabel id="country">Country</InputLabel>
                                        <Select
                                            labelId="country"
                                            name="country"
                                            required
                                            variant="standard"
                                            value={formData.country ?? ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="" key="country">
                                                --select--
                                            </MenuItem>

                                            {getCodelist(CountryCodes).map((t) => {
                                                return (
                                                    <MenuItem value={t["alpha-2"]} key={t["alpha-2"]}>
                                                        {t["name"]}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {errors.country && <FormHelperText error>{errors.country}</FormHelperText>}
                                    </FormControl>
                                </Box>
                            )}

                            {activeStep === 1 && (
                                <Box display="flex" flexDirection="column" width="50%">
                                    <ContactEditForm
                                        formData={formData}
                                        errors={errors}
                                        handleChange={handleChange}
                                        includeAddress={true}
                                        includeWebsite={true}
                                    />
                                </Box>
                            )}
                        </div>
                    )}
                </Box>
            </CardContent>
            <CardActions
                //disableSpacing
                sx={{
                    alignSelf: "stretch",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    paddingRight: 4,
                }}
            >
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                    Back
                </Button>
                <Button variant="contained" color="primary" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Done" : "Next"}
                </Button>
            </CardActions>
        </Card>
    );
}
