import { Mail, Person, Phone } from "@mui/icons-material";
import { Avatar, Box, Card, CardContent, CardHeader, Divider, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    gradientHeader: {
        background: `linear-gradient(90deg, black, ${theme.palette.primary.light})`,
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
    gradientBody: {
        background: `linear-gradient(to right bottom, black, ${theme.palette.primary.light})`,
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
    nongradientHeader: {
        background: "#ffffff",
        border: "1px solid #e0e0e0",
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
    nongradientBody: {
        background: "#f5f5f5",
        
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
}));

export default function EmployeeCard({ name, title, department, location, showLocation, showContact, color }) {
    const classes = useStyles();

    return (
        <>
            <Card elevation={0} sx={{ width: "300px" }} className={color === "gradient" ? classes.gradientBody : classes.nongradientBody}>
                <CardHeader
                    title={name}
                    titleTypographyProps={{ color: color === "gradient" ? "white" : "primary.main", variant: "subtitle" }}
                    subheader={title}
                    subheaderTypographyProps={{ color: color === "gradient" ? "#f5f5f5" : null, variant: "body" }}
                    className={color === "gradient" ? classes.gradientHeader : classes.nongradientHeader}
                    avatar={
                        <Avatar sx={{ bgcolor: "black" }}>
                            <Person color="secondary" />
                        </Avatar>
                    }
                />
                <Divider />
                {(showLocation || showContact) && (
                    <CardContent sx={{ position: "relative" }}>
                        {/* <BackgroundIcon component={PersonAdd} bottom={50} /> */}
                        {showLocation && (
                            <Box display="flex" flexDirection="column" flexGrow={1} gap={1}>
                                <Typography variant="body" color={color === "gradient" ? "white" : null}>
                                    Department: {department}
                                </Typography>
                                <Typography variant="body" color={color === "gradient" ? "white" : null}>
                                    Location: {location}
                                </Typography>
                            </Box>
                        )}
                        {showContact && (
                            <Box display="flex" flexDirection="row" justifyContent="center">
                                <IconButton sx={{ color: "white" }}>
                                    <Mail />
                                </IconButton>
                                <IconButton sx={{ color: "white" }}>
                                    <Phone />
                                </IconButton>
                            </Box>
                        )}
                    </CardContent>
                )}
            </Card>
        </>
    );
}
