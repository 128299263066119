export const Controls = {
    nist: [
        {
            id: 1,
            name: "AC-1",
            description: "Access Control Policy and Procedures",
            criticality: "High",
        },
        {
            id: 2,
            name: "AC-5",
            description: "Separation of Duties",
            criticality: "High",
        },
        {
            id: 3,
            name: "AC-11",
            description: "Session Lock",
            criticality: "High",
        },
        {
            id: 4,
            name: "AC-12",
            description: "Session Termination",
            criticality: "High",
        },
        {
            id: 5,
            name: "IA-4",
            description: "Identifier Management",
            criticality: "High",
        },
        {
            id: 6,
            name: "IA-7",
            description: "Cryptographic Module Authentication",
            criticality: "High",
        },
        {
            id: 7,
            name: "IA-9",
            description: "External Information System Services",
            criticality: "High",
        },
        {
            id: 8,
            name: "AC-14",
            description: "Permitted Actions without Identification",
            criticality: "Medium",
        },
        {
            id: 9,
            name: "AC-17",
            description: "Remote Access",
            criticality: "High",
        },
        {
            id: 10,
            name: "AC-20",
            description: "Use of External Information Systems",
            criticality: "High",
        },
    ],
    gdpr: [
        {
            id: 1,
            name: "GDPR-1",
            description: "Data Protection Policy",
            criticality: "Critical",
        },
        {
            id: 2,
            name: "GDPR-2",
            description: "Data Inventory and Mapping",
            criticality: "High",
        },
        {
            id: 3,
            name: "GDPR-3",
            description: "Data Subject Rights",
            criticality: "High",
        },
        {
            id: 4,
            name: "GDPR-4",
            description: "Consent Management",
            criticality: "High",
        },
        {
            id: 5,
            name: "GDPR-5",
            description: "Data Minimization",
            criticality: "Medium",
        },
        {
            id: 6,
            name: "GDPR-6",
            description: "Data Protection Impact Assessment (DPIA)",
            criticality: "High",
        },
        {
            id: 7,
            name: "GDPR-7",
            description: "Access Control",
            criticality: "Critical",
        },
        {
            id: 8,
            name: "GDPR-8",
            description: "Data Portability",
            criticality: "Medium",
        },
        {
            id: 9,
            name: "GDPR-9",
            description: "Data Breach Notification",
            criticality: "Critical",
        },
        {
            id: 10,
            name: "GDPR-10",
            description: "Security of Processing",
            criticality: "Critical",
        },
    ],
    iso27001: [
        {
            id: 1,
            name: "ISO 27001:2022-A.8.1",
            description: "Access Control",
            criticality: "Critical",
        },
        {
            id: 2,
            name: "ISO 27001:2022-A.8.2",
            description: "Authentication",
            criticality: "Critical",
        },
        {
            id: 3,
            name: "ISO 27001:2022-A.8.3",
            description: "Password Policy",
            criticality: "High",
        },
        {
            id: 4,
            name: "ISO 27001:2022-A.9.2",
            description: "User Training",
            criticality: "High",
        },
        {
            id: 5,
            name: "ISO 27001:2022-A.9.4",
            description: "System Monitoring",
            criticality: "Critical",
        },
        {
            id: 6,
            name: "ISO 27001:2022-A.9.5",
            description: "Access Review",
            criticality: "High",
        },
        {
            id: 7,
            name: "ISO 27001:2022-A.12.2",
            description: "Information Handling and Storage",
            criticality: "Medium",
        },
        {
            id: 8,
            name: "ISO 27001:2022-A.13.1",
            description: "Network Security",
            criticality: "Critical",
        },
        {
            id: 9,
            name: "ISO 27001:2022-A.14.2",
            description: "Secure Development",
            criticality: "Medium",
        },
        {
            id: 10,
            name: "ISO 27001:2022-A.18.1",
            description: "Compliance with Legal and Regulatory Requirements",
            criticality: "High",
        },
    ],
    ccpa: [
        {
            id: 1,
            name: "CCPA - 1798.100",
            description: "Data Collection Limitation",
            criticality: "Critical",
        },
        {
            id: 2,
            name: "CCPA - 1798.105",
            description: "Notice to Consumers",
            criticality: "High",
        },
        {
            id: 3,
            name: "CCPA - 1798.110",
            description: "Right to Know - Data Access",
            criticality: "Critical",
        },
        {
            id: 4,
            name: "CCPA - 1798.115",
            description: "Non-Discrimination",
            criticality: "High",
        },
        {
            id: 5,
            name: "CCPA - 1798.120",
            description: "Right to Delete - Erasure",
            criticality: "Critical",
        },
        {
            id: 6,
            name: "CCPA - 1798.125",
            description: "Verification of Requests",
            criticality: "High",
        },
        {
            id: 7,
            name: "CCPA - 1798.130",
            description: "Right to Opt-Out",
            criticality: "High",
        },
        {
            id: 8,
            name: "CCPA - 1798.135",
            description: "Financial Incentives",
            criticality: "High",
        },
        {
            id: 9,
            name: "CCPA - 1798.140",
            description: "Minors' Rights",
            criticality: "Medium",
        },
        {
            id: 10,
            name: "CCPA - 1798.145",
            description: "Training and Record-Keeping",
            criticality: "Medium",
        },
    ],
};
