import { useState } from "react";
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Drawer, Fade, Typography } from "@mui/material";
import { GridLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Person, ShoppingCart } from "@mui/icons-material";
import UnderConstructionImage from "../../assets/images/under-construction.gif";
import { Products, getCodelist } from "../../constants/Codelist";
import { addItemToCart } from "../../services/CartService";
import { useAuthContext } from "../../hooks/useAuthContext";

export default function RecommendationTable() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { person } = useAuthContext();
    const theme = useTheme();
    const [panelPosition, setPanelPosition] = useState({ x: 0, y: 0 });
    const [showDetails, setShowDetails] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleSelectProduct = (event, product) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        console.log("x: " + x + " y: " + y);

        setSelectedProduct(product);
        setShowDetails(true);
    };

    const handleAddToCart = () => {
        console.log("Add to cart");
        const cartItem = {
            productId: selectedProduct?.id,
            productName: selectedProduct?.name,
            quantity: 1,
        }
        addItemToCart(person?.id, cartItem);
    }


    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1} mt={1}>
            {getCodelist(Products).map((product) => {
                return (
                    <Fade key={product.id} in={true} timeout={1000} unmountOnExit>
                        <Card elevation={4} sx={{ maxWidth: "32%", bgcolor: "#fff", p: 1 }} onClick={(event) => handleSelectProduct(event, product)}>
                            <CardHeader title={product.name} titleTypographyProps={{ variant: "subtitle" }} subheader={product.shortDescription} />

                            <CardMedia component="img" height="200" src={UnderConstructionImage} />
                            <CardActions
                                sx={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                    paddingRight: 2,
                                }}
                            ></CardActions>
                        </Card>
                    </Fade>
                );
            })}

            <Drawer variant="temporary" anchor="right" open={showDetails} onClose={() => setShowDetails(false)}>
                <Card elevation={0} sx={{ width: "30vw", bgcolor: "#fff", mt: 2, p: 2 }}>
                    <CardHeader
                        avatar={<Avatar sx={{ bgcolor: "primary.main" }}>{selectedProduct?.code}</Avatar>}
                        // action={
                        //     <IconButton aria-label="settings">
                        //         <MoreVert />
                        //     </IconButton>
                        // }
                        title={selectedProduct?.name}
                        titleTypographyProps={{ variant: "subtitle" }}
                        subheader={selectedProduct?.shortDescription}
                    />

                    <CardMedia component="img" height="200" src={UnderConstructionImage} />
                    <CardContent>{showDetails && <Typography color="text.secondary">{selectedProduct?.description}</Typography>}</CardContent>
                    <CardActions
                        sx={{
                            alignSelf: "stretch",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "end",
                            paddingRight: 2,
                        }}
                    >
                        <Button startIcon={<ShoppingCart />} variant="contained" color="secondary" onClick={handleAddToCart}>
                            Add to Cart
                        </Button>
                    </CardActions>
                </Card>
                <Typography variant="subtitle" p={2}>Recommended for you</Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap" sx={{ boxShadow: 2 }} gap={1} m={1}>
                    <Fade in={true} timeout={1000} unmountOnExit>
                        
                        <Card elevation={4} sx={{ maxWidth: "32%", bgcolor: "#fff", p: 1 }}>
                            <CardHeader
                                title="Steve Rodgers"
                                titleTypographyProps={{ variant: "subtitle" }}
                                subheader="Your AI Defender"
                                subheaderTypographyProps={{ fontSize: "0.8rem" }}
                            />
                            <CardContent>
                                <Avatar sx={{ bgcolor: "primary.main", width: 100, height: 100 }}>
                                    <Person sx={{ width: 80, height: 80 }} />
                                </Avatar>
                            </CardContent>
                            <CardActions
                                sx={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                    paddingRight: 2,
                                }}
                            >
                                <Button startIcon={<ShoppingCart />} variant="contained" color="secondary">
                                    Add
                                </Button>
                            </CardActions>
                        </Card>
                    </Fade>
                    <Fade in={true} timeout={1000} unmountOnExit>
                        <Card elevation={4} sx={{ maxWidth: "32%", bgcolor: "#fff", p: 1 }}>
                            <CardHeader
                                title="Tony Stark"
                                titleTypographyProps={{ variant: "subtitle" }}
                                subheader="Chief Security Officer"
                                subheaderTypographyProps={{ fontSize: "0.8rem" }}
                            />
                            <CardContent>
                                <Avatar sx={{ bgcolor: "primary.main", width: 100, height: 100 }}>
                                    <Person sx={{ width: 80, height: 80 }} />
                                </Avatar>
                            </CardContent>
                            <CardActions
                                sx={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                    paddingRight: 2,
                                }}
                            >
                                <Button startIcon={<ShoppingCart />} variant="contained" color="secondary">
                                    Add
                                </Button>
                            </CardActions>
                        </Card>
                    </Fade>
                    <Fade in={true} timeout={1000} unmountOnExit>
                        <Card elevation={4} sx={{ maxWidth: "32%", bgcolor: "#fff", p: 1 }}>
                            <CardHeader
                                title="T'Challa"
                                titleTypographyProps={{ variant: "subtitle" }}
                                subheader="Offshore Coordinator"
                                subheaderTypographyProps={{ fontSize: "0.8rem" }}
                            />
                            <CardContent>
                                <Avatar sx={{ bgcolor: "primary.main", width: 100, height: 100 }}>
                                    <Person sx={{ width: 80, height: 80 }} />
                                </Avatar>
                            </CardContent>
                            <CardActions
                                sx={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                    paddingRight: 2,
                                }}
                            >
                                <Button startIcon={<ShoppingCart />} variant="contained" color="secondary">
                                    Add
                                </Button>
                            </CardActions>
                        </Card>
                    </Fade>
                </Box>
            </Drawer>
        </Box>
    );
}
