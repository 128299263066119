import { Box, Typography } from "@mui/material";
import React from "react";

export default function Workspace({ children }) {
    return (
        <Box
            p={0.5}
            bgcolor="#f9f9f9"
            display="flex"
            flexDirection="row"
            flexGrow={1}
            justifyContent="center"
            alignItems="top"
            sx={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        >
            {children}
        </Box>
    );
}
