import { Box, Button, Card, CardContent, CardHeader, Divider, List, ListItem, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { Permissions } from "../../samples/permissions";
import { Add, Close } from '@mui/icons-material';
import { findAllPermissions, savePermission } from '../../services/PermissionService';
import { useAuthContext } from '../../hooks/useAuthContext';

export default function PermissionKB( {toggleView}) {
  const { person } = useAuthContext()
  const [permissions, setPermissions] = useState([])

  const handleClose = () => {
    toggleView (false)
  }

  const handleAdd = async (permission) => {
    console.log("Add permission: ", permission)
    await savePermission(person.uid, person.tenant.id, permission)
    // Remove it from the list 
    const newPermissions = permissions.filter (p => p.id !== permission.id)
    setPermissions(newPermissions)
  }

  useEffect(() => {
    //Get the permissions for the tenant 
    setPermissions(Permissions)

  }, [])
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
    <Card >
        <CardHeader
            title="Permissions"
            titleTypographyProps={{ color: "primary.main", variant: "headline" }}
            subheader="Commonly used permissions"
            action={
                <Button variant='contained' startIcon={<Close />} onClick={handleClose} color="info">
                    Close
                </Button>
            }
        />
        <Divider />
        <CardContent>
          <List>
            {permissions && permissions.map((permission, index) => (
              <ListItem key={index} 
                secondaryAction={
                  <Button variant='outlined' startIcon={<Add />} onClick={() => handleAdd (permission)} color="info">
                    Add
                  </Button>
                }
              >
                <ListItemText primary={permission.name} secondary={permission.description} />
                                
              </ListItem>
            ))}
            

            
          </List>
        </CardContent>
        
    </Card>
</Box>
  )
}
