import { useState } from "react";
import { auth } from "../config/FirebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import { loadUserDetail } from "../services/UserService";
import { translateAuthCodeToMessage } from "../utils/AuthenticationUtils";
import { useNavigate } from "react-router-dom";


export const useLogin = () => {
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const { dispatch } = useAuthContext();
    const navigate = useNavigate()

    const login = async (email, password, code, from) => {
        setError(null);
        setIsPending(true);

            signInWithEmailAndPassword(auth, email, password)
            .then(async (res) => {
                const user = res.user;
                console.log("User Signed in: ", user.uid);

                const detail = await loadUserDetail(res.user.uid, email);
                await dispatch({
                    type: "LOGIN",
                    payload: res.user,
                    person: detail.person,
                    profile: detail.profile,
                });
                setError(null);
                setIsPending(false);

                if (code) {
                    navigate(`/`);
                } 
                else if (from) {
                    navigate(from);
                }
            })
            .catch((err) => {
                // User creation failed
                console.error("Error creating user:", err.code, err.message);
                var msg = translateAuthCodeToMessage(err.code);
                setError(msg);
                setIsPending(false);
            });
            
    };
    return { login, error, isPending };
};

