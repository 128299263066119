import { onAuthStateChanged } from "firebase/auth";
import { React, createContext, useEffect, useReducer, useRef } from "react";
import { auth } from "../config/FirebaseConfig";
import { loadUserDetail } from "../services/UserService";
import { findPersonByUserId } from "../services/PersonService";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
    //console.log("authReducer: ", state, action);
    switch (action.type) {
        case "LOGIN":
            return { ...state, user: action.payload, person: action.person, profile: action.profile};
        case "LOGOUT":
            return { ...state, user: null, person: null, profile: null };
        case "AUTH_IS_READY":
            return {
                user: action.payload,
                authIsReady: true,
                person: action.person,
                profile: action.profile,
            };
        case "PERSON_CHANGED":
            return {
                ...state,
                person: action.person,
            };
        case "PROFILE_CHANGED":
            console.log("Profile Changed: ", action.profile);
            return {
                ...state,
                profile: action.profile,
            };
        
        default:
            return state;
    }
};

export const AuthContextProvider = ({ children, effect }) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        person: null,
        profile: null,
        authIsReady: false,
    });

    const effectCalled = useRef(false);

    useEffect(() => {
        const unsub = onAuthStateChanged(auth, async (user) => {
            // only execute the effect first time around
            if (!effectCalled.current) {
                effectCalled.current = true;

                // Set the person with only id for new profiles
                if (user) {
                    const person = await findPersonByUserId(user.uid);
                    //console.log("Person: ", person);
                    loadUserDetail(user.uid, user.email, person?.firstName, person?.lastName, person?.id).then((detail) => {
                        dispatch({
                            type: "AUTH_IS_READY",
                            payload: user,
                            person: detail.person,
                            profile: detail.profile,
                        });
                    });
                }
            }

            dispatch({
                type: "AUTH_IS_READY",
                payload: user,
                person: state.person,
                profile: state.profile,
            });

            return () => unsub();
        });
    }, []);

    // console.log("AuthContext state:", state);

    return <AuthContext.Provider value={{ ...state, dispatch }}>{children}</AuthContext.Provider>;
};
