import { Box } from '@mui/material';
import React, { useState } from 'react'
import ReactFlow, {Panel} from 'reactflow'
import 'reactflow/dist/style.css';
import UserCard from './UserCard';
import RoleCard from './RoleCard';
import ApplicationCard from './GroupCard';
import EntitlementCard from './EntitlementCard';
import ApplicationRoleCard from './ApplicationRoleCard';

export default function AccessTab() {
  const initialNodes = [
    {
      id: 'user',
      data: { label: <UserCard />},
      position: { x: 400, y: 100 },
      style: { border: '0px'},
    },
  
    {
      id: 'role',
      data: { label: <RoleCard />},
      position: { x: 800, y: 50 },
      style: { border: '0px'},
    },
    {
      id: 'entitlement',
      data: { label: <EntitlementCard /> },
      position: { x: 800, y: 300 },
      style: { border: '0px'},
    },
    {
      id: 'application',
      data: { label: <ApplicationCard /> },
      position: { x: 0, y: 50 },
      style: { border: '0px'},
    },
    {
      id: 'appRole',
      data: { label: <ApplicationRoleCard /> },
      position: { x: 0, y: 300 },
      style: { border: '0px'},
    },
    
  ];
  
  const initialEdges = [
    { id: 'user-role', source: 'user', target: 'role', type: 'simplebezier' },
    { id: 'user-entitlement', source: 'user', target: 'entitlement', type: 'simplebezier' },
    { id: 'user-application', source: 'user', target: 'application', type: 'simplebezier' },
    { id: 'user-approle', source: 'user', target: 'appRole', type: 'simplebezier' },
    
  ];
  const defaultEdgeOptions = { animated: true };
  const proOptions = { hideAttribution: true };

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  return (
      <Box width="1200px" height="600px">
        <ReactFlow nodes={nodes} edges={edges} defaultEdgeOptions={defaultEdgeOptions} proOptions={proOptions}/>
      </Box>
  )
}
