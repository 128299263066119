import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc } from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { updateAudit } from "../utils/utils";

// Create a new asset
export const createAsset = async (uid, asset) => {
    const docRef = doc(collection(db, DBConstants.DB_ASSETS));
    asset.id = docRef.id;
    updateAudit(uid, asset);
    await setDoc(docRef, asset, { merge: true });
    return await findAsset(asset.id);
};

// update an existing asset
export const updateAsset = async (uid, asset) => {
    updateAudit(uid, asset);
    const docRef = doc(db, DBConstants.DB_ASSETS, asset.id);
    await setDoc(docRef, { ...asset }, { merge: true });
    return await findAsset(asset.id);
};

// Create a new asset or update an existing one
export const saveAsset = async (uid, tenantId, asset) => {
    asset.tenantId = tenantId;
    if (asset.id) {
        return await updateAsset(uid, asset);
    } else {
        return await createAsset(uid, asset);
    }
};

// Delete an asset
export const deleteAsset = async (assetId) => {
    // Delete the asset
    const docRef = doc(db, DBConstants.DB_ASSETS, assetId);
    await deleteDoc(docRef);
};

// Find an asset with id
export const findAsset = async (id) => {
    const docRef = doc(db, DBConstants.DB_ASSETS, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
};

// Find all assets for a tenant
export const findAllAssets = async (tenantId) => {
    let results = [];
    const q = query(
        collection(db, DBConstants.DB_ASSETS)
        //where("tenantId", "==", tenantId)
    );
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

