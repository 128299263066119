import { Box, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { React } from "react";
import { Link } from "react-router-dom";
import NotFoundImage from "../assets/images/not-found.gif";

export default function NotFound() {

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="headline">
                We could not find your page
            </Typography>
            <Box pt={4}>
                <img src={NotFoundImage} alt="Page Not Found" width="{400}" height={400}/>
            </Box>
        </Box>
    );
}
