import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid } from "@mui/material";
import { GridLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import * as Authority from "../../constants/Authorities";
import { checkPermission } from "../../utils/AuthenticationUtils";
import StatCard from "./StatCard";
import { Accessibility, Person, Policy, Reply, Reviews, School } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { countPeopleByTenantId } from "../../services/PersonService";


export default function Dashboard( {refresh} ) {
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const { user, person } = useAuthContext();

    const [stats, setStats] = useState({
        numberOfAssessments: 0,
        numberOfPolicies: 0,
        numberOfControls: 0,
        numberOfCertifications: 0,
        numberOfUsers: 0,
    });

    useEffect(() => {
        async function getStats() {
            setLoading(true);
            let users = await countPeopleByTenantId(person?.tenant?.id, [])
            //console.log ('Users: ', users)
            setStats({
                numberOfAssessments: 10,
                numberOfPolicies: 20,
                numberOfControls: 80,
                numberOfCertifications: 2,
                numberOfUsers: users ,
            })
            // setTimeout(() => {
            //     // Calculate the counts and then show the values
                
            //     setStats({
            //         numberOfAssessments: 10,
            //         numberOfPolicies: 20,
            //         numberOfControls: 80,
            //         numberOfCertifications: 2,
            //         numberOfUsers: users ,
            //     });
            // }, 1000);
            setLoading(false);
        }

        if (person) {
            setTimeout(() => {
            getStats();
            }, 1000);
        }
    }, [refresh]);

    const handleBack = () => {
        navigate(-1);
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }}>
            <Card>
                <CardHeader
                    title="Dashboard"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="Your Central Hub for Real-Time Insights and Actionable Data"
                    action={
                        <Button
                            startIcon={<Reply />}
                            //onClick={handleBack}
                            color="info"
                        >
                            Back
                        </Button>
                    }
                />
                <Divider />
                <CardContent sx={{ position: 'relative'}}>
                    <Grid container spacing={1}>
                        {person && checkPermission(person.role, Authority.PERMISSION_ADMIN_ALL) && (
                            <Grid item xs={12} sm={6} md={3}>
                                <StatCard
                                    type="fill"
                                    title="Assessments"
                                    value={stats && stats.numberOfAssessments}
                                    icon={<Reviews color="secondary" />}
                                    color="primary.main"
                                />
                            </Grid>
                        )}
                        {person && checkPermission(person.role, Authority.PERMISSION_ADMIN_ALL) && (
                            <Grid item xs={12} sm={6} md={3}>
                                <StatCard
                                    type="fill"
                                    title="Policies"
                                    value={stats && stats.numberOfPolicies}
                                    icon={<Policy color="secondary" />}
                                    color="secondary.main"
                                />
                            </Grid>
                        )}
                        {person && checkPermission(person.role, Authority.PERMISSION_ADMIN_ALL) && (
                            <Grid item xs={12} sm={6} md={3}>
                                <StatCard
                                    type="fill"
                                    title="Controls"
                                    value={stats && stats.numberOfControls}
                                    icon={<Accessibility color="secondary" />}
                                    color="primary.main"
                                />
                            </Grid>
                        )}
                        {person && checkPermission(person.role, Authority.PERMISSION_ADMIN_ALL) && (
                            <Grid item xs={12} sm={6} md={3}>
                                <StatCard
                                    type="fill"
                                    title="Certifications"
                                    value={stats && stats.numberOfCertifications}
                                    icon={<School color="secondary" />}
                                    color="secondary.main"
                                />
                            </Grid>
                        )}
                        {person && checkPermission(person.role, Authority.PERMISSION_ADMIN_ALL) && (
                            <Grid item xs={12} sm={6} md={3}>
                                <StatCard
                                    type="fill"
                                    title="Users"
                                    value={stats && stats.numberOfUsers}
                                    icon={<Person color="secondary" />}
                                    color="secondary.main"
                                />
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
}
