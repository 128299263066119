import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React from "react";

export default function RequestList() {
    const loop = Array.from({ length: 10 }, (_, index) => index + 1);

    return (
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
            {/* Display requests in a box format */}
            {loop.map((number) => (
            <Box display="flex" flexDirection="column" sx={{ width: "32%", bgcolor: "white", p: 1, gap: 1, boxShadow: 2 }}>
                <Stack direction="row" justifyContent="space-evenly" alignItems="center" gap={1} mb={1}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="smalltitle" gutterBottom>
                            Request Id
                        </Typography>
                        <Typography variant="smallbody">1000-1001</Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box display="flex" flexDirection="column">
                        <Typography variant="smalltitle" gutterBottom>
                            Date Submitted
                        </Typography>
                        <Typography variant="smallbody">10/01/2023</Typography>
                    </Box>
                    
                    <Button size="small" variant="contained" sx={{ borderRadius: 8 }}>
                        Pending
                    </Button>
                </Stack>
                <Stack direction="row" justifyContent="space-evenly" alignItems="center" gap={1} mb={1} sx={{ bgcolor: "#C5CAE9" }}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="smalltitle" gutterBottom>
                            Target User
                        </Typography>
                        <Typography variant="smallbody" >Jonathan Swift</Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box display="flex" flexDirection="column">
                        <Typography variant="smalltitle" gutterBottom>
                            Requester
                        </Typography>
                        <Typography variant="smallbody">Peter Parker</Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box display="flex" flexDirection="column">
                        <Typography variant="smalltitle" gutterBottom>
                            Request Type
                        </Typography>
                        <Typography variant="smallbody">Assign Role</Typography>
                    </Box>
                </Stack>
            </Box>
            ))}
        </Box>
    );
}
