import { useEffect, useState } from "react";
import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Fade, Stack, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { GridLoader } from "react-spinners";
import { openai } from "../../config/OpenAIConfig";
import { AI_ROLE_SYSTEM, AI_ROLE_USER } from "../../constants/AIConstants";
import { useAuthContext } from "../../hooks/useAuthContext";
import { getAttributeCSV } from "../../utils/utils";
import { ASSESSMENT_TYPE_REGULATION } from "../../constants/AssessmentConstants";
import { deleteAssessmentResultByAssessmentId, findRegulationAssessmentResult, saveAssessmentResult } from "../../services/AssessmentResultService";


export default function RegulationTable({ assessment, auditor, mod }) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const { person } = useAuthContext();
    const [regulations, setRegulations] = useState([]);

    useEffect(() => {
        async function getSavedRegulations() {
            const saved = await findRegulationAssessmentResult(assessment?.id);
            setRegulations(saved?.regulations ?? []);
        }

        getSavedRegulations();
    }, []);



    const getRequiredIcon = (compliance) => {
        let color = "error.light";

        switch (compliance) {
            case "Required":
                color = "error.light";
                break;
            case "Optional":
                color = "info.light";
                break;
            case "Low":
            default:
                color = "error.light";
        }
        return <Typography sx={{ color: color, fontWeight: "bold", fontSize: "0.9rem" }}>{compliance}</Typography>;
    };


    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
            <Typography variant="subtitle" mt={4}>
                Analyzing Regulations...
            </Typography>
        </Box>
    ) : (
        <Fade in={true} timeout={1000} unmountOnExit>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1} mt={1} p={1}>
                    {regulations.map((regulation) => {
                        return (
                            <Card key={regulation.id} elevation={8} sx={{ width: "32%", bgcolor: "#fff" }}>
                                <CardHeader
                                    display="flex"
                                    title={regulation.abbreviation}
                                    titleTypographyProps={{ variant: "subtitle" }}
                                    subheader={regulation.displayName}
                                    subheaderTypographyProps={{ fontSize: "0.8rem" }}
                                    sx={{ textAlign: "center" }}
                                    action={getRequiredIcon(regulation.compliance ?? "")}
                                />
                                <CardContent>
                                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" gap={8}>
                                        <Box display="flex" flexDirection="column" flexGrow={1}>
                                            <Stack direction="row" justifyContent="space-between" sx={{ color: "primary.main" }}>
                                                <Typography variant="body" color="inherit">
                                                    Security Controls
                                                </Typography>
                                                <Typography variant="subtitle" color="inherit" sx={{ textDecoration: "underline" }} gutterBottom>
                                                    10
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" justifyContent="space-between" sx={{ color: "primary.main" }}>
                                                <Typography variant="body" color="inherit">
                                                    Recommendations
                                                </Typography>
                                                <Typography variant="subtitle" color="inherit" sx={{ textDecoration: "underline" }} gutterBottom>
                                                    2
                                                </Typography>
                                            </Stack>
                                        </Box>
                                        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" flexShrink={0}>
                                            <Avatar
                                                sx={{
                                                    width: 60,
                                                    height: 60,
                                                    bgcolor: "secondary.main",
                                                    color: "primary.main",
                                                    fontSize: "0.9rem",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {regulation.abbreviation}
                                            </Avatar>
                                        </Box>
                                    </Box>
                                </CardContent>
                                {/* <Divider /> */}
                                {/* <CardActions>
                                    <Typography variant="body" color="grey">
                                        {regulation.description}
                                    </Typography>
                                </CardActions> */}
                            </Card>
                        );
                    })}
                </Box>

                <Box
                    m={1}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                    }}
                >
                </Box>
            </Box>
        </Fade>
    );
}
