import { Alert, Box, Button, Card, CardContent, CardHeader } from "@mui/material";

import { React, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogout } from "../../hooks/useLogout";

export default function DeleteAccount() {
    const navigate = useNavigate();
    const { logout } = useLogout();
    const { person } = useAuthContext();

    const [error, setError] = useState(null);
    const [info, setInfo] = useState(null);

    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: "",
    });
    const [errors, setErrors] = useState({
        password: "",
        confirmPassword: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Prompt warning");
        setTimeout(() => {
            setInfo("");
            logout();
        }, 3000);
    };

    return (

            <Card>
                <CardHeader
                    subheader="Danger: Choosing to Delete My Account will result in the permanent removal of all your data and information from our platform. Please be aware that this action cannot be undone."
                    subheaderTypographyProps={{ color: "warning.main", variant: "subtitle" }}
                />
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                gap: 1,
                                marginTop: 4,
                            }}
                        >
                            <Button variant="contained" type="submit" color="error">
                                Delete My Account
                            </Button>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                marginTop: 2,
                            }}
                        >
                            {error && <Alert severity="error">{error}</Alert>}
                            {info && <Alert severity="info">{info}</Alert>}
                        </Box>
                    </form>
                </CardContent>
            </Card>
   
    );
}
