import { Box } from "@mui/material";
import React from "react";

export default function Footer() {
    return (
        <Box bgcolor='black' py={1} pl={2} color={'warning.main'}>
          <div>© {new Date().getFullYear()} Cybercodee, LLC, All rights reserved</div>
        </Box>
      )
}
