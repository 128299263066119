import { addYears, format, parseISO } from "date-fns";
import * as Constants from "../constants/Constants";

// Capitalize
export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// Capitalize and underscore Ex: Hello World ==> Hello_World
export function capitalizeUnderscore(string) {
    const formatted =  string.trim().replace(/\s+/g, "_");
    return formatted.toUpperCase();
}

// Format price
export function formatPrice(number) {
    const fnumber = parseFloat(number);
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(fnumber);
}

// Concat name
export function personName(p) {
    let name = "";
    if (p && p.firstName) name = name.concat(p.firstName);
    if (p && p.lastName) name = name.concat(" ", p.lastName);
    return name;
}

export function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name) {
    console.log("name: " + name);
    let avtStr = "";
    if (!name) {
        avtStr = "";
    } else if (!name.includes(" ")) {
        // Return the first letters if name doesnt have any spaces
        avtStr = name.substring(0, 1).toUpperCase();
    } else {
        avtStr = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
    }

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: avtStr,
    };
}

export function generateAcronym(inputString) {
    //console.log ("inputString: " + inputString)
    const words = inputString.split(" ");
    const acronym = words.map((word) => word[0].toUpperCase()).join("");
    return acronym;
}

export function updateAudit(uid, obj) {
    if (!obj) return;

    if (!obj.createdBy) {
        obj["createdBy"] = uid;
        obj["createdDate"] = new Date();
    }
    obj["updatedBy"] = uid;
    obj["updatedDate"] = new Date();
}

export function noAudit(obj) {
    const clean = removeJsonAttributes(obj, ["createdBy", "createdDate", "updatedBy", "updatedDate"]);
    return clean;
}

export function formatDate(dt) {
    return format(dt.toDate(), Constants.DATE_FORMAT, { awareOfUnicodeTokens: true });
}

export function formatDateTime(dt) {
    return format(dt.toDate(), Constants.DATETIME_FORMAT, { awareOfUnicodeTokens: true });
}

// export function calculateSubscriptionEnd (dt, years) {
//     const start = new Date(dt.toDate())
//     const end = addYears(start, years);
//     console.log ('end: ' + end)
//     return end;
// }

// Removes null and empty values in a JSON object
export const removeEmptyJsonValues = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (value !== null && value !== "" && value !== undefined) {
            if (typeof value === "object") {
                const nestedObj = removeEmptyJsonValues(value);
                if (Object.keys(nestedObj).length !== 0) {
                    acc[key] = nestedObj;
                }
            } else {
                acc[key] = value;
            }
        }
        return acc;
    }, {});
};

// Remove a set of attributes from a JSON Object
export const removeJsonAttributes = (obj, attributes) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (!attributes.includes(key)) {
            if (value !== null && value !== "" && value !== undefined && typeof value === "object") {
                const nestedObj = removeJsonAttributes(value, attributes);
                if (Object.keys(nestedObj).length !== 0) {
                    acc[key] = nestedObj;
                }
            } else {
                acc[key] = value;
            }
        }
        return acc;
    }, {});
};

// Include only the set of attributes from a JSON Object
export const includeJsonAttributes = (obj, attributes) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (attributes.includes(key)) {
            if (typeof value === "object") {
                const nestedObj = includeJsonAttributes(value, attributes);
                if (Object.keys(nestedObj).length !== 0) {
                    acc[key] = nestedObj;
                }
            } else {
                acc[key] = value;
            }
        }
        return acc;
    }, {});
};

// Get only the "id" and "name" attributes from a JSON Object
export const getIdName = (obj) => {
    return includeJsonAttributes(obj, ["id", "name"]);
};

//Generates a numeric/ alphanumeric code of length 'totalLength',
// converts it to uppercase, and adds a hyphen every splitLength characters:
export const generateVariableVerificationCode = (characters, totalLength, splitLength) => {
    let code = "";

    for (let i = 0; i < totalLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        code += characters.charAt(randomIndex);
    }

    // Convert the code to uppercase
    code = code.toUpperCase();
    // Insert hyphens every `splitLength` characters
    code = code.replace(new RegExp(`(.{${splitLength}})`, "g"), "$1-");
    // Remove the trailing hyphen
    code = code.slice(0, -1);
    return code;
};

// Generates a verification code
export const generateVerificationCode = () => {
    return generateVariableVerificationCode(
        Constants.VERIFICATION_CODE_NUMERIC,
        Constants.VERIFICATION_CODE_LENGTH,
        Constants.VERIFICATION_CODE_SPLIT
    );
};

// Generate a random integer
export const generateRandomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

// check if an id exists in a list of objects. Each object is assumed to have an attribute called 'id'
export const checkIdExists = (array, idToCheck) => {
    return array?.some((item) => item.id === idToCheck);
};

// Get an object with id in a list of objects. Each object is assumed to have an attribute called 'id'
export const getObjectById = (array, idToFind) => {
    return array.find((item) => item.id === idToFind);
};

// Get the index of object with id in a list of objects. Each object is assumed to have an attribute called 'id'
export const getIndexById = (array, idToFind) => {
    return array.findIndex((item) => item.id === idToFind);
};

// Delete an object with id in a list of objects. Each object is assumed to have an attribute called 'id'
export const deleteObjectById = (array, idToDelete) => {
    return array.filter((item) => item.id !== idToDelete);
};

// Sort objects in a list with the attribute named "asc". Add parameter to order in ascending or descending order
export const sortObjectsByOrder = (array, asc) => {
    return array.sort((a, b) => (a.order > b.order ? asc : -asc));
};

export const getDefaultCode = (array) => {
    return array.find((item) => item.default === true) ?? 1;
};

export const getAttributeCSV = (array, attribute) => {
    return array.map((item) => item[attribute]).join(", ");
};

export const getAttributeList = (array, attribute) => {
    return array.map((item) => item[attribute]);
};
