import { Box, Checkbox, Fade, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React from "react";
import { ASSESSMENT_EXISTING_IAM_SOLUTION, ASSESSMENT_EXISTING_IAM_SOLUTION_DETAILS, ASSESSMENT_IAM_TEAM_SIZE, ASSESSMENT_RISK_MANAGEMENT_TEAM, IAM_TEAM_SIZE } from "./AssessmentQuestions";
import { IAMSolutionsTypes, IAMTeamSizeCodes, YesNoCodes, getCodelist } from "../../constants/Codelist";
import { checkIdExists, deleteObjectById } from "../../utils/utils";

export default function TeamsEditForm({ assessment, updateAssessment }) {

    const handleRiskTeamChange = (code) => {
        let newAssessment = { ...assessment };
        newAssessment.dedicatedRiskTeam = code;
        updateAssessment(newAssessment);
    };

    const handleIAMTeamSizeChange = (size) => {
        let newAssessment = { ...assessment };
        newAssessment.iamTeamSize = size;
        updateAssessment(newAssessment);
    };

    const handleExistingIAMChange = (code) => {
        let newAssessment = { ...assessment };
        newAssessment.haveIAMSolution = code;
        updateAssessment(newAssessment);
    };

    const handleIAMSolutionTypeChange = (type) => {
        let newAssessment = { ...assessment };
        newAssessment.iamSolutionType = type;
        updateAssessment(newAssessment);
    };


    return (
        <Box display="flex" flexDirection="column" pl={4}>
            <Typography variant="headline" mb={4}>
                {ASSESSMENT_RISK_MANAGEMENT_TEAM}
            </Typography>
            <Fade in={true} timeout={1000} unmountOnExit>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                    {getCodelist(YesNoCodes).map((code) => {
                        return (
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent={"center"}
                                key={code.id}
                                width="12%"
                                p={1}
                                gap={1}
                                sx={{ boxShadow: 1, bgcolor: assessment?.dedicatedRiskTeam.id === code.id ? "secondary.main" : "#eee" }}
                                textAlign="center"
                                onClick={() => handleRiskTeamChange(code)}
                            >
                                <Box sx={{ boxShadow: 0 }} display="flex" justifyContent="center" alignItems="center">
                                    <IconButton sx={{ color: 'black'}}>{code.image}</IconButton>
                                </Box>
                                <Box textAlign="center">
                                    <Typography variant="subtitle" color={(assessment?.dedicatedRiskTeam.id === code.id) ? "white" : "primary.main"}>
                                        {code.name}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {code.description}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Fade>


            <Box pt={8}></Box>
            <Typography variant="headline" mb={4}>
                {ASSESSMENT_IAM_TEAM_SIZE}
            </Typography>
            <Fade in={true} timeout={1000} unmountOnExit>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                    {getCodelist(IAMTeamSizeCodes).map((size) => {
                        return (
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent={"center"}
                                key={size.id}
                                width="12%"
                                p={1}
                                gap={1}
                                sx={{ boxShadow: 1, bgcolor: assessment?.iamTeamSize.id === size.id ? "secondary.main" : "#eee" }}
                                textAlign="center"
                                onClick={() => handleIAMTeamSizeChange(size)}
                            >
                                <Box textAlign="center">
                                    <Typography variant="subtitle" color={(assessment?.iamTeamSize.id === size.id) ? "white" : "primary.main"}>
                                        {size.name}
                                    </Typography>
                                    <Typography variant="body2" color={(assessment?.iamTeamSize.id === size.id) ? "white" : "primary.main"} gutterBottom>
                                        {size.description}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Fade>
            
            
            <Box pt={8}></Box>
            <Typography variant="headline" mb={4}>
                {ASSESSMENT_EXISTING_IAM_SOLUTION}
            </Typography>
            <Fade in={true} timeout={1000} unmountOnExit>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                    {getCodelist(YesNoCodes).map((code) => {
                        return (
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent={"center"}
                                key={code.id}
                                width="12%"
                                p={1}
                                gap={1}
                                sx={{ boxShadow: 1, bgcolor: assessment?.haveIAMSolution.id === code.id ? "secondary.main" : "#eee" }}
                                textAlign="center"
                                onClick={() => handleExistingIAMChange(code)}
                            >
                                <Box sx={{ boxShadow: 0 }} display="flex" justifyContent="center" alignItems="center">
                                    <IconButton sx={{ color: 'black'}}>{code.image}</IconButton>
                                </Box>
                                <Box textAlign="center">
                                    <Typography variant="subtitle" color={(assessment?.haveIAMSolution.id === code.id) ? "white" : "primary.main"}>
                                        {code.name}
                                    </Typography>
                                    
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Fade>

            {assessment?.haveIAMSolution?.name === "Yes" && (
                <>
                    <Box pt={4}></Box>
                    <Typography variant="headline" mb={4}>
                {ASSESSMENT_EXISTING_IAM_SOLUTION_DETAILS}
            </Typography>
            <Fade in={true} timeout={1000} unmountOnExit>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                    {getCodelist(IAMSolutionsTypes).map((type) => {
                        return (
                            <Box
                                key={type.id}
                                width="15%"
                                flexDirection="column"
                                p={1}
                                sx={{ boxShadow: 1, bgcolor: (assessment?.iamSolutionType.id === type.id) ? "secondary.main" : "#eee" }}
                                textAlign="center"
                                onClick={() => handleIAMSolutionTypeChange(type)}
                            >
                                <Box sx={{ boxShadow: 0 }} display="flex" justifyContent="center" alignItems="center">
                                    <IconButton sx={{ color: "primary.main" }}>{type.image}</IconButton>
                                </Box>

                                <Box textAlign="center">
                                    <Typography variant="subtitle" color={(assessment?.iamSolutionType.id === type.id) ? "white" : "primary.main"} gutterBottom>
                                        {type.name}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Fade>


                    {/* <Typography variant="headline">{ASSESSMENT_EXISTING_IAM_SOLUTION_DETAILS}</Typography>
                    <Box display="flex" flexWrap="wrap">
                        {getCodelist(IAMSolutionsTypes).map((code) => {
                            return (
                                <Box key={code.id} width="25%">
                                    <RadioGroup
                                        name="iamSolutionType"
                                        value={assessment?.iamSolutionType?.id}
                                        onChange={handleIAMSolutionTypeChange(code.id)}
                                    >
                                        <FormControlLabel key={code.id} value={code.id} control={<Radio />} label={code.name} />
                                    </RadioGroup>
                                </Box>
                            );
                        })}
                    </Box> */}
                </>
            )}
        </Box>
    );
}
