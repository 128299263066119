import { addDoc, and, collection, deleteDoc, doc, getDoc, getDocs, limit, or, orderBy, query, setDoc, where } from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { updateAudit } from "../utils/utils";
import { IAMCapabilityCategories, ProgramGovernanceAreas, getIAMCodelist } from "../constants/AssessmentConstants";

// Create a new assessment
export const createAssessment = async (uid, assessment) => {
    const docRef = doc(collection(db, DBConstants.DB_ASSESSMENTS));
    assessment.id = docRef.id;
    updateAudit(uid, assessment);
    await setDoc(docRef, assessment, { merge: true });
    return await findAssessment(assessment.id);
};

// update an existing assessment
export const updateAssessment = async (uid, assessment) => {
    updateAudit(uid, assessment);
    const docRef = doc(db, DBConstants.DB_ASSESSMENTS, assessment.id);
    await setDoc(docRef, { ...assessment }, { merge: true });
    return await findAssessment(assessment.id);
};

// Create a new assessment or update an existing one
export const saveAssessment = async (uid, tenantId, assessment) => {
    console.log ("Save assessment: ", assessment)
    assessment.tenantId = tenantId;
    if (assessment.id) {
        return await updateAssessment(uid, assessment);
    } else {
        return await createAssessment(uid, assessment);
    }
};

// Delete an assessment
export const deleteAssessment = async (uid, assessment) => {
    // Delete the assessment
    const docRef = doc(db, DBConstants.DB_ASSESSMENTS, assessment.id);
    await deleteDoc(docRef);
};

// Find an assessment with id
export const findAssessment = async (id) => {
    const docRef = doc(db, DBConstants.DB_ASSESSMENTS, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
};

// Find all assessments for a tenant
export const findAllAssessments = async (tenantId) => {
    let results = [];
    const q = query(
        collection(db, DBConstants.DB_ASSESSMENTS)
        //where("tenantId", "==", tenantId)
    );
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

export const findLatestAssessmentByTenant = async (tenantId) => {
    console.log("Find assessment for tenant: ", tenantId);
    let results = [];
    const q = query(collection(db, DBConstants.DB_ASSESSMENTS), where("tenantId", "==", tenantId), orderBy("createdDate", "desc"), limit(1));
    // Get the first matching document if found
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results.length > 0 ? results[0] : null;
};


export const getAllIAMCapabilities = async () => {
    const categories = getIAMCodelist(IAMCapabilityCategories);
    // for each category, get the capabilities
    categories.forEach((category) => {
        category.expanded = true;
        const capabilities = getIAMCodelist(category.code);
        category.capabilities = capabilities;
        capabilities.forEach((capability) => {
            capability.selected = false;
        });
    });
    return categories;
};

export const flattenIAMCapabilities = async () => {
    const categories = getIAMCodelist(IAMCapabilityCategories);
    let capabilities = [];
    // for each category, get the capabilities
    categories.forEach((category) => {
        const caps = getIAMCodelist(category.code);
        caps.forEach((capability) => {
            const cap = {
                id: `${category.id}-${capability.id}`,
                categoryId: category.id,
                categoryName: category.name,
                capabilityId: capability.id,
                capabilityName: capability.name,
            };
            capabilities.push(cap);
        });
    });
    return capabilities
};

// Get the list of capabilities not selected for the assessment
export const getUnselectedCapabilities = async (assessment) => {
    const capabilities = await flattenIAMCapabilities();
    console.log ('All capabilities: ', capabilities)
    const selectedCapabilities = assessment?.capabilityCategories;
    console.log ('Selected capabilities: ', selectedCapabilities)
    const unselectedCapabilities = capabilities.filter((capability) => !selectedCapabilities.includes(capability));
    console.log ('Unselected capabilities: ', unselectedCapabilities)
    return unselectedCapabilities;
}

// Get a list of governance areas not selected for the assessment. 
export const getUnselectedGovernanceAreas = async (assessment) => {
    const governanceAreas = getIAMCodelist(ProgramGovernanceAreas);
    const selectedGovernanceAreas = assessment?.governanceCategories;
    const unselectedGovernanceAreas = governanceAreas.filter((governanceArea) => !selectedGovernanceAreas.includes(governanceArea));
    return unselectedGovernanceAreas;
}