import { Avatar, Box, Checkbox, Fade, FormControlLabel, Grid, Icon, IconButton, Menu, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { IndustryCodes, OTHER_ID, OrganizationSizeCodes, RegionCodes, getCodelist } from "../../constants/Codelist";
import { set } from "date-fns";
import { checkIdExists, deleteObjectById, getIdName, getObjectById } from "../../utils/utils";
import { ASSESSMENT_INDUSTRY, ASSESSMENT_ORG_SIZE, ASSESSMENT_REGION } from "./AssessmentQuestions";
import { AccountBalance } from "@mui/icons-material";

export default function BusinessContextForm({ assessment, updateAssessment }) {
    // Select one or more industries
    const handleIndustryChange = (industry) => {
        let newAssessment = { ...assessment };
        newAssessment.industry = getIdName(industry);
        updateAssessment(newAssessment);
    };

    const handleOtherIndustry = (event) => {
        // Set the value of otherIndustry to assessment
        let newAssessment = { ...assessment };
        newAssessment.otherIndustry = event.target.value;
        updateAssessment(newAssessment);
    };

    // Select one or more regions
    const handleRegionChange = (region) => {
        let newAssessment = { ...assessment };
        // Check if the industry is already selected
        if (!checkIdExists(newAssessment?.regions, region.id)) {
            newAssessment.regions.push(getIdName(region));
        } else {
            newAssessment.regions = deleteObjectById(newAssessment?.regions, region.id);
        }
        updateAssessment(newAssessment);
    };

    const handleOtherRegion = (event) => {
        // Set the value of otherRegion to assessment
        let newAssessment = { ...assessment };
        newAssessment.otherRegion = event.target.value;
        updateAssessment(newAssessment);
    };

    const handleOrganizationSizeChange = (size) => {
        let newAssessment = { ...assessment };
        newAssessment.organizationSize = getIdName(size);
        updateAssessment(newAssessment);
    };

    return (
        <Box display="flex" flexDirection="column" pl={4}>
            <Typography variant="headline" mb={4}>
                {ASSESSMENT_INDUSTRY}
            </Typography>
            <Fade in={true} timeout={1000} unmountOnExit>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                    {getCodelist(IndustryCodes).map((industry) => {
                        return (
                            <Box
                                key={industry.id}
                                width="15%"
                                flexDirection="column"
                                p={1}
                                sx={{ boxShadow: 1, 
                                    bgcolor: assessment?.industry?.id === industry.id ? "secondary.main" : "#eee"
                                    //bgcolor: assessment?.organizationSize.id === size.id ? "secondary.main" : "#eee",
                                }}
                                textAlign="center"
                                onClick={() => handleIndustryChange(industry)}
                            >
                                <Box sx={{ boxShadow: 0 }} display="flex" justifyContent="center" alignItems="center">
                                    <IconButton sx={{ color: 'black'}}>{industry.image}</IconButton>
                                </Box>

                                <Box textAlign="center">
                                    <Typography variant="subtitle" color={assessment?.industry?.id === industry.id  ? "white" : "primary.main"} gutterBottom>
                                        {industry.name}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Fade>
            { (assessment?.industry?.id === OTHER_ID) && (
                <Box display="flex" flexDirection="column" mt={2} width="90%">
                    <TextField
                        label="If Other, please elaborate"
                        value={assessment?.otherIndustry}
                        multiline
                        rows={1}
                        fullWidth
                        variant="outlined"
                        onChange={handleOtherIndustry}
                    />
                </Box>
            )}

            <Box pt={8}></Box>
            <Typography variant="headline" mb={4}>
                {ASSESSMENT_REGION}
            </Typography>
            <Fade in={true} timeout={1000} unmountOnExit>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                    {getCodelist(RegionCodes).map((region) => {
                        return (
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent={"center"}
                                key={region.id}
                                width="12%"
                                p={1}
                                gap={1}
                                sx={{ boxShadow: 1, 
                                    bgcolor: checkIdExists(assessment?.regions, region.id) ? "secondary.main" : "#eee"
                                }}
                                    
                                textAlign="center"
                                onClick={() => handleRegionChange(region)}
                            >
                                <Box sx={{ boxShadow: 0 }} display="flex" justifyContent="center" alignItems="center">
                                    <Avatar src={require(`../../assets/images/${region.image}`)} sx={{ width: 60, height: 60 }} />
                                </Box>
                                {/* <Box sx={{ boxShadow: 0 }} display="flex" justifyContent="center" alignItems="center">
                                    <IconButton sx={{ color: "black" }}>{require("../../assets/images/regions/asia.png")}</IconButton>
                                </Box> */}

                                <Box textAlign="center">
                                    <Typography variant="subtitle" sx={{ color: checkIdExists(assessment?.regions, region.id) ? "white" : "primary.main" }} gutterBottom>
                                        {region.name}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Fade>
            {checkIdExists(assessment?.regions, OTHER_ID) && (
                <Box display="flex" flexDirection="column" mt={2} width="90%">
                    <TextField
                        label="If Other, please elaborate"
                        value={assessment?.otherRegion}
                        multiline
                        rows={1}
                        fullWidth
                        variant="outlined"
                        onChange={handleOtherIndustry}
                    />
                </Box>
            )}

            <Box pt={8}></Box>
            <Typography variant="headline" mb={4}>
                {ASSESSMENT_ORG_SIZE}
            </Typography>
            <Fade in={true} timeout={1000} unmountOnExit>
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                    {getCodelist(OrganizationSizeCodes).map((size) => {
                        return (
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent={"center"}
                                key={size.id}
                                width="12%"
                                p={1}
                                gap={1}
                                sx={{ boxShadow: 1, 
                                    bgcolor: assessment?.organizationSize.id === size.id ? "secondary.main" : "#eee",
                                    color: assessment?.organizationSize.id === size.id ? "white" : "#eee" 
                                }}
                                textAlign="center"
                                onClick={() => handleOrganizationSizeChange(size)}
                            >
                                <Box textAlign="center">
                                    <Typography variant="subtitle" color={assessment?.organizationSize.id === size.id ? "white" : "primary.main" }>
                                        {size.name}
                                    </Typography>
                                    <Typography variant="body2" color={assessment?.organizationSize.id === size.id ? "white" : "primary.main" }gutterBottom>
                                        {size.description}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Fade>
        </Box>
    );
}
