import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { AddCircle, Bolt, Delete, Edit, Light, OfflineBoltSharp } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { deletePermission, savePermission } from "../../services/PermissionService";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Typography } from "@mui/material";
import { GridLoader } from "react-spinners";
import { checkPermission } from "../../utils/AuthenticationUtils";
import * as Authority from "../../constants/Authorities";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StatusMessage from "../../components/StatusMessage";
import { set } from "date-fns";
import PermissionKB from "../knowledgebase/PermissionKB";
import { KNOWLEDGE_BASE } from "../../constants/Constants";
import { useTheme } from "@emotion/react";

export default function PermissionTable({ permissions, isTab }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [rowModesModel, setRowModesModel] = useState({});
    const [rows, setRows] = useState([]);
    const { user, person } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const location = useLocation()

    // Warning states
    const [selectedId, setSelectedId] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [openMultipleDelete, setOpenMultipleDelete] = useState(false);
    const [showKB, setShowKB] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [status, setStatus] = useState({
        open: false,
        type: "success",
        message: "",
    });

    useEffect(() => {
        setRows(permissions);
    }, [permissions]);

    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: "200",
            editable: true,
            renderCell: (cell) => {
                return <Link to={`/imag-ai/permissions/${cell.id}`}>{cell.row.name}</Link>;
            },
        },
        {
            field: "description",
            headerName: "Description",
            width: "400",
            editable: true,
        },
        
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 200,
            cellClassName: "actions",

            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                return [
                    <GridActionsCellItem
                        icon={<Edit color="primary" />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditPermission(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem icon={<Delete color="warning" />} label="Delete" onClick={() => handleOpenDelete(id)} color="inherit" />,
                ];
            },
        },
    ];

    const handleAddPermission = () => {
        navigate("/imag-ai/permissions/new");
    };

    const handleEditPermission = (id) => () => {
        console.log("clicked Edit:", id);
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        navigate(`/imag-ai/permissions/edit/${id}`);
    };

    const handleDeletePermission = (id) => async () => {
        setRows(rows.filter((row) => row.id !== id));
        await deletePermission(id);
        handleCloseDelete();
        setStatus({ open: true, type: "success", message: "Deleted Permission" });
    };

    const addKBPermissions = () => {
        setShowKB(true);
    }

    const handleDeleteMultiple = async () => {
        if (selectedRows) {
            const promises = selectedRows.map(async (id) => {
                await deletePermission(id);
            });
            await Promise.all(promises)
            handleCloseMultipleDelete();
            setStatus({
                open: true,
                type: "success",
                message: "Deleted Permission(s)",
            });
            navigate (location.pathname,  {state: {refresh: Math.random() }} );
        }
    };

    const handleProcessRowUpdate = async (updatedRow, originalRow) => {
        const s = await savePermission(person.id, updatedRow);
        setStatus({ open: true, type: "success", message: "Saved Permission" });
        return s;
    };
    const handleProcessRowUpdateError = (err) => {
        console.log(err);
        setStatus({ open: true, type: "error", message: err });
    };

    const handleSelectionModelChange = (selectedIds) => {
        setSelectedRows(selectedIds);
    };

    // Returns a custom tool bar for the permission table
    function PermissionTableToolbar() {
        return (
            <GridToolbarContainer
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <GridToolbarQuickFilter />

                <Box sx={{ marginRight: 2 }}>
                    <GridToolbarExport />
                    {checkPermission(person.permission, Authority.PERMISSION_ASSESSMENT_CAN_DELETE) && (
                        <Button
                            startIcon={<Delete color="warning" />}
                            onClick={handleOpenMultipleDelete}
                            disabled={selectedRows && selectedRows.length > 0 ? false : true}
                        >
                            Delete
                        </Button>
                    )}
                    {checkPermission(person.permission, Authority.PERMISSION_ASSESSMENT_CAN_CREATE) && (
                        <Button startIcon={<AddCircle />} onClick={handleAddPermission}>
                            Add Permission
                        </Button>
                    )}
                    {checkPermission(person.permission, Authority.PERMISSION_CAN_MOCK) && (
                        <Button startIcon={<OfflineBoltSharp />} color="warning" onClick={addKBPermissions}>
                           {KNOWLEDGE_BASE}
                        </Button>
                    )}
                    
                </Box>
            </GridToolbarContainer>
        );
    }

    function NoRowsOverlay() {
        return (
            <Box display="flex" flexDirection="column" justifyContent={"center"} alignItems={"center"}>
                <Typography sx={{ padding: 2 }}>No permissions found</Typography>
            </Box>
        );
    }

    const handleOpenDelete = (id) => {
        setSelectedId(id);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setSelectedId(null);
    };
    const handleOpenMultipleDelete = () => {
        setOpenMultipleDelete(true);
    };

    const handleCloseMultipleDelete = () => {
        setOpenMultipleDelete(false);
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <div style={{ width: "100%" }}>
            <StatusMessage status={status} changeStatus={setStatus} />
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                checkboxSelection
                disableRowSelectionOnClick
                autoHeight
                slots={{
                    toolbar: PermissionTableToolbar,
                    noRowsOverlay: NoRowsOverlay,
                }}
                sx={{
                    border: 0,
                    boxShadow: 0,
                    fontSize: "1rem",
                    "& .MuiDataGrid-cell:hover": {
                        color: "primary.main",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                    },
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                }}
                processRowUpdate={handleProcessRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                // Called on checkbox selection
                selectionModel={selectedRows}
                onRowSelectionModelChange={handleSelectionModelChange}
            />

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDelete} onClose={handleCloseDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Delete permission ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting a permission may have unintended consequences. Are you sure?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDelete} color="inherit">
                        No
                    </Button>
                    <Button variant="contained" color="warning" onClick={handleDeletePermission(selectedId)} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Multiple Confirmation Dialog */}
            <Dialog
                open={openMultipleDelete}
                onClose={handleCloseMultipleDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete permissions?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting permissions may have unintended consequences. Are you sure?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseMultipleDelete} color="inherit">
                        No
                    </Button>
                    <Button variant="contained" color="warning" onClick={handleDeleteMultiple} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Drawer variant="temporary" anchor="right" open={showKB} onClose={() => setShowKB(false)}>
                <Box sx={{ width: "50vw", p: 2 }}>
                   <PermissionKB toggleView={setShowKB} />
                </Box>
            </Drawer>
        </div>
    );
}
