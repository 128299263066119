import { addDoc, and, collection, deleteDoc, doc, getDoc, getDocs, limit, or, orderBy, query, setDoc, where } from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { getAttributeCSV, getAttributeList, getObjectById, updateAudit } from "../utils/utils";
import {
    ASSESSMENT_GAP_PEOPLE_NUMBER,
    ASSESSMENT_GAP_PROCESS_NUMBER,
    ASSESSMENT_GAP_TECHNOLOGY_NUMBER,
    ASSESSMENT_TYPE_GAP,
    ASSESSMENT_TYPE_REGULATION,
} from "../constants/AssessmentConstants";
import { openai } from "../config/OpenAIConfig";
import { AI_ROLE_SYSTEM, AI_ROLE_USER } from "../constants/AIConstants";
import { ca } from "date-fns/locale";
import { IndustryCodes, RegionCodes, RegulationCodes, getCodelist } from "../constants/Codelist";

// Create a new assessmentResult
export const createAssessmentResult = async (uid, assessmentResult) => {
    const docRef = doc(collection(db, DBConstants.DB_ASSESSMENT_RESULTS));
    assessmentResult.id = docRef.id;
    updateAudit(uid, assessmentResult);
    await setDoc(docRef, assessmentResult, { merge: true });
    return await findAssessmentResult(assessmentResult.id);
};

// update an existing assessmentResult
export const updateAssessmentResult = async (uid, assessmentResult) => {
    updateAudit(uid, assessmentResult);
    const docRef = doc(db, DBConstants.DB_ASSESSMENT_RESULTS, assessmentResult.id);
    await setDoc(docRef, { ...assessmentResult }, { merge: true });
    return await findAssessmentResult(assessmentResult.id);
};

// Create a new assessmentResult or update an existing one
export const saveAssessmentResult = async (uid, tenantId, assessmentResult) => {
    assessmentResult.tenantId = tenantId;
    if (assessmentResult.id) {
        return await updateAssessmentResult(uid, assessmentResult);
    } else {
        return await createAssessmentResult(uid, assessmentResult);
    }
};

// Delete an assessmentResult
export const deleteAssessmentResult = async (assessmentResult) => {
    // Delete the assessmentResult
    const docRef = doc(db, DBConstants.DB_ASSESSMENT_RESULTS, assessmentResult.id);
    await deleteDoc(docRef);
};

// Delete an assessmentResult
export const deleteAssessmentResultByAssessmentId = async (assessmentId, type) => {
    // Delete the assessmentResult
    let results = [];
    const q = query(
        collection(db, DBConstants.DB_ASSESSMENT_RESULTS),
        where("assessmentId", "==", assessmentId),
        where("type", "==", ASSESSMENT_TYPE_REGULATION)
    );
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        deleteAssessmentResult(doc);
    });
};

// Find an assessmentResult with id
export const findAssessmentResult = async (id) => {
    const docRef = doc(db, DBConstants.DB_ASSESSMENT_RESULTS, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
};

// Find all assessmentResults for a tenant
export const findAllAssessmentResults = async (tenantId) => {
    let results = [];
    const q = query(
        collection(db, DBConstants.DB_ASSESSMENT_RESULTS)
        //where("tenantId", "==", tenantId)
    );
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

export const findLatestAssessmentResultByTenant = async (tenantId) => {
    console.log("Find assessmentResult for tenant: ", tenantId);
    let results = [];
    const q = query(collection(db, DBConstants.DB_ASSESSMENT_RESULTS), where("tenantId", "==", tenantId), orderBy("createdDate", "desc"), limit(1));
    // Get the first matching document if found
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results.length > 0 ? results[0] : null;
};

export const findRegulationAssessmentResult = async (assessmentId) => {
    let results = [];
    const q = query(
        collection(db, DBConstants.DB_ASSESSMENT_RESULTS),
        where("assessmentId", "==", assessmentId),
        where("type", "==", ASSESSMENT_TYPE_REGULATION),
        orderBy("createdDate", "desc"),
        limit(1)
    );
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results.length > 0 ? results[0] : null;
};

export const findGapAssessmentResult = async (assessmentId, type, capabilityCategoryId, capabilityId) => {
    let results = [];
    const q = query(
        collection(db, DBConstants.DB_ASSESSMENT_RESULTS),
        where("assessmentId", "==", assessmentId),
        where("type", "==", type),
        where("capabilityCategoryId", "==", capabilityCategoryId),
        where("capabilityId", "==", capabilityId),
        orderBy("createdDate", "desc"),
        limit(1)
    );
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results.length > 0 ? results[0] : null;
};

export const deleteGapByAssessmentId = async (assessmentId, type, capabilityCategoryId, capabilityId) => {
    // Delete the assessmentResult
    let results = [];
    const q = query(
        collection(db, DBConstants.DB_ASSESSMENT_RESULTS),
        where("assessmentId", "==", assessmentId),
        where("type", "==", type),
        where("capabilityCategoryId", "==", capabilityCategoryId),
        where("capabilityId", "==", capabilityId)
    );
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        deleteAssessmentResult(doc);
    });
};

// Delete assessment results by type
export const deleteResultsByAssessmentId = async (assessmentId, type) => {
    // Delete the assessmentResult
    let results = [];
    const q = query(collection(db, DBConstants.DB_ASSESSMENT_RESULTS), where("assessmentId", "==", assessmentId), where("type", "==", type));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        deleteAssessmentResult(doc);
    });
};


// export const getRegulations = async (person, assessment, persona) => {
//     console.log("Assessment: ", assessment);
//     // Get the codelist for Industries 
//     const industryCodes = getCodelist(IndustryCodes);
//     // get the industry with the id 
//     const industry = getObjectById(industryCodes, assessment?.industry?.id);
//     const selectedIndustryCode = industry?.code;
//     console.log ('Selected Industry Code: ', selectedIndustryCode)

//     // Get the RegulationCodes from Codelist
    
//     const regionCodes = getCodelist(RegionCodes)
//     //Get the assessment regions an array of id attributes
//     const regions = getAttributeList(assessment?.regions, "id");
//     console.log ('Regions: ', regions  )
//     // For each region, get the region code
//     const selectedRegionCodes = regions.map((r) => {
//         const region = getObjectById(regionCodes, r);
//         return region?.code;
//     });
//     console.log ('Selected Region Codes: ', selectedRegionCodes)

//     const regulationCodes = getCodelist(RegulationCodes);
//     const filteredByIndustry = regulationCodes.filter((r) => {
//         console.log ('Type: ', typeof r.industries);
//         // check if the industry is of type string or array. Skip if not defined
//         if (typeof r.industries === 'undefined') {
//             return false;
//         }
//         return r.industries.includes(selectedIndustryCode);
//     });
//     console.log("Filtered: ", filteredByIndustry);

//     // Filter the list further based on the regions. If the assessment region is not in the list of regions, then remove the regulation
//     const filteredByRegions = filteredByIndustry.filter((r) => {
//         console.log ('Type: ', typeof r.regions)
//         if (typeof r.regions === 'undefined') {
//             return false;
//         }
//         return r.regions.some((region) => selectedRegionCodes.includes(region));
//     });
//     console.log("Filtered With Regions: ", filteredByRegions);
// }



export const getRegulations = async (person, assessment, persona) => {
    console.log("Find the regulations for assessment: ", assessment);
    const company = person?.tenant?.name;
    const regions = getAttributeCSV(assessment?.regions, "name");
    const industry = assessment?.industry.name;
    const regulationCodes = getCodelist(RegulationCodes);
    const regulations = getAttributeCSV(regulationCodes, "name");

    const messages = [
        { role: AI_ROLE_SYSTEM, content: persona },
        {
            role: AI_ROLE_USER,
            content: `I need to understand regulations that apply to my organization. Your job is to give me a list of compliance and regulations based style adopted by the Auditor.`,
        },
        {
            role: AI_ROLE_USER,
            content: `Assumptions: 
        - Add a numeric identifier to each record as id
        - For all North American organization, assume that data collection includes California residents 
        - For all European organization, assume GDPR applies
        - For all organization, assume the organization is not equipped to address consumer rights requests, like the right to know, right to delete, and right to opt-out`,
        },
        {
            role: AI_ROLE_USER,
            content: `Here are the steps to achieve your goal: 
        - Based on the regions, identify the regulations that are applicable for the given industry. Examples: NIST CSF, NIST SP 800-53, ISO 27001:2013, GDPR, HIPPA, SOX, PCI-DSS, CCPA, FISMA, PSD-2, FERPA, COPPA, GLBA, CMMC, NYDFS, NY SHIELD
        - Provide the name or short form of the standard with the key: abbreviation and give the long name with the key: displayName. 
        - Provide a brief description of why the standard applies to your organization. Use a personalized language such as "your bank", "your organization" or referring the company by the name in your responses.
        - Mark each regulation as compliance with values as either Required or Optional.
        - Provide your output in json format with keys: "criteria" as the inputs received from the me and response with the key: "regulations". Use camel case for all attributes. `,
        },
        //{ role: AI_ROLE_ASSISTANT, content: `Provide me with the Name of the Company, Regions and Industry your organization operates in`},
        { role: AI_ROLE_USER, content: `Company Name: ${company}, Regions: ${regions}, Industry: ${industry}` },
    ];

    console.log("Prompt: ", messages);

    // Send the prompt to AI
    try {
        const regulationQuery = await openai.chat.completions.create({
            model: process.env.REACT_APP_OPENAI_MODEL,
            temperature: 1,
            max_tokens: 2048,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
            messages: [...messages],
        });
        console.log(" Message: ", regulationQuery.choices[0].message);
        // Parse the JSON response
        let res = JSON.parse(regulationQuery.choices[0].message.content);
        console.log(res);

        // Extend the result
        res["type"] = ASSESSMENT_TYPE_REGULATION;
        res["assessmentId"] = assessment.id;
        console.log ('Saving the assessment: ', res)


        // Delete any older assessments
        await deleteAssessmentResultByAssessmentId(assessment.id, ASSESSMENT_TYPE_REGULATION);
        await saveAssessmentResult(person.id, person?.tenant?.id, res);
    } catch (e) {
        console.log("Error: ", e);
    }
};


export const getGapAnalysis = async (person, assessment, persona, category, capability) => {
    console.log("Find the Gap for category: ", category, " capability: ", capability, " Assessment: ", assessment);

    const company = person?.tenant?.name;
    const industry = assessment?.industry?.name;
    const companySize = `${assessment?.organizationSize?.name}`;
    const domainName = category.name;
    const capabilityName = `Lack of ${capability.name}`;

    // Create the prompt
    const messages = [
        { role: AI_ROLE_SYSTEM, content: persona },
        {
            role: AI_ROLE_USER,
            content: `I need to perform gap analysis on an IAM capability. Your job is to give me the top 5 gaps as part of the analysis. The analysis will depend on the size of the company, the domain the company belongs and nature of the Auditor.`,
        },
        {
            role: AI_ROLE_USER,
            content: `Assumptions: 
        - Small companies do not have a dedicated security team to address all gaps
        - Threats to systems is highest for Money moving and regulatory applications.
        - Regulatory standards such as GDPR, HIPAA, etc. require security controls to be in place irrespective of the size of the company.
        `,
        },
        {
            role: AI_ROLE_USER,
            content: `I will provide you: The Company Name, Industry, Size of the company, IAM Domain and IAM Capability`,
        },
        {
            role: AI_ROLE_USER,
            content: `Here are the steps to achieve your goal: 
            - Identify the top security gaps for the domain and IAM capability chosen. 
            - I need ${ASSESSMENT_GAP_PEOPLE_NUMBER} people gaps, ${ASSESSMENT_GAP_PROCESS_NUMBER} Process gaps and ${ASSESSMENT_GAP_TECHNOLOGY_NUMBER} Technology gaps based on the Industry and Company Size. 
            - For each gap identified, classify them with the key "type", Value are: People, Process and Technology 
            - For each gap identified, provide a short Name with 5-8 words, a longer description called "impact" and classify the risk posture into "High", "Medium", "Low"
            - For each gap identified, relate to one NIST CSF Functions (Identify, Protect, Detect, Respond and Recover) as "corefunction"
            - For each gap identified, relate to one or more NIST CSF Categories as "categories".
            - Create a valid JSON response with keys: "criteria" as the inputs received from the me and response with the key: "gaps". 
            - In the JSON object, group the security gaps by their type as keys: "people", "process" and "technology".
            `,
        },
        {
            role: AI_ROLE_USER,
            content: "Use camel case for all attributes and return the valid JSON Object.. Return without the leading text or explanation",
        },
        {
            role: AI_ROLE_USER,
            content: `Use this information:  Company Name: ${company}, Industry: ${industry}, Company Size: ${companySize}, Domain: ${domainName}, Capability: ${capabilityName} `,
        },
    ];

    console.log("Prompt: ", messages);

    // Send the prompt to AI
    try {
        const gapQuery = await openai.chat.completions.create({
            model: process.env.REACT_APP_OPENAI_MODEL,
            temperature: 1,
            max_tokens: 1024,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
            messages: [...messages],
        });
        console.log(" Message: ", gapQuery.choices[0].message);
        // Parse the JSON response
        let res = JSON.parse(gapQuery.choices[0].message.content);
        //let res = gapQuery.choices[0].message.content;
        console.log("Response : ", res);

        // Extend the result
        res["type"] = ASSESSMENT_TYPE_GAP;
        res["assessmentId"] = assessment.id;
        res["capabilityCategoryId"] = category.id;
        res["capabilityId"] = capability.id;

        console.log("Saving the gap assessment: ", res);

        // Delete any older assessments
        await deleteGapByAssessmentId(assessment.id, ASSESSMENT_TYPE_GAP, category.id, capability.id);
        await saveAssessmentResult(person.id, person?.tenant?.id, res);
    } catch (e) {
        console.log("Error: ", e);
    }
};
