import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import CompanyLogo from "../../assets/images/logo-text.png";
import { CheckCircle} from "@mui/icons-material";

export default function ForgotPasswordInfo() {
    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" width="50%" height="100%" bgcolor={"black"}>
            <Box display="flex" flexDirection="row" mx={1} my={1}>
                <Link to="https://www.cybercodee.com" target="_blank">
                    <img src={CompanyLogo} alt="Logo" />
                </Link>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={10}>
                <Typography variant="h4" color="primary">
                    There are other ways to secure your account
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircle color="success" />
                        </ListItemIcon>
                        <ListItemText sx={{ color: 'white' }} primary="Enable Multi-Factor Authentication" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckCircle color="success" />
                        </ListItemIcon>
                        <ListItemText sx={{ color: 'white' }} primary="Use Security Keys" />
                    </ListItem>
                </List>
                <Typography variant="h6" color="primary" pt={10}>
                    Secure your Digital Presence with Confidence!
                </Typography>
            </Box>

            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={10}>
                <Typography variant="subtitle" color="secondary">
                    Powered by CyberCodee, LLC.
                </Typography>
            </Box>
        </Box>
    );
}
