import * as Entitlements from "../constants/Entitlements"

// Check if a permission exists for a given role
export const checkPermission = (role, permission) => {
  //console.log ('check permission: ', role, permission)
    // Change this later to deny by default
    if(role === undefined || role === null  )
      return true;

    if(permission){
      let permissions = Entitlements.ROLE_PERMISSIONS[role];
      if (permissions && permissions.includes (permission))
        return true
    }
    return false
  };


  export const authErrorMap = {
    "auth/email-already-exists": "The provided email is already in use by an existing user. If you have an account already, sign-in directly.",
    "auth/email-already-in-use": "The provided email is already in use by an existing user. If you have an account already, sign-in directly.",
    "auth/invalid-email": "Email provided is invalid.",
    "auth/weak-password": "Password should be at least 6 characters.",
    "auth/invalid-password": "Password provided is not corrected.",
    "auth/missing-password": "Password is required.",
    "auth/user-not-found": "No user found with that name.",
    "auth/phone-number-already-exists": "The provided Phone Number is already in use by an existing user.",
  }

  // Translate a firebase auth code to user friendly message
  export const translateAuthCodeToMessage = (authCode) => {
    //console.log ('Error code: ', authCode)
    let key = authErrorMap[authCode]
    return key? authErrorMap[authCode]: "Could not signin the user with the credentials"
  }