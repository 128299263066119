import React from "react";
import * as Authority from "./constants/Authorities";
import Dashboard from "./pages/dashboard/Dashboard"
import { AdminPanelSettings, Apps, Assessment, Business, Group, Home, Language, List, Mail, Person, Policy, Reviews, Security, Sell, Settings, ShoppingCart } from "@mui/icons-material";
import * as Constants from "./constants/Constants";

import MyOrganization from "./pages/settings/MyOrganization";
import Privacy from "./pages/settings/Privacy";
import Localization from "./pages/settings/Localization";
import AuditList from "./pages/reports/AuditList";
import NotificationList from "./pages/reports/NotificationList";
import MyProfile from "./pages/profile/MyProfile";
import RoleList from "./pages/imag-ai/RoleList";
import GroupList from "./pages/imag-ai/GroupList";
import OrgProfile from "./pages/profile/OrgProfile";
import PersonList from "./pages/imag-ai/PersonList";
import PersonDetail from "./pages/imag-ai/PersonDetail";
import PersonEdit from "./pages/imag-ai/PersonEdit";
import InvitationList from "./pages/reports/InvitationList";
import AssessmentWizard from "./pages/assessment/AssessmentWizard";
import AssessmentMain from "./pages/assessment/AssessmentMain";
import AssessmentResult from "./pages/assessment/AssessmentResult";
import Cart from "./pages/cart/Cart";
import OrderList from "./pages/order/OrderList";
import OrderEdit from "./pages/order/OrderEdit";
import PaymentMethods from "./pages/order/PaymentMethods";
import RoleEdit from "./pages/imag-ai/RoleEdit";
import RoleDetail from "./pages/imag-ai/RoleDetail";
import GroupEdit from "./pages/imag-ai/GroupEdit";
import GroupDetail from "./pages/imag-ai/GroupDetail";
import PermissionList from "./pages/imag-ai/PermissionList";
import PermissionEdit from "./pages/imag-ai/PermissionEdit";
import PermissionDetail from "./pages/imag-ai/PermissionDetail";
import AssetList from "./pages/imag-ai/AssetList";
import AssetEdit from "./pages/imag-ai/AssetEdit";
import AssetDetail from "./pages/imag-ai/AssetDetail";
import ApplicationEdit from "./pages/application/ApplicationEdit";
import ApplicationList from "./pages/application/ApplicationList";
import DepartmentList from "./pages/imag-ai/DepartmentList";
import DepartmentEdit from "./pages/imag-ai/DepartmentEdit";
import DepartmentDetail from "./pages/imag-ai/DepartmentDetail";
import TenantList from "./pages/imag-ai/TenantList";
import TenantEdit from "./pages/imag-ai/TenantEdit";
import TenantDetail from "./pages/imag-ai/TenantDetail";
import RequestList from "./pages/request/RequestList";
import RequestEdit from "./pages/request/RequestEdit";
import RequestDetail from "./pages/request/RequestDetail";
import IMagHome from "./pages/imag-ai/IMagHome";
import AuditAIHome from "./pages/assessment/AuditAIHome";


export const routePaths = [
    {
        path: "/",
        name: "Home",
        type: Constants.ROUTE_CUSTOM,
        icon: Home,
        permission: Authority.PERMISSION_DASHBOARD_CAN_VIEW,
        component: <Dashboard />,
    },
    
    // {
    //     path: "/request",
    //     name: "Request Management",
    //     type: Constants.ROUTE_SUBMENU,
    //     icon: Apps,
    //     permission: Authority.PERMISSION_REQUEST_ALL,
    //     children: [
    //         {
    //             path: "/requests",
    //             name: "Requests",
    //             type: Constants.ROUTE_LINK,
    //             icon: Apps,
    //             permission: Authority.PERMISSION_REQUEST_ALL,
    //             component: <RequestList />,
    //         },
    //         {
    //             path: "/requests/new",
    //             name: "New Request",
    //             type: Constants.ROUTE_CUSTOM,
    //             icon: Apps,
    //             permission: Authority.PERMISSION_REQUEST_CAN_CREATE,
    //             component: <RequestEdit />,
    //         },
    //         {
    //             path: "/requests/edit/:id",
    //             name: "Edit Request",
    //             type: Constants.ROUTE_CUSTOM,
    //             icon: Apps,
    //             permission: Authority.PERMISSION_REQUEST_CAN_UPDATE,
    //             component: <RequestEdit />,
    //         },
    //         {
    //             path: "/requests/:id",
    //             name: "View Request",
    //             type: Constants.ROUTE_CUSTOM,
    //             icon: AdminPanelSettings,
    //             permission: Authority.PERMISSION_REQUEST_CAN_VIEW,
    //             component: <RequestDetail />,
    //         },
    //     ]
    // },
    {
        path: "/audit-ai",
        name: "AuditAI",
        type: Constants.ROUTE_SUBMENU,
        icon: Policy,
        permission: Authority.PERMISSION_ASSESSMENT_ALL,
        component: <AuditAIHome />,
        children: [
            // {
            //     path: "/Home",
            //     name: "Home",
            //     type: Constants.ROUTE_LINK,
            //     icon: Reviews,
            //     permission: Authority.PERMISSION_ASSESSMENT_ALL,
            //     component: <AuditAIHome />,
            // },
            {
                path: "/assessments",
                name: "Assessments",
                type: Constants.ROUTE_LINK,
                icon: Assessment,
                permission: Authority.PERMISSION_ASSESSMENT_ALL,
                component: <AssessmentMain />,
            },
            {
                path: "/assessments/result",
                name: "Analysis",
                type: Constants.ROUTE_LINK,
                icon: Reviews,
                permission: Authority.PERMISSION_ASSESSMENT_ALL,
                component: <AssessmentResult />,
            },
            {
                path: "/assessments/new",
                name: "New Assessment",
                type: Constants.ROUTE_CUSTOM,
                icon: Reviews,
                permission: Authority.PERMISSION_ASSESSMENT_CAN_CREATE,
                component: <AssessmentWizard />,
            },
            {
                path: "/assessments/:id/result",
                name: "Assessment Result",
                type: Constants.ROUTE_CUSTOM,
                icon: Reviews,
                permission: Authority.PERMISSION_ASSESSMENT_CAN_CREATE,
                component: <AssessmentResult />,
            },

            // {
            //     path: "/policies",
            //     name: "Policies",
            //     type: Constants.ROUTE_LINK,
            //     icon: Policy,
            //     permission: Authority.PERMISSION_ASSESSMENT_ALL,
            //     component: <PolicyList />,
            // },
            // {
            //     path: "/controls",
            //     name: "Controls",
            //     type: Constants.ROUTE_LINK,
            //     icon: Link,
            //     permission: Authority.PERMISSION_ASSESSMENT_ALL,
            //     component: <ControlList />,
            // },
            // {
            //     path: "/evidence",
            //     name: "Evidence",
            //     type: Constants.ROUTE_LINK,
            //     icon: Search,
            //     permission: Authority.PERMISSION_ASSESSMENT_ALL,
            //     component: <EvidenceList />,
            // },
        ],
    },
    {
        path: "/applaunch",
        name: "AppLaunch",
        type: Constants.ROUTE_SUBMENU,
        icon: Apps,
        permission: Authority.PERMISSION_APPLAUNCH_ALL,
        children: [
            {
                path: "/applications",
                name: "Applications",
                type: Constants.ROUTE_LINK,
                icon: Apps,
                permission: Authority.PERMISSION_APPLICATION_ALL,
                component: <ApplicationList />,
            },
            {
                path: "/applications/new",
                name: "New Application",
                type: Constants.ROUTE_CUSTOM,
                icon: Apps,
                permission: Authority.PERMISSION_APPLICATION_CAN_CREATE,
                component: <ApplicationEdit />,
            },
            {
                path: "/applications/edit/:id",
                name: "Edit Application",
                type: Constants.ROUTE_CUSTOM,
                icon: Apps,
                permission: Authority.PERMISSION_APPLICATION_CAN_UPDATE,
                component: <ApplicationEdit />,
            },
        ]
    },
    {
        path: "/imag-ai",
        name: "iMagAI",
        type: Constants.ROUTE_SUBMENU,
        icon: AdminPanelSettings,
        permission: Authority.PERMISSION_ASSESSMENT_ALL,
        component: <IMagHome />,
        children: [
            // {
            //     path: "/Home",
            //     name: "Home",
            //     type: Constants.ROUTE_LINK,
            //     icon: Business,
            //     permission: Authority.PERMISSION_TENANT_ALL,
            //     component: <IMagHome />,
            // },
            {
                path: "/tenants",
                name: "Tenants",
                type: Constants.ROUTE_LINK,
                icon: Business,
                permission: Authority.PERMISSION_TENANT_ALL,
                component: <TenantList />,
            },
            {
                path: "/tenants/new",
                name: "New Tenant",
                type: Constants.ROUTE_CUSTOM,
                icon: Business,
                permission: Authority.PERMISSION_TENANT_CAN_CREATE,
                component: <TenantEdit />,
            },
            {
                path: "/tenants/edit/:id",
                name: "Edit Tenant",
                type: Constants.ROUTE_CUSTOM,
                icon: Business,
                permission: Authority.PERMISSION_TENANT_CAN_UPDATE,
                component: <TenantEdit />,
            },
            {
                path: "/tenants/:id",
                name: "View Tenant",
                type: Constants.ROUTE_CUSTOM,
                icon: Business,
                permission: Authority.PERMISSION_TENANT_CAN_VIEW,
                component: <TenantDetail />,
            },
            {
                path: "/departments",
                name: "Departments",
                type: Constants.ROUTE_LINK,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_DEPARTMENT_ALL,
                component: <DepartmentList />,
            },
            {
                path: "/departments/new",
                name: "New Department",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_DEPARTMENT_CAN_CREATE,
                component: <DepartmentEdit />,
            },
            {
                path: "/departments/edit/:id",
                name: "Edit Department",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_DEPARTMENT_CAN_UPDATE,
                component: <DepartmentEdit />,
            },
            {
                path: "/departments/:id",
                name: "View Department",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_DEPARTMENT_CAN_VIEW,
                component: <DepartmentDetail />,
            },
            {
                path: "/people",
                name: "Users",
                type: Constants.ROUTE_LINK,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_PERSON_ALL,
                component: <PersonList />,
            },
            {
                path: "/people/new",
                name: "New Person",
                type: "custom",
                icon: Person,
                permisson: Authority.PERMISSION_PERSON_CAN_CREATE,
                component: <PersonEdit />,
            },
            {
                path: "/people/edit/:id",
                name: "Person Edit",
                type: "custom",
                icon: Person,
                permisson: Authority.PERMISSION_PERSON_CAN_UPDATE,
                component: <PersonEdit />,
            },
            {
                path: "/people/:id",
                name: "Person Detail",
                type: "custom",
                icon: Person,
                permisson: Authority.PERMISSION_PERSON_CAN_VIEW,
                component: <PersonDetail />,
            },
            {
                path: "/assets",
                name: "Assets",
                type: Constants.ROUTE_LINK,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_ROLE_ALL,
                component: <AssetList />,
            },
            {
                path: "/assets/new",
                name: "New Asset",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_ROLE_CAN_CREATE,
                component: <AssetEdit />,
            },
            {
                path: "/assets/edit/:id",
                name: "Edit Asset",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_ROLE_CAN_UPDATE,
                component: <AssetEdit />,
            },
            {
                path: "/assets/:id",
                name: "View Asset",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_ROLE_CAN_VIEW,
                component: <AssetDetail />,
            },
            
            
            {
                path: "/roles",
                name: "Roles",
                type: Constants.ROUTE_LINK,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_ROLE_ALL,
                component: <RoleList />,
            },
            {
                path: "/roles/new",
                name: "New Role",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_ROLE_CAN_CREATE,
                component: <RoleEdit />,
            },
            {
                path: "/roles/edit/:id",
                name: "Edit Role",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_ROLE_CAN_UPDATE,
                component: <RoleEdit />,
            },
            {
                path: "/roles/:id",
                name: "View Role",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_ROLE_CAN_VIEW,
                component: <RoleDetail />,
            },
            {
                path: "/groups",
                name: "Groups",
                type: Constants.ROUTE_LINK,
                icon: Group,
                permission: Authority.PERMISSION_GROUP_ALL,
                component: <GroupList />,
            },
            {
                path: "/groups/new",
                name: "New Group",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_GROUP_CAN_CREATE,
                component: <GroupEdit />,
            },
            {
                path: "/groups/edit/:id",
                name: "Edit Group",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_GROUP_CAN_UPDATE,
                component: <GroupEdit />,
            },
            {
                path: "/groups/:id",
                name: "View Group",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_GROUP_CAN_VIEW,
                component: <GroupDetail />,
            },
            {
                path: "/permissions",
                name: "Permissions",
                type: Constants.ROUTE_LINK,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_PERMISSION_ALL,
                component: <PermissionList />,
            },
            {
                path: "/permissions/new",
                name: "New Permission",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_PERMISSION_CAN_CREATE,
                component: <PermissionEdit />,
            },
            {
                path: "/permissions/edit/:id",
                name: "Edit Permission",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_PERMISSION_CAN_UPDATE,
                component: <PermissionEdit />,
            },
            {
                path: "/permissions/:id",
                name: "View Permission",
                type: Constants.ROUTE_CUSTOM,
                icon: AdminPanelSettings,
                permission: Authority.PERMISSION_PERMISSION_CAN_VIEW,
                component: <PermissionDetail />,
            },
            
            
        ],
    },
    {
        path: "/reports",
        name: "GuardAI",
        type: Constants.ROUTE_SUBMENU,
        icon: Assessment,
        permission: Authority.PERMISSION_REPORT_ALL,
        children: [
            {
                path: "/audit",
                name: "Audit Log",
                type: Constants.ROUTE_LINK,
                icon: List,
                permission: Authority.PERMISSION_AUDIT_ALL,
                component: <AuditList />,
            },
            {
                path: "/notifications",
                name: "Notifications",
                type: Constants.ROUTE_LINK,
                icon: List,
                permission: Authority.PERMISSION_NOTIFICATION_ALL,
                component: <NotificationList />,
            },
            {
                path: "/invitations",
                name: "Invitations",
                type: Constants.ROUTE_LINK,
                icon: Mail,
                permission: Authority.PERMISSION_INVITATION_ALL,
                component: <InvitationList />,
            },
            
        ],
    },
    {
        path: "/settings",
        name: "Administration",
        type: Constants.ROUTE_SUBMENU,
        icon: Settings,
        permission: Authority.PERMISSION_SETTINGS_ALL,
        children: [
            {
                path: "/myorganization",
                name: "My Organization",
                type: Constants.ROUTE_LINK,
                icon: Business,
                permission: Authority.PERMISSION_SETTINGS_ALL,
                component: <MyOrganization />,
            },
            {
                path: "/profile",
                name: "My Profile",
                type: Constants.ROUTE_CUSTOM,
                icon: Business,
                permission: Authority.PERMISSION_SETTINGS_ALL,
                component: <MyProfile />,
            },
            {
                path: "/security",
                name: "Privacy",
                type: Constants.ROUTE_LINK,
                icon: Security,
                permission: Authority.PERMISSION_SETTINGS_ALL,
                component: <Privacy />,
            },
            {
                path: "/localization",
                name: "Localization",
                type: Constants.ROUTE_LINK,
                icon: Language,
                permission: Authority.PERMISSION_SETTINGS_ALL,
                component: <Localization />,
            },
            {
                path: "/organization/edit",
                name: "Organization Edit",
                type: Constants.ROUTE_CUSTOM,
                icon: Business,
                permission: Authority.PERMISSION_SETTINGS_ALL,
                component: <OrgProfile />,
            },
            
        ],
    },
    
    {
        path: "/cart",
        name: "Cart",
        type: Constants.ROUTE_CUSTOM,
        icon: ShoppingCart,
        permission: Authority.PERMISSION_DASHBOARD_CAN_VIEW,
        component: <Cart />,
    },
    {
        path: "/billing",
        name: "Support",
        type: Constants.ROUTE_SUBMENU,
        icon: Sell,
        permission: Authority.PERMISSION_ORDER_ALL,
        children: [
            {
                path: "/orders",
                name: "Orders",
                type: Constants.ROUTE_LINK,
                icon: Sell,
                permission: Authority.PERMISSION_ORDER_ALL,
                component: <OrderList />,
            },
            {
                path: "/payment-methods",
                name: "Payment Methods",
                type: Constants.ROUTE_LINK,
                icon: Sell,
                permission: Authority.PERMISSION_ORDER_ALL,
                component: <PaymentMethods />,
            },
            {
                path: "/orders/edit/:id",
                name: "Order Edit",
                type: Constants.ROUTE_CUSTOM,
                icon: Sell,
                permisson: Authority.PERMISSION_ORDER_ALL,
                component: <OrderEdit />,
            },
        ]
    }
    
];
