import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { GridLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { Fingerprint, ManageAccounts, Portrait, Reply, VerifiedUser } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { countPeopleByTenantId } from "../../services/PersonService";
import { makeStyles } from "@mui/styles";
import AnnouncementSlider from "./AnnouncementSlider";
import RequestTabs from "./RequestTabs";
import { BackgroundIcon } from "../../utils/UIStyles";

const useStyles = makeStyles((theme) => ({
    gradientBody: {
        background: `linear-gradient(to right bottom, black, ${theme.palette.primary.light})`,
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
}));


export default function IMagHome() {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const { user, person } = useAuthContext();

    const [stats, setStats] = useState({
        numberOfAssessments: 0,
        numberOfPolicies: 0,
        numberOfControls: 0,
        numberOfCertifications: 0,
        numberOfUsers: 0,
    });

    useEffect(() => {
        async function getStats() {
            setLoading(true);
            let users = await countPeopleByTenantId(person?.tenant?.id, []);
            //console.log ('Users: ', users)
            setStats({
                numberOfAssessments: 10,
                numberOfPolicies: 20,
                numberOfControls: 80,
                numberOfCertifications: 2,
                numberOfUsers: users,
            });
            setLoading(false);
        }

        if (person) {
            setTimeout(() => {
                getStats();
            }, 1000);
        }
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    return loading ? (
        <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
            <GridLoader color={theme.palette.primary.light} />
        </Box>
    ) : (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 1 }}>
            <Card>
                <CardHeader
                    title="iMagAI Dashboard"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    //subheader="Your Central Hub for Real-Time Insights and Actionable Data"
                    action={
                        <Button
                            startIcon={<Reply />}
                            //onClick={handleBack}
                            color="info"
                        >
                            Back
                        </Button>
                    }
                />
                <Divider />
                <CardContent sx={{ bgcolor: "#f9f9f9" }}>
                    <List
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 1,
                        }}
                    >
                        <ListItem
                            sx={{ position:"relative", width: "24%", minHeight: '150px',  bgcolor: "white", border: "1px solid #e0e0e0" }}
                            className={classes.gradientBody}
                            //secondaryAction={<Button startIcon={<ArrowCircleRight />} />}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: "black" }}>
                                    <Fingerprint color="secondary" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Identity and Authentication"
                                secondary="Applications, Platforms, Databases"
                                primaryTypographyProps={{ color: "white", variant: "subtitle" }}
                                secondaryTypographyProps={{ color: "white", fontSize: "0.8rem" }}
                            />
                            <BackgroundIcon component={Fingerprint} />
                        </ListItem>

                        <ListItem
                            sx={{ position:"relative", width: "24%", minHeight: '150px',  bgcolor: "white", border: "1px solid #e0e0e0" }}
                            className={classes.gradientBody}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: "black" }}>
                                    <Portrait color="secondary" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Request Access"
                                secondary="Applications, Platforms, Databases"
                                primaryTypographyProps={{ color: "white", variant: "subtitle" }}
                                secondaryTypographyProps={{ color: "white", fontSize: "0.8rem" }}
                            />
                            <BackgroundIcon component={Portrait} />
                        </ListItem>

                        <ListItem
                            sx={{ position:"relative", width: "24%", minHeight: '150px',  bgcolor: "white", border: "1px solid #e0e0e0" }}
                            className={classes.gradientBody}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: "black" }}>
                                    <ManageAccounts color="secondary" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Role Management"
                                secondary="Catalog and impact analysis"
                                primaryTypographyProps={{ color: "white", variant: "subtitle" }}
                                secondaryTypographyProps={{ color: "white", fontSize: "0.8rem" }}
                            />
                            <BackgroundIcon component={ManageAccounts} />
                        </ListItem>

                        <ListItem
                            sx={{ position:"relative", width: "24%", minHeight: '150px',  bgcolor: "white", border: "1px solid #e0e0e0" }}
                            className={classes.gradientBody}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: "black" }}>
                                    <VerifiedUser color="secondary" />
                                </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                                primary="Re-certification"
                                secondary="Reviews and compliance"
                                primaryTypographyProps={{ color: "white", variant: "subtitle" }}
                                secondaryTypographyProps={{ color: "white", fontSize: "0.8rem" }}
                            />
                            <BackgroundIcon component={VerifiedUser} />
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
            <AnnouncementSlider />
            <RequestTabs />
        </Box>
    );
}
