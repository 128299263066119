import { and, collection, deleteDoc, doc, getCountFromServer, getDoc, getDocs, limit, query, setDoc, where } from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import * as Constants from "../constants/Constants";
import { db } from "../config/FirebaseConfig";
import { personName, removeEmptyJsonValues, updateAudit } from "../utils/utils";
import { deleteRelation, findRelationForEntity, saveRelation } from "./RelationService";


// Get a person with their ID
export const findPersonByUserId = async (uid) => {
    const q = query(collection(db, DBConstants.DB_PEOPLE), where("uid", "==", uid), limit(1));
    const snapshot = await getDocs(q);
    let person = null;
    if (!snapshot.empty) {
        person = snapshot.docs[0];
    }
    return person ? person.data() : null;
};

// Get a person with their ID
export const findPersonByEmail = async (email) => {
    const q = query(collection(db, DBConstants.DB_PEOPLE), where("email", "==", email), limit(1));
    const snapshot = await getDocs(q);
    let person = null;
    if (!snapshot.empty) {
        person = snapshot.docs[0];
    }
    return person ? person.data() : null;
};

// Find a person with id
export const findPerson = async (id) => {
    const docRef = doc(db, DBConstants.DB_PEOPLE, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
};

export const findPeopleByIds = async (ids) => {
    const q = query(collection(db, DBConstants.DB_PEOPLE), where("id", "in", ids));

    const snapshot = await getDocs(q);
    const people = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));
    return people;
};

// Find a person with text matching partial name or email
export const findPersonByText = async (searchText, filter) => {
    const peopleLikeFirstName = await findPersonLikeFirstName(searchText, filter);
    const peopleLikeLastName = await findPersonLikeLastName(searchText, filter);
    const peopleLikeEmail = await findPersonLikeEmail(searchText, filter);
    const people = [...peopleLikeFirstName, ...peopleLikeLastName, ...peopleLikeEmail];

    var list = people.filter(function ({ id }) {
        return !this[id] && (this[id] = id);
    }, {});
    return list;
};

/* Since Firebase doesnt support complex queries, filter by property  */
const findPersonLikeFirstName = async (searchText, filter) => {
    const constraints = [];
    constraints.push(where("firstName", ">=", searchText), where("firstName", "<=", searchText + "\uf8ff"));
    return filterPeople(constraints, filter);
};

const findPersonLikeLastName = async (searchText, filter) => {
    const constraints = [];
    constraints.push(where("lastName", ">=", searchText), where("lastName", "<=", searchText + "\uf8ff"));
    return filterPeople(constraints, filter);
};

const findPersonLikeEmail = async (searchText, filter) => {
    const constraints = [];
    constraints.push(where("email", ">=", searchText), where("email", "<=", searchText + "\uf8ff"));
    return filterPeople(constraints, filter);
};

const filterPeople = async (constraints, filter) => {
    if (filter && filter.tenantId) {
        constraints.push(where("tenant.id", "==", filter.tenantId));
    }
    if (filter && filter.schoolId) {
        constraints.push(where("school.id", "==", filter.schoolId));
    }
    if (filter && filter.role) {
        constraints.push(where("role", "==", filter.role));
    }
    //console.log (constraints)
    const q = query(collection(db, DBConstants.DB_PEOPLE), and(...constraints), limit(5));

    const snapshot = await getDocs(q);
    const people = [];
    snapshot.forEach((p) => {
        people.push(p.data());
    });
    return people;
};

export const savePerson = async (uid, obj, toEntityId, role) => {
    console.log("Saving ", obj, " for ", toEntityId, " with role ", role);

    let name = "";
    // Find the person
    const p = await findPerson(uid);
    if (p && p.tenant) {
        name = p["tenant"].name;
        obj.tenant = p.tenant;
    }
    let docRef = null;
    let person = null;

    updateAudit(uid, obj);

    if (obj && obj.id) {
        docRef = doc(db, DBConstants.DB_PEOPLE, obj.id);
        //obj = removeEmptyJsonValues(obj);
        await setDoc(docRef, obj, { merge: true });
    } else {
        // Create a new document reference
        if (role) {
            obj.role = role;
        }
        var displayName = obj.firstName + " " + obj.lastName;

        // Save the new doc
        docRef = doc(collection(db, DBConstants.DB_PEOPLE));
        obj.id = docRef.id;
        // console.log ('saving person: ', obj)
        obj = removeEmptyJsonValues(obj);
        await setDoc(docRef, obj, { merge: true });

        // Create a new account
        // const functions = getFunctions();
        // const accountFunction = httpsCallable(functions, "createAccount");
        // const response = await accountFunction({id: docRef.id, email: obj.email, password: Constants.STATIC_PASSWORD, displayName: displayName})
    }

    // Save the relationship with the user
    if (toEntityId) {
        const rel = {
            from: obj.id,
            fromName: personName(obj),
            fromEntity: Constants.ENTITY_PERSON ,
            to: toEntityId,
            toName: name,
            toEntity: Constants.ENTITY_ORGANIZATION,
            relation: role,
        };
        await saveRelation(uid, rel);
    }

    person = await findPerson(obj.id);

    return person;
};

export const deletePerson = async (uid) => {
    const ref = doc(db, DBConstants.DB_PEOPLE, uid);
    await deleteDoc(ref);

    // Delete any associations
    const relations = await findRelationForEntity(uid);
    if (relations) {
        relations.map((r) => {
            deleteRelation(r.id);
        });
    }
};

export const countPeopleByTenantId = async (tenantId) => {
    const q = query(collection(db, DBConstants.DB_PEOPLE), where("tenant.id", "==", tenantId));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
};

export const findPeopleInTenantByRole = async (tenantId) => {
    const q = query(collection(db, DBConstants.DB_PEOPLE), where("tenant.id", "==", tenantId));
    const snapshot = await getDocs(q);
    const people = [];
    snapshot.forEach((p) => {
        people.push(p.data());
    });
    return people;
};


