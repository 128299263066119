import { Reply } from '@mui/icons-material'
import { Box, Button, Card, CardContent, CardHeader, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AssessmentWizard from './AssessmentWizard'
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { findLatestAssessmentByTenant } from '../../services/AssessmentService';
import { GridLoader } from 'react-spinners';
import { useTheme } from '@emotion/react';
import { AuditorTypes, IAMSolutionsTypes, IAMTeamSizeCodes, IndustryCodes, OrganizationSizeCodes, RegionCodes, YesNoCodes, getCodelist } from '../../constants/Codelist';
import { getDefaultCode, getIdName, removeJsonAttributes } from '../../utils/utils';

export default function AssessmentMain() {
    const navigate = useNavigate();
    const { person } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const theme = useTheme();


    // Initialize a base assessment form
    const [ assessment, setAssessment ] = useState({
        regions: [ getIdName(getDefaultCode(getCodelist(RegionCodes)))],
        otherRegion:null,
        industry: getIdName(getDefaultCode(getCodelist(IndustryCodes))),
        otherIndustry:null,
        organizationSize: getIdName(getDefaultCode(getCodelist(OrganizationSizeCodes))),
        iamTeamSize: getIdName(getDefaultCode(getCodelist(IAMTeamSizeCodes))),
        iamSolutionReasons: [],
        iamSolutionReasonsOther: null,
        iamCapabilities: [],
        iamCapabilitiesOther: null,
        haveProgramGovernance: getIdName(getDefaultCode(getCodelist(YesNoCodes))),
        dedicatedRiskTeam: getIdName(getDefaultCode(getCodelist(YesNoCodes))),
        haveIAMSolution: getIdName(getDefaultCode(getCodelist(YesNoCodes))),
        iamSolutionType: getIdName(getDefaultCode(getCodelist(IAMSolutionsTypes))),
        auditor: removeJsonAttributes(getDefaultCode(getCodelist(AuditorTypes)), ["avatar", "default", "order"]),
        governanceCategories: [],
        capabilityCategories: []

    });

    const [originalAssessment, setOriginalAssessment] = useState(null); 



    useEffect(() => {
        async function getAssessment() {
            setLoading(true);
            let result = await findLatestAssessmentByTenant(person?.tenant?.id);
            result = result? result : assessment
            setAssessment(result);
            setOriginalAssessment({ ...result });


            setLoading(false);
        }

        if(person)
            getAssessment();
        
            
    }, [person?.tenant?.id]);

    const handleBack = () => {
        navigate(-1);
    };


  return loading ? (
    <Box sx={{ display: "flex", flexDirection: "column", width: "70vw", height: "70vh", justifyContent: "center", alignItems: "center" }}>
        <GridLoader color={theme.palette.primary.light} />
    </Box>
) : (
    <Box width="100%">
        <AssessmentWizard assessment={assessment} updateAssessment={setAssessment} originalAssessment={originalAssessment}/>
    </Box>
)
}
