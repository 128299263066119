import { Alert, Box, Button, Card, CardContent, CardHeader, Divider, TextField } from "@mui/material";

import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Lock } from "@mui/icons-material";

import { useAuthContext } from "../../hooks/useAuthContext";
import { changePassword } from "../../services/UserService";
import { useLogout } from "../../hooks/useLogout";

export default function ProfilePasswordChange() {
    const navigate = useNavigate();
    const { logout } = useLogout();
    const { person } = useAuthContext();

    const [error, setError] = useState(null);
    const [info, setInfo] = useState(null);

    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: "",
    });
    const [errors, setErrors] = useState({
        password: "",
        confirmPassword: "",
    });

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        // Validate email field
        if (formData.password.trim() === "") {
            newErrors.password = "Password is required";
            isValid = false;
        } else if (formData.password.length < 6) {
            newErrors.password = "Password must be at least 6 characters";
            isValid = false;
        } else {
            newErrors.password = "";
        }

        if (formData.confirmPassword.trim() === "") {
            newErrors.confirmPassword = "Confirm Password is required";
            isValid = false;
        } else if (formData.confirmPassword.length < 6) {
            newErrors.confirmPassword = "Confirm Password must be at least 6 characters";
            isValid = false;
        } else {
            newErrors.confirmPassword = "";
        }

        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match";
            isValid = false;
        } else {
            newErrors.confirmPassword = "";
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                //console.log("Change the password for the user: ", pid);
                const response = await changePassword(person.uid, formData.password);
                console.log(response);
                if (response.data.success) {
                    // Redirect to Login page
                    setInfo("Password has been changed. You will be signed out...");
                    
                    setTimeout(() => {
                        setInfo("");
                        logout()
                        //navigate("/login");
                    }, 3000);
                }
            } catch (err) {
                console.error("Error verifying code:", err.code, err.message);
                setError("Error verifying code");
            }
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleCancel = (e) => {
        setFormData ({
            password: "",
            confirmPassword: "",
        })
    }

    return (
        <Box>
            <Card>
                <CardHeader 
                title="Change your password" 
                titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                subheader="You can change your password here for added security. Update your password regularly to ensure the safety of your account and protect your personal information."
                ></CardHeader>
                {/* <Divider /> */}
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            name="password"
                            label="New Password"
                            type="password"
                            required
                            fullWidth
                            variant="standard"
                            value={formData.password ?? ''}
                            onChange={handleChange}
                            error={!!errors.password}
                            helperText={errors.password}
                        />
                        <TextField
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            required
                            fullWidth
                            variant="standard"
                            value={formData.confirmPassword ?? ''}
                            onChange={handleChange}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                        />

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                gap: 1,
                                marginTop: 4
                            }}
                        >
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button variant="contained" type="submit">
                                Change Password
                            </Button>
                        </Box>
                        
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                marginTop: 2,
                            }}
                        >
                            {error && <Alert severity="error">{error}</Alert>}
                            {info && <Alert severity="info">{info}</Alert>}
                        </Box>
                    </form>
                </CardContent>
            </Card>
        </Box>
    );
}
