import React from "react";

import PaymentMethodsImage from "../../assets/images/payment-methods.png";
import { Box, Button, Card, CardContent, CardHeader, Divider } from "@mui/material";
import { Reply } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function PaymentMethods() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Card elevation={1}>
                <CardHeader
                    title="Payment Methods"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    action={
                        <Button startIcon={<Reply />} onClick={handleBack} color="info">
                            Back
                        </Button>
                    }
                />
                <Divider />
                <CardContent>
                 <img src={PaymentMethodsImage} alt="Payment Methods" width="100%" height="100%" />

                </CardContent>
            </Card>
        </Box>
    );
}
