import { Apps, Dvr, FactCheck, PendingActions } from "@mui/icons-material";
import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React from "react";
import RequestSearch from "./RequestSearch";
import RequestList from "./RequestList";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    gradientBody: {
        background: `linear-gradient(to right bottom, black, ${theme.palette.primary.light})`,
        color: theme.palette.primary.contrastText, // Set text color to contrast text
    },
    backgroundImage: {
        position: "absolute",
        right: "0px",
        bottom: 10,
        fontSize: 100,
        opacity: 0.2, 
    },
}));

export default function  MyRequestList () {
    const classes = useStyles();
    
    return <Box display="flex" flexDirection="column">
        <List
            sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 1,
            }}
        >

            <ListItem sx={{ width: "32%", border: "1px solid", borderColor:"primary.main"}} >
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "secondary.main" }}>
                        <Dvr color="primary" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary="2"
                    secondary="Open Requests"
                    primaryTypographyProps={{ variant: "subtitle"}}
                    secondaryTypographyProps={{ variant: "subtitle1"  }} />
            </ListItem>


            <ListItem sx={{ width: "32%", border: "1px solid", borderColor:"primary.main"}} >
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "secondary.main" }}>
                        <PendingActions color="primary" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary="3"
                    secondary="Pending Requests"
                    primaryTypographyProps={{ variant: "subtitle"}}
                    secondaryTypographyProps={{ variant: "subtitle1"  }} />
            </ListItem>

            <ListItem sx={{ width: "32%", border: "1px solid", borderColor:"primary.main"}} >
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "secondary.main" }}>
                        <FactCheck color="primary" />
                    </Avatar>
                </ListItemAvatar>

                <ListItemText
                    primary="1"
                    secondary="Resolved"
                    primaryTypographyProps={{ variant: "subtitle"}}
                    secondaryTypographyProps={{ variant: "subtitle1"  }} />
            </ListItem>
        </List>
        <Divider sx={{ my: 2 }} />
        <RequestSearch />
        <Box sx={{ my: 2 }} />
        <RequestList />
    </Box>;
}
