import { Reply } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { findAllOrganizations } from "../../services/OrganizationService";
import TenantTable from "./TenantTable";

export default function TenantList() {
    const navigate = useNavigate();
    const [tenants, setTenants] = useState([]);
    const { state } = useLocation();
    const { refresh } = { ...state };


    useEffect(() => {
        async function getTenants() {
            const results = await findAllOrganizations();
            setTenants(results);
        }

        getTenants();
    }, [refresh]);

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Card>
                <CardHeader
                    title="Tenants"
                    titleTypographyProps={{ color: "primary.main", variant: "headline" }}
                    subheader="View your tenants or start a new one"
                    action={
                        <Button startIcon={<Reply />} onClick={handleBack} color="info">
                            Back
                        </Button>
                    }
                />
                <Divider />
                {tenants && <CardContent>{tenants && <TenantTable tenants={tenants} title="Tenants" />}</CardContent>}
            </Card>
        </Box>
    );
}
