import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import ContactEditForm from "./ContactEditForm";

export default function PersonEditForm({ formData, errors, handleChange, includeAddress = true, includePhone = true, includeEmail = true, includeWebsite=false }) {
    return (
        <Box display="flex" flexDirection="column">
            <TextField
                name="firstName"
                label="First Name"
                required
                fullWidth
                variant="standard"
                value={formData.firstName ?? ""}
                onChange={handleChange}
                error={!!errors.firstName}
                helperText={errors.firstName}
            />
            <TextField
                name="lastName"
                label="Last Name"
                required
                fullWidth
                variant="standard"
                value={formData.lastName ?? ""}
                onChange={handleChange}
                error={!!errors.lastName}
                helperText={errors.lastName}
            />
            <ContactEditForm
                formData={formData}
                errors={errors}
                handleChange={handleChange}
                includeAddress={includeAddress}
                includePhone={includePhone}
                includeEmail={includeEmail}
            />
        </Box>
    );
}
