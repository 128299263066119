import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import * as Constants from "../constants/Constants";
import * as Authority from "../constants/Authorities";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { personName, updateAudit } from "../utils/utils";
import { deletePerson, findPerson, savePerson } from "./PersonService";
import {
  deleteRelation,
  findRelationForEntity,
  saveRelation,
} from "./RelationService";
import { deleteProfile } from "./ProfileService";

// Get all organizations
export const findAllOrganizations = async () => {
  let results = [];
  const q = query(
    collection(db, DBConstants.DB_TENANTS)
    //where("createdBy", "==", uid)
  );
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
    results.push({ ...doc.data(), id: doc.id });
  });
  return results;
};

// Find a organization with id
export const findOrganization = async (id) => {
  const docRef = doc(db, DBConstants.DB_TENANTS, id);
  const docSnap = await getDoc(docRef);
  const data = docSnap.exists() ? docSnap.data() : null;
  return data;
};

// Save a Organization
export const saveOrganization = async (uid, organization) => {
  let docRef = null;

  // Find the related person
  const person = await findPerson(uid);

  if (organization && organization.id) {
    docRef = doc(db, DBConstants.DB_TENANTS, organization.id);
  } else {
    // Save the person as the primary contact
    organization.primaryContact = {
      id: person.id,
      name: personName(person),
    };
    // Create a new document reference
    docRef = await addDoc(collection(db, DBConstants.DB_TENANTS), organization);
    organization.id = docRef.id;
  }

  updateAudit(uid, organization);
  setDoc(docRef, organization, { merge: true });

  // Save the relationship with the user
  const rel = {
    from: person.id,
    fromName: personName(person),
    fromEntity: Constants.ENTITY_PERSON,
    to: organization.id,
    toName: organization.name,
    toEntity: Constants.ENTITY_ORGANIZATION,
    relation: Authority.ROLE_TENANT_ADMIN,
  };
  await saveRelation(uid, rel);

  // Save the organization Id on the person object
  const t = {
    id: organization.id,
    name: organization.name,
  };
  person.tenant = t;
  await savePerson(uid, person);

  organization = await findOrganization(organization.id);
  return organization;
};

// Delete a organization
export const deleteOrganization = async (uid, id) => {
  const organization = await findOrganization(id);
  // If the user is the primary contact for the organization, then set the reference to null
  if (organization.primaryContact.id === uid) {
    const person = await findPerson(uid);
    person.organization = null;
    await savePerson(uid, person);
  }

  // Delete the organization itself
  const ref = doc(db, DBConstants.DB_TENANTS, id);
  await deleteDoc(ref);

  // Delete all people created by this organization
  deleteAllPeopleByOrganizationId(id);

  // Delete any associations
  const relations = await findRelationForEntity(id);
  if (relations) {
    relations.map((r) => {
      deleteRelation(r.id);
    });
  }


};

// Delete all people created by a organization
export const deleteAllPeopleByOrganizationId = async (organizationId) => {
  const q = query(
    collection(db, DBConstants.DB_PEOPLE),
    where("tenant.id", "==", organizationId)
  );
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
    deleteProfile(doc.id)
    return deletePerson(doc.id);
  });
};
