import * as Authority from "./Authorities";

export const PROFILE_ROLES = [
  Authority.ROLE_TENANT_ADMIN,
  Authority.ROLE_AUDITOR,
  Authority.ROLE_USER,

];

export const ROLE_PERMISSIONS = {
  [Authority.ROLE_SUPERADMIN]: [
    Authority.PERMISSION_CAN_MOCK,
    Authority.PERMISSION_DASHBOARD_CAN_VIEW,
    Authority.PERMISSION_TENANT_ALL,
    Authority.PERMISSION_TENANT_CAN_VIEW,
    Authority.PERMISSION_TENANT_CAN_UPDATE,
    Authority.PERMISSION_TENANT_CAN_UPDATE,
    Authority.PERMISSION_TENANT_CAN_DELETE,
  ],
  [Authority.ROLE_TENANT_ADMIN]: [
    Authority.PERMISSION_CAN_MOCK,
    Authority.PERMISSION_ADMIN_ALL,
    Authority.PERMISSION_DASHBOARD_CAN_VIEW,

    Authority.PERMISSION_PERSON_ALL,
    Authority.PERMISSION_PERSON_CAN_CREATE,
    Authority.PERMISSION_PERSON_CAN_VIEW,
    Authority.PERMISSION_PERSON_CAN_UPDATE,
    Authority.PERMISSION_PERSON_CAN_DELETE,
    
    Authority.PERMISSION_PROFILE_ALL,
    Authority.PERMISSION_PROFILE_CAN_CREATE,
    Authority.PERMISSION_PROFILE_CAN_VIEW,
    Authority.PERMISSION_PROFILE_CAN_UPDATE,
    Authority.PERMISSION_PROFILE_CAN_DELETE,

    Authority.PERMISSION_TENANT_MY,
    Authority.PERMISSION_TENANT_CAN_VIEW,
    Authority.PERMISSION_TENANT_CAN_UPDATE,
    Authority.PERMISSION_TENANT_CAN_UPDATE,
    Authority.PERMISSION_TENANT_CAN_DELETE,

    Authority.PERMISSION_ASSESSMENT_ALL,
    Authority.PERMISSION_ASSESSMENT_CAN_CREATE,
    Authority.PERMISSION_ASSESSMENT_CAN_VIEW,
    Authority.PERMISSION_ASSESSMENT_CAN_UPDATE,
    Authority.PERMISSION_ASSESSMENT_CAN_DELETE,

    Authority.PERMISSION_CONTROL_ALL,
    Authority.PERMISSION_CONTROL_CAN_CREATE,
    Authority.PERMISSION_CONTROL_CAN_VIEW,
    Authority.PERMISSION_CONTROL_CAN_UPDATE,
    Authority.PERMISSION_CONTROL_CAN_DELETE,

    Authority.PERMISSION_POLICY_ALL,
    Authority.PERMISSION_POLICY_CAN_CREATE,
    Authority.PERMISSION_POLICY_CAN_VIEW,
    Authority.PERMISSION_POLICY_CAN_UPDATE,
    Authority.PERMISSION_POLICY_CAN_DELETE,

    Authority.PERMISSION_EVIDENCE_ALL,
    Authority.PERMISSION_EVIDENCE_CAN_CREATE,
    Authority.PERMISSION_EVIDENCE_CAN_VIEW,
    Authority.PERMISSION_EVIDENCE_CAN_UPDATE,
    Authority.PERMISSION_EVIDENCE_CAN_DELETE,

    Authority.PERMISSION_AUDIT_ALL,
    Authority.PERMISSION_AUDIT_CAN_CREATE,
    Authority.PERMISSION_AUDIT_CAN_VIEW,
    Authority.PERMISSION_AUDIT_CAN_UPDATE,
    Authority.PERMISSION_AUDIT_CAN_DELETE,

    Authority.PERMISSION_NOTIFICATION_ALL,
    Authority.PERMISSION_NOTIFICATION_CAN_CREATE,
    Authority.PERMISSION_NOTIFICATION_CAN_VIEW,
    Authority.PERMISSION_NOTIFICATION_CAN_UPDATE,
    Authority.PERMISSION_NOTIFICATION_CAN_DELETE,

    Authority.PERMISSION_REPORT_ALL,
    Authority.PERMISSION_REPORT_CAN_CREATE,
    Authority.PERMISSION_REPORT_CAN_VIEW,
    Authority.PERMISSION_REPORT_CAN_UPDATE,
    Authority.PERMISSION_REPORT_CAN_DELETE,
    
    Authority.PERMISSION_SETTINGS_ALL,
    Authority.PERMISSION_SETTINGS_CAN_CREATE,
    Authority.PERMISSION_SETTINGS_CAN_VIEW,
    Authority.PERMISSION_SETTINGS_CAN_UPDATE,
    Authority.PERMISSION_SETTINGS_CAN_DELETE,


    
  ],
  [Authority.ROLE_AUDITOR]: [
    Authority.PERMISSION_CAN_MOCK,
    Authority.PERMISSION_DASHBOARD_CAN_VIEW,
    Authority.PERMISSION_PERSON_CAN_VIEW,
    Authority.PERMISSION_TENANT_CAN_VIEW,
    Authority.PERMISSION_ASSESSMENT_CAN_CREATE,
    Authority.PERMISSION_ASSESSMENT_CAN_VIEW,
    Authority.PERMISSION_ASSESSMENT_CAN_UPDATE,
    Authority.PERMISSION_ASSESSMENT_CAN_DELETE,
  ]
};
