import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import AppThemeProvider from "./context/AppThemeContext";
import NotFound from "./components/NotFound";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ForgotPassword from "./pages/auth/ForgotPassword";
import { useAuthContext } from "./hooks/useAuthContext";
import ProfileCheck from "./pages/profile/ProfileCheck";
import InvitationView from "./pages/auth/InvitationView";

export default function App() {
    const { user, person, profile, authIsReady } = useAuthContext();

    return (
        <AppThemeProvider>
            {authIsReady && (
            <Router>
                <Routes>
                {/* Unauthenticated Paths  */}
                    <Route exact path="/404" element={<NotFound />} />
                    <Route exact path="/signin" element={<SignIn />} />
                    <Route exact path="/signup" element={<SignUp />} />
                    <Route exact path="/forgot-password" element={<ForgotPassword />} />
                    <Route exact path="/invitations/:id" element={<InvitationView />} />

                    {/* Authenticated Paths  */}
                    { (profile && !profile.complete) && <Route path="/*" element={<ProfileCheck />}></Route>}
                    

                    {user && person && profile && <Route path="/*" element={<Home />} />}
                    {!user && (
                        <Route exact path="/*" element={ <Navigate to="/signin" state={ { from: window.location.pathname }} />}></Route>
            )}
                    
                </Routes>
            </Router>
            )}
        </AppThemeProvider>
    );
}
