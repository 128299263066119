import { collection, deleteDoc, doc, getCountFromServer, getDoc, getDocs, limit, orderBy, query, setDoc, where } from "firebase/firestore";
import * as DBConstants from "../constants/DBConstants";
import { db } from "../config/FirebaseConfig";
import { generateVerificationCode, personName, updateAudit } from "../utils/utils";
import { findOrganization } from "./OrganizationService";
import { addDays, endOfDay } from "date-fns";
import { NOTIFICATION_EVENTS } from "../constants/EventTypes";

// Get all audits
export const findAllAudits = async (uid) => {
    let results = [];
    const q = query(collection(db, DBConstants.DB_INVITATIONS), where("createdBy", "==", uid));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

export const findAuditsByTenantId = async (tenantId) => {
    let results = [];
    const q = query(collection(db, DBConstants.DB_INVITATIONS), where("tenant.id", "==", tenantId));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};

export const countAuditsByTenantId = async (tenantId) => {
    const q = query(collection(db, DBConstants.DB_INVITATIONS), where("tenant.id", "==", tenantId));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
};

// Find a audit with id
export const findAudit = async (id) => {
    const docRef = doc(db, DBConstants.DB_INVITATIONS, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    return data;
};

export const findAuditByIds = async (auditIds) => {
    let results = [];
    const q = query(collection(db, DBConstants.DB_INVITATIONS), where("id", "in", auditIds), orderBy("createdDate", "desc"));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
    });
    return results;
};


// Save a Audit
export const saveAuditRaw = async (id, audit) => {
    let docRef
    if(audit.id) {
      docRef = doc(collection(db, DBConstants.DB_INVITATIONS), audit.id);
    }else {
      docRef = doc(collection(db, DBConstants.DB_INVITATIONS));
      audit.id = docRef.id;
    }
  
    updateAudit(id, audit);
    await setDoc(docRef, audit, { merge: true });
    return await findAudit(audit.id);
  
  };


export const saveAudit = async (uid, tenantId, audit) => {
    console.log ('Saving audit: ', audit)
    let docRef = null;
    const tenant = await findOrganization(tenantId);

    if (audit && audit.id) {
        docRef = doc(db, DBConstants.DB_INVITATIONS, audit.id);
    } else {
        // Save the new doc
        docRef = doc(collection(db, DBConstants.DB_INVITATIONS));
        audit.id = docRef.id;
        await setDoc(docRef, audit, { merge: true });
    }

    updateAudit(uid, audit);
    await setDoc(docRef, audit, { merge: true });

    audit = await findAudit(audit.id);
    return audit;
};

// Delete a audit
export const deleteAudit = async (id) => {
    const ref = doc(db, DBConstants.DB_INVITATIONS, id);
    await deleteDoc(ref);
};

