import { findPerson, findPersonByEmail, findPersonByUserId, savePerson } from "./PersonService";
import { findProfile, findProfileByUserId, saveProfile } from "./ProfileService";
import { FORGOT_PASSWORD_EMAIL_TEMPLATE_ID } from "../constants/EmailTemplates";
import { generateVerificationCode, personName } from "../utils/utils";
import { EVENT_PASSWORD_RESET } from "../constants/EventTypes";
import { addDays, endOfDay } from "date-fns";
import { saveInvitationRaw } from "./InvitationService";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FUNCTION_UPDATE_USER } from "../constants/CloudFunctions";
import { auth } from "../config/FirebaseConfig";
import { findCart, findCartByUserId, saveCart } from "./CartService";

const findOrCreatePerson = async (uid, email, firstName, lastName, personId) => {
    let person = personId ? await findPerson(personId) : await findPersonByUserId(uid);

    if (person) {
        //console.log("Found person: ", person);
        if (!person.uid) {
            person.uid = uid;
            person = await savePerson(uid, person);
        }
    } else {
        //console.log("No user found for: ", uid, email, firstName, lastName);
        person = await savePerson(uid, {
            id: uid,
            uid: uid,
            email: email,
            firstName: firstName,
            lastName: lastName,
        });
    }
    return person;
};

const findOrCreateProfile = async (uid, personId) => {
    let profile = personId ? await findProfile(personId) : await findProfileByUserId(uid);

    if (profile) {
        //console.log("Found profile: ", profile);
        if (!profile.uid) {
            //console.log("Saving profile: ", profile);
            profile = await saveProfile(uid, {
                id: profile.id,
                uid: uid,
                complete:false
            });
        }
    } else {
        //("No profile found for: ", uid);
        profile = await saveProfile(uid, {
            id: personId || uid,
            uid: uid,
            complete: false,
            firstTimeLogin: true
        });
    }

    return profile;
};

export const loadUserDetail = async (uid, email="", firstName="", lastName="", personId=null) => {
    // console.log ('Load user detail: ', uid, email, firstName, lastName, personId)

    const person = await findOrCreatePerson(uid, email, firstName, lastName, personId);
    const profile = await findOrCreateProfile(uid, personId);

    const detail = {
        person: person,
        profile: profile,
    };
    return detail;
};

// This function returns a list of users in firestore database
export const findAllUsers = async () => {
    // const userRecords = await auth.listUsers()  
    // console.log ('Users = ', userRecords)
    // return userRecords
    return []
    
}


export const initiatePasswordReset = async (email) => {
    const person = await findPersonByEmail(email);
    if (person) {
        //console.log(person);
        try {
                // Save a notification document. Cloud function will pick it up.
                const verificationCode = generateVerificationCode();
                const notification = {
                    to: person.email,
                    templateId: FORGOT_PASSWORD_EMAIL_TEMPLATE_ID,
                    subject: `Reset your account password with ${process.env.REACT_APP_TITLE}`,
                    appName: process.env.REACT_APP_TITLE,
                    person: {
                        id: person.id, 
                        firstName: person.firstName,
                        lastName: person.lastName,
                        displayName: personName(person)
                      },
                    verificationCode: verificationCode,
                    notificationType: EVENT_PASSWORD_RESET,
                    complete: false,
                    expirationDate: endOfDay(addDays(new Date(), 1)),
                    verifyPasswordUrl: `${window.location.origin}/verify-password?pid=${person.id}`,
                }
                //console.log (notification)
                await saveInvitationRaw(notification )
        } catch (err) {
            console.error("Error checking user existence: ", err);
        }
    }
    return person
}


export const changePassword = async (uid, password) => {
    //console.log ('Change password: ', uid, '/ ', password)
    const functions = getFunctions();
    const updateUser = httpsCallable(functions, FUNCTION_UPDATE_USER);

    const response = await updateUser({
        uid: uid,
        params: { password: password}
      });
    return response
}




